import { FC, useState, useEffect, useCallback } from "react";
import { FieldValues } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import PauseCircleOutlineIcon from "@mui/icons-material/PauseCircleOutline";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import { Box, Button, Tab } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import { useQueryClient } from "@tanstack/react-query";
import slug from "slug";
import { PAGE_SNACKBAR } from "../../../../constants";
import { useAppContext } from "../../../../context/AppContext";
import {
  Automation as AutomationData,
  CreateAutomationMutation,
  AutomationMode,
  UpdateAutomationMutation,
  ArchiveAutomationMutation,
} from "../../../../graphql/operations";
import { ConfirmationDialog } from "../../../../shared/components/ConfirmationDialog/ConfirmationDialog";
import { LeaveModal } from "../../../../shared/components/LeaveModal";
import { ChangeNameDialog } from "../../../../shared/components/NameWithBackButton";
import { SwitchButtons } from "../../../../shared/components/SwitchButtons";
import Tabs from "../../../../shared/components/Tabs";
import { TabsVariant } from "../../../../shared/components/Tabs/Tabs";
import { ToggleButtonOption } from "../../../../shared/components/ToggleButtons";
import useBreakpoint from "../../../../shared/hooks/useBreakpoint";
import { usePrompt } from "../../../../shared/hooks/usePrompt";
import { useSpinner } from "../../../../shared/hooks/useSpinner";
import { NavigationRoutes } from "../../../../utils/routes/routesUtils";
import { AutomationSettings } from "./AutomationSettings";
import { AutomationTasks } from "./AutomationTasks";
import {
  AutomationInitialValues,
  useAutomationForm,
} from "./hooks/useAutomationForm";
import { useAutomationsApi } from "./hooks/useAutomationsApi";
import {
  archiveAutomationOnErrorCallback,
  createAutomationOnErrorCallback,
  mapAutomationActions,
  onSuccessArchiveCallback,
  onSuccessCreateCallback,
  onSuccessUpdateCallback,
  updateAutomationOnErrorCallback,
} from "./utils/utils";

interface AutomationProps {
  isTemplate: boolean;
  automation?: AutomationData | null;
}

const tabs = [
  {
    label: "Tasks",
    value: 0,
  },
  {
    label: "Settings",
    value: 1,
  },
];

export const Automation: FC<AutomationProps> = ({
  isTemplate,
  automation: automationProp,
}) => {
  const {
    state: {
      theme: { theme },
      selectedOrganization: { selectedOrganization },
    },
    dispatch,
  } = useAppContext();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [loading, setLoading] = useState(false);
  const [shouldCreateAutomation, setShouldCreateAutomation] = useState(false);

  const isDarkMode = theme === "dark";
  const color = isDarkMode ? "white" : "black";

  const [automation, setAutomation] = useState<
    AutomationData | null | undefined
  >(automationProp);

  const [automationMode, setAutomationMode] = useState<AutomationMode>(
    automationProp ? automationProp.mode : AutomationMode.Pause
  );

  useEffect(() => {
    if (automation?.mode) {
      setAutomationMode(automation.mode);
    }
  }, [automation?.mode]);

  const name = automation?.name ?? "Automation";
  const [automationName, setAutomationName] = useState(name);

  const { form } = useAutomationForm(automation as AutomationInitialValues);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState<boolean>(false);

  const [selectedAutomationTab, setSelectedAutomationTab] = useState(0);

  const shouldScroll = useBreakpoint("down", "xl");

  const openDeleteDialog = () => {
    setIsDeleteDialogOpen(true);
  };

  const createAutomationOnSuccess = async (data: CreateAutomationMutation) => {
    await onSuccessCreateCallback({
      dispatch,
      created: data.createAutomation,
      navigate,
      queryClient,
      setAutomation,
      setLoading,
      setShouldCreateAutomation,
    });

    form.reset();
    setShouldPrompt(false);
  };
  const updateAutomationOnSuccess = async (data: UpdateAutomationMutation) => {
    await onSuccessUpdateCallback({
      dispatch,
      updated: data.updateAutomation,
      queryClient,
      setAutomation,
      setLoading,
    });

    form.reset({}, { keepValues: true, keepDirty: false });
    setShouldPrompt(false);
  };
  const archiveAutomationOnSuccess = async (
    data: ArchiveAutomationMutation
  ) => {
    await onSuccessArchiveCallback({
      dispatch,
      navigate,
      queryClient,
      setLoading,
    });

    form.reset();
    setIsConfirmed(true);
    setIsLeaveModalOpen(false);
  };

  const createAutomationOnError = (error: unknown) => {
    createAutomationOnErrorCallback({ error, dispatch, setLoading });
  };
  const updateAutomationOnError = (error: unknown) => {
    updateAutomationOnErrorCallback({ error, dispatch, setLoading });
  };
  const archiveAutomationOnError = (error: unknown) => {
    archiveAutomationOnErrorCallback({ error, dispatch, setLoading });
  };

  const {
    createAutomation,
    updateAutomation,
    archiveAutomation,
    isAutomationUpdate,
  } = useAutomationsApi({
    createAutomationOnSuccess,
    createAutomationOnError,
    updateAutomationOnSuccess,
    updateAutomationOnError,
    archiveAutomationOnSuccess,
    archiveAutomationOnError,
  });

  const handleFormSubmit = async (data: FieldValues) => {
    const valid = await form.trigger();
    if (!valid) {
      return;
    }

    if (!data.actions?.length) {
      dispatch({
        type: PAGE_SNACKBAR,
        payload: {
          severity: "error",
          title: "Error!",
          text: "The Actions are required for Automation!",
        },
      });
      return;
    }

    if (isTemplate) {
      setShouldCreateAutomation(true);
      const actions = data.actions.map(mapAutomationActions);
      createAutomation({
        actions: actions,
        mode: automationMode,
        name: automationName,
        orgId: selectedOrganization.value,
        parameters: data.parameters,
        trigger: data.trigger,
      });
    } else {
      updateAutomation({
        _id: automation?._id,
        actions: data.actions.map(mapAutomationActions),
        mode: automationMode,
        name: automationName,
        trigger: data.trigger,
      });
    }
    setLoading(true);
  };

  const handleSubmitClick = () => {
    form.handleSubmit((data) => handleFormSubmit(data))();
  };

  const handleAutomationStatusChange = async (value: AutomationMode) => {
    if (value) {
      const formValues = form.getValues();
      setAutomationMode(value);
      if (automation?._id) {
        setLoading(true);
        updateAutomation({
          _id: automation?._id,
          actions: formValues.actions?.map(mapAutomationActions),
          mode: value,
          name: automationName,
          trigger: formValues.trigger,
        });
        setLoading(false);
      }
    }
  };

  const confirmDeleteAutomation = () => {
    if (automation) {
      archiveAutomation(automation._id);
      setIsDeleteDialogOpen(false);
      setLoading(true);
    }
  };

  const cancelDeleteAutomation = () => {
    setIsDeleteDialogOpen(false);
  };

  const AUTOMATION_STATUS_SWITCH_OPTIONS: ToggleButtonOption[] = [
    {
      value: "pause",
      label: "Pause",
      icon: <PauseCircleOutlineIcon />,
    },
    {
      value: "run",
      label: "Run",
      icon: <TrendingUpIcon />,
    },
  ];

  const handleCloseEditNameDialog = () => {
    setIsDialogOpen(false);
  };
  const handleSubmitEditNameDialog = (name: string) => {
    setAutomationName(name);

    // prevent loosing the name in case of failed update request
    if (automation) setAutomation({ ...automation, name });

    handleSubmitClick();
    setIsDialogOpen(false);
  };

  const onBackBtnClick = () => navigate(NavigationRoutes.AdminPanelAutomations);

  const [shouldPrompt, setShouldPrompt] = useState(false);
  const { isLeaveModalOpen, setIsLeaveModalOpen, setIsConfirmed } =
    usePrompt(shouldPrompt);
  const handleLeaveModalClose = () => {
    setIsLeaveModalOpen(false);
    setIsConfirmed(false);
  };
  const dirtyFields = Object.keys(form.formState.dirtyFields);
  const values = form.getValues();
  const actionsCountChanged =
    values.actions?.length !== automation?.actions?.length;
  const formValues = form.watch();

  const updatePromptState = useCallback(() => {
    const condition =
      (!!dirtyFields.length ||
        automation?.name !== automationName ||
        actionsCountChanged) &&
      !shouldCreateAutomation;
    if (condition) {
      setShouldPrompt(true);
    } else {
      setShouldPrompt(false);
    }
    return condition;
  }, [
    dirtyFields,
    automation?.name,
    automationName,
    shouldCreateAutomation,
    actionsCountChanged,
  ]);

  useEffect(() => {
    updatePromptState();
  }, [
    automationName,
    automation?.name,
    formValues,
    dirtyFields,
    shouldCreateAutomation,
    updatePromptState,
  ]);

  useSpinner(loading);

  return (
    <Box data-testid="automation" className="pb-8">
      <div className="flex flex-col px-16 pt-8 pb-4">
        <Box className="mb-8">
          <Button
            className="!p-0 !font-bold !capitalize !text-brand"
            onClick={onBackBtnClick}
          >
            <ArrowBackIcon className="mr-3" />
            Back
          </Button>
        </Box>

        <div className="flex justify-between items-center flex-col sm:flex-row sm:items-baseline">
          <Box className="flex justify-between">
            <h2
              className="group min-h-[3rem] text-2xl font-semibold leading-10 text-header-text"
              data-testid={`alert-${slug(name)}`}
            >
              {name}
              <IconButton
                color="inherit"
                className="!hidden group-hover:!inline-flex"
                data-testid="editNameBtn"
                aria-label="editName"
                onClick={() => setIsDialogOpen(true)}
              >
                <ModeEditIcon />
              </IconButton>
            </h2>
          </Box>

          <SwitchButtons
            id="automation-status-switch"
            data-testid="automation-status-switch-mobile"
            value={automationMode}
            onChange={(_, value) => handleAutomationStatusChange(value)}
            options={AUTOMATION_STATUS_SWITCH_OPTIONS}
            size="small"
            groupclass={`!w-[300px] min-w-80 flex justify-between h-8 overflow-hidden !rounded-3xl p-0.5 !border ${
              isDarkMode ? "!border-white" : "!border-black"
            }`}
            className={`!text-${color} !font-medium`}
            fullWidth
            exclusive
            disabled={isAutomationUpdate}
          />
        </div>
      </div>

      {!isTemplate && (
        <Tabs
          tabs={tabs}
          centered={!shouldScroll}
          variant={shouldScroll ? TabsVariant.Scroll : TabsVariant.Standard}
          controlled={true}
          scrollButtons={shouldScroll}
          allowScrollButtonsMobile={shouldScroll}
          value={selectedAutomationTab}
          onClick={(value) => setSelectedAutomationTab(value)}
          showTabContent={false}
          tabClasses={{ "mb-8 flex justify-center border-x-0": true }}
        >
          {(activeTab) => (
            <Tab
              label={tabs[activeTab].label}
              value={tabs[activeTab].value}
              key={tabs[activeTab].label}
            />
          )}
        </Tabs>
      )}
      {selectedAutomationTab === 0 && !isTemplate && (
        <AutomationTasks automationId={automation?._id} />
      )}

      {(selectedAutomationTab === 1 || isTemplate) && (
        <AutomationSettings
          form={form}
          isTemplate={isTemplate}
          onSave={handleSubmitClick}
          onDelete={openDeleteDialog}
        />
      )}

      <ChangeNameDialog
        name={automation?.name ?? automationName}
        nameType={"automation"}
        open={isDialogOpen}
        setName={setAutomationName}
        onClose={handleCloseEditNameDialog}
        onSubmit={handleSubmitEditNameDialog}
      />
      {isDeleteDialogOpen && (
        <ConfirmationDialog
          title="You are about to delete an Automation"
          message="Are you sure?"
          open={isDeleteDialogOpen}
          confirmButtonText="Delete Automation"
          cancelButtonText="Cancel"
          handleConfirmationResult={(isConfirmed) => {
            if (isConfirmed) {
              confirmDeleteAutomation();
            } else {
              cancelDeleteAutomation();
            }
          }}
        />
      )}
      <LeaveModal
        isOpen={isLeaveModalOpen}
        confirmNavigation={() => setIsConfirmed(true)}
        onClose={handleLeaveModalClose}
      />
    </Box>
  );
};
