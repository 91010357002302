import { useCallback, useEffect, useState } from "react";
import {
  Autocomplete,
  Box,
  Divider,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import debounce from "lodash/debounce";
import { TIME_ZONE_OPTIONS } from "../../../../../../constants/account";
import { DateTextField } from "../../../../../../shared/components/DateTextField";
import {
  AM_PM_OPTIONS,
  HOUR_OPTIONS,
} from "../../../../../../shared/components/FrequencySelect/constants";
import { addDaysToDate, checkIsValidDate } from "../../../../../../utils";
import { useAssetsDataContext } from "../../../../shared/AssetsDataContext";
import {
  convertTo12HoursFormat,
  convertTo24HoursFormat,
  optionise,
} from "../../../../shared/AssetsFilterControls/Filters/GeofenceMoreFiltersTab/helpers";
import { InstalledDateFilterOptions } from "../../../../shared/AssetsFilterControls/Filters/MoreFilters";
import { convertToIsoStringIgnoringTimezoneOffset } from "../../../Assets/TiresTabPanel/helpers";

export const MoreGeofenceFilters = () => {
  const {
    currentGeofenceFilter: {
      timezones,
      createdRange,
      updatedRange,
      operatingHoursRange,
      locationCodes,
    },
    onChangeGeofenceFilters,
  } = useAssetsDataContext();
  const [locationCode, setLocationCode] = useState<string | null>("");

  let moreFiltersCount = 0;
  moreFiltersCount += timezones?.length ? 1 : 0;
  moreFiltersCount += createdRange?.startDate || createdRange?.endDate ? 1 : 0;
  moreFiltersCount += updatedRange?.startDate || updatedRange?.endDate ? 1 : 0;
  moreFiltersCount +=
    operatingHoursRange?.timeFrom || operatingHoursRange?.timeTo ? 1 : 0;
  moreFiltersCount += locationCodes?.length ? 1 : 0;

  const { time: startTime, format: startTimeFormat } = convertTo12HoursFormat(
    operatingHoursRange?.timeFrom
  );
  const { time: endTime, format: endTimeFormat } = convertTo12HoursFormat(
    operatingHoursRange?.timeTo
  );

  const timezonesOptions = TIME_ZONE_OPTIONS.map((timezone) => ({
    id: timezone.value,
    label: timezone.label,
  }));

  const handleLocationCodeChange = useCallback(
    (value: string) => {
      const debouncedSetLocationCode = debounce((value: string) => {
        if (!value) {
          onChangeGeofenceFilters({ locationCodes: [] });
          return;
        }
        onChangeGeofenceFilters({ locationCodes: [value] });
      }, 500);

      setLocationCode(value);
      debouncedSetLocationCode(value);
    },
    [setLocationCode, onChangeGeofenceFilters]
  );

  // Sync local value of locationCode with debounced value in geofence filters
  useEffect(() => {
    if (!locationCodes || !locationCodes.length) {
      setLocationCode("");
    }
  }, [locationCodes, setLocationCode]);

  const changeDateHandler = (
    selectedDate: Date | null,
    dateField: string,
    name: string
  ) => {
    if (!selectedDate || selectedDate.toString() === "Invalid Date") {
      onChangeGeofenceFilters({
        [name]: null,
      });
      return;
    }

    let startDate =
      name === "createdRange"
        ? createdRange?.startDate
        : updatedRange?.startDate;
    let endDate =
      name === "createdRange" ? createdRange?.endDate : updatedRange?.endDate;
    if (dateField === InstalledDateFilterOptions.StartDate) {
      const newStartDate = checkIsValidDate(selectedDate)
        ? convertToIsoStringIgnoringTimezoneOffset(selectedDate)
        : null;
      const endDateAsDate = endDate ? new Date(endDate) : null;
      if (
        !endDateAsDate ||
        (newStartDate && new Date(newStartDate) >= endDateAsDate)
      ) {
        const nextDay = new Date(selectedDate);
        nextDay.setDate(nextDay.getDate() + 1);
        endDate = nextDay.toISOString();
      }
      startDate = newStartDate;
    } else {
      endDate = checkIsValidDate(selectedDate)
        ? convertToIsoStringIgnoringTimezoneOffset(selectedDate)
        : null;
    }

    if (startDate && endDate) {
      onChangeGeofenceFilters({
        [name]: {
          startDate: startDate,
          endDate: endDate,
        },
      });
    }
  };

  const handleTimeChange = (value: string | undefined, name: string) => {
    if (!value) {
      onChangeGeofenceFilters({
        operatingHoursRange: {
          timeFrom:
            name === "timeFrom" || name === "timeFromFormat"
              ? ""
              : operatingHoursRange?.timeFrom,
          timeTo:
            name === "timeTo" || name === "timeToFormat"
              ? ""
              : operatingHoursRange?.timeTo,
        },
      });
    }
    if (name === "timeFrom") {
      const time = convertTo24HoursFormat(value, startTimeFormat ?? "AM");
      onChangeGeofenceFilters({
        operatingHoursRange: {
          timeFrom: time as string,
          timeTo: operatingHoursRange?.timeTo,
        },
      });
    } else if (name === "timeFromFormat") {
      const timeValue = startTime ? startTime : "10:00";
      const time = convertTo24HoursFormat(timeValue, value as string);
      onChangeGeofenceFilters({
        operatingHoursRange: {
          timeFrom: time as string,
          timeTo: operatingHoursRange?.timeTo,
        },
      });
    } else if (name === "timeTo") {
      const time = convertTo24HoursFormat(value, endTimeFormat ?? "AM");
      onChangeGeofenceFilters({
        operatingHoursRange: {
          timeFrom: operatingHoursRange?.timeFrom,
          timeTo: time as string,
        },
      });
    } else if (name === "timeToFormat") {
      const timeValue = endTime ? endTime : "10:00";
      const time = convertTo24HoursFormat(timeValue, value as string);
      onChangeGeofenceFilters({
        operatingHoursRange: {
          timeFrom: operatingHoursRange?.timeFrom,
          timeTo: time as string,
        },
      });
    }
  };

  return (
    <>
      <div className="flex justify-between">
        <Typography className="!text-lg !font-semibold py-6 px-0">
          More
        </Typography>
        <Typography className="!text-lg !font-semibold px-0">
          {moreFiltersCount ? moreFiltersCount : ""}
        </Typography>
      </div>

      <Box>
        <Box className="font-bold text-[10px] leading-4">Time Zone</Box>
        <Autocomplete
          data-testid="geofence-filter__timezones"
          limitTags={3}
          options={timezonesOptions}
          size="small"
          value={timezones?.[0] ?? null}
          isOptionEqualToValue={(o, v) => o.id === v.id}
          onChange={(_, value) => {
            onChangeGeofenceFilters({
              timezones: value ? [value as { id: string; label: string }] : [],
            });
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Time Zone"
              data-testid="geofence-timezones-input"
            />
          )}
        />
      </Box>
      <Divider className="!mt-2 !mb-3" />

      <Box>
        <Box className="font-bold text-[10px] leading-4">Location Code</Box>
        <div data-testid="geofence-filter__location-code">
          <TextField
            label="Location Code"
            variant="standard"
            fullWidth
            value={locationCode}
            onChange={(e) => handleLocationCodeChange(e.target.value)}
          />
        </div>
      </Box>
      <Divider className="!mt-2 !mb-3" />

      <Box>
        <Box className="font-bold text-[10px] leading-4">Geofence Created</Box>
        <Grid container columnSpacing={2}>
          <Grid item xs={6}>
            <div data-testid="geofence-filter__created-start-date">
              <DateTextField
                label="Start Date"
                value={createdRange?.startDate ?? null}
                data-title="Created Start Date"
                fullWidth
                onChange={(value) => {
                  changeDateHandler(
                    value,
                    InstalledDateFilterOptions.StartDate,
                    "createdRange"
                  );
                }}
              />
            </div>
          </Grid>
          <Grid item xs={6}>
            <div data-testid="geofence-filter__created-end-date">
              <DateTextField
                label="End Date"
                value={createdRange?.endDate ?? null}
                data-title="Created End Date"
                fullWidth
                onChange={(value) => {
                  changeDateHandler(
                    value,
                    InstalledDateFilterOptions.EndDate,
                    "createdRange"
                  );
                }}
                minDate={addDaysToDate(
                  new Date(createdRange?.startDate ?? null),
                  1
                )}
              />
            </div>
          </Grid>
        </Grid>
      </Box>
      <Divider className="!mt-2 !mb-3" />

      <Box>
        <Box className="font-bold text-[10px] leading-4">Geofence Updated</Box>
        <Grid container columnSpacing={2}>
          <Grid item xs={6}>
            <div data-testid="geofence-filter__updated-start-date">
              <DateTextField
                label="Start Date"
                value={updatedRange?.startDate ?? null}
                data-title="Updated Start Date"
                fullWidth
                onChange={(value) => {
                  changeDateHandler(
                    value,
                    InstalledDateFilterOptions.StartDate,
                    "updatedRange"
                  );
                }}
              />
            </div>
          </Grid>
          <Grid item xs={6}>
            <div data-testid="geofence-filter__updated-end-date">
              <DateTextField
                label="End Date"
                value={updatedRange?.endDate ?? null}
                data-title="Updated End Date"
                fullWidth
                onChange={(value) => {
                  changeDateHandler(
                    value,
                    InstalledDateFilterOptions.EndDate,
                    "updatedRange"
                  );
                }}
                minDate={addDaysToDate(
                  new Date(updatedRange?.startDate ?? null),
                  1
                )}
              />
            </div>
          </Grid>
        </Grid>
      </Box>
      <Divider className="!mt-2 !mb-3" />

      <Box>
        <Box className="font-bold text-[10px] leading-4">Currently Open</Box>
        <Grid container columnSpacing={2} className="!mb-4">
          <Grid item xs={6}>
            <Autocomplete
              data-testid="geofence-filter__open-time-from"
              limitTags={3}
              options={HOUR_OPTIONS}
              size="small"
              value={startTime ? optionise(startTime) : null}
              fullWidth
              isOptionEqualToValue={(o, v) => o.value === v.value}
              onChange={(_, value) =>
                handleTimeChange(value?.value, "timeFrom")
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Time From"
                  data-testid="geofence-open-time-from-input"
                />
              )}
            />
          </Grid>
          <Grid item xs={6}>
            <Autocomplete
              data-testid="geofence-filter__time-format-from"
              limitTags={3}
              options={AM_PM_OPTIONS}
              size="small"
              fullWidth
              value={startTimeFormat ? optionise(startTimeFormat) : null}
              isOptionEqualToValue={(o, v) => o.value === v.value}
              onChange={(_, value) =>
                handleTimeChange(value?.value, "timeFromFormat")
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="AM/PM"
                  data-testid="geofence-open-time-format-from-input"
                />
              )}
            />
          </Grid>
        </Grid>
        <Grid container columnSpacing={2}>
          <Grid item xs={6}>
            <Autocomplete
              data-testid="geofence-filter__open-time-to"
              limitTags={3}
              options={HOUR_OPTIONS}
              size="small"
              value={endTime ? optionise(endTime) : null}
              isOptionEqualToValue={(o, v) => o.value === v.value}
              onChange={(_, value) => handleTimeChange(value?.value, "timeTo")}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Time To"
                  data-testid="geofence-open-time-to-input"
                />
              )}
            />
          </Grid>
          <Grid item xs={6}>
            <Autocomplete
              data-testid="geofence-filter__time-format-to"
              limitTags={3}
              options={AM_PM_OPTIONS}
              size="small"
              value={endTimeFormat ? optionise(endTimeFormat) : null}
              isOptionEqualToValue={(o, v) => o.value === v.value}
              onChange={(_, value) =>
                handleTimeChange(value?.value, "timeToFormat")
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="AM/PM"
                  data-testid="geofence-open-time-format-to-input"
                />
              )}
            />
          </Grid>
        </Grid>
      </Box>
      <Divider className="!mt-2 !mb-3" />
    </>
  );
};
