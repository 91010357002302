import { FC, memo, useCallback, useMemo, useState } from "react";
import { Box } from "@mui/material";
import { useGridApiRef } from "@mui/x-data-grid-premium";
import { useAppContext } from "../../../../../context/AppContext";
import {
  AssetDetentionTableData,
  FileFormat,
  FindAssetDetentionByGeofenceIdOsQuery,
  GeofenceAssetDetentionTableDataInput,
  GetEventHistoryTableDataInput,
  SortOrder,
  TableDomain,
  TableViewType,
  useFindAssetDetentionByGeofenceIdOsQuery,
} from "../../../../../graphql/operations";
import Spinner from "../../../../../shared/components/Spinner";
import { BackEndProcessingTable } from "../../../../../shared/components/Table/BackEndProcessingTable";
import {
  ServerSideExport,
  ServerSideExportFormat,
  StringTableFilterValue,
  TableGridColDef,
} from "../../../../../shared/components/Table/types";
import { usePreferredTimezone } from "../../../../../shared/hooks/usePreferredTimezone";
import { useTableDataExporter } from "../../../../../shared/hooks/useTableDataExporter/useTableDataExporter";
import { columnVisibilityModel, getColumns } from "./columns";

type GeofenceDwellHistoryProps = {
  geofenceId: string;
};

export const GeofenceDwellHistory: FC<GeofenceDwellHistoryProps> = memo(
  ({ geofenceId }: { geofenceId: string }) => {
    const apiRef = useGridApiRef();
    const {
      state: { appConfig },
    } = useAppContext();
    const timezone = usePreferredTimezone();
    const columns = useMemo(() => getColumns(timezone), [timezone]);
    const [isExporting, setIsExporting] = useState(false);
    const [fileFormat, setFileFormat] = useState<FileFormat>(FileFormat.Excel);
    const [isSendingEmail, setIsSendingEmail] = useState(false);

    const [queryInput, setQueryInput] =
      useState<GeofenceAssetDetentionTableDataInput>({
        geofence_id: geofenceId,
        sorting: [{ field: "eventDate", order: SortOrder.Desc }],
        pagination: {
          skip: 0,
          limit: appConfig.table.defaultRowsPerPage,
        },
      });

    const { data, isSuccess, isLoading, isRefetching } =
      useFindAssetDetentionByGeofenceIdOsQuery<
        FindAssetDetentionByGeofenceIdOsQuery,
        unknown
      >(
        {
          input: queryInput,
        },
        {
          enabled: Boolean(geofenceId),
        }
      );
    const updateQueryInput = useCallback(
      (data: Partial<GetEventHistoryTableDataInput>) =>
        setQueryInput((prev: GeofenceAssetDetentionTableDataInput) => ({
          ...prev,
          ...data,
        })),
      [setQueryInput]
    );

    useTableDataExporter<AssetDetentionTableData>({
      queryInput,
      apiRef,
      columns,
      domain: TableDomain.AssetsDetention,
      fileFormat,
      isExporting,
      setExporting: setIsExporting,
      totalCount: data?.findAssetDetentionByGeofenceIdOS?.pagination.total,
    });

    const handleExport = useCallback((format: ServerSideExportFormat) => {
      if (format === ServerSideExport.EMAIL) {
        setIsSendingEmail(true);
      } else {
        setFileFormat(format);
        setIsExporting(true);
      }
    }, []);

    return (
      <Box className="h-full w-full px-4 pt-8 pb-4 md:px-6 md:pt-0 md:pb-3 lg:px-16">
        <BackEndProcessingTable
          domain={TableDomain.AssetsDetention}
          queryInput={queryInput}
          apiRef={apiRef}
          tableType={TableViewType.AlertsHistory}
          tableName={"geofence-detention-history"}
          columns={columns}
          columnVisibilityModel={columnVisibilityModel}
          data={{
            rows: (data?.findAssetDetentionByGeofenceIdOS
              ?.assetDetentionTableData ?? []) as AssetDetentionTableData[],
            pagination:
              data?.findAssetDetentionByGeofenceIdOS?.pagination ?? undefined,
          }}
          updateQueryInput={updateQueryInput}
          sorting={queryInput.sorting ?? undefined}
          isDataRefetching={isRefetching}
          onExport={handleExport}
          isLoading={isLoading}
          isSuccess={isSuccess}
          isSendingEmail={isSendingEmail}
          setSendingEmail={setIsSendingEmail}
          rowsPerPage={
            data?.findAssetDetentionByGeofenceIdOS?.pagination?.limit
          }
          totalCount={data?.findAssetDetentionByGeofenceIdOS?.pagination?.total}
          tableFiltersToSkip={[
            StringTableFilterValue.DoesNotContain,
            StringTableFilterValue.IsAnyOf,
          ]}
        />
        <Spinner counter={Number(isExporting) || Number(isSendingEmail)} />
      </Box>
    );
  }
);
