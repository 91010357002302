import { ChangeEvent, FC, useCallback, useEffect, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import { Button, TextButton } from "../Button";

type NameOf = "automation" | "alert" | "report";

interface EditNameDialogProps {
  name: string;
  open: boolean;
  onClose: () => void;
  onSubmit: (name: string) => void;
  nameType: NameOf;
  setName?: (name: string) => void;
}

export const ChangeNameDialog: FC<EditNameDialogProps> = ({
  open,
  onClose,
  onSubmit,
  name,
  nameType,
  setName,
}) => {
  const [newName, setNewName] = useState(name);
  const [helperText, setHelperText] = useState("");

  const capitalizedNameType = nameType[0].toUpperCase() + nameType.slice(1);

  const handleNameChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setNewName(event.target.value);
      if (setName) setName(event.target.value);
    },
    [setNewName, setName]
  );

  useEffect(() => {
    setNewName(name);
  }, [name, setNewName]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleClose = useCallback(() => {
    setHelperText("");
    onClose();
  }, [onClose, name]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleDone = useCallback(() => {
    const value = newName && newName.trim();
    if (!value) {
      setHelperText("Please enter the name");
      return;
    } else if (value.length > 255) {
      setHelperText("Max 255 characters");
      return;
    } else {
      setHelperText("");
    }

    onSubmit(value);
    handleClose();
  }, [onSubmit, handleClose, newName]);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      data-testid="edit-name-dialog"
      aria-labelledby="edit-name-dialog-title"
      aria-describedby="edit-name-dialog-description"
      sx={{ backgroundColor: "var(--backdrop)" }}
      slotProps={{ backdrop: { invisible: true } }}
      PaperProps={{
        sx: {
          backgroundImage: "none",
          backgroundColor: "var(--background) !important",
          borderRadius: "8px",
          width: "100%",
          maxWidth: "720px !important",
        },
      }}
    >
      <DialogTitle
        id="edit-alert-name-dialog-title"
        className="flex justify-between"
      >
        <span
          className="text-2xl font-semibold !text-typography-secondary"
          data-testid="dialog-title"
        >
          {nameType ? capitalizedNameType + " Name" : "Alert Name"}
        </span>
        <IconButton
          onClick={handleClose}
          aria-label="close"
          data-testid="edit-alert-name-dialog-close-btn"
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent className="!pb-0">
        <DialogContentText
          id="edit-alert-name-dialog-description"
          className="!mb-7 text-base !text-typography-secondary"
        >
          Please choose a name for your {capitalizedNameType}
        </DialogContentText>
        <Typography
          data-testid="name-input-label"
          className="!mb-1 !text-sm !font-bold"
        >
          {capitalizedNameType} Name
        </Typography>
        <TextField
          error={!!helperText}
          data-testid="nameInput"
          variant="standard"
          fullWidth
          value={newName}
          onChange={handleNameChange}
          helperText={helperText}
        />
      </DialogContent>
      <DialogActions className="!p-6">
        <TextButton
          text="Cancel"
          size="medium"
          onClick={handleClose}
          theme="blue"
          data-testid="btn-name-cancel"
          iconPosition="none"
          icon={undefined}
          className={undefined}
        />
        <Button
          text="Done"
          size="medium"
          theme="blue"
          variant="default"
          onClick={handleDone}
          dataTestid="btn-update-name"
        />
      </DialogActions>
    </Dialog>
  );
};
