import { memo, useCallback, useMemo, FC, useRef } from "react";
import SharedIcon from "@mui/icons-material/Share";
import { Divider, Typography } from "@mui/material";
import { useIsFetching, useQueryClient } from "@tanstack/react-query";
import classNames from "classnames";
import { useAppContext } from "../../../../../context/AppContext";
import {
  Asset,
  AssetCluster,
  AssetFullAddress,
  AssetOs,
  GetAssetsClustersQuery,
  GetAssetsForClustersOsQuery,
} from "../../../../../graphql/operations";
import { useFindAssetById } from "../../../../../shared/hooks/openSearchMongoPolyfillHooks/useFindAssetById";
import { usePreferredTimezone } from "../../../../../shared/hooks/usePreferredTimezone";
import { getAssetFromCluster, useFeatureFlag } from "../../../../../utils";
import { FeatureFlags } from "../../../../../utils/featureFlagsConstants";
import AssetIcon from "../../../TableView/components/AssetIcon/AssetIcon";
import {
  FeatureProperties,
  useAssetsDataContext,
} from "../../../shared/AssetsDataContext";
import { AssetShortTooltipSensors } from "./components/AssetShortTooltipSensors/AssetShortTooltipSensors";
import { LastEventChip } from "./components/LastEventChip/LastEventChip";
import { formatSensorEventName, getTooltipStatus } from "./helpers";
import { useGetEnabledSensors } from "./hooks/useGetEnabledSensors";
import "./style.scss";

export interface AssetShortTooltipProps extends FeatureProperties {
  breadCrumbsTooltipText?: string;
  fullAddress?: AssetFullAddress | null;
}

const AssetShortTooltip: FC<AssetShortTooltipProps> = ({
  cluster_id,
  lst_evnt_t,
  fullAddress,
  name,
  event_name,
  category,
  dwelling,
  breadCrumbsTooltipText,
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const { setSelectedAssetId } = useAssetsDataContext();
  const queryClient = useQueryClient();
  const {
    state: {
      selectedOrganization: { selectedOrganization },
    },
  } = useAppContext();
  const timezone = usePreferredTimezone();

  // TODO: Cleanup with PRJIND-9218
  const fetchAssetsFromOpenSearchFeatureFlag = useFeatureFlag(
    FeatureFlags.Connect1FetchAssetsFromOpenSearch
  );

  const isFetchingClustersDocumentDB = useIsFetching({
    queryKey: ["getAssetsClusters"],
  });
  const isFetchingClustersOpenSearch = useIsFetching({
    queryKey: ["getAssetsForClustersOS"],
  });
  const isFetchingClusters =
    isFetchingClustersDocumentDB || isFetchingClustersOpenSearch;
  const clusters = useMemo(() => {
    if (isFetchingClusters === 0) {
      let mapDataFromCache;

      // TODO: Cleanup with PRJIND-9218
      if (fetchAssetsFromOpenSearchFeatureFlag) {
        mapDataFromCache = queryClient.getQueriesData({
          queryKey: ["getAssetsForClustersOS"],
          exact: false,
          type: "active",
        });

        if (mapDataFromCache.length > 0) {
          const assetsClusters =
            mapDataFromCache[0][1] as GetAssetsForClustersOsQuery;

          return assetsClusters?.getAssetsOS?.clusters;
        }
      } else {
        mapDataFromCache = queryClient.getQueriesData({
          queryKey: ["getAssetsClusters"],
          exact: false,
          type: "active",
        });

        if (mapDataFromCache.length > 0) {
          const assetsClusters =
            mapDataFromCache[0][1] as GetAssetsClustersQuery;

          return assetsClusters.getAssets.clusters;
        }
      }
    }
    return [];
  }, [isFetchingClusters]); // eslint-disable-line react-hooks/exhaustive-deps

  const asset = useMemo(() => {
    if (clusters && cluster_id) {
      return getAssetFromCluster(clusters as AssetCluster[], cluster_id);
    }
  }, [clusters, cluster_id]);

  const onClickAsset = useCallback(async () => {
    if (asset) {
      setSelectedAssetId(asset._id);
      await exitFullscreen();
    }
  }, [asset, setSelectedAssetId]);

  const assetId = asset?._id || "";

  const { data: selectedAsset, isFetching: isFetchingAssetById } =
    useFindAssetById(
      {
        assetId,
        timezone: timezone,
        currentOrgId: selectedOrganization?.value ?? "",
      },
      {
        enabled: Boolean(assetId && timezone && selectedOrganization?.value),
      }
    );

  const exitFullscreen = async (): Promise<void> => {
    const isFullscreen = !!document.fullscreenElement;

    // Note: [#PRJIND-5457] We should force exit the full-screen map mode when the user clicks on an asset
    if (document.documentElement && isFullscreen) {
      await document.exitFullscreen();
    }
  };

  const combinedAsset = { ...selectedAsset };
  if (dwelling) {
    combinedAsset.dwelling = dwelling;
  }
  const { enabledSensors } = useGetEnabledSensors(combinedAsset as Asset);
  const statusString = getTooltipStatus(
    selectedAsset?.tripStatus?.tripState ?? undefined,
    selectedAsset?.dwelling?.dwellingDays,
    selectedAsset?.sensors?.chassis?.data?.cargoState ?? undefined,
    selectedAsset?.tripStatus?.signal ?? undefined
  );

  return (
    <div
      data-testid="asset-short-tooltip-wrapper"
      className={classNames(
        "bg-custom-tooltip-background",
        "p-5",
        "rounded-lg"
      )}
    >
      <div
        ref={ref}
        className={`flex ${
          breadCrumbsTooltipText ? "w-42" : "w-64"
        }  flex-col gap-2 bg-custom-tooltip-background text-typography-tooltip mx-[-10px] mb-[-15px] mt-[-10px] rounded p-2 pr-0 text-xs`}
      >
        {!breadCrumbsTooltipText && (
          <>
            <div className="flex justify-between items-start">
              <div className="flex items-center" data-testid="asset-icon">
                <AssetIcon
                  dwellingDays={Number(selectedAsset?.dwelling?.dwellingDays)}
                  signal={Boolean(selectedAsset?.tripStatus?.signal)}
                  cargo_state={String(
                    selectedAsset?.sensors?.chassis?.data?.cargoState
                  )}
                  trip_st={String(selectedAsset?.tripStatus?.tripState)}
                  svgProps={{ height: 48, width: 48 }}
                />
              </div>
              <div className="flex flex-col flex-1 pl-2">
                <div className="flex items-center justify-between gap-2 text-2xs">
                  <div className="cursor-pointer" onClick={onClickAsset}>
                    <p className="font-bold text-sub-header-text capitalize relative">
                      {category && <span>{category}&nbsp;</span>}
                      {name?.toUpperCase()}
                      <span className="block absolute bottom-0 left-0 w-full h-[1px] bg-sub-header-text"></span>
                    </p>
                  </div>
                  <LastEventChip lastEvent={new Date(lst_evnt_t)} />
                </div>
                {selectedAsset?.shared?.status === "Shared" && (
                  <div
                    className="flex !font-medium text-sub-header-text pt-2 text-2xs"
                    data-testid="shared-info"
                  >
                    <SharedIcon
                      sx={{
                        width: 15,
                        height: 15,
                        marginRight: "3px",
                      }}
                      data-testid="shared-icon"
                    />
                    {(selectedAsset as AssetOs).shared?.shareType}{" "}
                    {(selectedAsset as AssetOs).shared?.shareOrganizationName}
                  </div>
                )}
              </div>
            </div>
            <div className="font-medium">{`${statusString}`}</div>
            <div className="font-medium">{`${fullAddress?.city}, ${fullAddress?.state}`}</div>
          </>
        )}
        {breadCrumbsTooltipText && (
          <Typography className="text-typography-tooltip">
            {breadCrumbsTooltipText}
          </Typography>
        )}
        {!breadCrumbsTooltipText && (
          <>
            <div className="font-medium">
              {formatSensorEventName(event_name ?? "")}
            </div>
            <Divider />
            <AssetShortTooltipSensors
              sensors={enabledSensors}
              isFetchingAssetById={
                isFetchingAssetById || Boolean(isFetchingClusters)
              }
            />
          </>
        )}
      </div>
    </div>
  );
};

export default memo(AssetShortTooltip);
