import React from "react";
import { TooltipProps } from "recharts";
import { AirBagHistoricalData } from "../../../../../graphql/operations";
import { useGetPressureUnitPreference } from "../../../../../shared/hooks/useGetPressureUnitPreference";
import { getConvertedPressureValueWithUnit } from "../../../../../utils/convertPressure";

export const AirBagCustomTooltip: React.FC<
  // @ts-ignore
  TooltipProps<AirBagHistoricalData, string>
> = ({ active, payload }) => {
  const pressureUnit = useGetPressureUnitPreference();
  if (active && payload?.length) {
    const leftMin =
      getConvertedPressureValueWithUnit(
        payload?.[0].payload.airbag.min_airbag_pressure_mbar_0?.value,
        pressureUnit
      ) ?? "N/A";

    const leftMax =
      getConvertedPressureValueWithUnit(
        payload?.[0].payload.airbag.max_airbag_pressure_mbar_0?.value,
        pressureUnit
      ) ?? "N/A";

    const rightMin =
      getConvertedPressureValueWithUnit(
        payload?.[0].payload.airbag.min_airbag_pressure_mbar_1?.value,
        pressureUnit
      ) ?? "N/A";

    const rightMax =
      getConvertedPressureValueWithUnit(
        payload?.[0].payload.airbag.max_airbag_pressure_mbar_1?.value,
        pressureUnit
      ) ?? "N/A";

    const date = payload?.[0].payload?.eventHistoryData?.toolTipDate;

    if (!leftMin && !leftMax && !rightMin && !rightMax) return null;

    return (
      <div
        className="p-6 bg-custom-tooltip-background rounded-lg"
        data-testid="tooltip-wrapper"
      >
        <p
          className="text-base font-normal text-typography"
          data-testid="tooltip-date"
        >
          {date}
        </p>
        <div className="flex justify-between">
          <div className="mr-6">
            <p className="text-base text-info">Axle One Left Air Bag</p>
            <p className="text-base font-normal text-typography">
              Pressure:
              <span
                className="font-bold"
                data-testid="airbag-tooltip-left-pressure"
              >{` ${leftMin} - ${leftMax}`}</span>
            </p>
          </div>
          <div>
            <p className="text-base text-info">Axle One Right Air Bag</p>
            <p className="text-base font-normal text-typography">
              Pressure:
              <span
                className="font-bold"
                data-testid="airbag-tooltip-right-pressure"
              >{` ${rightMin} - ${rightMax}`}</span>
            </p>
          </div>
        </div>
      </div>
    );
  }

  return null;
};
