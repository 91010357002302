import { GridValueGetterParams } from "@mui/x-data-grid-premium";
import {
  Maybe,
  TableColumnFormat as ReportColumnFormat,
  SearchEventHistoryResultData,
  TableValueDataType,
} from "../../../../../graphql/operations";
import { columnTimezoneDateTimeFormatParams } from "../../../../../utils";
import { ReportGridColDef } from "../../../helpers/helpers";

export const defaultColumnVisibilityModel = {
  assetId: false,
  geofenceName: false,
  orgName: false,
  freeDaysStart: false,
  freeDaysEnd: false,
  detentionStart: false,
  daysInDetention: false,
  detentionCharges: false,
  detentionEnd: false,
  cargoStatusOnEnter: false,
  unloadDuration: false,
  cargoStatusOnExit: false,
  locationAddress: false,
};

export const getColumns = (
  timezone: Maybe<string> | undefined
): ReportGridColDef[] => {
  const commonColumnConfig = {
    flex: 1,
    minWidth: 120,
  };

  const createColumn = (
    field: string,
    headerName: string,
    format: ReportColumnFormat,
    additionalConfig = {}
  ) => ({
    field,
    headerName,
    format,
    ...commonColumnConfig,
    ...additionalConfig,
  });

  return [
    createColumn("assetId", "Asset ID", ReportColumnFormat.String),
    createColumn("geofenceName", "Geofence", ReportColumnFormat.String),
    createColumn("orgName", "Organization Name", ReportColumnFormat.String),
    createColumn(
      "freeDaysStart",
      "Free Days Start",
      ReportColumnFormat.IsoDateTime,
      {
        ...columnTimezoneDateTimeFormatParams(timezone as string),
        filterable: false,
        sortable: false,
      }
    ),
    createColumn(
      "freeDaysEnd",
      "Free Days End",
      ReportColumnFormat.IsoDateTime,
      {
        ...columnTimezoneDateTimeFormatParams(timezone as string),
        filterable: false,
        sortable: false,
      }
    ),
    createColumn(
      "detentionStart",
      "Detention Start",
      ReportColumnFormat.IsoDateTime,
      {
        ...columnTimezoneDateTimeFormatParams(timezone as string),
        filterable: false,
        sortable: false,
      }
    ),
    createColumn(
      "daysInDetention",
      "Days In Detention",
      ReportColumnFormat.String,
      {
        type: "number",
      }
    ),
    createColumn(
      "detentionCharges",
      "Detention Charges",
      ReportColumnFormat.String,
      {
        type: "number",
      }
    ),
    createColumn(
      "detentionEnd",
      "Detention End",
      ReportColumnFormat.IsoDateTime,
      {
        ...columnTimezoneDateTimeFormatParams(timezone as string),
        filterable: false,
        sortable: false,
      }
    ),
    createColumn(
      "cargoStatusOnEnter",
      "Cargo Status On Enter",
      ReportColumnFormat.String
    ),
    createColumn(
      "unloadDuration",
      "Unload Duration",
      ReportColumnFormat.String,
      {
        valueGetter: ({
          row,
        }: GridValueGetterParams<SearchEventHistoryResultData>) => {
          if (row.unloadDuration <= 0) {
            return "";
          }
          const hours = Math.floor(row.unloadDuration / 3600);
          const minutes = Math.floor((row.unloadDuration % 3600) / 60);
          return `${String(hours).padStart(2, "0")}:${String(minutes).padStart(
            2,
            "0"
          )}`;
        },
      }
    ),
    createColumn(
      "cargoStatusOnExit",
      "Cargo Status On Exit",
      ReportColumnFormat.String
    ),
    createColumn(
      "locationAddress",
      "Location Address ",
      ReportColumnFormat.String,
      {
        filterable: false,
        sortable: false,
      }
    ),
  ];
};

export const SEARCH_KEYS = [
  "assetId",
  "geofenceName",
  "orgName",
  "freeDaysStart",
  "freeDaysEnd",
  "detentionStart",
  "daysInDetention",
  "detentionCharges",
  "detentionEnd",
  "cargoStatusOnEnter",
  "unloadDuration",
  "cargoStatusOnExit",
  "locationAddress",
];

export const ASSET_DETENTION_COLUMN_TYPE_MAP = {
  assetId: TableValueDataType.String,
  geofenceName: TableValueDataType.String,
  orgName: TableValueDataType.String,
  freeDaysStart: TableValueDataType.Date,
  freeDaysEnd: TableValueDataType.Date,
  detentionStart: TableValueDataType.Date,
  daysInDetention: TableValueDataType.Number,
  detentionCharges: TableValueDataType.Number,
  detentionEnd: TableValueDataType.Date,
  cargoStatusOnEnter: TableValueDataType.String,
  unloadDuration: TableValueDataType.Date,
  cargoStatusOnExit: TableValueDataType.String,
  locationAddress: TableValueDataType.String,
};
