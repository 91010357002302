import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { Box } from "@mui/material";
import { GridRenderCellParams } from "@mui/x-data-grid/models/params/gridCellParams";
import { TableColumnFormat } from "../../../../../graphql/operations";
import {
  AssetBalanceTableReportGridColDef,
  AssetBalanceTableRow,
} from "./types";

const baseColumnProps: Pick<
  AssetBalanceTableReportGridColDef,
  | "type"
  | "format"
  | "sortable"
  | "groupable"
  | "filterable"
  | "flex"
  | "minWidth"
> = {
  type: "number",
  format: TableColumnFormat.String,
  sortable: false,
  groupable: false,
  filterable: false,
  flex: 1,
  minWidth: 120,
};

export const renderGroupedCell = ({
  row,
}: GridRenderCellParams<AssetBalanceTableRow>) => (
  <Box sx={{ paddingLeft: `${row.nestingLevel * 24}px` }}>
    {row.hasChildren &&
      (row.expanded ? (
        <KeyboardArrowUpIcon
          data-testid="arrow-up-icon"
          fontSize="small"
          sx={{ marginInline: "4px" }}
        />
      ) : (
        <KeyboardArrowDownIcon
          data-testid="arrow-down-icon"
          fontSize="small"
          sx={{ marginInline: "4px" }}
        />
      ))}
    {row.area}
  </Box>
);

export const distributionOverviewTableColumns: AssetBalanceTableReportGridColDef[] =
  [
    {
      ...baseColumnProps,
      field: "area",
      headerName: "Area",
      type: "string",
      renderCell: renderGroupedCell,
    },
    {
      ...baseColumnProps,
      field: "present",
      headerName: "Assets Present Today Day 1",
    },
    {
      ...baseColumnProps,
      field: "oneWeek",
      headerName: "Assets Present 7 Days ago",
    },
    {
      ...baseColumnProps,
      field: "fourWeeks",
      headerName: "Assets Present 28 Days ago",
    },
    {
      ...baseColumnProps,
      field: "eightWeeks",
      headerName: "Assets Present 56 Days ago",
    },
    {
      ...baseColumnProps,
      field: "twelveWeeks",
      headerName: "Assets Present 84 Days ago",
    },
    {
      ...baseColumnProps,
      field: "twentySixWeeks",
      headerName: "Assets Present 182 Days ago",
    },
    {
      ...baseColumnProps,
      field: "fiftyTwoWeeks",
      headerName: "Assets Present 364 Days ago",
    },
  ];

export const deltaOverviewTableColumns: AssetBalanceTableReportGridColDef[] = [
  {
    ...baseColumnProps,
    field: "area",
    headerName: "Area Balance - Country",
    type: "string",
    renderCell: renderGroupedCell,
  },
  { ...baseColumnProps, field: "oneWeek", headerName: "1 Week Effect" },
  { ...baseColumnProps, field: "fourWeeks", headerName: "4 Weeks Effect" },
  { ...baseColumnProps, field: "eightWeeks", headerName: "8 Weeks Effect" },
  { ...baseColumnProps, field: "twelveWeeks", headerName: "12 Weeks Effect" },
  {
    ...baseColumnProps,
    field: "twentySixWeeks",
    headerName: "26 Weeks Effect",
  },
  { ...baseColumnProps, field: "fiftyTwoWeeks", headerName: "52 Weeks Effect" },
];
