import { FC, useState } from "react";
import OpenInFullIcon from "@mui/icons-material/OpenInFull";
import {
  Box,
  CircularProgress,
  Grid,
  IconButton,
  MenuItem,
  Stack,
  TextField,
  ThemeProvider,
} from "@mui/material";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import { last, sortBy } from "lodash";
import {
  ComposedChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  ReferenceArea,
} from "recharts";
import { ReactComponent as AirBagSensorDark } from "../../../../../assets/svgs/airbagSensorDark.svg";
import { ReactComponent as AirBagSensorLight } from "../../../../../assets/svgs/airbagSensorLight.svg";
import { VOLTAGE_CHART_DATE_RANGE_OPTIONS } from "../../../../../constants/map";
import { useAppContext } from "../../../../../context/AppContext";
import { ColorsPalette } from "../../../../../design-system/colors-palette";
import {
  HistoricalEventHistory,
  MergedAirbagSensorConfig,
  SensorThresholdShort,
} from "../../../../../graphql/operations";
import SensorHeader from "../../../../../shared/components/SensorHeader/SensorHeader";
import useBreakpoint from "../../../../../shared/hooks/useBreakpoint";
import useChartExpand from "../../../../../shared/hooks/useChartExpand";
import {
  DATE_FORMAT,
  DAY_MONTH_FORMAT,
  formatDate,
  getStartOfDay,
  getSubDays,
  parseISODate,
} from "../../../../../utils/date";
import { State } from "../../Assets/TiresTabPanel/components/charts/components/tpms/TiresChartBody";
import { sensorConfigToReferenceAreaValues } from "../../Assets/TiresTabPanel/helpers";
import { AirBagCustomTooltip } from "./AirBagCustomTooltip";
import { AirBagTrendingGraphLegend } from "./AirBagTrendingGraphLegend";
import {
  AIR_BAG_TRENDING_GRAPH_COLORS_DARK,
  AIR_BAG_TRENDING_GRAPH_COLORS_LIGHT,
  AirBagTrendingGraphLegendKeys,
} from "./constants";
import { useAirBagTheme } from "./useAirBagTheme";

export interface AirBagTrendingGraphProps {
  data: Array<HistoricalEventHistory>;
  setEndDateAirBag: (date: Date | null) => void;
  setStartDateAirBag: (date: Date | null) => void;
  startDateAirBag: Date | null;
  endDateAirBag: Date | null;
  selectedDateRangeOptionAirBag: number;
  setSelectedDateRangeOptionAirBag: (value: number) => void;
  initialChartSize?: number;
  allowResize?: boolean;
  isLoading: boolean;
  sensorProfile: MergedAirbagSensorConfig | null;
}
export enum AirBagTrendingGraphSize {
  small = 6,
  medium = 9,
  large = 12,
}

export const STATE_TO_COLOR = {
  [State.Critical]: ColorsPalette.ErrorOpacity20,
  [State.Warning]: ColorsPalette.CautionOpacity10,
  [State.Alert]: ColorsPalette.AlertOpacity20,
  [State.Healthy]: ColorsPalette.SuccessOpacity20,
};

export const AirBagTrendingGraph: FC<AirBagTrendingGraphProps> = ({
  data,
  initialChartSize = AirBagTrendingGraphSize.large,
  allowResize = true,
  setEndDateAirBag,
  setStartDateAirBag,
  startDateAirBag,
  endDateAirBag,
  selectedDateRangeOptionAirBag,
  setSelectedDateRangeOptionAirBag,
  isLoading,
  sensorProfile,
}) => {
  const [activeIndex, setActiveIndex] = useState(1);
  const isDesktop = useBreakpoint("up", "lg");

  const AirBagTheme = useAirBagTheme();

  const {
    state: { theme },
  } = useAppContext();
  const isLightTheme = theme.theme === "light";
  const svgIconSettings = {
    width: "80px",
    height: "80px",
    display: "block",
  };

  const chartColors = isLightTheme
    ? AIR_BAG_TRENDING_GRAPH_COLORS_LIGHT
    : AIR_BAG_TRENDING_GRAPH_COLORS_DARK;

  const [visibleLines, setVisibleLines] = useState<
    AirBagTrendingGraphLegendKeys[]
  >([
    AirBagTrendingGraphLegendKeys.AxleOneLeftAirBag,
    AirBagTrendingGraphLegendKeys.AxleOneRightAirBag,
  ]);

  const handleDateRangeChange = (value: number) => {
    const option = VOLTAGE_CHART_DATE_RANGE_OPTIONS.find(
      (option) => option.value === value
    );
    setSelectedDateRangeOptionAirBag(value);
    if (option?.getRange) {
      const range = option.getRange();
      setStartDateAirBag(range[0]);
      setEndDateAirBag(range[1]);
    }
  };

  const formatXAxis = (tickItem: string) => {
    return formatDate(new Date(tickItem), DAY_MONTH_FORMAT);
  };

  const { gridWidth, gridWidthIndicator, handleToggleGridWidth } =
    useChartExpand();

  const formattedData = data?.map((d) => {
    const date = new Date(d?.date ?? "");
    return {
      ...d,
      eventHistoryData: {
        ...d,
        date: new Date(date).getTime(),
        toolTipDate: formatDate(parseISODate(d?.date ?? ""), DATE_FORMAT),
      },
    };
  });

  const sortedData = sortBy(formattedData, (item) => item?.date ?? "");

  const domain = [
    sortedData[0]?.date ?? "auto",
    sortedData[sortedData.length - 1]?.date ?? "auto",
  ];

  const referenceAreaValues = sensorConfigToReferenceAreaValues(
    (sensorProfile?.measures?.match?.thresholds as SensorThresholdShort) ?? []
  );

  return (
    <Grid item lg={gridWidth} className={isDesktop ? "block" : "hidden"}>
      <Box className="h-full min-h-[900px] w-full flex flex-col p-5 bg-dashboard_subheader__bg !text-xs !font-medium">
        <Stack direction="row" justifyContent="end">
          <SensorHeader title="Air Bag Trending Graph" noBorder>
            {isLightTheme ? (
              <AirBagSensorDark style={svgIconSettings} />
            ) : (
              <AirBagSensorLight style={svgIconSettings} />
            )}
          </SensorHeader>
          {allowResize && (
            <IconButton
              sx={{
                width: "2rem",
                height: "1rem",
                backgroundColor: "transparent!important",
              }}
              aria-label="toggleWidth"
              data-testid="toggleWidth"
              onClick={() => handleToggleGridWidth()}
            >
              <OpenInFullIcon
                fontSize="medium"
                style={{
                  fill: "var(--cargo-trend-expand-icon)",
                  marginRight: "0.313rem",
                }}
              />
              <span
                className="font-bold text-sm leading-4"
                style={{ marginTop: "-0.5rem", color: ColorsPalette.LightBlue }}
              >
                {gridWidthIndicator}
              </span>
            </IconButton>
          )}
        </Stack>
        <ThemeProvider theme={AirBagTheme}>
          <Box className="mb-5">
            <Box className="flex items-center justify-end gap-4 py-4">
              <MobileDatePicker
                label="Start date"
                inputFormat="MM/dd/yyyy"
                closeOnSelect
                value={startDateAirBag}
                shouldDisableDate={(day) =>
                  day < getStartOfDay(getSubDays(new Date(), 90))
                }
                disableFuture
                onChange={(date) => {
                  setStartDateAirBag(date);
                  setSelectedDateRangeOptionAirBag(
                    last(VOLTAGE_CHART_DATE_RANGE_OPTIONS)?.value!
                  );
                }}
                DialogProps={{
                  className: "trending-voltage-date-picker",
                }}
                renderInput={(params) => (
                  <TextField
                    data-testid="airbag-chart-start-date"
                    variant="outlined"
                    {...params}
                  />
                )}
              />
              <span className="text-base font-normal text-primary">to</span>
              <MobileDatePicker
                DialogProps={{
                  className: "trending-voltage-date-picker",
                }}
                label="End date"
                closeOnSelect
                inputFormat="MM/dd/yyyy"
                value={endDateAirBag}
                shouldDisableDate={(day) =>
                  !!startDateAirBag && day < startDateAirBag
                }
                disableFuture
                onChange={(date) => {
                  setEndDateAirBag(date);
                  setSelectedDateRangeOptionAirBag(
                    last(VOLTAGE_CHART_DATE_RANGE_OPTIONS)?.value!
                  );
                }}
                renderInput={(params) => (
                  <TextField
                    data-testid="airbag-chart-end-date"
                    variant="outlined"
                    {...params}
                  />
                )}
              />

              <TextField
                data-testid="airbag-chart-date-range-select"
                select
                value={selectedDateRangeOptionAirBag}
                variant="outlined"
                className="w-40"
                onChange={(e) => handleDateRangeChange(+e.target.value)}
              >
                {VOLTAGE_CHART_DATE_RANGE_OPTIONS.map((option) => (
                  <MenuItem
                    key={option.value}
                    value={option.value}
                    data-testid={`airbag-chart-date-range-select-option-${option.value}`}
                  >
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </Box>
          </Box>
        </ThemeProvider>
        {isLoading ? (
          <Box
            className="flex h-full w-full items-center justify-center"
            data-testid="brakes-loading-screen"
          >
            <CircularProgress />
          </Box>
        ) : (
          <ResponsiveContainer width="100%" height="100%">
            <ComposedChart
              data={sortedData}
              margin={{
                top: 20,
                right: 20,
                bottom: 20,
                left: 20,
              }}
            >
              <CartesianGrid stroke="#f5f5f5" />
              <XAxis
                dataKey="eventHistoryData.date"
                tickFormatter={formatXAxis}
                scale="time"
                type="number"
                domain={domain}
              />
              <YAxis
                label={{
                  value: "Pressure",
                  angle: -90,
                  position: "insideLeft",
                }}
                type={"number"}
                yAxisId={"pressure"}
              />
              <Tooltip
                content={<AirBagCustomTooltip labelFormatter={undefined} />}
              />

              {referenceAreaValues?.map((value, index) => (
                <ReferenceArea
                  yAxisId={"pressure"}
                  y1={value.min}
                  y2={value.max}
                  fill={STATE_TO_COLOR[value.state as State]}
                  fillOpacity={0.8}
                  ifOverflow="extendDomain"
                  data-testid="air-tank-trending-graph-chart-body-reference-area"
                  key={`air-tank-trending-graph-chart-body-reference-area-${index}`}
                />
              ))}

              {/* Left Airbag */}
              {visibleLines.includes(
                AirBagTrendingGraphLegendKeys.AxleOneLeftAirBag
              ) && (
                <Line
                  type="linear"
                  dataKey={AirBagTrendingGraphLegendKeys.AxleOneLeftAirBag}
                  stroke={chartColors[0]}
                  strokeWidth={3}
                  onClick={() => setActiveIndex(1)}
                  dot={false}
                  connectNulls={true}
                  yAxisId={"pressure"}
                />
              )}

              {/* Right Airbag */}
              {visibleLines.includes(
                AirBagTrendingGraphLegendKeys.AxleOneRightAirBag
              ) && (
                <Line
                  type="linear"
                  dataKey={AirBagTrendingGraphLegendKeys.AxleOneRightAirBag}
                  stroke={chartColors[1]}
                  strokeWidth={3}
                  onClick={() => setActiveIndex(2)}
                  dot={false}
                  connectNulls={true}
                  yAxisId={"pressure"}
                />
              )}
            </ComposedChart>
          </ResponsiveContainer>
        )}

        <Box className="mb-12 flex justify-center gap-8">
          <AirBagTrendingGraphLegend
            visibleLines={visibleLines}
            onClick={(key: AirBagTrendingGraphLegendKeys) =>
              setVisibleLines((prevState) =>
                prevState.includes(key)
                  ? prevState.filter((k) => k !== key)
                  : [...prevState, key]
              )
            }
          />
        </Box>
      </Box>
    </Grid>
  );
};
