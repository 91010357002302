import { memo, useState } from "react";
import { FieldValues, useForm } from "react-hook-form";
import { TextFieldElement } from "react-hook-form-mui";
import { useNavigate } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import { Box, ThemeProvider, Typography } from "@mui/material";
import { isEmpty } from "lodash";
import * as yup from "yup";
import { useAppContext } from "../../../context/AppContext";
import { forgotPassword } from "../../../services/aws/auth";
import { NavigationRoutes } from "../../../utils/routes/routesUtils";
import { AuthButton } from "../components/AuthButton";
import { AuthenticationBox } from "../components/AuthenticationBox";
import { useAuthFormTheme } from "../hooks/useAuthFormTheme";

export const schema = yup.object().shape({
  username: yup
    .string()
    .min(2, "Username must be at least 2 characters")
    .required("Username is required"),
});

const ForgotPassword = () => {
  const navigate = useNavigate();
  const { dispatch } = useAppContext();
  const formTheme = useAuthFormTheme();
  const {
    control,
    handleSubmit,
    formState: { dirtyFields },
    setError,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      username: "",
    },
  });

  const [isLoading, setIsLoading] = useState(false);

  const isSubmitDisabled = isLoading || isEmpty(dirtyFields);

  const handlePasswordReset = async ({ username }: FieldValues) => {
    setIsLoading(true);
    try {
      await forgotPassword({ username, dispatch });
      const usernameParam = `?username=${username}`;
      navigate(`${NavigationRoutes.ConfirmForgotPassword}${usernameParam}`);
    } catch (error) {
      setIsLoading(false);
      setError("username", {
        type: "manual",
        message: "Forgot password failed",
      });
    }
  };

  return (
    <AuthenticationBox>
      <ThemeProvider theme={formTheme}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            gap: "40px",
            width: "100%",
            height: "100%",
          }}
        >
          <Typography sx={{ fontWeight: 500 }}>
            Forgot your password?
          </Typography>
          <form onSubmit={handleSubmit(handlePasswordReset)}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "20px",
                justifyContent: "center",
                alignItems: "center",
                width: "234px",
              }}
            >
              <Typography sx={{ fontSize: "14px" }}>
                Enter your E-mail or Username and we will send a message to
                reset your password
              </Typography>
              <TextFieldElement
                fullWidth
                control={control}
                name="username"
                inputProps={{
                  "data-testid": "custom-login-username",
                  placeholder: "E-mail or Username",
                  autoFocus: true,
                }}
              />
              <AuthButton
                text="Reset my password"
                type="submit"
                disabled={isSubmitDisabled}
                iconPosition={isLoading ? "right" : "none"}
              />
            </Box>
          </form>
        </Box>
      </ThemeProvider>
    </AuthenticationBox>
  );
};

export default memo(ForgotPassword);
