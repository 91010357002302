import { FC } from "react";
import { UseFormReturn } from "react-hook-form";
import { AutocompleteElement } from "react-hook-form-mui";
import { Grid } from "@mui/material";
import { SensorStatus } from "../../../../graphql/operations";
import { useAssetParameters } from "../../hooks/useAssetParameters";
import { AlertFormValues } from "../../interfaces";
import { AlertParametersContainer } from "../AlertParametersContainer";
import { AssetsParameters } from "./AssetsParameters";

interface AlertRegulatorParametersProps {
  form: UseFormReturn<Partial<AlertFormValues>>;
}
export const AlertRegulatorParameters: FC<AlertRegulatorParametersProps> = ({
  form,
}: AlertRegulatorParametersProps) => {
  const healthStateOptions = [
    { id: SensorStatus.Warning, label: "Warning" },
    { id: SensorStatus.Alert, label: "Alert" },
    { id: SensorStatus.Critical, label: "Critical" },
  ];

  const {
    assetTypeOptions,
    setAssetNameSearch,
    watchAssetsValue,
    watchAssetTypeValue,
    isFetching,
    hideInputValue,
    setSelectedAsset,
    assetsOptionsList,
    setAssetsOptionsList,
  } = useAssetParameters({ form });

  return (
    <AlertParametersContainer>
      <AssetsParameters
        form={form}
        setAssetNameSearch={setAssetNameSearch}
        setSelectedAsset={setSelectedAsset}
        assetsOptionsList={assetsOptionsList}
        setAssetsOptionsList={setAssetsOptionsList}
        assetTypeOptions={assetTypeOptions}
        watchAssetsValue={watchAssetsValue}
        watchAssetTypeValue={watchAssetTypeValue}
        isFetching={isFetching}
        hideInputValue={hideInputValue}
        assetsSelectTestId="regulator-assets-select"
        assetTypeSelectTestId="regulator-asset-type-select"
      />

      <Grid
        item
        xs={12}
        md={6}
        lg={6}
        xl={4}
        data-testid="regulator-asset-health-state"
      >
        <AutocompleteElement
          matchId={true}
          label="Health State"
          control={form.control}
          name="parameters.regulatorHealthStatus"
          options={healthStateOptions}
        />
      </Grid>
    </AlertParametersContainer>
  );
};
