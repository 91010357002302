import { FC, useEffect, useMemo, useState } from "react";
import { UseFormReturn, useWatch } from "react-hook-form";
import { CheckboxElement, AutocompleteElement } from "react-hook-form-mui";
import { Chip, Grid } from "@mui/material";
import property from "lodash/property";
import { useAppContext } from "../../../../context/AppContext";
import {
  FindMatchesType,
  GeofenceData,
  useFindGeofencesByIdsQuery,
  useFindGeofencesQuery,
  useGetGeofenceTypeQuery,
} from "../../../../graphql/operations";
import { useGlobalSearchQuery } from "../../../../shared/hooks/useGlobalSearchQuery";
import { useRegionsList } from "../../../../shared/hooks/useRegionsList";
import {
  useGeofenceNameOptions,
  useGeofenceTypeOptions,
} from "../../../AssetsView/TableView/hooks";
import { useZonesFilterOptions } from "../../../AssetsView/shared/AssetsFilterControls/hooks/useZonesFilterOptions";
import { AlertFormValues } from "../../interfaces";
import {
  getAlertMultiselectIds,
  isFindGeofenceByIdQueryEnabled,
  onFindGeofencesSettledCallback,
} from "../../utils";
import { AlertParametersContainer } from "../AlertParametersContainer";

interface AlertGeofenceParametersProps {
  form: UseFormReturn<Partial<AlertFormValues>>;
}
export const AlertGeofenceParameters: FC<AlertGeofenceParametersProps> = ({
  form,
}: AlertGeofenceParametersProps) => {
  const { state } = useAppContext();
  const [selectedGeofence, setSelectedGeofence] = useState<any>(null);
  const queryType = FindMatchesType.Geofence;
  const historyItems: string[] = [];
  const optionsLimit = state.appConfig.searchOptionsLimit;

  const watchGeofences = useWatch({
    name: "parameters.geofenceIds",
    control: form.control,
  });
  const watchGeofenceType = useWatch({
    name: "parameters.geofenceType",
    control: form.control,
  });
  const watchRegion = useWatch({
    name: "parameters.region",
    control: form.control,
  });
  const watchZone = useWatch({
    name: "parameters.zone",
    control: form.control,
  });

  const { regions: regionsData, isLoading: isRegionsLoading } =
    useRegionsList();
  const regionOptions = useMemo(
    () =>
      regionsData
        ?.map((region) => ({
          country: region?.country ?? "",
          label: region?.name,
          id: region?.name,
        }))
        .sort((reg1, reg2) => reg1.country.localeCompare(reg2.country)) ?? [],
    [regionsData]
  );

  const { zonesOptions: zonesData, isLoadingZones } = useZonesFilterOptions();
  const zonesOptions = useMemo(
    () =>
      zonesData
        ?.map((zone) => ({
          id: zone.label,
          label: zone.label,
          country: zone.country,
        }))
        ?.sort((zone1, zone2) => zone1.country.localeCompare(zone2.country)) ??
      [],
    [zonesData]
  );

  const { data: query } = useGetGeofenceTypeQuery(
    {},
    {
      onError: (error) => {
        console.error(`Handled error in GetGeofenceTypeQuery, ${error}`);
      },
    }
  );
  const geofenceTypes = useMemo(() => query?.getGeofenceType ?? [], [query]);
  const geofenceTypesList = useGeofenceTypeOptions(geofenceTypes);
  let shouldBeEnabled = isFindGeofenceByIdQueryEnabled(watchGeofences);

  const { data } = useFindGeofencesQuery();
  const geofenceData = data?.findGeofences?.data ?? [];
  const geofenceNameOpts = useGeofenceNameOptions(
    geofenceData as GeofenceData[]
  );

  const {
    data: foundGeofence,
    isLoading: findGeofenceLoading,
    isSuccess: findGeofenceSuccess,
  } = useFindGeofencesByIdsQuery(
    { ids: getAlertMultiselectIds(watchGeofences) ?? [] },
    {
      enabled: shouldBeEnabled,
    }
  );
  useEffect(() => {
    if (foundGeofence && !findGeofenceLoading && findGeofenceSuccess) {
      onFindGeofencesSettledCallback({
        data: foundGeofence,
        setSelectedGeofence,
      });
    }
  }, [
    foundGeofence,
    findGeofenceLoading,
    findGeofenceSuccess,
    form,
    shouldBeEnabled,
  ]);

  useEffect(() => {
    if (foundGeofence && selectedGeofence) {
      form.setValue("parameters.geofenceIds", selectedGeofence);
    }
  }, [form, selectedGeofence, foundGeofence]);

  const { isLoading, setSearch } = useGlobalSearchQuery(
    queryType,
    historyItems,
    optionsLimit
  );

  const hideGeofenceInputValue =
    !selectedGeofence &&
    !!watchGeofences?.length &&
    findGeofenceLoading &&
    !findGeofenceSuccess;

  const hideRegionInputValue =
    !watchRegion?.length && !regionOptions?.length && isRegionsLoading;

  const isRegionDisabled =
    Boolean(watchGeofenceType) ||
    Boolean(watchGeofences?.length) ||
    Boolean(watchZone?.length) ||
    isRegionsLoading;
  const isGeofenceTypeDisabled =
    Boolean(watchRegion?.length) ||
    Boolean(watchGeofences?.length) ||
    Boolean(watchZone?.length);
  const isGeofenceDisabled =
    Boolean(watchRegion?.length) ||
    Boolean(watchGeofenceType) ||
    Boolean(watchZone?.length);
  const isZoneDisabled =
    Boolean(watchGeofenceType) ||
    Boolean(watchGeofences?.length) ||
    Boolean(watchRegion?.length) ||
    isLoadingZones;

  const hideZoneInputValue =
    !watchZone?.length && !zonesOptions?.length && isLoadingZones;

  return (
    <AlertParametersContainer>
      <Grid item xs={12} md={6} lg={6} xl={4}>
        <AutocompleteElement
          matchId={true}
          autocompleteProps={{
            groupBy: property("country"),
            noOptionsText: "No options",
            autoComplete: true,
            blurOnSelect: true,
            disabled: isRegionDisabled,
            readOnly: isRegionDisabled,
            renderTags: (value, getTagProps) =>
              value.map((option, index) => (
                <Chip
                  variant="outlined"
                  label={
                    hideRegionInputValue || !option?.label
                      ? "loading"
                      : option.label
                  }
                  {...getTagProps({ index })}
                />
              )),
          }}
          multiple
          loading={isRegionsLoading}
          label="Region"
          control={form.control}
          name="parameters.region"
          options={regionOptions}
        />
      </Grid>

      <Grid item xs={12} md={6} lg={6} xl={4}>
        <AutocompleteElement
          matchId={true}
          name="parameters.zone"
          control={form.control}
          options={zonesOptions}
          data-testid="alert-parameters-zone"
          label="Zone"
          multiple
          loading={isLoadingZones}
          autocompleteProps={{
            groupBy: property("country"),
            autoComplete: true,
            blurOnSelect: true,
            noOptionsText: "No options",
            disabled: isZoneDisabled,
            readOnly: isZoneDisabled,
            renderTags: (value, getTagProps) =>
              value.map((option, index) => (
                <Chip
                  variant="outlined"
                  label={
                    hideZoneInputValue || !option?.label
                      ? "loading"
                      : option.label
                  }
                  {...getTagProps({ index })}
                />
              )),
          }}
        />
      </Grid>
      <Grid
        item
        xs={12}
        md={6}
        lg={6}
        xl={4}
        data-testid="alert-parameters-geofence-type"
      >
        <AutocompleteElement
          autocompleteProps={{
            disabled: isGeofenceTypeDisabled,
            readOnly: isGeofenceTypeDisabled,
          }}
          matchId={true}
          name="parameters.geofenceType"
          data-testid="alert-parameters-geofence-type"
          label="Geofence Type"
          control={form.control}
          options={geofenceTypesList}
        />
      </Grid>

      <Grid item xs={12} md={6} lg={6} xl={4}>
        <AutocompleteElement
          autocompleteProps={{
            noOptionsText: "No options",
            onInputChange: (event, newInputValue) => {
              if (newInputValue) {
                setSearch(newInputValue);
              }
            },
            onChange: (event, newInputValue) => {
              if (newInputValue) {
                form.setValue("parameters.geofenceIds", newInputValue);
                setSelectedGeofence(newInputValue);
              }
            },
            autoComplete: true,
            blurOnSelect: true,
            disabled: isGeofenceDisabled,
            readOnly: isGeofenceDisabled,
            renderTags: (value, getTagProps) =>
              value.map((option, index) => (
                <Chip
                  variant="outlined"
                  label={
                    hideGeofenceInputValue || !option.label
                      ? "loading"
                      : option.label
                  }
                  {...getTagProps({ index })}
                />
              )),
          }}
          multiple
          loading={isLoading || hideGeofenceInputValue}
          label="Geofences"
          control={form.control}
          name="parameters.geofenceIds"
          options={geofenceNameOpts}
        />
      </Grid>
      <Grid
        item
        xs={12}
        display={{ md: "flex" }}
        md={"auto"}
        lg={"auto"}
        xl={6}
      >
        <Grid
          item
          xs={12}
          md={"auto"}
          lg={"auto"}
          xl={"auto"}
          data-testid="alert-parameters-geofence-enter"
        >
          <CheckboxElement
            name="parameters.alertOnEnter"
            control={form.control}
            label="Geofence Enter"
          />
        </Grid>
        <Grid
          item
          xs={12}
          md={"auto"}
          lg={"auto"}
          xl={"auto"}
          data-testid="alert-parameters-geofence-exit"
        >
          <CheckboxElement
            name="parameters.alertOnExit"
            control={form.control}
            label="Geofence Exit"
          />
        </Grid>
      </Grid>
    </AlertParametersContainer>
  );
};
