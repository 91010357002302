import { ReactNode, useState, useEffect } from "react";
import { Box, Skeleton } from "@mui/material";
import useBreakpoint from "../../../shared/hooks/useBreakpoint";
import { useAuthenticationViewBrand } from "../hooks/useAuthenticationViewBrand";

interface AuthenticationBoxProps {
  children: ReactNode;
}

export const AuthenticationBox: React.FC<AuthenticationBoxProps> = ({
  children,
}) => {
  const brand = useAuthenticationViewBrand();
  const isMobile = useBreakpoint("down", "sm");

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        height: "100%",
        background: brand?.backgroundImage
          ? ""
          : "var(--custom-auth-background-color)",
        backgroundSize: "cover",
        backgroundPosition: "center",
        position: "relative",
      }}
    >
      <img
        src={brand?.backgroundImage ?? ""}
        style={{
          position: "absolute",
          width: "100%",
          height: "100%",
          zIndex: -1,
        }}
        alt="background"
      />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: "4rem",
          width: isMobile ? "350px" : "400px",
          maxHeight: "90vh",
          overflowY: "auto",
          margin: "0 1rem",
          paddingY: "3rem",
          borderRadius: "8px",
          borderTop: `20px solid ${brand?.color ?? "white"}`,
          backgroundColor: "var(--white)",
          boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
        }}
      >
        {!brand ? (
          <Skeleton
            variant="rounded"
            animation="wave"
            data-testid={"auth-logo-skeleton"}
            sx={{ width: "300px", height: "100px" }}
          />
        ) : (
          <Box sx={{ maxWidth: "200px" }}>
            <img src={brand?.logo ?? ""} alt={brand?.logo ?? ""} />
          </Box>
        )}
        <Box>{children}</Box>
      </Box>
    </Box>
  );
};
