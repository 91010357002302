import AWS from "aws-sdk";
import { v4 } from "uuid";
import { env } from "../../env";

export interface SignedUploadUrlConfig {
  fileName: string;
  contentType: string;
  bucket: string;
  bucketDirectory?: string;
  metadata?: { [key: string]: string | number };
}
export interface SignedDownloadUrlConfig {
  contentType: string;
  bucket: string;
  key: string;
}

/**
 * Returns a signed upload URL for the given S3 bucket, key, and content type
 *
 * @param {SignedUploadUrlConfig} config
 * @returns {string} signed upload URL
 */

export const getS3SignedUploadUrl = ({
  fileName,
  contentType,
  bucket,
  bucketDirectory,
  metadata,
}: SignedUploadUrlConfig): string => {
  const s3 = new AWS.S3({
    region: env.REACT_APP_REGION,
    signatureVersion: "v4",
  });

  const uniqueName = `${v4()}_${fileName}`;
  const key = bucketDirectory ? `${bucketDirectory}/${uniqueName}` : uniqueName;
  return s3.getSignedUrl("putObject", {
    Bucket: bucket,
    Key: key,
    ContentType: contentType,
    Metadata: metadata,
    Expires: 3600,
  });
};

export const getS3SignedDownloadUrl = ({
  bucket,
  key,
  contentType,
}: SignedDownloadUrlConfig): string => {
  const s3 = new AWS.S3({
    region: env.REACT_APP_REGION,
    signatureVersion: "v4",
  });

  return s3.getSignedUrl("getObject", {
    Bucket: bucket,
    Key: key,
    ContentType: contentType,
    Expires: 3600,
  });
};
