import { GridValueGetterParams } from "@mui/x-data-grid-premium";
import { GridColDef } from "@mui/x-data-grid/models";
import { get, round } from "lodash";
import {
  Asset,
  SensorStatusUppercase,
} from "../../../../../graphql/operations";
import { BatteryIcon } from "../../../../../shared/components/BatteryIcon";
import { DoorTypeLabel } from "../../../../../shared/components/DoorTypeLabel/DoorTypeLabel";
import {
  columnTimezoneDateTimeFormatParams,
  imeiValueFormatter,
} from "../../../../../utils";
import { stripNAValue } from "../../assetUtils";
import AssetIcon from "../AssetIcon/AssetIcon";
import { getIconType } from "../AssetIcon/utils";

export const SEARCH_KEYS: Array<keyof Asset | string> = [
  "org_name",
  "asset_id",
  "name",
  "vin",
  "imei",
  "prd_cde",
  "trip_st",
  "city",
  "state",
  "category",
  "location.coordinates",
  "tags",
  "last_gps_t",
  "postcode",
];

export const columnVisibilityModel = {
  org_name: true,
  icon: true,
  "sensors.voltage.data.battery": true,
  asset_id: true,
  name: true,
  imei: true,
  prd_cde: true,
  lst_evnt_t: true,
  device_associated: true,
  trip_st: true,
  city: true,
  state: true,
  postcode: false,
  vin: false,
  longitude: false,
  latitude: false,
  category: true,
  tags: true,
  wheel_config: false,
  num_of_axles: false,
  length: false,
  door_type: false,
  last_gps_t: false, // GPS Time
};

export const trimmedSorting = (a: string, b: string) =>
  a.trim().localeCompare(b.trim());

export enum IconTypeComplete {
  EMPTY,
  MOVING,
  LOW_DWELL,
  MEDIUM_DWELL,
  HIGH_DWELL,
  MEGA_DWELL,
  MOVING_ONLINE,
  LOW_DWELL_ONLINE,
  MEDIUM_DWELL_ONLINE,
  HIGH_DWELL_ONLINE,
  MEGA_DWELL_ONLINE,
  MOVING_EMPTY,
  LOW_DWELL_EMPTY,
  MEDIUM_DWELL_EMPTY,
  HIGH_DWELL_EMPTY,
  MEGA_DWELL_EMPTY,
  MOVING_ONLINE_EMPTY,
  LOW_DWELL_ONLINE_EMPTY,
  MEDIUM_DWELL_ONLINE_EMPTY,
  HIGH_DWELL_ONLINE_EMPTY,
  MEGA_DWELL_ONLINE_EMPTY,
  MOVING_LOADED,
  LOW_DWELL_LOADED,
  MEDIUM_DWELL_LOADED,
  HIGH_DWELL_LOADED,
  MEGA_DWELL_LOADED,
  MOVING_ONLINE_LOADED,
  LOW_DWELL_ONLINE_LOADED,
  MEDIUM_DWELL_ONLINE_LOADED,
  HIGH_DWELL_ONLINE_LOADED,
  MEGA_DWELL_ONLINE_LOADED,
}

export const getIconValue = (params: GridValueGetterParams<any, Asset>) => {
  const iconType = getIconType(
    params.row.tripStatus?.tripState,
    params.row.dwelling?.dwellingDays,
    params.row.tripStatus?.signal,
    params.row.sensors?.chassis?.data?.cargoState
  );
  const value = IconTypeComplete[iconType as keyof typeof IconTypeComplete];
  return value;
};

export const getColumns = (timezone: string): GridColDef<Asset>[] => [
  {
    field: "org_name",
    headerName: "Organization Name",
    flex: 1,
    minWidth: 120,
  },
  {
    field: "icon",
    headerName: "Icon",
    flex: 1,
    minWidth: 30,
    groupable: false,
    valueGetter: getIconValue,
    renderCell: (params) => (
      <AssetIcon
        dwellingDays={Number(params.row.dwelling?.dwellingDays)}
        signal={Boolean(params.row.tripStatus?.signal)}
        cargo_state={String(params.row.sensors?.chassis?.data?.cargoState)}
        trip_st={String(params.row.tripStatus?.tripState)}
      />
    ),
    disableExport: true,
    filterable: false,
  },
  {
    field: "sensors.voltage.data.battery",
    headerName: "Battery Status",
    flex: 1,
    minWidth: 30,
    groupable: false,
    valueGetter: (params) => get(params.row, "sensors.voltage.data.battery"),
    renderCell: (params) => {
      return (
        <BatteryIcon
          chargeLevel={params.value ? round(params.value, 2) : undefined}
          svgProps={{ width: "24px", height: "24px" }}
          status={
            params.row.sensors?.voltage?.data?.statusBattery ??
            SensorStatusUppercase.Unknown
          }
        />
      );
    },
    disableExport: true,
    filterable: false,
  },
  {
    field: "asset_id",
    headerName: "Asset ID",
    flex: 1,
    minWidth: 120,
    groupable: false,
    valueFormatter: imeiValueFormatter,
    sortComparator: trimmedSorting,
  },
  {
    field: "name",
    headerName: "Asset Nickname",
    flex: 1,
    minWidth: 120,
    valueFormatter: imeiValueFormatter,
    sortComparator: trimmedSorting,
  },
  {
    field: "imei",
    headerName: "Device ID",
    flex: 1,
    minWidth: 120,
    groupable: false,
    valueFormatter: imeiValueFormatter,
  },
  {
    field: "prd_cde",
    headerName: "Product Name",
    flex: 1,
    minWidth: 120,
    filterable: false,
    valueGetter: (params) =>
      (params.row as Asset & { prd_name: string | undefined }).prd_name,
  },
  {
    field: "lst_evnt_t",
    headerName: "Last Reported Date",
    flex: 1,
    minWidth: 190,
    ...columnTimezoneDateTimeFormatParams(timezone),
  },
  {
    field: "device_associated",
    headerName: "Associated",
    flex: 1,
    minWidth: 120,
    filterOperators: [
      {
        label: "Yes",
        value: "isNotEmpty",
        getApplyFilterFn: (filterItem, column) => null,
      },
      {
        label: "No",
        value: "isEmpty",
        getApplyFilterFn: (filterItem, column) => null,
      },
    ],
  },
  {
    field: "trip_st",
    headerName: "Trip Status",
    flex: 1,
    minWidth: 120,
    valueGetter: (params) => stripNAValue(params.value) ?? "",
  },
  { field: "city", headerName: "City", flex: 1, minWidth: 120 },
  { field: "state", headerName: "State", flex: 1, minWidth: 120 },
  { field: "postcode", headerName: "Zip", flex: 1, minWidth: 120 },
  {
    field: "category",
    headerName: "Asset Type",
    flex: 1,
    minWidth: 120,
  },
  { field: "tags", headerName: "Asset Tags", flex: 1, minWidth: 120 },
  {
    field: "latitude",
    headerName: "Latitude",
    flex: 1,
    minWidth: 120,
    groupable: false,
    valueGetter: (params) => params.row.location?.coordinates?.[1],
    type: "number",
    filterable: false,
  },
  {
    field: "longitude",
    headerName: "Longitude",
    flex: 1,
    minWidth: 120,
    groupable: false,
    valueGetter: (params) => params.row.location?.coordinates?.[0],
    type: "number",
    filterable: false,
  },
  { field: "vin", headerName: "VIN", flex: 1, minWidth: 120 },
  {
    field: "wheel_config",
    headerName: "# of Tires",
    flex: 1,
    minWidth: 120,
    valueGetter: (params) => {
      const value = stripNAValue(params.value);
      if (value) {
        return Number.parseInt(value, 10);
      }
      return undefined;
    },
  },
  {
    field: "num_of_axles",
    headerName: "# of Axles",
    flex: 1,
    minWidth: 120,
    valueGetter: (params) => {
      const value =
        params.value === -1 ? undefined : stripNAValue(params.value);
      if (value) {
        return Number.parseInt(value, 10);
      }
      return undefined;
    },
    type: "number",
  },
  {
    field: "length",
    headerName: "Length",
    flex: 1,
    minWidth: 120,
    renderCell: (params) =>
      !params.value || params.value === -1 ? "" : params.value + "'",
    type: "number",
  },
  {
    field: "door_type",
    headerName: "Door Type",
    flex: 1,
    minWidth: 120,
    renderCell: (params) => <DoorTypeLabel value={params.value} />,
    filterable: false,
  },
  {
    field: "last_gps_t",
    headerName: "GPS Time",
    flex: 1,
    minWidth: 120,
    ...columnTimezoneDateTimeFormatParams(timezone),
  },
];
