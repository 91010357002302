import { AssetBalanceData, Maybe } from "../../../../../graphql/operations";
import {
  AssetBalanceGroupBy,
  AssetBalanceTableData,
  AssetBalanceTableRow,
  TableRowCalculations,
  TableViewMode,
} from "./types";

const NESTING_FIELDS: Array<AssetBalanceGroupBy> = [
  "country",
  "region",
  "zone",
];

const getGroupedRows = (
  data: AssetBalanceTableData[],
  nestingLevel: number,
  parentId: string
): AssetBalanceTableRow[] => {
  const nestingField = NESTING_FIELDS[nestingLevel];
  const result: AssetBalanceTableRow[] = [];

  const uniqueValues = [...new Set(data.map((item) => item[nestingField]))];
  for (const value of uniqueValues) {
    const entries = data.filter((item) => item[nestingField] === value);
    const row: AssetBalanceTableRow = {
      id: [parentId, value].filter(Boolean).join("-"),
      area: value,
      groupBy: nestingField,
      parentId,
      hasChildren:
        // Check if there are more than one entry with the same value or if there is a next nesting level
        (entries.length === 1 &&
          !!entries[0][NESTING_FIELDS[nestingLevel + 1]]) ||
        entries.length > 1,
      visible: nestingLevel === 0,
      expanded: false,
      nestingLevel,
      ...entries.reduce(
        (
          calculations: TableRowCalculations,
          data: AssetBalanceTableData
        ): TableRowCalculations => ({
          present: calculations.present + data.present,
          oneWeek: calculations.oneWeek + data.oneWeek,
          fourWeeks: calculations.fourWeeks + data.fourWeeks,
          eightWeeks: calculations.eightWeeks + data.eightWeeks,
          twelveWeeks: calculations.twelveWeeks + data.twelveWeeks,
          twentySixWeeks: calculations.twentySixWeeks + data.twentySixWeeks,
          fiftyTwoWeeks: calculations.fiftyTwoWeeks + data.fiftyTwoWeeks,
        }),
        {
          present: 0,
          oneWeek: 0,
          fourWeeks: 0,
          eightWeeks: 0,
          twelveWeeks: 0,
          twentySixWeeks: 0,
          fiftyTwoWeeks: 0,
        }
      ),
    };

    result.push(row);
    if (row.hasChildren) {
      result.push(...getGroupedRows(entries, nestingLevel + 1, row.id));
    }
  }

  return result;
};

export const getTableRows = (
  data: AssetBalanceTableData[],
  tableViewMode: TableViewMode
): AssetBalanceTableRow[] => {
  if (tableViewMode === "delta-overview") {
    return getGroupedRows(
      data.map((row) => ({
        ...row,
        oneWeek: row.present - row.oneWeek,
        fourWeeks: row.present - row.fourWeeks,
        eightWeeks: row.present - row.eightWeeks,
        twelveWeeks: row.present - row.twelveWeeks,
        twentySixWeeks: row.present - row.twentySixWeeks,
        fiftyTwoWeeks: row.present - row.fiftyTwoWeeks,
      })),
      0,
      ""
    );
  }

  return getGroupedRows(data, 0, "");
};

const hideChildren = (parentId: string, rows: AssetBalanceTableRow[]) => {
  const children = rows.filter((row) => row.parentId === parentId);
  for (const child of children) {
    child.visible = false;
    if (child.hasChildren) {
      child.expanded = false;
      hideChildren(child.id, rows);
    }
  }
};

export const expandOrCollapseRow = (
  selectedRowId: string,
  rows: AssetBalanceTableRow[]
): AssetBalanceTableRow[] => {
  const selectedRow = rows[rows.findIndex(({ id }) => id === selectedRowId)];

  if (!selectedRow.hasChildren) {
    return rows;
  }

  const newRows = [...rows];

  selectedRow.expanded = !selectedRow.expanded;
  const children = newRows.filter(({ parentId }) => parentId === selectedRowId);
  for (const child of children) {
    const visible = !child.visible;
    child.visible = visible;
    if (!visible && child.hasChildren) {
      child.expanded = false;
      hideChildren(child.id, newRows);
    }
  }

  return newRows;
};

export const buildAssetBalanceTableData = (data?: Maybe<AssetBalanceData[]>) =>
  data?.map(({ assetsCounts, country, zone, region }) => ({
    country,
    ...assetsCounts,
    zone: zone ?? "Unknown",
    region: region ?? "Unknown",
  })) ?? [];

export const getTableTitle = (viewMode: TableViewMode) =>
  viewMode === "distribution-overview" ? "Asset Counts" : "Area Balance";
