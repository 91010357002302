import { FC } from "react";
import { Paper, Button, Box } from "@mui/material";
import Popover from "../../../Popover";
import type { SectionsItem } from "../../DashboardFrame";

export interface DashBoardSectionsMenuProps {
  open: boolean;
  anchorEl: HTMLButtonElement | null;
  sectionItemsList: SectionsItem[];
  onClose: () => void;
}

export enum Sections {
  Cargo = "cargo-sensor",
  Door = "door-sensor",
  Airbag = "airbag",
  Camera = "camera",
}

const scrollToSection = (section: Sections) => {
  const element = document.getElementById(section);
  if (element) {
    element.scrollIntoView({ behavior: "smooth" });
  }
};

export const DashboardSectionsMenu: FC<DashBoardSectionsMenuProps> = ({
  open,
  anchorEl,
  sectionItemsList = [],
  onClose,
}) => {
  return (
    <Popover
      open={open}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      sx={{ margin: "15px -5px" }}
      elevation={2}
    >
      <Paper className="p-4 !bg-card__placeholder" data-testid="menu-wrapper">
        {sectionItemsList
          .filter((item) => Boolean(item.visible))
          .map((listItem) => (
            <Box key={listItem.title}>
              <Button
                data-testid={listItem.section}
                onClick={() => scrollToSection(listItem.section)}
                startIcon={listItem.icon}
                className="!text-primary !flex !justify-start"
                fullWidth
              >
                {listItem.title}
              </Button>
            </Box>
          ))}
      </Paper>
    </Popover>
  );
};

export default DashboardSectionsMenu;
