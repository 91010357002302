import { FC, memo, useCallback, useMemo, useState } from "react";
import Box from "@mui/material/Box";
import { useGridApiRef } from "@mui/x-data-grid-premium";
import { useAppContext } from "../../../../context/AppContext";
import {
  AlertHistoryTableDataResponse,
  Asset,
  GetAlertHistoryTableDataInput,
  GetTableDataInput,
  SortOrder,
  useGetAlertHistoryTableDataQuery,
  TableViewType,
  TableDomain,
  FileFormat,
  AlertHistoryTableData,
} from "../../../../graphql/operations";
import Spinner from "../../../../shared/components/Spinner";
import {
  ServerSideExport,
  ServerSideExportFormat,
} from "../../../../shared/components/Table";
import { BackEndProcessingTable } from "../../../../shared/components/Table/BackEndProcessingTable";
import { usePreferredTimezone } from "../../../../shared/hooks/usePreferredTimezone";
import { useTableDataExporter } from "../../../../shared/hooks/useTableDataExporter/useTableDataExporter";
import { columnVisibilityModel, getColumns } from "./columns";

export type AlertsTableProps = {
  alertId: string;
};
export type AlertHistoryMap = Asset & {
  issue: string;
  trigger: string;
};

export const AlertHistoryTable: FC<AlertsTableProps> = memo(
  (props: AlertsTableProps) => {
    const {
      state: { appConfig },
    } = useAppContext();
    const apiRef = useGridApiRef();
    const { alertId } = props;

    const [fileFormat, setFileFormat] = useState<FileFormat>(FileFormat.Excel);
    const [isSendingEmail, setIsSendingEmail] = useState(false);
    const [isExporting, setIsExporting] = useState(false);

    const timezone = usePreferredTimezone();
    const columns = useMemo(() => getColumns(timezone), [timezone]);

    const [queryInput, setQueryInput] = useState<GetAlertHistoryTableDataInput>(
      {
        alertId,
        sorting: [{ field: "lastEventDate", order: SortOrder.Desc }],
        pagination: {
          skip: 0,
          limit: appConfig.table.defaultRowsPerPage,
        },
      }
    );

    const {
      data: queryData,
      isSuccess,
      isLoading,
    } = useGetAlertHistoryTableDataQuery<AlertHistoryTableDataResponse>(
      { input: queryInput },
      {
        refetchOnMount: true,
        staleTime: 5000,
        select: ({ getAlertHistoryTableData }) => getAlertHistoryTableData,
      }
    );

    const updateQueryInput = useCallback(
      (data: Partial<GetTableDataInput>) => {
        setQueryInput((prev) => ({ ...prev, ...data }));
      },
      [setQueryInput]
    );

    useTableDataExporter<AlertHistoryTableData>({
      apiRef,
      columns,
      queryInput,
      domain: TableDomain.AlertHistory,
      fileFormat,
      isExporting,
      setExporting: setIsExporting,
      totalCount: queryData?.pagination.total,
      baseFilters: { alertId },
    });

    const handleExport = useCallback((format: ServerSideExportFormat) => {
      if (format === ServerSideExport.EMAIL) {
        setIsSendingEmail(true);
      } else {
        setFileFormat(format);
        setIsExporting(true);
      }
    }, []);

    return (
      <Box className="h-1/2 w-full px-4 mt-5 md:px-6 lg:px-16">
        <BackEndProcessingTable
          apiRef={apiRef}
          baseFilters={{ alertId }}
          domain={TableDomain.AlertHistory}
          isSendingEmail={isSendingEmail}
          setSendingEmail={setIsSendingEmail}
          queryInput={queryInput}
          tableName={"alert-history"}
          tableType={TableViewType.AlertsHistory}
          columnVisibilityModel={columnVisibilityModel}
          columns={columns}
          data={{
            rows: queryData?.data ?? [],
            pagination: queryData?.pagination,
          }}
          updateQueryInput={updateQueryInput}
          onExport={handleExport}
          sorting={queryInput.sorting ?? undefined}
          isLoading={isLoading}
          isSuccess={isSuccess}
          totalCount={queryData?.pagination.total}
        />
        <Spinner counter={Number(isExporting) || Number(isSendingEmail)} />
      </Box>
    );
  }
);
