import { KeyboardEventHandler } from "react";
import { UseFormReturn } from "react-hook-form";
import { SelectElement, TextFieldElement } from "react-hook-form-mui";
import { Grid } from "@mui/material";
import { Asset } from "../../../../../../graphql/operations";
import type { Breakpoints } from "../AssetForm";
import {
  ASSET_LIFT_FORM_FIELDS,
  AssetFormSectionTitles,
  AssetLiftgateFields,
  booleanOptions,
} from "../assetFormUtils";
import { AccordionWrapper } from "../components/AccordionWrapper";

interface LiftgateProps {
  showDetailsHeader?: boolean;
  form: UseFormReturn<Partial<Asset>>;
  breakpoints: Breakpoints;
  asset?: Partial<Asset>;
  disableAllFields?: boolean;
  numberChangeHandler: KeyboardEventHandler<HTMLDivElement>;
}

export const Liftgate = ({
  showDetailsHeader = false,
  form,
  breakpoints,
  disableAllFields = false,
  numberChangeHandler,
}: LiftgateProps) => {
  return (
    <AccordionWrapper
      accordionSummaryTitle={AssetFormSectionTitles.Liftgate}
      showDetailsHeader={showDetailsHeader}
      isDashboardSettings={showDetailsHeader}
    >
      <Grid
        container
        rowGap={showDetailsHeader ? 3 : 0}
        className={`!text-primary ${
          showDetailsHeader ? "formSection" : "secondDrawerSection"
        }`}
        spacing={6}
      >
        <Grid item {...breakpoints}>
          <SelectElement
            disabled={disableAllFields}
            fullWidth
            control={form.control}
            name={ASSET_LIFT_FORM_FIELDS[AssetLiftgateFields.Liftgate].name}
            label={ASSET_LIFT_FORM_FIELDS[AssetLiftgateFields.Liftgate].label}
            options={booleanOptions}
          />
        </Grid>

        <Grid item {...breakpoints}>
          <TextFieldElement
            disabled={disableAllFields}
            fullWidth
            control={form.control}
            name={
              ASSET_LIFT_FORM_FIELDS[AssetLiftgateFields.LiftgateMacAddress]
                .name
            }
            label={
              ASSET_LIFT_FORM_FIELDS[AssetLiftgateFields.LiftgateMacAddress]
                .label
            }
          />
        </Grid>

        <Grid item {...breakpoints}>
          <TextFieldElement
            disabled={disableAllFields}
            fullWidth
            control={form.control}
            name={
              ASSET_LIFT_FORM_FIELDS[AssetLiftgateFields.LiftgateManufacturer]
                .name
            }
            label={
              ASSET_LIFT_FORM_FIELDS[AssetLiftgateFields.LiftgateManufacturer]
                .label
            }
          />
        </Grid>

        <Grid item {...breakpoints}>
          <TextFieldElement
            disabled={disableAllFields}
            fullWidth
            control={form.control}
            name={
              ASSET_LIFT_FORM_FIELDS[AssetLiftgateFields.LiftgateModel].name
            }
            label={
              ASSET_LIFT_FORM_FIELDS[AssetLiftgateFields.LiftgateModel].label
            }
          />
        </Grid>

        <Grid item {...breakpoints}>
          <TextFieldElement
            disabled={disableAllFields}
            fullWidth
            control={form.control}
            name={
              ASSET_LIFT_FORM_FIELDS[AssetLiftgateFields.LiftgateSerial].name
            }
            label={
              ASSET_LIFT_FORM_FIELDS[AssetLiftgateFields.LiftgateSerial].label
            }
          />
        </Grid>

        <Grid item {...breakpoints}>
          <TextFieldElement
            type="number"
            onKeyDown={numberChangeHandler}
            disabled={disableAllFields}
            fullWidth
            control={form.control}
            name={ASSET_LIFT_FORM_FIELDS[AssetLiftgateFields.LiftgateYear].name}
            label={
              ASSET_LIFT_FORM_FIELDS[AssetLiftgateFields.LiftgateYear].label
            }
          />
        </Grid>

        <Grid item {...breakpoints}>
          <TextFieldElement
            disabled={disableAllFields}
            fullWidth
            control={form.control}
            name={
              ASSET_LIFT_FORM_FIELDS[AssetLiftgateFields.LiftgateFWVersion].name
            }
            label={
              ASSET_LIFT_FORM_FIELDS[AssetLiftgateFields.LiftgateFWVersion]
                .label
            }
          />
        </Grid>
      </Grid>
    </AccordionWrapper>
  );
};
