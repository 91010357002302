import { FC } from "react";
import { UseFormReturn } from "react-hook-form";
import { useAssetParameters } from "../../hooks/useAssetParameters";
import { AlertFormValues } from "../../interfaces";
import { AlertParametersContainer } from "../AlertParametersContainer";
import { AssetsParameters } from "./AssetsParameters";

interface AlertDoorOpenOutsideOfGeofenceParametersProps {
  form: UseFormReturn<Partial<AlertFormValues>>;
}

export const AlertDoorOpenOutsideOfGeofenceParameters: FC<
  AlertDoorOpenOutsideOfGeofenceParametersProps
> = ({ form }: AlertDoorOpenOutsideOfGeofenceParametersProps) => {
  const {
    assetTypeOptions,
    setAssetNameSearch,
    watchAssetsValue,
    watchAssetTypeValue,
    isFetching,
    hideInputValue,
    setSelectedAsset,
    assetsOptionsList,
    setAssetsOptionsList,
  } = useAssetParameters({ form });

  return (
    <AlertParametersContainer>
      <AssetsParameters
        form={form}
        setAssetNameSearch={setAssetNameSearch}
        setSelectedAsset={setSelectedAsset}
        assetsOptionsList={assetsOptionsList}
        setAssetsOptionsList={setAssetsOptionsList}
        assetTypeOptions={assetTypeOptions}
        watchAssetsValue={watchAssetsValue}
        watchAssetTypeValue={watchAssetTypeValue}
        isFetching={isFetching}
        hideInputValue={hideInputValue}
        assetsSelectTestId="door-open-outside-geofence-assets-select"
        assetTypeSelectTestId="door-open-outside-geofence-type-select"
      />
    </AlertParametersContainer>
  );
};
