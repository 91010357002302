import React from "react";
import { isNumber } from "lodash";
import { TooltipProps } from "recharts";
import { PressureUnit } from "../../../../../graphql/operations";
import { getPressureUnitLabel } from "../../../../../utils/convertPressure";
import type { IndividualAirTank } from "./BrakesAirTankChart";

export const CustomBrakesChartTooltip: React.FC<
  TooltipProps<any, any> & { activeIndex?: number; pressureUnit: PressureUnit }
> = ({ active, payload, activeIndex, pressureUnit }) => {
  if (active && payload?.length && isNumber(activeIndex)) {
    const currentTankAccessor = `airTank${activeIndex + 1}`;
    const currentTank: IndividualAirTank =
      payload?.[activeIndex]?.payload?.airTank?.[currentTankAccessor];
    const tankPressureMin = currentTank?.min ?? "N/A";
    const tankPressureMax = currentTank?.max ?? "N/A";

    const date = payload?.[activeIndex]?.payload?.toolTipDate;

    return (
      <div
        className="p-6 bg-custom-tooltip-background rounded-lg border border-sub-header-border"
        data-testid="tooltip-wrapper"
      >
        <p
          className="text-base font-normal text-typography"
          data-testid="tooltip-date"
        >
          {date}
        </p>
        <div className="flex">
          <div>
            <p className="text-base text-info">Air Tank {activeIndex + 1}</p>
            <p className="text-base font-normal text-typography">
              Pressure:
              <span
                className="font-bold"
                data-testid="tooltip-pressure"
              >{` ${tankPressureMin} ${getPressureUnitLabel(
                pressureUnit
              )} - ${tankPressureMax} ${getPressureUnitLabel(
                pressureUnit
              )}`}</span>
            </p>
          </div>
        </div>
      </div>
    );
  }

  return null;
};
