import { useEffect, useState } from "react";
import { Box, CircularProgress } from "@mui/material";
import {
  DEFAULT_DATE_RANGE_OPTIONS,
  DateRangeKey,
} from "../../../../../../../../constants/map";
import {
  Asset,
  HistoricalEventHistory,
  SortOrder,
  useGetPsiWheelEndSensorHistoricalDataQuery,
} from "../../../../../../../../graphql/operations";
import { useGetTemperatureUnitPreference } from "../../../../../../../../shared/hooks/useGetTemperatureUnitPreference";
import { FIVE_MINUTES_REFETCH_INTERVAL } from "../../../../SummaryTabPanel/Charts/chartUtils";
import {
  convertToIsoStringIgnoringTimezoneOffset,
  mapPsiWheelEndSensorDataToChartData,
} from "../../../helpers";
import PsiWheelEndChartBody, {
  PsiWheelEndChartData,
} from "../components/psiWheelEnd/PsiWheelEndChartBody";
import TiresChartHeader from "../components/tpms/TiresChartHeader";
import { useDateFilters } from "./hooks/useDateFilters";
import { usePsiWheelEndSensorProfile } from "./hooks/usePsiWheelEndSensorProfile";

type PsiWheelEndChartProps = {
  asset: Asset | null | undefined;
};

const PsiWheelEndChart: React.FC<PsiWheelEndChartProps> = ({ asset }) => {
  const [chartData, setChartData] = useState<PsiWheelEndChartData[]>([]);

  const {
    startDate,
    setStartDate,

    endDate,
    setEndDate,

    dateRangeOption,
    setDateRangeOption,

    handleDateRangeChange,
  } = useDateFilters();

  const tempUnitPreference = useGetTemperatureUnitPreference();

  const { data, isLoading } = useGetPsiWheelEndSensorHistoricalDataQuery(
    {
      input: {
        startDate: convertToIsoStringIgnoringTimezoneOffset(startDate),
        endDate: convertToIsoStringIgnoringTimezoneOffset(endDate),
        period:
          DEFAULT_DATE_RANGE_OPTIONS.find(
            (option) => option.value === dateRangeOption
          )?.key ?? DateRangeKey.Last30Days,
        sort: SortOrder.Asc,
        includeEmptyDates: true,
        imei: asset?.imei ?? "",
        customerOrgId: asset?.customer_orgs_id ?? "",
      },
    },
    {
      refetchInterval: FIVE_MINUTES_REFETCH_INTERVAL,
      enabled: Boolean(asset?.imei),
    }
  );

  useEffect(() => {
    if (data?.searchHistoricalEventHistory?.data) {
      setChartData(
        mapPsiWheelEndSensorDataToChartData(
          data?.searchHistoricalEventHistory?.data as HistoricalEventHistory[],
          tempUnitPreference
        )
      );
    }
  }, [data, tempUnitPreference]);

  const { referenceAreaValues } = usePsiWheelEndSensorProfile(asset?._id);

  return (
    <Box
      className="flex-1 rounded-lg bg-dashboard_subheader__bg p-4 border border-asset-card-border"
      data-testid="asset-dashboard--tires-tab--psi-wheel-end-chart"
    >
      <>
        <TiresChartHeader
          title="Wheel End Temperature"
          subtitle="Trending pressure data over time"
          startDate={startDate}
          setStartDate={setStartDate}
          dateRangeOption={dateRangeOption}
          setDateRangeOption={setDateRangeOption}
          endDate={endDate}
          setEndDate={setEndDate}
          changeDateRangeOption={handleDateRangeChange}
        />

        {isLoading ? (
          <Box
            className="flex h-full w-full self-center justify-center"
            data-testid="asset-dashboard--tires-tab--psi-wheel-end-chart-loader"
          >
            <CircularProgress />
          </Box>
        ) : (
          <PsiWheelEndChartBody
            data={chartData}
            referenceAreaValues={referenceAreaValues}
            tempUnitPreference={tempUnitPreference}
          />
        )}
      </>
    </Box>
  );
};

export default PsiWheelEndChart;
