import { useAppContext } from "../../../context/AppContext";
import { SelectedOrganization } from "../../../context/reducers/selectedOrganization";
import { useGetOrgsDownwardHierarchyQuery } from "../../../graphql/operations";

export type UseOrgsDownwardHierarchyParams = {
  selectedOrganization?: SelectedOrganization;
};

/**
 * Hook to get the downward hierarchy of organizations for the currently selected organization.
 *
 * @params
 */
/**
 * Custom hook to fetch the downward hierarchy of organizations.
 *
 * @param {Object} params.selectedOrganization - The organization to generate the hierarchy for.
 * @returns {string[]} - An array of organization IDs representing the downward hierarchy.
 *
 * @example
 * const orgsHierarchy = useOrgsDownwardHierarchy({
 *   selectedOrganization: { value: 'some-org-object-id', label: 'Organization 1' }
 * });
 */
export const useOrgsDownwardHierarchy = ({
  selectedOrganization,
}: UseOrgsDownwardHierarchyParams = {}): string[] => {
  const {
    state: {
      selectedOrganization: { selectedOrganization: selectedOrgFromStore },
    },
  } = useAppContext();

  const selectedOrg = selectedOrganization ?? selectedOrgFromStore;

  const { data } = useGetOrgsDownwardHierarchyQuery(
    {
      input: {
        selectedOrganizationId: selectedOrg?.value,
        selectedOrganizationLabel: selectedOrg?.label,
      },
    },
    { enabled: !!selectedOrg?.value && !!selectedOrg?.label }
  );

  return data?.getOrgsDownwardHierarchy ?? [];
};
