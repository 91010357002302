import { Box, CircularProgress } from "@mui/material";
import {
  DateRangeKey,
  TPMS_CHART_DATE_RANGE_OPTIONS,
} from "../../../../../../../../constants/map";
import {
  MergedTireSensorConfig,
  SortOrder,
  useGetTpmsSensorHistoricalDataQuery,
} from "../../../../../../../../graphql/operations";
import { useGetTemperatureUnitPreference } from "../../../../../../../../shared/hooks/useGetTemperatureUnitPreference";
import { FIVE_MINUTES_REFETCH_INTERVAL } from "../../../../SummaryTabPanel/Charts/chartUtils";
import {
  convertToIsoStringIgnoringTimezoneOffset,
  sensorConfigToReferenceAreaValues,
} from "../../../helpers";
import TiresChartBody, { Axle } from "../components/tpms/TiresChartBody";
import TiresChartHeader from "../components/tpms/TiresChartHeader";
import { TiresChartType } from "../components/tpms/TiresChartTooltip";
import { useDateFilters } from "./hooks/useDateFilters";
import { useTrendData } from "./hooks/useTrendData";

type TemperatureChartProps = {
  axle: Axle;
  imei: string;
  customerOrgId: string;
  tiresAxleImage: JSX.Element;
  sensorConfig?: MergedTireSensorConfig | null;
};

export type TemperatureReferenceAreaValueType = {
  state: string;
  min: string;
  max: string;
};

const TemperatureChart: React.FC<TemperatureChartProps> = ({
  axle,
  imei,
  customerOrgId,
  tiresAxleImage,
  sensorConfig,
}) => {
  const {
    startDate,
    setStartDate,

    endDate,
    setEndDate,

    dateRangeOption,
    setDateRangeOption,

    handleDateRangeChange,
  } = useDateFilters();

  const { data, isLoading } = useGetTpmsSensorHistoricalDataQuery(
    {
      input: {
        imei,
        customerOrgId: customerOrgId,
        startDate: convertToIsoStringIgnoringTimezoneOffset(startDate),
        endDate: convertToIsoStringIgnoringTimezoneOffset(endDate),
        period:
          TPMS_CHART_DATE_RANGE_OPTIONS.find(
            (option) => option.value === dateRangeOption
          )?.key ?? DateRangeKey.Last30Days,
        includeEmptyDates: true,
        sort: SortOrder.Asc,
      },
    },
    {
      refetchInterval: FIVE_MINUTES_REFETCH_INTERVAL,
      enabled: Boolean(imei),
    }
  );

  const temperatureUnit = useGetTemperatureUnitPreference();

  // Compute temperature trend to display by transforming aggregated data
  const temperatureTrend = useTrendData(
    data,
    axle,
    TiresChartType.Temperature,
    undefined,
    temperatureUnit
  );

  // Compute chart reference areas from sensor configuration

  const referenceAreaValues =
    sensorConfigToReferenceAreaValues(sensorConfig?.match?.thresholds) ?? [];

  return (
    <Box
      className="flex-1 rounded-lg bg-dashboard_subheader__bg border border-asset-card-border p-4"
      data-testid="tires-chart-container"
    >
      <TiresChartHeader
        title={`Axle ${axle} Tire Temperature`}
        subtitle="Trending temperature data over time"
        startDate={startDate}
        setStartDate={setStartDate}
        dateRangeOption={dateRangeOption}
        setDateRangeOption={setDateRangeOption}
        endDate={endDate}
        setEndDate={setEndDate}
        changeDateRangeOption={handleDateRangeChange}
        tiresChartAxleImage={tiresAxleImage}
      />
      {isLoading ? (
        <Box
          className="flex h-full w-full self-center justify-center"
          data-testid="tires-trends-loader"
        >
          <CircularProgress />
        </Box>
      ) : (
        <TiresChartBody
          data={temperatureTrend}
          label="Temperature"
          chartTooltipType={TiresChartType.Temperature}
          axle={axle}
          referenceAreaValues={referenceAreaValues}
          temperatureUnit={temperatureUnit}
        />
      )}
    </Box>
  );
};

export default TemperatureChart;
