export const GENERIC_LOGIN_ERROR_SNACK_PAYLOAD = {
  title: "Something went wrong!",
  text: "Something went wrong on our end, apologies. Please try logging in again later.",
  severity: "error",
};
export const GENERIC_ERROR_SNACK_PAYLOAD = {
  title: "Something went wrong!",
  text: "Something went wrong on our end, apologies. Please try again later.",
  severity: "error",
};
