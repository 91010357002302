import { FC } from "react";
import { Grid, Typography } from "@mui/material";
import { v4 } from "uuid";
import { TriplegridOverlay } from "../../LoadingOverlaySkeletons/TriplegridLoader/TripleGridOverlay";
import BaseDashboardWidget from "../BaseDashboardWidget";
import { NoDataAvailableComponent } from "../EmptyWidgetState/NoDataAvailable";

export type TripleGridWidgetData = {
  title: string;
  columnOneContent: string;
  columnTwoContent: string;
  columnThreeContent: string;
};

interface TripleGridWidgetProps {
  title: string;
  data: TripleGridWidgetData[];
  isLoading: boolean;
  noPermission?: boolean;
}

export const TripleGridWidget: FC<TripleGridWidgetProps> = ({
  title,
  data,
  isLoading = false,
  noPermission,
}) => {
  return (
    <BaseDashboardWidget
      title={title}
      isLoading={isLoading}
      noPermission={noPermission}
    >
      {isLoading ? (
        <TriplegridOverlay />
      ) : (
        <>
          <Grid container>
            {data.map((item, index) => {
              return (
                <Grid item container className="!mb-4" key={v4()}>
                  <Typography
                    data-testid={`tripleGrid-title-${index + 1}`}
                    className="!mb-3 !font-semibold !text-lg !capitalize"
                  >
                    {item.title}
                  </Typography>
                  <Grid item container rowSpacing={1} columnSpacing={2}>
                    <Grid item xs={12} sm={4}>
                      <Typography
                        data-testid={`tripleGrid-column-one-${index + 1}`}
                        className="p-2 rounded !text-lg !font-medium !capitalize bg-triple-grid-widget-label"
                        sx={{ color: "var(--dark-charcoal)" }}
                      >
                        {item.columnOneContent}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <Typography
                        data-testid={`tripleGrid-column-two-${index + 1}`}
                        className="p-2 rounded !text-lg !font-medium !capitalize bg-triple-grid-widget-label"
                        sx={{ color: "var(--dark-charcoal)" }}
                      >
                        {item.columnTwoContent}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <Typography
                        data-testid={`tripleGrid-column-three-${index + 1}`}
                        className="p-2 rounded !text-lg !font-medium !capitalize bg-triple-grid-widget-label"
                        sx={{ color: "var(--dark-charcoal)" }}
                      >
                        {item.columnThreeContent}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              );
            })}
          </Grid>
          {!data?.length && !isLoading && (
            <NoDataAvailableComponent title={"No data available"} />
          )}
        </>
      )}
    </BaseDashboardWidget>
  );
};
