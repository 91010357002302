import { lazy, memo, Suspense, useEffect, useMemo } from "react";
import { useCookies } from "react-cookie";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
//  Views
import { ThemeProvider } from "@mui/material";
import { LicenseInfo } from "@mui/x-license-pro";
import config from "./config";
import {
  SELECTED_ORGANIZATION,
  SET_APP_THEME,
  SET_SELECTED_ORGANIZATION,
  USER_REDIRECT_URL,
} from "./constants";
import { useAppContext } from "./context/AppContext";
import { useAuthContext } from "./context/AuthContext";
import { useGetUserDataQuery, OrgData } from "./graphql/operations";
import RequireAuth from "./permissions/RequireAuth";
import Spinner from "./shared/components/Spinner";
import { useMUITheme } from "./shared/hooks/theme/useMUITheme";
import { useTheme } from "./shared/hooks/theme/useTheme";
import { useThemeSynchronizer } from "./shared/hooks/theme/useThemeSynchronizer";
import { themes } from "./shared/hooks/theme/utils";
import { useSetAppConfig } from "./shared/hooks/useGetAppConfig";
import usePendo from "./shared/hooks/usePendo";
import { useFeatureFlag } from "./utils";
import { addAnyOfMethod } from "./utils/addAnyOfMethod";
import { FeatureFlags } from "./utils/featureFlagsConstants";
import { NavigationRoutes } from "./utils/routes/routesUtils";
import { useCheckRouteSecurity } from "./utils/routes/useCheckRouteSecurity";
import { withDisplayName } from "./utils/withDisplayName";
import AdminPanel from "./views/AdminPanel";
import { AutomationView } from "./views/AdminPanel/tabs/Automations/AutomationView";
import { AlertsView } from "./views/AlertsView";
import AssetsView from "./views/AssetsView";
import CustomLogin from "./views/CustomAuthentication/CustomLogin/CustomLogin";
import ConfirmForgotPassword from "./views/CustomAuthentication/ForgotPassword/ConfirmForgotPassword";
import ForgotPassword from "./views/CustomAuthentication/ForgotPassword/ForgotPassword";
import MfaAuthAppSetup from "./views/CustomAuthentication/Mfa/MfaAuthAppSetup";
import MFASmsValidation from "./views/CustomAuthentication/Mfa/MfaSMSValidationStep";
import MfaVerifyAuthAppCode from "./views/CustomAuthentication/Mfa/MfaVerifyAuthAppCode";
import NewUserPassword from "./views/CustomAuthentication/NewUserPassword/NewUserPassword";
import DashboardsView from "./views/DashboardsView";
import DeviceManagementView from "./views/DeviceManagementView/DeviceManagementView";
import Login from "./views/LoginView/Login";
import { ReportsListView } from "./views/ReportsListView";
import TermsAndConditions from "./views/TermsAndConditions";

const MyAccountComponent = lazy(() => import("./views/MyAccountView"));
const MyAccount = withDisplayName(MyAccountComponent, "MyAccount");

const BatchManagementComponent = lazy(() => import("./views/BatchesView"));
const BatchManagement = withDisplayName(
  BatchManagementComponent,
  "BatchManagement"
);

LicenseInfo.setLicenseKey(config.muiPremiumLicence);
addAnyOfMethod();

const App = () => {
  const [redirectCookies, setRedirectCookie, removeRedirectCookie] = useCookies(
    [USER_REDIRECT_URL]
  );

  useSetAppConfig();
  const { tokens, isAuthorized } = useAuthContext();
  const { dispatch } = useAppContext();
  const { data: accountData } = useGetUserDataQuery(undefined, {
    enabled: isAuthorized,
  });
  const { theme, setPreference } = useTheme();
  const { defaultPath, isLoadingDefaultPath, isFetchingDefaultPath } =
    useCheckRouteSecurity();
  usePendo();

  const appTheme = useMemo(() => {
    const userPreferencedTheme = (accountData?.me?.user_preferences?.ui_theme ??
      localStorage.getItem("theme")) as keyof typeof themes;
    return themes[userPreferencedTheme] ?? themes.light;
  }, [accountData]);
  const {
    state: {
      selectedOrganization: { selectedOrganization },
    },
  } = useAppContext();

  const muiTheme = useMUITheme(theme);
  useThemeSynchronizer(theme as any);
  const [cookies, setCookie] = useCookies([SELECTED_ORGANIZATION]);
  const enableCustomAuthFlowFeatureFlag = useFeatureFlag(
    FeatureFlags.connect1RolloutCustomAuthFlow
  );

  const isSelectedOrgAccessible = (
    accessibleOrgs: OrgData[],
    selectedOrgId: string
  ) => {
    // Check if accessibleOrgs has length and if selectedOrgId is a valid string
    if (!accessibleOrgs?.length || !selectedOrgId) return false;

    // Check if the selectedOrgId exists in the accessibleOrgs array
    return accessibleOrgs.some((org: OrgData) => org._id === selectedOrgId);
  };

  // clear the USER_REDIRECT_URL cookie when we visit the page stored in this cookie
  useEffect(() => {
    if (window.location.pathname === redirectCookies[USER_REDIRECT_URL]) {
      removeRedirectCookie(USER_REDIRECT_URL);
    }
  });

  useEffect(() => {
    dispatch({
      type: SET_APP_THEME,
      payload: appTheme,
    });
    setPreference(appTheme);
  }, [setPreference, appTheme, dispatch]);

  useEffect(() => {
    // Do not update the selected organization before the user data is fetched
    if (!accountData) return;

    // Check if the selected organization is accessible for the user
    const isAccessible = isSelectedOrgAccessible(
      accountData?.me?.accessibleOrgs,
      cookies?.SELECTED_ORGANIZATION?.value
    );

    const defaultOrgData = {
      label: accountData?.me?.customerOrg?.name,
      value: accountData?.me?.customerOrg?._id,
      timezone: accountData?.me?.customerOrg?.time_zones,
    };
    let newOrg;
    if (cookies?.SELECTED_ORGANIZATION?.value && isAccessible) {
      newOrg = cookies.SELECTED_ORGANIZATION;
    } else if (selectedOrganization?.value && isAccessible) {
      newOrg = selectedOrganization;
    } else {
      newOrg = defaultOrgData;
    }
    if (
      selectedOrganization?.value !== newOrg.value ||
      selectedOrganization?.label !== newOrg.label
    ) {
      dispatch({
        type: SET_SELECTED_ORGANIZATION,
        payload: newOrg,
      });
      setCookie(SELECTED_ORGANIZATION, newOrg, { path: "/" });
    }
  }, [accountData, cookies, selectedOrganization, dispatch, setCookie]);

  //  Routes
  return (
    <ThemeProvider theme={muiTheme}>
      <Spinner counter={Number(isAuthorized && isLoadingDefaultPath)} />
      <BrowserRouter>
        <Suspense fallback={<Spinner />}>
          <Routes>
            <Route
              path={NavigationRoutes.Root}
              element={
                (!tokens && <Navigate to={NavigationRoutes.Login} />) ||
                (!isLoadingDefaultPath && <Navigate to={defaultPath} />)
              }
            />
            <Route
              path={`${NavigationRoutes.Assets}/*`}
              element={
                <RequireAuth>
                  <AssetsView />
                </RequireAuth>
              }
            />

            <Route
              path={`${NavigationRoutes.Geofences}/:id?`}
              element={
                <RequireAuth>
                  <AssetsView isGeofencesView />
                </RequireAuth>
              }
            />

            <Route
              path={NavigationRoutes.MyAccount}
              element={
                <RequireAuth>
                  <MyAccount />
                </RequireAuth>
              }
            />
            <Route
              path={`${NavigationRoutes.Devices}/*`}
              element={
                <RequireAuth>
                  <DeviceManagementView />
                </RequireAuth>
              }
            />
            <Route
              path={NavigationRoutes.Batches}
              element={
                <RequireAuth>
                  <BatchManagement />
                </RequireAuth>
              }
            />
            <Route
              path={`${NavigationRoutes.Dashboard}/*`}
              element={
                <RequireAuth>
                  <DashboardsView />
                </RequireAuth>
              }
            />

            <Route
              path={`${NavigationRoutes.Reports}/*`}
              element={
                <RequireAuth>
                  <ReportsListView />
                </RequireAuth>
              }
            />

            <Route
              path={`${NavigationRoutes.Alerts}/*`}
              element={
                <RequireAuth>
                  <AlertsView />
                </RequireAuth>
              }
            />
            <Route
              path={`${NavigationRoutes.AdminPanel}/*`}
              element={
                <RequireAuth>
                  <AdminPanel />
                </RequireAuth>
              }
            />
            <Route
              path={NavigationRoutes.TermsAndConditions}
              element={<TermsAndConditions />}
            />
            <Route
              path={NavigationRoutes.CustomLogin}
              element={<CustomLogin />}
            />
            <Route
              path={NavigationRoutes.MfaVerifyAuthAppCode}
              element={<MfaVerifyAuthAppCode />}
            />
            <Route
              path={NavigationRoutes.MfaAuthAppSetup}
              element={<MfaAuthAppSetup />}
            />
            <Route
              path={NavigationRoutes.NewUserPassword}
              element={<NewUserPassword />}
            />
            <Route
              path={NavigationRoutes.MfaSmsVerification}
              element={<MFASmsValidation />}
            />
            <Route
              path={NavigationRoutes.ForgotPassword}
              element={<ForgotPassword />}
            />
            <Route
              path={NavigationRoutes.ConfirmForgotPassword}
              element={<ConfirmForgotPassword />}
            />

            <Route
              path={`${NavigationRoutes.AdminPanelAutomations}/:id`}
              element={<AutomationView />}
            />

            {!tokens && (
              <Route
                path={NavigationRoutes.Login}
                element={
                  enableCustomAuthFlowFeatureFlag ? <CustomLogin /> : <Login />
                }
              />
            )}

            {/* Default */}
            {!isFetchingDefaultPath && (
              <Route path="*" element={<Navigate to={defaultPath} />} />
            )}
          </Routes>
        </Suspense>
      </BrowserRouter>
    </ThemeProvider>
  );
};

export default memo(App);
