import { useCallback, useMemo } from "react";
import { Autocomplete, Box, Divider, Typography } from "@mui/material";
import TextField from "@mui/material/TextField";
import { property } from "lodash";
import { useAppContext } from "../../../../../../context/AppContext";
import {
  GeofenceShape,
  useGetGeofencesTagsQuery,
} from "../../../../../../graphql/operations";
import { SwitchButtons } from "../../../../../../shared/components/SwitchButtons";
import { ToggleButtonOption } from "../../../../../../shared/components/ToggleButtons";
import { useAssetTypes } from "../../../../../../shared/hooks/useAssetTypes";
import { useRegionsList } from "../../../../../../shared/hooks/useRegionsList";
import {
  useGeofenceNameOptions,
  useGeofenceTagOptions,
  useGeofenceTypeOptions,
} from "../../../../TableView/hooks";
import {
  GeofenceShapesFilter,
  useAssetsDataContext,
} from "../../../../shared/AssetsDataContext";
import { SelectedValueType } from "../../../../shared/AssetsFilterControls/Filters/GeofenceFiltersTab/GeofenceFiltersTab";
import { ZonesFilter } from "../../ZonesFilter/ZonesFilter";

const HAS_ASSETS_OPTIONS: ToggleButtonOption[] = [
  {
    value: "all",
    label: "All",
  },
  {
    value: "true",
    label: "Has assets present",
  },
  {
    value: "false",
    label: "Has no assets present",
  },
];

export const BaseGeofenceFilters = () => {
  const {
    state: {
      theme: { theme },
    },
  } = useAppContext();

  const isDarkMode = theme === "dark";
  const {
    currentGeofenceFilter: {
      names,
      shapes,
      typeIds,
      tags,
      underMin,
      overMax,
      hasAssets,
      regions,
    },
    onChangeGeofenceFilters,
    geofenceTypes,
    geofencesByViewport,
    geofences,
  } = useAssetsDataContext();
  const geofenceNameOpts = useGeofenceNameOptions(geofences);
  const geofenceTypesOpts = useGeofenceTypeOptions(geofenceTypes);
  const { assetTypeOptionsState: assetTypeOpts } = useAssetTypes();
  const { data: geofenceTagsData } = useGetGeofencesTagsQuery({ input: {} });
  const geofenceTags = useMemo(
    () => geofenceTagsData?.getGeofencesTags ?? [],
    [geofenceTagsData]
  );

  const { regions: regionsData, isLoading: isRegionsLoading } =
    useRegionsList();
  const regionOptions =
    regionsData
      ?.map((region) => ({
        country: region?.country ?? "",
        label: region?.name,
        value: region?.value,
      }))
      .sort((reg1, reg2) => reg1.country.localeCompare(reg2.country)) ?? [];

  const geofenceTagsOpts = useGeofenceTagOptions(geofenceTags);

  let geofenceFiltersCount = 0;
  geofenceFiltersCount += names?.length ? 1 : 0;
  geofenceFiltersCount += shapes !== "all" ? 1 : 0;
  geofenceFiltersCount += typeIds?.length ? 1 : 0;
  geofenceFiltersCount += tags?.length ? 1 : 0;
  geofenceFiltersCount += underMin?.length ? 1 : 0;
  geofenceFiltersCount += overMax?.length ? 1 : 0;
  geofenceFiltersCount += hasAssets !== "all" ? 1 : 0;
  geofenceFiltersCount += regions?.value?.length ? 1 : 0;

  const allGeofencesCount = geofencesByViewport?.length;
  let polygonGeofencesCount = 0;
  let circleGeofencesCount = 0;

  geofencesByViewport?.forEach((el) => {
    if (el.geofence?.gisConfig?.shape === "Polygon") {
      polygonGeofencesCount++;
    } else if (el.geofence?.gisConfig?.shape === "Circle") {
      circleGeofencesCount++;
    }
  });

  const GEOFENCE_SHAPE_OPTIONS: ToggleButtonOption[] = [
    {
      value: "all",
      label: "All",
      subLabel: allGeofencesCount?.toString(),
    },
    {
      value: GeofenceShape.Polygon,
      label: "Polygon",
      subLabel: polygonGeofencesCount?.toString(),
    },
    {
      value: GeofenceShape.Circle,
      label: "Radius",
      subLabel: circleGeofencesCount?.toString(),
    },
  ];

  const onChangeShape = useCallback(
    (value: SelectedValueType) =>
      onChangeGeofenceFilters({
        shapes: (value ?? "all") as GeofenceShapesFilter,
      }),
    [onChangeGeofenceFilters]
  );

  const onChangeAssets = useCallback(
    (value: SelectedValueType) => {
      switch (value) {
        case "all":
          return onChangeGeofenceFilters({ hasAssets: "all" });
        case "true":
          return onChangeGeofenceFilters({ hasAssets: true });
        case "false":
          return onChangeGeofenceFilters({ hasAssets: false });
      }
    },
    [onChangeGeofenceFilters]
  );

  const color = isDarkMode ? "white" : "black";

  return (
    <>
      <div className="flex justify-between">
        <Typography className="!text-lg !font-semibold pb-6 px-0">
          Geofence
        </Typography>
        {/* Hidden for now as client is still deciding if they want it */}
        {/* <Typography className="!text-lg !font-semibold px-0">
          {geofenceFiltersCount ?? ""}
        </Typography> */}
      </div>
      <Box>
        <Box className="font-bold text-[10px] leading-4">Name</Box>
        <Autocomplete
          data-testid="geofence-filter__name"
          limitTags={3}
          multiple
          options={geofenceNameOpts}
          fullWidth
          value={names}
          isOptionEqualToValue={(o, v) => o.id === v.id}
          onChange={(_, value) => onChangeGeofenceFilters({ names: value })}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Search Geofence Names"
              data-testid="geofence-names-input"
            />
          )}
        />
      </Box>
      <Divider className="!mt-2 !mb-3" />

      <Box className="h-16 mt-4 flex flex-col justify-between">
        <Box className="font-bold text-[10px] leading-4">Geofence Shape</Box>

        <SwitchButtons
          id="geofences-shape-switch"
          data-testid="geofences-shape-switch"
          value={shapes}
          onChange={(_, value) => value !== null && onChangeShape(value)}
          options={GEOFENCE_SHAPE_OPTIONS}
          size="small"
          groupclass={`flex justify-between h-[40px] overflow-hidden !rounded-[30px] p-0.5 !border !border-${color}`}
          className={`!text-${color} !font-medium`}
          fullWidth
          exclusive
        />
      </Box>
      <Divider className="!mt-2 !mb-3" />

      <Box>
        <Box className="font-bold text-[10px] leading-4">Geofence Type</Box>

        <Autocomplete
          data-testid="geofence-filter__type-ids"
          limitTags={3}
          multiple
          options={geofenceTypesOpts}
          size="small"
          value={typeIds}
          isOptionEqualToValue={(o, v) => o.id === v.id}
          onChange={(_, value) => onChangeGeofenceFilters({ typeIds: value })}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Search Geofence Type"
              data-testid="geofence-type-input"
            />
          )}
        />
      </Box>
      <Divider className="!mt-2 !mb-3" />

      <Box>
        <Typography className="!font-bold !text-[10px] !leading-4">
          Under Min
        </Typography>
        <Autocomplete
          data-testid="geofence-filter__under-min"
          limitTags={3}
          multiple
          options={assetTypeOpts}
          size="small"
          value={underMin}
          isOptionEqualToValue={(o, v) => o.id === v.id}
          onChange={(_, value) => onChangeGeofenceFilters({ underMin: value })}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Select Assets"
              data-testid="geofence-under-min-input"
            />
          )}
        />
      </Box>
      <Divider className="!mt-2 !mb-3" />

      <Box>
        <Typography className="!font-bold !text-[10px] !leading-4">
          Over Max
        </Typography>
        <Autocomplete
          data-testid="geofence-filter__over-max"
          limitTags={3}
          multiple
          options={assetTypeOpts}
          size="small"
          value={overMax}
          isOptionEqualToValue={(o, v) => o.id === v.id}
          onChange={(_, value) => onChangeGeofenceFilters({ overMax: value })}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Select Assets"
              data-testid="geofence-over-max-input"
            />
          )}
        />
      </Box>
      <Divider className="!mt-2 !mb-3" />

      <Box>
        <Typography className="!font-bold !text-[10px] !leading-4">
          Geofence Tags
        </Typography>
        <Autocomplete
          data-testid="geofence-filter__geofence-tags"
          limitTags={3}
          multiple
          options={geofenceTagsOpts}
          size="small"
          value={tags}
          isOptionEqualToValue={(o, v) => o.id === v.id}
          onChange={(_, value) => onChangeGeofenceFilters({ tags: value })}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Search Tags"
              data-testid="geofence-tags-input"
            />
          )}
        />
      </Box>
      <Divider className="!mt-2 !mb-3" />

      <Box className="h-20 mt-4 flex flex-col justify-evenly">
        <Box className="font-bold text-[10px] leading-4">Geofence Assets</Box>

        <SwitchButtons
          id="geofence-filter__has-assets"
          data-testid="geofence-filter__has-assets"
          value={hasAssets?.toString()}
          onChange={(_, value) => onChangeAssets(value)}
          options={HAS_ASSETS_OPTIONS}
          size="small"
          groupclass={`flex justify-between h-[40px] overflow-hidden !rounded-[30px] p-0.5 !border !border-${color}`}
          className={`!text-${color} !font-medium`}
          fullWidth
          exclusive
        />
      </Box>
      <Divider className="!mt-2 !mb-3" />

      <Box className="my-4 flex flex-col justify-evenly">
        <Box className="font-bold text-[10px] leading-4">Regions</Box>
        <Autocomplete
          sx={{
            "& .MuiChip-label": {
              maxWidth: "15ch",
            },
          }}
          data-testid="geofence-filter__regions"
          groupBy={property("country")}
          options={regionOptions}
          disabled={isRegionsLoading}
          loading={isRegionsLoading}
          size="small"
          value={regions}
          isOptionEqualToValue={(o, v) => o.value === v.value}
          onChange={(_, value) =>
            onChangeGeofenceFilters({
              regions: value,
            })
          }
          renderInput={(params) => (
            <TextField {...params} label="Search" data-testid="regions-input" />
          )}
        />
      </Box>
      <Divider className="!mt-2 !mb-3" />

      <ZonesFilter type="geofences" />
      <Divider className="!mt-2 !mb-3" />
    </>
  );
};
