import { UseMutateFunction } from "@tanstack/react-query";
import {
  CreateReportInput,
  CreateReportMutation,
  Exact,
  ReportType,
  UpdateReportInput,
  UpdateReportMutation,
  useCreateReportMutation,
  useUpdateReportMutation,
} from "../../../graphql/operations";

interface IUseReportApi {
  reportType: string;
  createReportOnSuccess: (
    data: CreateReportMutation,
    variables: Exact<{
      input: CreateReportInput;
    }>,
    context: unknown
  ) => unknown;
  createReportOnError: (error: unknown) => void;
  updateReportOnSuccess: (
    data: UpdateReportMutation,
    variables: Exact<{
      input: UpdateReportInput;
    }>,
    context: unknown
  ) => unknown;
  updateReportOnError: (error: unknown) => void;
}

interface IUseReportApiResult {
  createReport: UseMutateFunction<
    CreateReportMutation,
    unknown,
    Exact<{
      input: CreateReportInput;
    }>,
    unknown
  >;
  updateReport: UseMutateFunction<
    UpdateReportMutation,
    unknown,
    Exact<{
      input: UpdateReportInput;
    }>,
    unknown
  >;
}

export const useReportApi = ({
  reportType,
  createReportOnSuccess,
  createReportOnError,
  updateReportOnSuccess,
  updateReportOnError,
}: IUseReportApi): IUseReportApiResult => {
  const { mutate: createReport } = useCreateReportMutation({
    onSuccess: createReportOnSuccess,
    onError: createReportOnError,
  });
  const { mutate: updateReport } = useUpdateReportMutation({
    onSuccess: updateReportOnSuccess,
    onError: updateReportOnError,
  });
  if (
    [
      ReportType.Accounts,
      ReportType.AlertHistory,
      ReportType.AssetBalance,
      ReportType.AssetInstall,
      ReportType.AssetInventory,
      ReportType.AssetTransfer,
      ReportType.Mileage,
      ReportType.AssetYard,
      ReportType.OutsideGeofence,
      ReportType.DwellHierarchy,
      ReportType.MediaActivities,
      ReportType.Sensors,
      ReportType.AssetAbsFaultHistory,
      ReportType.AssetDetention,
    ].includes(reportType as ReportType)
  ) {
    return {
      createReport,
      updateReport,
    };
  }
  return {
    createReport: () => {},
    updateReport: () => {},
  };
};
