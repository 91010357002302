import { useState, FC, useEffect, useCallback } from "react";
import { Box } from "@mui/material";
import { GridSortModel, useGridApiRef } from "@mui/x-data-grid-premium";
import { isUndefined } from "lodash";
import { useAppContext } from "../../../../../context/AppContext";
import {
  FiltersInput,
  TableFilterLinkOperator,
  TableFiltersInput,
  TableViewType,
} from "../../../../../graphql/operations";
import { Table, TableDataModes } from "../../../../../shared/components/Table";
import useExportedFileNameForOrg from "../../../../../shared/hooks/useExportedFileNameForOrg";
import { usePreferredTimezone } from "../../../../../shared/hooks/usePreferredTimezone";
import { validateAndMapFiltersOS } from "../../../../ReportView/AssetReport/utils";
import {
  SEARCH_KEYS,
  getColumns,
  columnVisibilityModel,
  ASSET_ACTIVITY_COLUMN_TYPES_MAP,
} from "./columns";
import { TableAssetEvent } from "./types";
import useAssetEvents from "./useAssetEvents";

export interface AssetActivityLogProps {
  selectedAssetId: string;
  selectedAssetOrgName: string;
}

export const AssetActivityLog: FC<AssetActivityLogProps> = ({
  selectedAssetId,
  selectedAssetOrgName,
}) => {
  const [tableRows, setTableRows] = useState<TableAssetEvent[]>([]);
  const [totalTableRowsCount, setTotalTableRowsCount] = useState<number>(0);
  const [currentPageNo, setCurrentPageNo] = useState<number>(1);
  const [paginationSkip, setPaginationSkip] = useState<number>(0);
  const [filters, setFilters] = useState<TableFiltersInput | null>(null);
  const [searchValue, setSearchValue] = useState("");

  const initialSortModel: GridSortModel = [
    { field: "updatedAt", sort: "desc" },
  ];
  const [sortModel, setSortModel] = useState<GridSortModel>(initialSortModel);

  const fileName = useExportedFileNameForOrg(
    selectedAssetOrgName,
    "Asset Activity Log"
  );
  const gridApiRef = useGridApiRef();
  const userTimezone = usePreferredTimezone();
  const columns = getColumns(userTimezone);
  const {
    state: { appConfig },
  } = useAppContext();
  const activityLogsPerPage = appConfig.table.defaultRowsPerPage;

  const { assetEvents, pagination, isLoading, isSuccess, refetch } =
    useAssetEvents({
      selectedAssetId,
      filters,
      paginationSkip,
      activityLogsPerPage,
      sortModel,
      initialSortModel,
      searchValue,
      selectedAssetOrgName,
    });

  const onPageChange = useCallback(
    (page: number) => {
      setCurrentPageNo(page);
      setPaginationSkip(activityLogsPerPage * (page - 1));
    },
    [activityLogsPerPage]
  );

  const onFilterChange = (value: {
    items: FiltersInput[];
    linkOperator: TableFilterLinkOperator;
  }) => {
    setCurrentPageNo(1);
    setPaginationSkip(0);
    const newFilters = validateAndMapFiltersOS({
      ...value,
      columnTypesMap: ASSET_ACTIVITY_COLUMN_TYPES_MAP,
    });
    if (!isUndefined(newFilters)) {
      setFilters(newFilters);
    }
  };

  const onSearch = useCallback((value: string) => {
    setSearchValue(value);
  }, []);

  const onSortModelChange = useCallback((sortModel: GridSortModel) => {
    setSortModel(sortModel);
  }, []);

  useEffect(() => {
    refetch();
  }, [searchValue, sortModel, filters, refetch]);

  useEffect(() => {
    setTableRows(assetEvents);
    setTotalTableRowsCount(pagination?.total ?? 0);
  }, [isSuccess, assetEvents, pagination?.total]);

  return (
    <Box className="h-full w-full bg-background">
      <Box className="h-full w-full pt-2 px-4 pb-4 md:px-6 md:pb-10 lg:px-16 lg:pb-20">
        <Table
          tableType={TableViewType.AssetsActivityLog}
          handleDataMode={TableDataModes.SemiServer}
          columns={columns}
          rows={tableRows}
          rowCount={totalTableRowsCount}
          initialSearch={""}
          error={!isLoading && !isSuccess ? true : null}
          showToolbar
          apiRef={gridApiRef}
          columnVisibilityModel={columnVisibilityModel}
          enableSearch
          searchExactMatch
          searchKeys={SEARCH_KEYS}
          searchMinLength={appConfig.searchMinLength}
          getRowId={(row) => row._id}
          sx={{ "& .MuiDataGrid-row": { cursor: "pointer" } }}
          exportProps={{
            csvOptions: { fileName },
            excelOptions: { fileName },
            printOptions: { fileName },
          }}
          initialState={{
            sorting: { sortModel: [initialSortModel] },
          }}
          tableName="asset_activity_log"
          pageSize={activityLogsPerPage}
          loading={isLoading}
          currentPage={currentPageNo}
          onPageChange={onPageChange}
          onPaginationModelChange={onPageChange}
          allowExport
          disableRowGrouping
          onSearch={onSearch}
          onFilterModelChange={onFilterChange}
          onSortModelChange={onSortModelChange}
        />
      </Box>
    </Box>
  );
};
