import { FC, useEffect, useMemo } from "react";
import { useForm, FieldValues } from "react-hook-form";
import {
  TextFieldElement,
  CheckboxElement,
  AutocompleteElement,
} from "react-hook-form-mui";
import { yupResolver } from "@hookform/resolvers/yup";
import CloseIcon from "@mui/icons-material/Close";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  ThemeProvider,
  Grid,
  IconButton,
  Box,
  Button,
} from "@mui/material";
import { isEmpty } from "lodash";
import * as yup from "yup";
import { ROOT_ORGANIZATION_NAME } from "../../../../constants";
import {
  Dashboard,
  DashboardType,
  DashboardVisibility,
  Maybe,
  OrgData,
  useFindOrgsQuery,
} from "../../../../graphql/operations";
import {
  TextButton,
  Button as SubmitButton,
} from "../../../../shared/components/Button";
import { CustomTooltip } from "../../../../shared/components/CustomTooltip/CustomTooltip";
import Text from "../../../../shared/components/Text";
import { useFormTheme } from "../../../../shared/hooks/theme/useFormTheme";
import useBreakpoint from "../../../../shared/hooks/useBreakpoint";
import { Option } from "../../../../views/AdminPanel/tabs/Configurations/configurationsUtils";
import { useOrgsOptions } from "../../../AssetsView/TableView/hooks";

export interface CreateEditDashboardDialogProps {
  open: boolean;
  isLoading: boolean;
  processing: boolean;
  type: DashboardDialogType | undefined;
  onClose: () => void;
  onSubmit: (formData: FieldValues) => void;
  isDashboardFavoriteForTheUserOrganization?: boolean;
  onDelete?: () => void;
  dashboard?: Dashboard;
  dashboards?: Maybe<Dashboard>[];
}

export enum DashboardDialogType {
  Create = "create",
  Edit = "edit",
}

export enum DashboardTemplateOptions {
  Blank = "blank",
  Organization = "organization",
  Device = "device",
  AssetHealth = "assetHealth",
  AssetOverview = "assetOverview",
  Geofence = "geofence",
}

const getValidationSchema = (isEditMode: string) => {
  return yup.object().shape({
    name: yup
      .string()
      .max(
        32,
        "The name of the Dashboard is too long. Maximum length is 32 characters."
      )
      .required("Field is required!"),
    orgName: yup.string().required("Field is required!"),
    public: yup.boolean(),
    template: yup.string().when(isEditMode, {
      is: "create",
      then: yup.string().required("This field is required"),
      otherwise: yup.string().notRequired(),
    }),
  });
};

const BREAKPOINTS = { xs: 12 };

const CreateEditDashboardDialog: FC<CreateEditDashboardDialogProps> = ({
  dashboards,
  open,
  isLoading,
  processing,
  type,
  onClose,
  onSubmit: onSubmitProp,
  onDelete: onDeleteProp,
  dashboard,
  isDashboardFavoriteForTheUserOrganization,
}) => {
  const isInitialDashboard = dashboard?.type === DashboardType.Default;
  const isEditDialog = type === DashboardDialogType.Edit;
  const formTheme = useFormTheme();
  const { data: dataOrgsList } = useFindOrgsQuery();
  const isMobile = useBreakpoint("down", "sm");

  const dashboardTemplateOptions: {
    id: string;
    value: string;
    label: string;
  }[] = useMemo(() => {
    if (dashboards?.length) {
      const emptyDashboardOption = {
        value: "0",
        id: "0",
        label: "Blank Dashboard",
      };
      const dashboardOptions = dashboards.map((dashboard) => ({
        value: (dashboard?.id ?? "").toString(),
        id: (dashboard?.id ?? "").toString(),
        label: dashboard?.name as string,
      }));
      return [emptyDashboardOption, ...dashboardOptions];
    } else return [];
  }, [dashboards]);

  const orgsData = useMemo(
    () => dataOrgsList?.findOrgs || [],
    [dataOrgsList?.findOrgs]
  );
  const orgsOptionsSorted = useOrgsOptions(orgsData);
  const orgOptions = useMemo(
    () =>
      orgsOptionsSorted.map((org) => ({
        value: org.id as keyof OrgData,
        id: org.id as keyof OrgData,
        label: org.label,
      })),
    [orgsOptionsSorted]
  );

  const { control, handleSubmit, reset, formState } = useForm({
    resolver: yupResolver(getValidationSchema(type as string)),
    defaultValues: {
      name: "",
      orgName: "",
      template: "0",
      public: false,
    },
  });

  const onSubmit = (formData: FieldValues) => {
    onSubmitProp(formData);
  };

  useEffect(() => {
    if (isEditDialog) {
      const dashboardVisibility =
        dashboard?.visibility === DashboardVisibility.Public;
      const rootOrg = orgsData.find(
        (org) => org.name === ROOT_ORGANIZATION_NAME
      );
      let dashboardOrgId = dashboard?.customerOrgId ?? "";

      if (isInitialDashboard && rootOrg) {
        // Initial dashboards don't have `customerOrgId`, so we display the root organization
        dashboardOrgId = rootOrg._id;
      }

      reset({
        name: dashboard?.name ?? "",
        orgName: dashboardOrgId,
        public: dashboardVisibility,
      });
    }
  }, [isEditDialog, reset, dashboard, isInitialDashboard, orgsData]);

  const isDeleteDisabled = useMemo(
    () => isInitialDashboard || isDashboardFavoriteForTheUserOrganization,
    [isDashboardFavoriteForTheUserOrganization, isInitialDashboard]
  );

  return (
    <Dialog
      data-testid={
        isEditDialog ? "edit-dashboard-dialog" : "create-dashboard-dialog"
      }
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: {
          padding: "32px 24px 24px 24px",
          width: "100%",
          maxWidth: "680px !important",
        },
      }}
    >
      <DialogTitle className="flex justify-between items-center !p-0 !pb-[32px]">
        <Text
          classes="!text-2xl !font-semibold capitalize !text-typography-secondary "
          dataTestId="create-edit-dashboard-dialog-title"
        >
          {isEditDialog ? "Edit Dashboard" : "New Dashboard"}
        </Text>
        <IconButton
          aria-label="close"
          onClick={onClose}
          data-testid="create-edit-dashboard-dialog-close-icon-btn"
          className="h-6 w-6 flex align-center"
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <ThemeProvider theme={formTheme}>
        <form onSubmit={handleSubmit(onSubmit)} noValidate>
          <DialogContent className="!p-0 !pb-[24px] overflow-hidden	">
            <Grid
              container
              className="bg-background topPaddingDrawerSection !pt-0"
            >
              <Grid
                item
                data-testid="create-edit-dashboard-name-field-wrapper"
                xs={12}
                sx={{ marginBottom: "1rem" }}
              >
                <TextFieldElement
                  data-testid="name-control"
                  fullWidth
                  control={control}
                  disabled={isLoading || processing}
                  name="name"
                  required
                  label="Dashboard Name"
                />
              </Grid>

              <Grid
                item
                {...BREAKPOINTS}
                data-testid="orgName-control"
                sx={{ marginBottom: "1rem" }}
              >
                <AutocompleteElement
                  autocompleteProps={{
                    disabled: isLoading || type === DashboardDialogType.Edit,
                  }}
                  required
                  matchId={true}
                  label={"Organization"}
                  control={control}
                  name={"orgName"}
                  options={orgOptions ?? ([] as Option[])}
                />
              </Grid>
              {!isEditDialog && (
                <Grid item {...BREAKPOINTS} sx={{ marginBottom: "1rem" }}>
                  <AutocompleteElement
                    autocompleteProps={{
                      disabled: isLoading,
                    }}
                    matchId={true}
                    required
                    label={"Template"}
                    control={control}
                    name={"template"}
                    rules={{ required: true }}
                    options={dashboardTemplateOptions}
                  />
                </Grid>
              )}

              <Grid item sm={12} className="!pt-0">
                <Grid container>
                  <Grid item sm={6} className="!p-0">
                    <CheckboxElement
                      disabled={isInitialDashboard}
                      name="public"
                      label="Public"
                      control={control}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions
            sx={{
              display: "flex",
              flexDirection: isMobile ? "column" : "row",
              justifyContent: "space-between",
              gap: "1.5rem",
              margin: "0 auto",
              padding: 0,
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignSelf: isMobile ? "flex-start" : "center",
                height: "100%",
              }}
            >
              {isEditDialog && onDeleteProp && (
                <CustomTooltip
                  title={
                    isDashboardFavoriteForTheUserOrganization
                      ? "This dashboard is used by your organization and cannot be deleted"
                      : ""
                  }
                  arrow
                >
                  <span>
                    <Button
                      variant="text"
                      startIcon={
                        <DeleteOutlineIcon sx={{ width: 24, height: 24 }} />
                      }
                      data-testid={"edit-dashboard-actions-delete-btn"}
                      onClick={isDeleteDisabled ? () => {} : onDeleteProp}
                      className={
                        isDeleteDisabled
                          ? "!text-light-charcoal !cursor-default"
                          : "!text-error"
                      }
                      sx={{
                        fontSize: "1rem",
                        fontWeight: 800,
                        textTransform: "inherit",
                        padding: 0,
                        ":hover": {
                          textDecoration: "underline",
                          backgroundColor: "inherit",
                        },
                      }}
                    >
                      Delete Dashboard
                    </Button>
                  </span>
                </CustomTooltip>
              )}
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                alignSelf: isMobile ? "flex-end" : "center",
                height: "100%",
                gap: "1rem",
              }}
            >
              <TextButton
                data-testid="cancel-action"
                text="Cancel"
                size="medium"
                theme="blue"
                disabled={isLoading || processing}
                onClick={onClose}
              />

              <SubmitButton
                sx={{
                  padding: "24px",
                  margin: 0,
                  width: "100%",
                }}
                dataTestid="submit-action"
                text={processing ? "Saving..." : "Save"}
                disabled={
                  isLoading || processing || isEmpty(formState.dirtyFields)
                }
                size="medium"
                theme="blue"
                variant="default"
                type="submit"
                onClick={() => undefined}
              />
            </Box>
          </DialogActions>
        </form>
      </ThemeProvider>
    </Dialog>
  );
};

export default CreateEditDashboardDialog;
