import {
  ReportAlertHistoryTableData,
  DwellHierarchyTableData,
  Maybe,
  TableColumnFormat as ReportColumnFormat,
} from "../../../../../graphql/operations";
import { TableGridColDef } from "../../../../../shared/components/Table";
import { columnTimezoneDateTimeFormatParams } from "../../../../../utils";

export const defaultColumnVisibilityModel = {
  startedAt: false,
  alertName: false,
  alertType: false,
  assetName: false,
  assetCategory: false,
  modelYear: false,
  manufacturerName: false,
  model: false,
  imei: false,
  customer: false,
  vin: false,
  lastEventDate: false,
  assetType: false,
  orgName: false,
};

export const getColumns = (
  timezone: Maybe<string> | undefined
): TableGridColDef<DwellHierarchyTableData | ReportAlertHistoryTableData>[] => {
  const columns = [
    {
      field: "startedAt",
      headerName: "Date",
      flex: 1,
      minWidth: 120,
      format: ReportColumnFormat.IsoDateTime,
      ...columnTimezoneDateTimeFormatParams(timezone as string),
      filterable: false,
    },
    {
      field: "lastEventDate",
      headerName: "Last Event Date",
      flex: 1,
      minWidth: 120,
      format: ReportColumnFormat.IsoDateTime,
      ...columnTimezoneDateTimeFormatParams(timezone as string),
      sortable: false,
      filterable: false,
    },
    {
      field: "alertName",
      headerName: "Alert Name",
      flex: 1,
      minWidth: 120,
      type: "string",
      format: ReportColumnFormat.String,
      sortable: false,
      filterable: false,
    },
    {
      field: "alertType",
      headerName: "Alert Type",
      flex: 1,
      minWidth: 120,
      type: "string",
      format: ReportColumnFormat.String,
    },
    {
      field: "assetType",
      headerName: "Asset Type",
      flex: 1,
      minWidth: 120,
      type: "string",
      format: ReportColumnFormat.String,
      sortable: false,
      filterable: false,
    },
    {
      field: "assetName",
      headerName: "Asset Name",
      flex: 1,
      minWidth: 120,
      type: "string",
      format: ReportColumnFormat.String,
    },
    {
      field: "orgName",
      headerName: "Organization Name",
      flex: 1,
      minWidth: 120,
      type: "string",
      format: ReportColumnFormat.String,
      sortable: false,
      filterable: false,
    },
    {
      field: "imei",
      headerName: "IMEI",
      flex: 1,
      minWidth: 120,
      type: "string",
      format: ReportColumnFormat.String,
    },
    {
      field: "manufacturerName",
      headerName: "Manufacturer",
      flex: 1,
      minWidth: 120,
      type: "string",
      format: ReportColumnFormat.String,
      sortable: false,
      filterable: false,
    },
    {
      field: "model",
      headerName: "Model",
      flex: 1,
      minWidth: 120,
      type: "string",
      format: ReportColumnFormat.String,
      sortable: false,
      filterable: false,
    },
    {
      field: "modelYear",
      headerName: "Model Year",
      flex: 1,
      minWidth: 120,
      type: "string",
      format: ReportColumnFormat.String,
      sortable: false,
      filterable: false,
    },
    {
      field: "vin",
      headerName: "VIN",
      flex: 1,
      minWidth: 120,
      type: "string",
      format: ReportColumnFormat.String,
      sortable: false,
      filterable: false,
    },
  ];

  return columns;
};
