import { Icon } from "@mui/material";
import {
  GridColDef,
  GridValueFormatterParams,
  GridCellParams,
} from "@mui/x-data-grid-premium";
import { EventHistoryEventNameUi } from "../../../../../../constants/map";
import { HistoricalEventHistory } from "../../../../../../graphql/operations";
import AssetIcon from "../../../../TableView/components/AssetIcon/AssetIcon";

interface ExtendedSearchEventHistoryInputResultData
  extends HistoricalEventHistory {
  "breadcrumbs.eventName": string;
  icon: Record<string, any>;
}

const capitalizeFirstLetter = (string: string) =>
  string ? string.charAt(0).toUpperCase() + string.slice(1) : "";

export enum BreadcrumbsFields {
  Date = "date",
  EventName = "breadcrumbs.eventName",
  PrimaryPower = "breadcrumbs.primaryPower",
  Speed = "breadcrumbs.speed",
  City = "breadcrumbs.city",
  State = "breadcrumbs.state",
  Icon = "icon",
}

export const BreadcrumbsHeaderNames = {
  [BreadcrumbsFields.Date]: "Time",
  [BreadcrumbsFields.EventName]: "Event Name",
  [BreadcrumbsFields.PrimaryPower]: "Primary Voltage",
  [BreadcrumbsFields.Speed]: "Speed",
  [BreadcrumbsFields.City]: "City",
  [BreadcrumbsFields.State]: "State",
  [BreadcrumbsFields.Icon]: "Status",
};

export const columns: GridColDef<ExtendedSearchEventHistoryInputResultData>[] =
  [
    {
      field: BreadcrumbsFields.Date,
      headerName: BreadcrumbsHeaderNames[BreadcrumbsFields.Date],
      flex: 1,
      minWidth: 210,
      sortable: false,
    },
    {
      field: BreadcrumbsFields.EventName,
      headerName: BreadcrumbsHeaderNames[BreadcrumbsFields.EventName],
      flex: 1,
      minWidth: 200,
      sortable: false,
      valueGetter: ({ row }) => {
        return (
          row["breadcrumbs.eventName"] ?? EventHistoryEventNameUi.UnknownEvent
        );
      },
    },
    {
      field: BreadcrumbsFields.PrimaryPower,
      headerName: BreadcrumbsHeaderNames[BreadcrumbsFields.PrimaryPower],
      flex: 1,
      minWidth: 200,
      valueFormatter: (params: GridValueFormatterParams) =>
        params.value?.toFixed(2),
      cellClassName: (params: GridCellParams<number>) => {
        let primaryVoltage = params.row.primaryPower ?? 0;
        let speed = params.row.speed ?? 0;

        if (primaryVoltage >= 10.5 && speed > 5) {
          // Moving with Primary Voltage
          return "blue-cell";
        } else if (primaryVoltage < 10.5 && speed > 5) {
          //Moving without Primary Voltage
          return "green-cell";
        } else return "";
      },
      sortable: false,
    },
    {
      field: BreadcrumbsFields.Speed,
      headerName: BreadcrumbsHeaderNames[BreadcrumbsFields.Speed],
      flex: 1,
      minWidth: 80,
      sortable: false,
    },
    {
      field: BreadcrumbsFields.City,
      headerName: BreadcrumbsHeaderNames[BreadcrumbsFields.City],
      flex: 1,
      minWidth: 150,
      sortable: false,
    },
    {
      field: BreadcrumbsFields.State,
      headerName: BreadcrumbsHeaderNames[BreadcrumbsFields.State],
      flex: 1,
      minWidth: 100,
      sortable: false,
    },
    {
      field: BreadcrumbsFields.Icon,
      headerName: BreadcrumbsHeaderNames[BreadcrumbsFields.Icon],
      minWidth: 120,
      groupable: false,
      sortable: false,
      renderCell: (params: any) => {
        const { icon } = params.row;
        const cargoState = capitalizeFirstLetter(icon?.cargoStatus);
        const tripState = capitalizeFirstLetter(icon?.tripState);
        return (
          <AssetIcon
            dwellingDays={Number(icon?.dwellTime)}
            cargo_state={cargoState}
            trip_st={tripState}
            customColor="var(--primary-blue)"
          />
        );
      },
      disableExport: true,
      filterable: false,
    },
  ];
