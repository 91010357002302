import React, { useCallback, useEffect, useState } from "react";
import { useQueryClient } from "@tanstack/react-query";
import {
  DEVICES_UPLOAD_FILE_TEMPLATE_PATH,
  PAGE_SNACKBAR,
} from "../../../constants";
import { useAppContext } from "../../../context/AppContext";
import {
  BatchTemplate,
  CreateDevicesErrorMsg,
} from "../../../graphql/operations";
import { parseContentAsJsonBlobFile } from "../../../shared/components/TableActionsMenu/helpers";
import UploadFileDialog from "../../../shared/components/UploadFileDialog/UploadFileDialog";
import UploadResult from "../../../shared/components/UploadFileDialog/UploadResult";
import { ParseFileContentError } from "../../../utils";
import { BatchUploadDialogProps } from "../../BatchesView/BatchManagementUtils";
import { useUploadJsonBatchFileToS3 } from "../../BatchesView/hooks/useUploadJsonBatchFileToS3";
import {
  getValidationDevicesErrors,
  useDeviceBatchUploadForm,
  parseDevicesFile,
  validateAndMapDataForDevices,
  CreateDevicesUploadInput,
} from "../deviceUtils";

export const UploadDevicesDialog: React.FC<BatchUploadDialogProps> = ({
  title,
  dialogFields,
  customerOrg,
  isOpen,
  onClose,
  onUpload,
}) => {
  // Hooks
  const { dispatch } = useAppContext();
  const queryClient = useQueryClient();
  const { uploadBatchFileToS3 } = useUploadJsonBatchFileToS3();
  const { form, getValues } = useDeviceBatchUploadForm(title, customerOrg);

  // State
  const [uploadedMessage, setUploadedMessage] = useState<string | undefined>();
  const [isDevicesUploading, setIsDevicesUploading] = useState<boolean>(false);
  const [isDevicesUploaded, setIsDevicesUploaded] = useState<boolean>(false);
  const [parsedDevices, setParsedDevices] = useState<
    CreateDevicesUploadInput[]
  >([]);
  const [responseValidationErrors, setResponseValidationErrors] = useState<
    CreateDevicesErrorMsg[]
  >([]);
  const [validateUploadedDevicesErrors, setValidateUploadedDevicesErrors] =
    useState<ParseFileContentError[]>([]);

  // Effects
  useEffect(() => {
    const parsedResponseValidationErrors = getValidationDevicesErrors(
      parsedDevices as CreateDevicesUploadInput[],
      responseValidationErrors
    );
    setValidateUploadedDevicesErrors((initialState) => {
      return [...initialState, ...parsedResponseValidationErrors];
    });
  }, [responseValidationErrors, parsedDevices]);

  // Handlers and callbacks
  const onFilesUploadDialogClose = useCallback(() => {
    setIsDevicesUploading(false);
    setIsDevicesUploaded(false);
    setResponseValidationErrors([]);
    setParsedDevices([]);
    onClose();
  }, [onClose]);

  const handleSubmit = useCallback(
    async (file: File) => {
      setIsDevicesUploading(true);

      const {
        org_select: { id: orgId, label: orgName },
        batch_name,
      } = getValues();

      try {
        const { devices, errors: parseErrors } = await parseDevicesFile(file);

        const { validatedDevices, errors: validationErrors } =
          validateAndMapDataForDevices(devices, { orgName, orgId });

        setParsedDevices(devices as CreateDevicesUploadInput[]);

        const combinedErrors = [...validationErrors, ...parseErrors];
        setValidateUploadedDevicesErrors(combinedErrors);
        if (combinedErrors.length > 0) {
          throw new Error("Validation errors");
        }

        const fileName = file.name.replace(/\.(csv|xls|xlsx)$/i, ".json");
        setUploadedMessage(`${fileName} ${batch_name} Uploaded`);

        const jsonFile = parseContentAsJsonBlobFile(validatedDevices, fileName);
        await uploadBatchFileToS3(
          jsonFile,
          BatchTemplate.CreateDevices,
          batch_name,
          orgId
        );

        setIsDevicesUploaded(true);

        if (onUpload) onUpload();

        queryClient.invalidateQueries(["findDevices"], {
          refetchType: "all",
        });
      } catch (error) {
        console.error("Error uploading devices:", error);

        dispatch({
          type: PAGE_SNACKBAR,
          payload: {
            title: "Devices Uploading Failed",
            text: "Something Went Wrong.",
            severity: "error",
            onClose: () => {},
          },
        });
      } finally {
        setIsDevicesUploading(false);
        setIsDevicesUploaded(true);
      }
    },
    [dispatch, queryClient, getValues, onUpload, uploadBatchFileToS3]
  );

  return (
    <UploadFileDialog
      title={title}
      form={form}
      allowedFormats={["csv", "xls", "xlsx"]}
      dialogFields={dialogFields}
      isOpen={isOpen}
      handleSubmit={handleSubmit}
      onClose={onFilesUploadDialogClose}
      isDataUploading={isDevicesUploading}
      isDataUploaded={isDevicesUploaded}
      downloadTemplateFilePath={DEVICES_UPLOAD_FILE_TEMPLATE_PATH}
      uploadResultComponent={
        <UploadResult
          uploadedMessage={uploadedMessage}
          errors={validateUploadedDevicesErrors}
          processedRecordsCount={parsedDevices?.length}
          itemName={"Device"}
        />
      }
    />
  );
};
