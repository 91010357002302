import { memo, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { ThemeProvider } from "@mui/material";
import { NavigationRoutes } from "../../../utils/routes/routesUtils";
import { useAssetsDataContext } from "../shared/AssetsDataContext";
import { AssetsTable } from "./components/AssetsTable";
import { useAssetManagementTheme } from "./hooks/useAssetManagementTheme";

const AssetsTableView = memo(() => {
  const assetManagementTheme = useAssetManagementTheme();
  const { gridApiRef, setShouldShowFiltersStripAndViewToggle } =
    useAssetsDataContext();
  const [shouldShowTable, setShouldShowTable] = useState(false);
  const location = useLocation();
  useEffect(() => {
    setShouldShowFiltersStripAndViewToggle(true);
  }, [setShouldShowFiltersStripAndViewToggle]);
  useEffect(() => {
    setShouldShowTable(location.pathname === NavigationRoutes.AssetTable);
  }, [location]);
  return gridApiRef ? (
    <ThemeProvider theme={assetManagementTheme}>
      {shouldShowTable ? <AssetsTable /> : null}
    </ThemeProvider>
  ) : null;
});

export default AssetsTableView;
