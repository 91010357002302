import { memo, useMemo, useState, useCallback } from "react";
import { useFormContext } from "react-hook-form";
import { Box, Button, ThemeProvider } from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import { isEmpty, isEqual, debounce } from "lodash";
import { usePopupState } from "material-ui-popup-state/hooks";
import { PAGE_SNACKBAR } from "../../../../../constants";
import { BatchTitles } from "../../../../../constants/batches";
import { FILTERS_LIST_WIDTH } from "../../../../../constants/map";
import { useAppContext } from "../../../../../context/AppContext";
import {
  AssetFilter,
  useFindOrgsQuery,
  CreateAssetFilterMutation,
  useCreateAssetFilterMutation,
  useUpdateAssetFilterMutation,
  useDeleteAssetFilterMutation,
  UpdateAssetFilterMutation,
  DeleteAssetFilterMutation,
} from "../../../../../graphql/operations";
import { useUserData } from "../../../../../shared/hooks/useUserData";
import {
  BATCH_FORM_FIELDS,
  BatchFormFieldsNames,
  mapOrgs,
} from "../../../../BatchesView/BatchManagementUtils";
import { AddAssetDrawer } from "../../../TableView/components/AddAssetDrawer";
import { useAssetManagementTheme } from "../../../TableView/hooks/useAssetManagementTheme";
import {
  AssetFilters,
  initialFilterState,
  useAssetsDataContext,
} from "../../../shared/AssetsDataContext";
import AssetsFilters from "../../../shared/AssetsFilterControls/Filters/AssetFilters/AssetFilters";
import MoreFilters from "../../../shared/AssetsFilterControls/Filters/MoreFilters";
import QueryBuilderFilters from "../../../shared/AssetsFilterControls/Filters/QueryBuilderFilters";
import SensorsFilters from "../../../shared/AssetsFilterControls/Filters/SensorsFilters";
import StatusFilters from "../../../shared/AssetsFilterControls/Filters/StatusFilters";
import FilterModal, {
  ModalModeMap,
} from "../../../shared/AssetsFilterControls/MoreActions/FilterModal";
import { UploadAssetsDialog } from "../../../shared/UploadAssetsDialog/UploadAssetsDialog";
import { getViewFromUrl } from "../../../utils";
import { UploadGeofencesDialog } from "../../Geofences/UploadGeofencesDialog/UploadGeofencesDialog";
import { GeofenceFilters } from "../GeofenceFilters/GeofenceFilters";
import { TOP_SECTION_HEIGHT } from "./constants";
import { Modes } from "./enums";

const FiltersDrawerContent = memo(() => {
  const queryClient = useQueryClient();

  const {
    dispatch,
    state: {
      theme: { theme },
    },
  } = useAppContext();

  const {
    reset,
    formState: { errors },
  } = useFormContext();

  const {
    savedFilters,
    currentFilter: criteria,
    removeAssetFilterCache,
    refetchAssetFilters,
    onChangeFilters,
    clearAllAssetFilters,
    isFiltersDrawerOpen,
    showAddAsset,
    setShowAddAsset,
    isFileUploadDialogOpen,
    setIsFileUploadDialogOpen,
    isGeofencesView,
    isGeofencesFileUploadDialogOpen,
    setIsGeofencesFileUploadDialogOpen,
  } = useAssetsDataContext();

  const assetManagementTheme = useAssetManagementTheme(); // in context of Map View we will not have same theme as we have in AssetManagement View, so we need to set it explicitly

  const userData = useUserData();

  const view = getViewFromUrl(window.location.pathname);

  const { data: orgData } = useFindOrgsQuery();
  const orgs = mapOrgs(orgData?.findOrgs ?? []);
  BATCH_FORM_FIELDS[BatchFormFieldsNames.AddToOrganization].values = orgs;

  const [mode, setMode] = useState<string>("");
  const [selectedFilter, setSelectedFilter] = useState<AssetFilter | null>(
    null
  );
  const [name, setName] = useState<string>(selectedFilter?.name ?? "");
  const [isPublic, setPublic] = useState<boolean>(
    selectedFilter?.is_public || false
  );

  const userMadeChange = !isEqual(criteria, initialFilterState);
  const disabled = !userMadeChange || !isEmpty(errors);

  const popupState = usePopupState({
    variant: "popover",
    popupId: "more-actions-popup",
  });

  const onCloseModal = useCallback(() => {
    setMode("");
  }, []);

  const onReset = useCallback(() => {
    popupState.close();
    reset();
    clearAllAssetFilters();
    setSelectedFilter(null);
  }, [popupState, reset, clearAllAssetFilters]);

  const onSuccess = useCallback(
    async (
      data:
        | CreateAssetFilterMutation
        | UpdateAssetFilterMutation
        | DeleteAssetFilterMutation
        | AssetFilter
    ) => {
      if (mode === Modes.Save && "createAssetFilter" in data) {
        data.createAssetFilter?.criteria &&
          onChangeFilters(
            JSON.parse(
              data.createAssetFilter?.criteria
            ) as Partial<AssetFilters>
          );
        setSelectedFilter(data.createAssetFilter);
      }
      if (mode === Modes.Update && "updateAssetFilter" in data) {
        onChangeFilters(
          JSON.parse(data.updateAssetFilter?.criteria) as Partial<AssetFilters>
        );
      }
      if (mode === Modes.Delete && "deleteAssetFilter" in data) {
        onReset(); // reset assets back to original state when selected filter is deleted
      }
      onCloseModal();
      dispatch({
        type: PAGE_SNACKBAR,
        payload: {
          title: `Asset Filter "${name}" ${mode}d `,
          text: "Success!",
          severity: "success",
          onClose: () => {},
        },
      });
      if (removeAssetFilterCache && refetchAssetFilters) {
        removeAssetFilterCache();
        refetchAssetFilters();
      }
    },
    [
      removeAssetFilterCache,
      refetchAssetFilters,
      mode,
      dispatch,
      onChangeFilters,
      onCloseModal,
      name,
      onReset,
    ]
  );

  const onError = useCallback(
    (error: unknown) => {
      onCloseModal();
      console.error(error);
      dispatch({
        type: PAGE_SNACKBAR,
        payload: {
          title: `Asset Filter "${name}" ${mode} Failed`,
          text: "Something Went Wrong.",
          severity: "error",
          onClose: () => {},
        },
      });
    },
    [mode, dispatch, onCloseModal, name]
  );

  const { mutate: createAssetFilter, isLoading: isCreatingAssetFilter } =
    useCreateAssetFilterMutation({ onSuccess, onError });

  const { mutate: updateAssetFilter, isLoading: isUpdatingAssetFilter } =
    useUpdateAssetFilterMutation({
      onSuccess,
      onError,
    });

  const { mutate: deleteAssetFilter, isLoading: isDeletingAssetFilter } =
    useDeleteAssetFilterMutation({
      onSuccess,
      onError,
    });

  const filterAlreadyExists = useCallback(() => {
    if (!name || !savedFilters?.length) {
      return false;
    }
    return savedFilters.some((filter) => filter.name === name);
  }, [name, savedFilters]);

  const isUpdatingName = useMemo(
    () => mode === Modes.Update && name !== selectedFilter?.name,
    [selectedFilter, mode, name]
  );

  const handleHelperText = useCallback((): string => {
    if ((mode === Modes.Save || isUpdatingName) && filterAlreadyExists()) {
      return "This filter name already exists.";
    }
    return "";
  }, [mode, isUpdatingName, filterAlreadyExists]);

  const handleFilterMenuClick = (clickMode: string, filter?: AssetFilter) => {
    popupState.close();
    // If user made any change and clicked edit then use that change for Edit Else
    const filterToApply: AssetFilter =
      userMadeChange && clickMode
        ? ({ ...filter, criteria: JSON.stringify(criteria) } as AssetFilter)
        : (filter as AssetFilter);
    // either Save, Update or Delete
    if (clickMode) {
      setMode(clickMode);
    }
    // filter == true means Update or Delete mode, filter === false means Save mode
    if (filter) {
      setSelectedFilter(filterToApply);
      setName(filterToApply?.name);
      setPublic(filterToApply?.is_public || false);
      onChangeFilters(JSON.parse(filterToApply?.criteria));
    } else {
      setName("");
      setPublic(false);
      setSelectedFilter(null);
    }
  };

  const isSaveFilterDisabled = filterAlreadyExists();

  const modalModeMapping: ModalModeMap = useMemo(
    () => ({
      Save: {
        title: "Save New Filter",
        action: () => {
          createAssetFilter({
            input: {
              name,
              is_public: isPublic,
              criteria: JSON.stringify(criteria),
            },
          });
        },
        disabled: isSaveFilterDisabled,
      },
      Update: {
        title: "Update Filter",
        action: () => {
          selectedFilter &&
            updateAssetFilter({
              input: {
                ...(({ email, ...rest }) => rest)(selectedFilter),
                name,
                is_public: isPublic,
              },
            });
        },
      },
      Delete: {
        title: "Delete Filter",
        action: () => {
          selectedFilter && deleteAssetFilter({ id: selectedFilter._id });
        },
      },
    }),
    [
      selectedFilter,
      createAssetFilter,
      updateAssetFilter,
      deleteAssetFilter,
      name,
      isPublic,
      criteria,
      isSaveFilterDisabled,
    ]
  );

  const classes = `fixed h-full !x-overflow-hidden bg-background text-primary relative z-10`;
  const borderColor = theme === "dark" ? "gray" : "var(--border-color)";

  if (!isFiltersDrawerOpen) return null;

  return (
    <Box
      data-testid="map-filters-drawer"
      className={classes}
      sx={{
        transform: `translateY(-${TOP_SECTION_HEIGHT})`,
        width: FILTERS_LIST_WIDTH,
        minWidth: FILTERS_LIST_WIDTH,
        borderRight: `1px solid ${borderColor}`,
      }}
    >
      {isGeofencesView ? (
        <>
          <GeofenceFilters />
          <UploadGeofencesDialog
            title={BatchTitles.CreateUpdateGeofences}
            customerOrg={userData?.customerOrg?.name}
            dialogFields={BATCH_FORM_FIELDS}
            isOpen={isGeofencesFileUploadDialogOpen}
            onClose={() => setIsGeofencesFileUploadDialogOpen(false)}
            onUpload={() => queryClient.invalidateQueries(["getBatchHistory"])}
          />
        </>
      ) : (
        <>
          <Box
            sx={{
              marginTop: TOP_SECTION_HEIGHT,
            }}
            className="h-full w-full p-5 absolute justify-center items-center overflow-x-hidden overflow-y-visible shadow-2xl"
          >
            <AssetsFilters />
            <StatusFilters />
            <SensorsFilters />
            <MoreFilters />
            <QueryBuilderFilters />

            <Box className="pt-8 pb-6 flex justify-center">
              <Button
                className="!bg-brand global-btn !rounded-[100px] !py-2 !px-6 !text-sm !font-bold !text-white"
                type="button"
                disabled={disabled}
                data-testid="filters-drawer-save-filter-btn"
                onClick={() => handleFilterMenuClick("Save")}
                title="Save"
              >
                Save
              </Button>
            </Box>
          </Box>

          {mode && (
            <FilterModal
              open={Boolean(mode)}
              mode={(mode || "") as string}
              onClose={onCloseModal}
              modalModeMapping={modalModeMapping}
              name={name}
              isPublic={isPublic}
              originalIsPublic={selectedFilter?.is_public ?? isPublic}
              setPublic={setPublic}
              setName={setName}
              isCreatingAssetFilter={isCreatingAssetFilter}
              isUpdatingAssetFilter={isUpdatingAssetFilter}
              isDeletingAssetFilter={isDeletingAssetFilter}
              helperText={handleHelperText()}
            />
          )}

          <ThemeProvider theme={assetManagementTheme}>
            <AddAssetDrawer open={showAddAsset} setOpen={setShowAddAsset} />
          </ThemeProvider>

          <UploadAssetsDialog
            title={BatchTitles.CreateUpdateAssets}
            customerOrg={userData?.customerOrg?.name}
            dialogFields={BATCH_FORM_FIELDS}
            isOpen={isFileUploadDialogOpen}
            onClose={() => setIsFileUploadDialogOpen(false)}
            onUpload={() => queryClient.invalidateQueries(["getBatchHistory"])}
          />
        </>
      )}
    </Box>
  );
});

export default FiltersDrawerContent;
