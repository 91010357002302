import React from "react";
import { isNumber } from "lodash";
import { TooltipProps } from "recharts";
import { useGetTemperatureUnitPreference } from "../../../../../../shared/hooks/useGetTemperatureUnitPreference";
import {
  getConvertedTemperatureValue,
  getTemperatureUnitLabel,
} from "../../../../../../utils/convertTemperature";

export interface TemperatureChartTooltipProps {
  active?: boolean;
  payload?: TooltipProps<string, string>["payload"];
  activeIndex?: number;
}

export const TemperatureChartTooltip: React.FC<
  TemperatureChartTooltipProps
> = ({ active, payload, activeIndex }) => {
  const tempUnitPreference = useGetTemperatureUnitPreference();

  if (active && payload?.length && isNumber(activeIndex)) {
    const data = payload[activeIndex]?.payload;

    if (!data) {
      return null;
    }

    const temperature = data?.temperature?.median ?? "N/A";
    const date = data.toolTipDate ?? "N/A";

    return (
      <div
        className="p-6 bg-custom-tooltip-background rounded-lg border border-sub-header-border"
        data-testid="tooltip-wrapper"
      >
        <p
          className="text-base font-normal text-typography mb-3"
          data-testid="tooltip-date"
        >
          {date}
        </p>
        <div className="flex">
          <div>
            <p className="text-base font-normal text-typography">
              Temperature:
              <span
                className="font-bold ml-1"
                data-testid="tooltip-pressure"
              >{`${getConvertedTemperatureValue(
                temperature,
                tempUnitPreference
              )} ${getTemperatureUnitLabel(tempUnitPreference, true)}`}</span>
            </p>
          </div>
        </div>
      </div>
    );
  }

  return null;
};
