import { GridValueGetterParams } from "@mui/x-data-grid-premium";
import {
  AssetDetentionTableData,
  TableColumnFormat,
} from "../../../../../graphql/operations";
import {
  getTableColumn,
  TableColumnProps,
  TableGridColDef,
} from "../../../../../shared/components/Table";
import { columnTimezoneDateTimeFormatParams } from "../../../../../utils";

export const columnVisibilityModel = {
  arrivalCargoState: true,
  arrivalTime: true,
  assetId: true,
  departureCargoState: true,
  departureTime: true,
  detentionStatus: true,
  enteredAtDays: true,
  imei: true,
  timestamp: true,
  totalDwell: true,
  unloadDuration: true,
};

export const getColumns = (
  timezone: string
): TableGridColDef<AssetDetentionTableData>[] => {
  const baseOptions = {
    flex: 1,
    filterable: false,
  };

  const dateTimeOptions = (timezone: string) => ({
    ...baseOptions,
    ...columnTimezoneDateTimeFormatParams(timezone),
    minWidth: 190,
  });

  const columns: TableColumnProps<AssetDetentionTableData>[] = [
    {
      field: "asset_id",
      headerName: "Asset ID",
      type: "string",
      options: { ...baseOptions, minWidth: 100 },
    },
    {
      field: "device_id",
      headerName: "IMEI",
      type: "string",
      options: { ...baseOptions, minWidth: 150 },
    },
    {
      field: "current_date",
      headerName: "Date And Time",
      format: TableColumnFormat.IsoDateTime,
      type: "date",
      options: dateTimeOptions(timezone),
    },
    {
      field: "entered_at_days",
      headerName: "Days In Detention",
      type: "number",
      options: { ...baseOptions, minWidth: 100 },
    },
    {
      field: "detention_status",
      headerName: "Detention Status",
      type: "string",
      options: { ...baseOptions, minWidth: 130 },
    },
    {
      field: "entered_at",
      headerName: "Entered At",
      format: TableColumnFormat.IsoDateTime,
      type: "date",
      options: dateTimeOptions(timezone),
    },
    {
      field: "exited_at",
      headerName: "Exited At",
      format: TableColumnFormat.IsoDateTime,
      type: "date",
      options: dateTimeOptions(timezone),
    },
    {
      field: "unloading_time",
      headerName: "Unload Duration",
      format: TableColumnFormat.IsoDateTime,
      type: "date",
      options: dateTimeOptions(timezone),
    },
    {
      field: "entered_cargo_state",
      headerName: "Cargo Status On Enter",
      type: "string",
      options: { ...baseOptions, minWidth: 130 },
    },
    {
      field: "exited_cargo_state",
      headerName: "Cargo Status On Exit",
      type: "string",
      options: { ...baseOptions, minWidth: 130 },
    },
    {
      field: "total_dwell",
      headerName: "Total Dwell",
      type: "number",
      options: {
        ...baseOptions,
        minWidth: 150,
        valueGetter: ({
          row,
        }: GridValueGetterParams<AssetDetentionTableData>) => {
          const totalDwellInDays = Math.floor(row.total_dwell);
          return `${totalDwellInDays} Days`;
        },
      },
    },
  ];

  return columns.map(getTableColumn);
};
