import {
  AssetDetentionTableData,
  TableColumnFormat,
} from "../../../../../graphql/operations";
import Status from "../../../../../shared/components/Status";
import {
  TableColumnProps,
  TableGridColDef,
  getTableColumn,
} from "../../../../../shared/components/Table";
import { columnTimezoneDateTimeFormatParams } from "../../../../../utils";

export const columnVisibilityModel = {
  geofence_name: true,
  free_days_start: true,
  free_days_end: true,
  exited_at: true,
  total_days_in_detention: true,
  detention_charges: true,
  detention_status: true,
};

export const EVENT_HISTORY_DEFAULT_SORT_COLUMN = "eventDate";
export const EVENT_HISTORY_PAGE_SIZE = 100;

export const getColumns = (
  timezone: string,
  isDarkTheme: boolean
): TableGridColDef<AssetDetentionTableData>[] => {
  const columns: TableColumnProps<AssetDetentionTableData>[] = [
    {
      field: "geofence_name",
      headerName: "Geofence",
      type: "string",
      options: {
        filterable: false,
      },
    },
    {
      field: "free_days_start",
      headerName: "Free Days Start",
      format: TableColumnFormat.IsoDateTime,
      type: "date",
      options: { ...columnTimezoneDateTimeFormatParams(timezone) },
    },
    {
      field: "free_days_end",
      headerName: "Detention Start",
      format: TableColumnFormat.IsoDateTime,
      type: "date",
      options: { ...columnTimezoneDateTimeFormatParams(timezone) },
    },
    {
      field: "exited_at",
      headerName: "Detention End",
      format: TableColumnFormat.IsoDateTime,
      type: "date",
      options: { ...columnTimezoneDateTimeFormatParams(timezone) },
    },
    {
      field: "total_days_in_detention",
      headerName: "Total Days",
      options: {
        filterable: false,
      },
      type: "string",
    },
    {
      field: "detention_charges",
      headerName: "Charges and Details",
      type: "string",
      options: {
        filterable: false,
        renderCell: (params) => (params.value ? "$" + params.value : ""),
      },
    },
    {
      field: "detention_status",
      headerName: "Status",
      type: "string",
      options: {
        filterable: false,
        renderCell: (params) =>
          getDetentionStatus(params.value as string, isDarkTheme),
      },
    },
  ];

  return columns.map(getTableColumn);
};

export const getDetentionStatus = (status: string, isDarkTheme: boolean) => {
  const maxWidth: number = 103;
  const bigTextMaxWidth: number = 130;
  switch (status) {
    case "In detention":
      return (
        <Status
          bgColor="bg-green"
          iconColor=""
          text="In detention"
          maxWidth={maxWidth}
        />
      );
    case "Out of detention":
      return (
        <Status
          bgColor={`${isDarkTheme ? "bg-light-charcoal" : "bg-concrete"}`}
          textColor={`${isDarkTheme ? "text-white" : "var(--primary)"}`}
          iconColor=""
          text="Out of detention"
          maxWidth={bigTextMaxWidth}
        />
      );
    case "Free days":
      return (
        <Status
          bgColor={`${isDarkTheme ? "bg-light-charcoal" : "bg-concrete"}`}
          iconColor=""
          textColor={`${isDarkTheme ? "text-white" : "var(--primary)"}`}
          text="Free days"
          maxWidth={maxWidth}
        />
      );
    default:
      return <></>;
  }
};
