import { FC } from "react";
import { UseFormReturn } from "react-hook-form";
import { TextFieldElement } from "react-hook-form-mui";
import { Grid } from "@mui/material";
import { useAssetParameters } from "../../hooks/useAssetParameters";
import { AlertFormValues } from "../../interfaces";
import { AlertParametersContainer } from "../AlertParametersContainer";
import { AssetsParameters } from "./AssetsParameters";

interface AlertABSFaultParametersProps {
  form: UseFormReturn<Partial<AlertFormValues>>;
}

export const AlertABSFaultParameters: FC<AlertABSFaultParametersProps> = ({
  form,
}: AlertABSFaultParametersProps) => {
  const {
    assetTypeOptions,
    setAssetNameSearch,
    watchAssetsValue,
    watchAssetTypeValue,
    isFetching,
    hideInputValue,
    setSelectedAsset,
    assetsOptionsList,
    setAssetsOptionsList,
  } = useAssetParameters({ form });

  return (
    <AlertParametersContainer>
      <AssetsParameters
        form={form}
        setAssetNameSearch={setAssetNameSearch}
        setSelectedAsset={setSelectedAsset}
        assetsOptionsList={assetsOptionsList}
        setAssetsOptionsList={setAssetsOptionsList}
        assetTypeOptions={assetTypeOptions}
        watchAssetsValue={watchAssetsValue}
        watchAssetTypeValue={watchAssetTypeValue}
        isFetching={isFetching}
        hideInputValue={hideInputValue}
        assetsSelectTestId="abs-fault-assets-select"
        assetTypeSelectTestId="abs-fault-asset-type-select"
      />
      <Grid
        item
        xs={12}
        md={6}
        lg={6}
        xl={4}
        data-testid="abs-fault-parameters-number-of-faults"
      >
        <TextFieldElement
          fullWidth
          control={form.control}
          name="parameters.absNumberOfFaults"
          label={`Number of ABS faults`}
          type="number"
        />
      </Grid>
    </AlertParametersContainer>
  );
};
