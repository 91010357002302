/**
 * Note: This is very very very ugly approach, but it is the only way to get around a circular dependency issue.
 *
 * tl;dr This enum is complete duplicate of the same thing we have in the GraphQL schema.
 * TODO: Make sure to keep this in sync with the GraphQL schema and preferably use this definition in the code to avoid potential circular dependency issues.
 *
 * More Information:
 *
 * src/graphql/operations.ts
 *  -> src/graphql/fetcher.ts
 *   -> src/context/AuthContext.tsx
 *     -> src/graphql/operations.ts
 *
 * - operations.ts file contains all the GraphQL types compiled into JavaScript, along with some generic hooks that can be used later by React Query to make API requests
 * - fetcher.ts file is where we have custom logic for React Query. It handles how we send requests to the API, including appending the user's auth token, which is stored in the AuthContext
 * - AuthContext is a general place that holds information about the current authentication state of the user. Since we define some types here and reference the operations.ts file, this creates a loop
 *
 * And in order to break this loop, we have to define the enum here, which is a complete duplicate of the same thing we have in the GraphQL schema.
 * We just avoid importing the operations.ts file in the AuthContext.tsx file, and instead use this enum definition.
 *
 * Reference: https://phillips-connect.atlassian.net/browse/PRJIND-9078
 */

export enum UserRole {
  Clientadmin = "clientadmin",
  Customerservice = "customerservice",
  Driver = "driver",
  Equipmentmanager = "equipmentmanager",
  Fleetmanager = "fleetmanager",
  Fleetviewer = "fleetviewer",
  Installer = "installer",
  Leaseagent = "leaseagent",
  Loadplanner = "loadplanner",
  Maintenancemanager = "maintenancemanager",
  Shoptechnician = "shoptechnician",
  Superadmin = "superadmin",
  Supervisor = "supervisor",
}
