import { HTMLAttributes, useCallback, useEffect, useMemo } from "react";
import { Controller, UseFormReturn, useWatch } from "react-hook-form";
import { AutocompleteElement, TextFieldElement } from "react-hook-form-mui";
import { CircularProgress, FormHelperText, Grid } from "@mui/material";
import classNames from "classnames";
import { ASSET_VIN_LENGTH } from "../../../../../../constants/assets";
import { useAuthContext } from "../../../../../../context/AuthContext";
import {
  Asset,
  DeviceData,
  useGetVinCheckDataQuery,
  VinCheckData,
  useGetConfigurationSetsQuery,
  ConfigurationSetType,
  ProductData,
} from "../../../../../../graphql/operations";
import { useGetAssetsTags } from "../../../../../../shared/hooks/openSearchMongoPolyfillHooks/useGetAssetsTags";
import { useAvailableOrgs } from "../../../../../../shared/hooks/useAvailableOrgs";
import { AutocompleteOption } from "../../../../../../types";
import {
  useDevicesOptions,
  useOrgsOptions,
  useProductOptions,
} from "../../../hooks";
import { useAssetTagOptions } from "../../../hooks/useAssetTagOptions";
import {
  NOMENCLATURE_NAMES,
  useNomenclatures,
} from "../../../hooks/useNomenclatures";
import type { Breakpoints } from "../AssetForm";
import { ASSET_FORM_FIELDS, AssetFormSectionTitles } from "../assetFormUtils";
import { CreatableAutocomplete } from "../components";
import { AccordionWrapper } from "../components/AccordionWrapper";

interface AssetInformationProps {
  availableDevices: DeviceData[];
  isLoadingDevices: boolean;
  onChangeDevice: (autoCompleatValue: AutocompleteOption) => void;
  onVinEntered?: (vinData: VinCheckData) => void;
  showDetailsHeader?: boolean;
  form: UseFormReturn<Partial<Asset>>;
  breakpoints: Breakpoints;
  asset?: Partial<Asset>;
  disableAllFields?: boolean;
}

export const AssetInformation = ({
  availableDevices,
  isLoadingDevices,
  onChangeDevice,
  onVinEntered,
  showDetailsHeader = false,
  form,
  breakpoints,
  asset = {},
  disableAllFields = false,
}: AssetInformationProps) => {
  const { userRolePermissions } = useAuthContext();
  const availableOrgs = useAvailableOrgs();

  const customer_orgs_id = useWatch({
    control: form.control,
    name: "customer_orgs_id",
  });

  const { tags, isLoading: isLoadingAssetsTags } = useGetAssetsTags({
    orgId: customer_orgs_id ?? "",
  });

  const isFindAvailableDevicesQueryEnabled =
    userRolePermissions.device.view && !!customer_orgs_id;

  const vinFormValue = form.watch(ASSET_FORM_FIELDS[7].name);
  const isuseGetVinCheckDataQueryEnabled = !!(
    vinFormValue && vinFormValue.length === ASSET_VIN_LENGTH
  );

  const { data: vinCheckData, isLoading: vinCheckDataLoading } =
    useGetVinCheckDataQuery(
      {
        input: {
          vin: vinFormValue,
        },
      },
      { enabled: isuseGetVinCheckDataQueryEnabled }
    );

  const { isLoading: isProductsLoading, data: productsData } =
    useGetConfigurationSetsQuery(
      {
        input: {
          orgId: customer_orgs_id ?? "",
          type: ConfigurationSetType.ProductCode,
        },
      },
      {
        enabled: Boolean(customer_orgs_id),
        refetchOnWindowFocus: false,
        refetchOnMount: false,
        cacheTime: 86400, // 1 day. Products list is not supposed to change frequently
        staleTime: Infinity,
      }
    );

  const products: ProductData[] = useMemo(() => {
    const configurationSets = productsData?.getConfigurationSets ?? [];

    return configurationSets
      .map((configurationSet) => {
        if (!configurationSet?.value) {
          return null;
        }

        const product = JSON.parse(configurationSet.value);
        if (!product.product_code || !product.asset_configuration) {
          return null;
        }

        return {
          _id: configurationSet._id,
          product_name: `${configurationSet.name} (${product.product_code})`,
        };
      })
      .filter(Boolean) as ProductData[];
  }, [productsData?.getConfigurationSets]);

  const assetTypeOptions = useNomenclatures(NOMENCLATURE_NAMES.assetType);
  const assetTagsOptions = useAssetTagOptions(tags);
  const orgOptions = useOrgsOptions(availableOrgs);
  const productOptions = useProductOptions(products);
  const devicesOptions = useDevicesOptions(availableDevices);

  const isDevicesLoading =
    userRolePermissions.device.view &&
    isFindAvailableDevicesQueryEnabled &&
    isLoadingDevices;

  const isVinCheckLoading =
    isuseGetVinCheckDataQueryEnabled && vinCheckDataLoading;

  const findDeviceById = useCallback(
    (deviceId: string) => {
      return availableDevices.find((device) => device.imei === deviceId);
    },
    [availableDevices]
  );

  useEffect(() => {
    if (vinCheckData && !vinCheckDataLoading && onVinEntered) {
      onVinEntered(vinCheckData?.getVinCheckData?.data as VinCheckData);
    }
  }, [vinCheckData, vinCheckDataLoading, onVinEntered]);

  // Note: It's ugly, but we should pass the org_name alongside the customer_orgs_id
  // We use it on a few places as a source of information (instead of the corresponding entity in Document DB)
  useEffect(() => {
    if (customer_orgs_id) {
      const organization = orgOptions.find(
        (org) => org.id === customer_orgs_id
      );

      if (organization) {
        form.setValue("org_name", organization?.label);
      }
    }
  }, [customer_orgs_id, orgOptions, form]);

  useEffect(() => {
    const orgName = form.getValues("org_name");
    if (orgName !== undefined) {
      form.setValue("tags", null);
    }
  }, [customer_orgs_id, form]);

  return (
    <AccordionWrapper
      accordionSummaryTitle={AssetFormSectionTitles.AssetInformation}
      showDetailsHeader={showDetailsHeader}
      isDashboardSettings={showDetailsHeader}
    >
      <Grid
        container
        rowGap={showDetailsHeader ? 3 : 0}
        className={`!text-primary ${
          showDetailsHeader ? "formSection" : "secondDrawerSection"
        }`}
        spacing={6}
      >
        <Grid item {...breakpoints}>
          <AutocompleteElement
            autocompleteProps={{
              disabled: disableAllFields,
              readOnly: disableAllFields,
            }}
            loading={!availableOrgs.length}
            rules={{ required: true }}
            matchId={true}
            label={ASSET_FORM_FIELDS[0].label}
            control={form.control}
            name={ASSET_FORM_FIELDS[0].name}
            options={orgOptions}
          />

          <TextFieldElement
            type="hidden"
            name="org_name"
            control={form.control}
          />
        </Grid>
        <Grid item {...breakpoints}>
          <TextFieldElement
            disabled={disableAllFields}
            fullWidth
            control={form.control}
            name={ASSET_FORM_FIELDS[1].name}
            required
            label={ASSET_FORM_FIELDS[1].label}
            data-testid={ASSET_FORM_FIELDS[1].name}
          />
        </Grid>
        <Grid item {...breakpoints}>
          <TextFieldElement
            disabled={disableAllFields}
            fullWidth
            control={form.control}
            name={ASSET_FORM_FIELDS[2].name}
            required
            label={ASSET_FORM_FIELDS[2].label}
          />
        </Grid>
        <Grid item {...breakpoints} data-testid="device-dropdown">
          <AutocompleteElement
            autocompleteProps={{
              disabled:
                !customer_orgs_id ||
                !userRolePermissions.device.view ||
                disableAllFields,
              readOnly: !userRolePermissions.device.view || disableAllFields,
              onChange: (event, value) => onChangeDevice(value),
              renderOption: (
                params: HTMLAttributes<HTMLLIElement>,
                option: any
              ) => {
                const deviceInOption = findDeviceById(option.id);
                return (
                  <li {...params} className="relative pl-[10px]">
                    {option.label}
                    <span
                      className={classNames(
                        "absolute top-[10px] right-[10px] w-[12px] h-[12px] rounded-full",
                        {
                          "bg-green": deviceInOption?.assets_id === null,
                          "bg-error": deviceInOption?.assets_id !== null,
                        }
                      )}
                    >
                      &nbsp;
                    </span>
                  </li>
                );
              },
            }}
            matchId={userRolePermissions.device.view}
            loading={isDevicesLoading}
            label={ASSET_FORM_FIELDS[3].label}
            control={form.control}
            name={ASSET_FORM_FIELDS[3].name}
            options={devicesOptions}
          />
          {!userRolePermissions.device.view && (
            <FormHelperText>
              You don't have permissions to set Device ID, please contact your
              administrator
            </FormHelperText>
          )}
          {!customer_orgs_id && userRolePermissions.device.view && (
            <FormHelperText>
              Please choose Organization Name first
            </FormHelperText>
          )}
        </Grid>
        <Grid item {...breakpoints}>
          <AutocompleteElement
            autocompleteProps={{
              disabled: !customer_orgs_id || disableAllFields,
              readOnly: disableAllFields,
            }}
            matchId={true}
            loading={Boolean(customer_orgs_id) && isProductsLoading}
            label={ASSET_FORM_FIELDS[4].label}
            control={form.control}
            name={ASSET_FORM_FIELDS[4].name}
            options={productOptions}
          />
          {!customer_orgs_id && (
            <FormHelperText>
              Please choose Organization Name first
            </FormHelperText>
          )}
        </Grid>
        <Grid item {...breakpoints}>
          <AutocompleteElement
            autocompleteProps={{
              disabled: disableAllFields,
              readOnly: disableAllFields,
            }}
            matchId={true}
            label={ASSET_FORM_FIELDS[5].label}
            control={form.control}
            name={ASSET_FORM_FIELDS[5].name}
            rules={{ required: true }}
            options={assetTypeOptions}
          />
        </Grid>
        <Grid item {...breakpoints}>
          <Controller
            name="tags"
            control={form.control}
            defaultValue={null}
            render={({ field }) => {
              return (
                <CreatableAutocomplete
                  disabled={disableAllFields}
                  name={ASSET_FORM_FIELDS[6].name}
                  label={ASSET_FORM_FIELDS[6].label}
                  field={field}
                  loading={isLoadingAssetsTags}
                  errors={form.formState.errors}
                  options={assetTagsOptions}
                />
              );
            }}
          />
        </Grid>
        <Grid item {...breakpoints}>
          <div className="relative w-full">
            <TextFieldElement
              fullWidth
              aria-disabled={isVinCheckLoading}
              disabled={disableAllFields}
              control={form.control}
              name={ASSET_FORM_FIELDS[7].name}
              label={ASSET_FORM_FIELDS[7].label}
              sx={{
                "& input": {
                  textTransform: "uppercase",
                },
              }}
            />
            {isVinCheckLoading && (
              <CircularProgress
                className="absolute right-4 top-1/2 transform -translate-y-1/2"
                size={20}
              />
            )}
          </div>
        </Grid>

        <Grid item {...breakpoints}>
          <TextFieldElement
            disabled={disableAllFields}
            fullWidth
            control={form.control}
            name={ASSET_FORM_FIELDS[12].name}
            label={ASSET_FORM_FIELDS[12].label}
            data-testid={ASSET_FORM_FIELDS[12].name}
          />
        </Grid>
      </Grid>
    </AccordionWrapper>
  );
};
