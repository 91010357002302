import { MfaOption } from "../graphql/operations";
import { FormField, FormFieldDropdownOption } from "../types";

export const TIME_ZONE_OPTIONS: FormFieldDropdownOption[] = [
  {
    value: "America/Moncton",
    label: "Atlantic Standard (GMT-4)",
  },
  {
    value: "America/New_York",
    label: "Eastern Standard (GMT-5)",
  },
  {
    value: "America/Chicago",
    label: "Central Standard (GMT-6)",
  },
  {
    value: "America/Denver",
    label: "Mountain Standard (GMT-7)",
  },
  {
    value: "America/Los_Angeles",
    label: "Pacific Standard (GMT-8)",
  },
  {
    value: "America/Anchorage",
    label: "Alaskan Standard (GMT-9)",
  },
  {
    value: "Pacific/Honolulu",
    label: "Hawaii-Aleutian Standard (GMT-10)",
  },
];

export const MFA_DISABLED = "DISABLED";

export const MFA_METHOD_OPTIONS: FormFieldDropdownOption[] = [
  {
    value: MfaOption.SoftwareTokenMfa,
    label: "Authenticator app",
  },
  {
    value: MfaOption.SmsMfa,
    label: "SMS",
  },
  {
    value: MFA_DISABLED,
    label: "Disabled",
  },
];

export const ALERT_RECEIVING_METHOD_OPTIONS: FormFieldDropdownOption[] = [
  {
    value: "email",
    label: "Email",
  },
  {
    value: "inApp",
    label: "In Application",
  },
  {
    value: "sms",
    label: "SMS",
  },
];

// if this functionality is restored someday, get the options from the DocDB nomenclatures
export const ALERT_TYPE_OPTIONS: FormFieldDropdownOption[] = [];

type AccountFormFieldsType = {
  personalInformation: FormField[];
  notificationPreferences: FormField[];
};
export const ACCOUNT_FORM_FIELDS: AccountFormFieldsType = {
  personalInformation: [
    {
      name: "firstName",
      type: "text",
      label: "First Name",
      dataTestid: "first-name-input",
      required: true,
    },
    {
      name: "lastName",
      type: "text",
      label: "Last Name",
      required: true,
    },
    {
      name: "email",
      type: "email",
      label: "Email",
    },
    {
      name: "time_zones",
      type: "autocomplete",
      label: "Time Zone",
      dataTestid: "input-org-timezone",
      required: true,
      options: TIME_ZONE_OPTIONS,
    },
    {
      name: "phoneNumber",
      type: "phoneNumber",
    },
    {
      name: "mfa",
      type: "autocomplete",
      label: "MFA method",
      dataTestid: "mfa-method",
      placeholder: "Select MFA method",
      required: true,
      options: MFA_METHOD_OPTIONS,
    },
  ],
  notificationPreferences: [
    {
      name: "alertReceivingMethod",
      type: "multiSelect",
      label: "Alert Receiving Method",
      dataTestid: "alert-receving-method",
      required: false,
      options: ALERT_RECEIVING_METHOD_OPTIONS,
    },
    // Commented due to https://phillips-connect.atlassian.net/browse/PRJIND-6127
    // {
    //   name: "alertTypes",
    //   type: "multiSelect",
    //   label: "Alert Type",
    //   dataTestid: "alert-types",
    //   required: false,
    //   options: ALERT_TYPE_OPTIONS,
    // },
  ],
};
