import { useMemo } from "react";
import {
  GridRenderCellParams,
  GridValueGetterParams,
} from "@mui/x-data-grid-premium";
import { capitalize } from "lodash";
import {
  AssetTableData,
  SensorStatusUppercase,
  TableColumnFormat,
} from "../../../../../graphql/operations";
import {
  getTableColumn,
  TableColumnProps,
  TableGridColDef,
} from "../../../../../shared/components/Table";
import { columnTimezoneDateTimeFormatParams } from "../../../../../utils";
import {
  CargoState,
  DoorSensorState,
} from "../../../MapView/Assets/AssetShortTooltip/components/AssetShortTooltipSensors/AssetShortTooltipSensors.interface";
import { InternalCameraFloorSpaceStatus } from "../../../MapView/Assets/CargoTabPanel/InternalCameraFloorSpaceGraph/types";
import { NOMENCLATURE_NAMES, useNomenclatures } from "../../hooks";
import AssetIcon from "../AssetIcon/AssetIcon";
import { getIconType } from "../AssetIcon/utils";

export enum IconTypeComplete {
  EMPTY,
  MOVING,
  LOW_DWELL,
  MEDIUM_DWELL,
  HIGH_DWELL,
  MEGA_DWELL,
  MOVING_ONLINE,
  LOW_DWELL_ONLINE,
  MEDIUM_DWELL_ONLINE,
  HIGH_DWELL_ONLINE,
  MEGA_DWELL_ONLINE,
  MOVING_EMPTY,
  LOW_DWELL_EMPTY,
  MEDIUM_DWELL_EMPTY,
  HIGH_DWELL_EMPTY,
  MEGA_DWELL_EMPTY,
  MOVING_ONLINE_EMPTY,
  LOW_DWELL_ONLINE_EMPTY,
  MEDIUM_DWELL_ONLINE_EMPTY,
  HIGH_DWELL_ONLINE_EMPTY,
  MEGA_DWELL_ONLINE_EMPTY,
  MOVING_LOADED,
  LOW_DWELL_LOADED,
  MEDIUM_DWELL_LOADED,
  HIGH_DWELL_LOADED,
  MEGA_DWELL_LOADED,
  MOVING_ONLINE_LOADED,
  LOW_DWELL_ONLINE_LOADED,
  MEDIUM_DWELL_ONLINE_LOADED,
  HIGH_DWELL_ONLINE_LOADED,
  MEGA_DWELL_ONLINE_LOADED,
}

export const columnVisibilityModel = {
  name: true,
  assetIdentifier: true,
  imei: true,
  associated: true,
  orgName: true,
  productName: true,
  category: true,
  city: true,
  state: true,
  zip: false,
  vin: false,
  tags: false,
  dwellingDays: true,
  doorType: false,
  tripStatus: true,
  length: false,
  lastEventTime: true,
  lastGpsTime: false,
  numOfTires: false,
  numOfAxles: false,
  lon: false,
  lat: false,
  signal: false,
  shareType: false,
  shareOrganizationName: false,
  shareGroupName: false,
  shareStartDate: false,
  shareEndDate: false,
  statusAsset: true,
  batteryHealthCondition: true,
  airbagHealthCondition: false,
  airTankHealthCondition: false,
  atisAlphaHealthCondition: false,
  cargoState: false,
  doorState: false,
  dualImbalanceHealthCondition: false,
  internalCameraStatus: false,
  liftgateHealthCondition: false,
  liteSentryGammaHealthCondition: false,
  regulatorHealthCondition: false,
  temperatureHealthCondition: false,
  tpmsBetaHealthCondition: false,
  psiWheelEndHealthCondition: false,
};

export const getIconValue = (
  params: GridValueGetterParams<any, AssetTableData>
) => {
  const iconType = getIconType(
    params.row.tripStatus,
    params.row.dwellingDays,
    params.row.signal,
    params.row.cargoState
  );
  const value = IconTypeComplete[iconType as keyof typeof IconTypeComplete];
  return value;
};

export const CategoryFormatter = ({
  params,
}: {
  params: GridRenderCellParams;
}) => {
  const nomenclatures = useNomenclatures(NOMENCLATURE_NAMES.assetType);
  const result = useMemo(() => {
    const value = params.value;
    if (!value) return "";
    const nomenclature = nomenclatures.find(
      (nomenclature) => nomenclature.value === value
    );
    return nomenclature ? nomenclature.label : value;
  }, [nomenclatures, params.value]);
  return result;
};

export const getSensorStatusCell = (status: SensorStatusUppercase) => {
  const colorsMap = new Map<SensorStatusUppercase, string>([
    [SensorStatusUppercase.Healthy, "var(--success)"],
    [SensorStatusUppercase.Warning, "var(--caution)"],
    [SensorStatusUppercase.Alert, "var(--warning)"],
    [SensorStatusUppercase.Critical, "var(--error)"],
  ]);

  if (!colorsMap.has(status)) {
    return "";
  }

  return (
    <span style={{ color: colorsMap.get(status), fontWeight: 700 }}>
      {status}
    </span>
  );
};

export const getDefaultAssetsListColumns = (
  timezone: string
): TableColumnProps<AssetTableData>[] => {
  const columns: TableColumnProps<AssetTableData>[] = [
    { field: "orgName", headerName: "Organization Name", order: 1 },
    {
      field: "icon",
      headerName: "Icon",
      order: 2,
      options: {
        valueGetter: getIconValue,
        renderCell: (params) => (
          <AssetIcon
            dwellingDays={Number(params.row.dwellingDays)}
            signal={Boolean(params.row.signal)}
            cargo_state={String(params.row.cargoState)}
            trip_st={String(params.row.tripStatus)}
          />
        ),
      },
    },
    {
      field: "statusAsset",
      headerName: "Asset Health",
      order: 2,
      options: {
        renderCell: (params) =>
          getSensorStatusCell(params.row.statusAsset as SensorStatusUppercase),
      },
    },
    {
      field: "batteryHealthCondition",
      headerName: "Battery",
      order: 3,
      options: {
        renderCell: (params) =>
          getSensorStatusCell(
            params.row.batteryHealthCondition as SensorStatusUppercase
          ),
      },
    },
    {
      field: "airbagHealthCondition",
      headerName: "Air Bag",
      order: 4,
      options: {
        renderCell: (params) =>
          getSensorStatusCell(
            params.row.airbagHealthCondition as SensorStatusUppercase
          ),
      },
    },
    {
      field: "airTankHealthCondition",
      headerName: "Air Tank",
      order: 5,
      options: {
        renderCell: (params) =>
          getSensorStatusCell(
            params.row.airTankHealthCondition as SensorStatusUppercase
          ),
      },
    },
    {
      field: "atisAlphaHealthCondition",
      headerName: "ATIS",
      order: 6,
      options: {
        renderCell: (params) =>
          getSensorStatusCell(
            params.row.atisAlphaHealthCondition as SensorStatusUppercase
          ),
      },
    },
    {
      field: "cargoState",
      headerName: "Cargo",
      order: 7,
      options: {
        renderCell: (params) => {
          const state = params.row.cargoState;
          const color =
            state === CargoState.Empty
              ? "var(--primary-blue)"
              : "var(--indigo)";

          return (
            <span style={{ color, fontWeight: 700 }}>
              {capitalize(state ?? "")}
            </span>
          );
        },
      },
    },
    {
      field: "doorState",
      headerName: "Door",
      order: 8,
      options: {
        renderCell: (params) => {
          const state = params.row.doorState;
          const color =
            state === DoorSensorState.Open
              ? "var(--primary-blue)"
              : "var(--indigo)";

          return (
            <span style={{ color, fontWeight: 700 }}>
              {capitalize(state ?? "")}
            </span>
          );
        },
      },
    },
    {
      field: "dualImbalanceHealthCondition",
      headerName: "Dual Imbalance",
      order: 9,
      options: {
        renderCell: (params) =>
          getSensorStatusCell(
            params.row.dualImbalanceHealthCondition as SensorStatusUppercase
          ),
      },
    },
    {
      field: "internalCameraStatus",
      headerName: "Internal Camera",
      order: 10,
      options: {
        renderCell: (params) => {
          if (!params.row.internalCameraStatus) return null;

          const state = params.row.internalCameraStatus
            ?.toLowerCase()
            .includes(InternalCameraFloorSpaceStatus.Loaded)
            ? InternalCameraFloorSpaceStatus.Loaded
            : InternalCameraFloorSpaceStatus.Empty;
          const color =
            state === InternalCameraFloorSpaceStatus.Empty
              ? "var(--primary-blue)"
              : "var(--indigo)";

          return (
            <span style={{ color, fontWeight: 700 }}>{capitalize(state)}</span>
          );
        },
      },
    },
    {
      field: "liftgateHealthCondition",
      headerName: "Liftgate",
      order: 11,
      options: {
        renderCell: (params) =>
          getSensorStatusCell(
            params.row.liftgateHealthCondition as SensorStatusUppercase
          ),
      },
    },
    {
      field: "liteSentryGammaHealthCondition",
      headerName: "Light Circuit",
      order: 12,
      options: {
        renderCell: (params) =>
          getSensorStatusCell(
            params.row.liteSentryGammaHealthCondition as SensorStatusUppercase
          ),
      },
    },
    {
      field: "regulatorHealthCondition",
      headerName: "Regulator",
      order: 13,
      options: {
        renderCell: (params) =>
          getSensorStatusCell(
            params.row.regulatorHealthCondition as SensorStatusUppercase
          ),
      },
    },
    {
      field: "temperatureHealthCondition",
      headerName: "Temperature",
      order: 14,
      options: {
        renderCell: (params) =>
          getSensorStatusCell(
            params.row.temperatureHealthCondition as SensorStatusUppercase
          ),
      },
    },
    {
      field: "tpmsBetaHealthCondition",
      headerName: "TPMS",
      order: 15,
      options: {
        renderCell: (params) =>
          getSensorStatusCell(
            params.row.tpmsBetaHealthCondition as SensorStatusUppercase
          ),
      },
    },
    {
      field: "psiWheelEndHealthCondition",
      headerName: "Wheel End",
      order: 16,
      options: {
        renderCell: (params) =>
          getSensorStatusCell(
            params.row.psiWheelEndHealthCondition as SensorStatusUppercase
          ),
      },
    },
    { field: "assetIdentifier", headerName: "Asset ID", order: 17 },
    {
      field: "name",
      headerName: "Nickname",
      order: 18,
    },
    { field: "imei", headerName: "Device ID", order: 19 },
    { field: "productName", headerName: "Product Name", order: 20 },
    {
      field: "lastEventTime",
      headerName: "Last Reported Date",
      type: "date",
      format: TableColumnFormat.IsoDateTime,
      order: 21,
      options: { ...columnTimezoneDateTimeFormatParams(timezone) },
    },
    { field: "associated", headerName: "Associated", order: 22 },
    {
      field: "tripStatus",
      headerName: "Trip Status",
      order: 23,
      options: {
        renderCell: (params) => capitalize(params.row.tripStatus),
      },
    },
    { field: "city", headerName: "City", order: 25 },
    { field: "state", headerName: "State", order: 26 },
    { field: "zip", headerName: "Zip", order: 27 },
    {
      field: "category",
      headerName: "Category",
      order: 28,
      options: {
        renderCell: (params) => <CategoryFormatter params={params} />,
      },
    },
    { field: "tags", headerName: "Asset Tags", order: 29 },
    { field: "lat", headerName: "Latitude", order: 30 },
    { field: "lon", headerName: "Longitude", order: 31 },
    { field: "vin", headerName: "VIN", order: 32 },
    { field: "numOfTires", headerName: "# of Tires", order: 33 },
    { field: "numOfAxles", headerName: "# of Axles", order: 34 },
    { field: "length", headerName: "Length", order: 35 },
    { field: "doorType", headerName: "Door Type", order: 36 },
    {
      field: "lastGpsTime",
      headerName: "GPS Time",
      type: "date",
      format: TableColumnFormat.IsoDateTime,
      order: 37,
      options: { ...columnTimezoneDateTimeFormatParams(timezone) },
    },
    {
      field: "shareType",
      headerName: "Share Type",
      order: 38,
    },
    {
      field: "shareOrganizationName",
      headerName: "Share Organization",
      order: 39,
    },
    {
      field: "shareGroupName",
      headerName: "Share Group Name",
      order: 40,
    },
    {
      field: "shareStartDate",
      headerName: "Share Start Date",
      order: 41,
    },
    {
      field: "shareEndDate",
      headerName: "Share End Date",
      order: 42,
    },
  ];

  return columns;
};

export const getColumns = (
  timezone: string
): TableGridColDef<AssetTableData>[] => {
  const columns: TableColumnProps<AssetTableData>[] = [
    ...getDefaultAssetsListColumns(timezone),
    {
      field: "dwellingDays",
      headerName: "Dwell",
      order: 24,
      options: {
        renderCell: (params) =>
          params.row.dwellingDays != null
            ? `${params.row.dwellingDays} days`
            : "",
        sortable: false,
        filterable: false,
      },
    },
  ];

  return columns
    .sort((a, b) => (a?.order ?? 0) - (b?.order ?? 0))
    .map(getTableColumn);
};
