import {
  ArchiveAutomationInput,
  ArchiveAutomationMutation,
  Automation,
  CreateAutomationInput,
  CreateAutomationMutation,
  Exact,
  UpdateAutomationInput,
  UpdateAutomationMutation,
  useArchiveAutomationMutation,
  useCreateAutomationMutation,
  useUpdateAutomationMutation,
} from "../../../../../graphql/operations";

type AutomationsApi = {
  createAutomationOnSuccess: (
    data: CreateAutomationMutation,
    variables: Exact<{ input: CreateAutomationInput }>,
    context: unknown
  ) => unknown;
  updateAutomationOnSuccess: (
    data: UpdateAutomationMutation,
    variables: Exact<{ input: UpdateAutomationInput }>,
    context: unknown
  ) => unknown;
  archiveAutomationOnSuccess: (
    data: ArchiveAutomationMutation,
    variables: Exact<{ input: ArchiveAutomationInput }>,
    context: unknown
  ) => unknown;
  createAutomationOnError: (error: unknown) => unknown;
  updateAutomationOnError: (error: unknown) => unknown;
  archiveAutomationOnError: (error: unknown) => unknown;
};

export const useAutomationsApi = ({
  createAutomationOnSuccess,
  createAutomationOnError,
  updateAutomationOnSuccess,
  updateAutomationOnError,
  archiveAutomationOnSuccess,
  archiveAutomationOnError,
}: AutomationsApi) => {
  const { mutate: createAutomation } = useCreateAutomationMutation({
    onSuccess: createAutomationOnSuccess,
    onError: createAutomationOnError,
  });
  const { mutate: updateAutomation, isLoading: isAutomationUpdate } =
    useUpdateAutomationMutation({
      onSuccess: updateAutomationOnSuccess,
      onError: updateAutomationOnError,
    });
  const { mutate: archiveAutomation } = useArchiveAutomationMutation({
    onSuccess: archiveAutomationOnSuccess,
    onError: archiveAutomationOnError,
  });

  return {
    createAutomation: (automation: Partial<Automation>) => {
      return createAutomation({
        input: {
          actions: automation?.actions ?? [],
          mode: automation.mode!,
          name: automation.name!,
          orgId: automation.orgId!,
          parameters: automation.parameters,
          trigger: automation.trigger,
        },
      });
    },
    updateAutomation: (automation: Partial<Automation>) => {
      return updateAutomation({
        input: {
          id: automation._id!,
          actions: automation?.actions ?? [],
          mode: automation.mode!,
          name: automation.name!,
          trigger: automation.trigger,
        },
      });
    },
    archiveAutomation: (automationId: string) => {
      return archiveAutomation({
        input: {
          _id: automationId,
        },
      });
    },
    isAutomationUpdate,
  };
};
