import { useSearchParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { BRAND_ID } from "../../../constants";
import { env } from "../../../env";
import {
  type AuthenticationViewBrand,
  getBrand,
} from "../../AdminPanel/tabs/Brands/BrandUtils";

export const useBrandId = () => {
  const [searchParams] = useSearchParams();

  const brandIdFromParams = searchParams.get("brandId");
  const brandIdFromStorage = localStorage.getItem(BRAND_ID);
  const defaultBrandIdFromEnv = env.REACT_APP_DEFAULT_PCT_BRAND_ID;

  const brandId =
    brandIdFromParams ?? brandIdFromStorage ?? defaultBrandIdFromEnv;

  // save brandId to a local storage
  localStorage.setItem(BRAND_ID, brandId);

  return brandId;
};

/**
 * This hook is used to obtain brand data, that is used in branding the authentication flows.
 */
export const useAuthenticationViewBrand = ():
  | AuthenticationViewBrand
  | undefined => {
  const brandId = useBrandId();
  const { data } = useQuery<AuthenticationViewBrand>(
    ["useGetBrandQuery", brandId],
    () => getBrand(brandId)
  );

  return data;
};
