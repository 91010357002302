import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { Box } from "@mui/material";
import { useGridApiRef } from "@mui/x-data-grid-premium";
import { SET_BREADCRUMBS_CURRENT_PAGE_RANGE } from "../../../../../../constants";
import { useAppContext } from "../../../../../../context/AppContext";
import {
  HistoricalEventHistory,
  TableViewType,
} from "../../../../../../graphql/operations";
import {
  getRows,
  Table,
  TableDataModes,
} from "../../../../../../shared/components/Table";
import { useFindAssetById } from "../../../../../../shared/hooks/openSearchMongoPolyfillHooks/useFindAssetById";
import { usePreferredTimezone } from "../../../../../../shared/hooks/usePreferredTimezone";
import { useUserData } from "../../../../../../shared/hooks/useUserData";
import {
  DATE_TIME_FORMAT,
  formatDateInTimezone,
  mapTimezoneToIANAValue,
} from "../../../../../../utils";
import { useAssetsDataContext } from "../../../../shared/AssetsDataContext";
import { useBreadcrumbsContext } from "../context/BreadCrumbsContext";
import { BreadcrumbsFields, columns } from "./columns";

export interface BreadcrumbsTableProps {
  totalTableRowsCount: number;
  isLoading: boolean;
  eventHistory: HistoricalEventHistory[];
}

const BreadcrumbsTable: FC<BreadcrumbsTableProps> = ({
  eventHistory,
  isLoading,
  totalTableRowsCount,
}) => {
  const { state } = useAppContext();
  const { selectedAssetId } = useAssetsDataContext();
  const { breadcrumbsHistoryEventsPerPage } = state.appConfig.table;
  const gridApiRef = useGridApiRef();
  const {
    state: {
      breadcrumbs: { selectedMapMarkerID, selectedFilters },
    },
    dispatch,
  } = useBreadcrumbsContext();

  const user = useUserData();

  const { data: selectedAsset } = useFindAssetById(
    { assetId: selectedAssetId ?? "" },
    { enabled: !!selectedAssetId }
  );

  const timezone = usePreferredTimezone();

  const [rows, setRows] = useState<any[]>([]);

  const onPageChange = useCallback(
    (page: number) => {
      dispatch({
        type: SET_BREADCRUMBS_CURRENT_PAGE_RANGE,
        payload: breadcrumbsHistoryEventsPerPage * (page - 1),
      });
    },
    [dispatch, breadcrumbsHistoryEventsPerPage]
  );

  useEffect(() => {
    if (eventHistory) {
      const mappedEvents = eventHistory.map((event) => {
        const formattedDate =
          event.date &&
          formatDateInTimezone(
            event.date,
            DATE_TIME_FORMAT,
            mapTimezoneToIANAValue(timezone)
          );

        return {
          ...event,
          eventDate: formattedDate || event.date,
          icon: {
            tripState: event?.breadcrumbs?.tripState,
            dwellTime: event?.breadcrumbs?.dwellTime,
            cargoStatus: event?.breadcrumbs?.cargoState,
          },
        };
      });

      setRows(getRows(mappedEvents, columns, "", "", "id"));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventHistory, timezone]);

  if (
    typeof gridApiRef.current.getSortedRows !== "undefined" &&
    selectedMapMarkerID
  ) {
    gridApiRef.current.selectRow(selectedMapMarkerID, true, true);
    gridApiRef.current.scrollToIndexes({ rowIndex: selectedMapMarkerID });
  }

  useEffect(() => {
    // update table pagination when new filter is applied.
    dispatch({
      type: SET_BREADCRUMBS_CURRENT_PAGE_RANGE,
      payload: 0,
    });

    // Workaround until we find way to use gridApiRef.current.setPage method when server side paging is used on MUI Premium Grid
    // TODO: Check for other solution
    const paginationPageOneButton = document.querySelector(
      '[aria-label="Go to page 1"]'
    ) as HTMLElement;

    if (paginationPageOneButton) {
      paginationPageOneButton?.click();
    }
  }, [dispatch, gridApiRef, selectedFilters]);

  const hideSpeed = useMemo(
    () =>
      selectedAsset?.shared?.organizationId === user?.customerOrg._id &&
      Boolean(selectedAsset?.shared?.hideProperties?.speed),
    [
      selectedAsset?.shared?.hideProperties?.speed,
      selectedAsset?.shared?.organizationId,
      user?.customerOrg._id,
    ]
  );

  const visibleColumns = useMemo(
    () =>
      hideSpeed
        ? columns.filter((column) => column.field !== BreadcrumbsFields.Speed)
        : columns,
    [hideSpeed]
  );

  return (
    <Box
      className="flex justify-center bg-dashboard_subheader__bg flex h-full w-full rounded-lg"
      sx={{
        "& .green-cell": {
          backgroundColor:
            "var(--breadcrumbs-table-moving-without-primary-voltage)",
        },
        "& .blue-cell": {
          backgroundColor:
            "var(--breadcrumbs-table-moving-with-primary-voltage)",
        },
      }}
    >
      <Table
        tableType={TableViewType.Breadcrumbs}
        showToolbar
        rows={rows ?? []}
        apiRef={gridApiRef}
        columns={visibleColumns}
        initialState={{
          sorting: {
            sortModel: [{ field: "eventDate", sort: "desc" }],
          },
        }}
        disableColumnFilter={true}
        loading={isLoading}
        handleDataMode={TableDataModes.Client} // TODO: PRJIND-9769 Change to Server, once backend pagination is implemented
        onPageChange={onPageChange}
        onPaginationModelChange={onPageChange}
        pageSize={breadcrumbsHistoryEventsPerPage}
        rowCount={totalTableRowsCount}
        tableName="breadcrumbs"
        tableMinHeight="100%"
        isDataRefetching={false}
      />
    </Box>
  );
};

export default BreadcrumbsTable;
