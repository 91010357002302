import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import WarningAmber from "@mui/icons-material/WarningAmber";
import { Box, Typography, Grid } from "@mui/material";
import { ReactComponent as AirbagSensorDark } from "../../../../../../../assets/svgs/airbagSensorDark.svg";
import { ReactComponent as AirbagSensorLight } from "../../../../../../../assets/svgs/airbagSensorLight.svg";
import { useAppContext } from "../../../../../../../context/AppContext";
import { ColorsPalette } from "../../../../../../../design-system/colors-palette";
import {
  AirBagSensorMeasures,
  Maybe,
  SensorStatusUppercase,
} from "../../../../../../../graphql/operations";
import SensorHeader from "../../../../../../../shared/components/SensorHeader/SensorHeader";
import { useDateInPreferredTimezone } from "../../../../../../../shared/hooks/useDateInPreferredTimezone";
import { useGetPressureUnitPreference } from "../../../../../../../shared/hooks/useGetPressureUnitPreference";
import { getConvertedPressureValueWithUnit } from "../../../../../../../utils/convertPressure";
import { axleLabels } from "../constants";
import { getAirBagPositionLabel } from "../helpers";

/****** Airbag sensor cards ******/
interface AirBagStatusCardProps {
  lastReported: string;
  airbagMeasures: AirBagSensorMeasures[];
}

const AirBagCard: React.FC<AirBagStatusCardProps> = ({
  lastReported,
  airbagMeasures,
}) => {
  const {
    state: { theme },
  } = useAppContext();
  const pressureUnit = useGetPressureUnitPreference();
  const isLightTheme = theme.theme === "light";
  const svgIconSettings = {
    width: "5rem",
    height: "5rem",
    display: "block",
  };
  const timezoneDate = useDateInPreferredTimezone(
    lastReported,
    "MM/dd/yy h:mm a"
  );

  const renderIcon = (airBagStatus: SensorStatusUppercase) => {
    switch (airBagStatus) {
      case SensorStatusUppercase.Healthy:
        return (
          <span
            style={{ color: ColorsPalette.Success }}
            data-testid="airbag-status-icon"
          >
            <CheckCircleOutlineIcon />
          </span>
        );
      case SensorStatusUppercase.Warning:
        return (
          <span
            style={{ color: ColorsPalette.Alert }}
            data-testid="airbag-status-icon"
          >
            <WarningAmber />
          </span>
        );
      case SensorStatusUppercase.Alert:
        return (
          <span
            style={{ color: ColorsPalette.AlertOpacity80 }}
            data-testid="airbag-status-icon"
          >
            <WarningAmber />
          </span>
        );
      case SensorStatusUppercase.Critical:
        return (
          <span
            style={{ color: ColorsPalette.Error }}
            data-testid="airbag-status-icon"
          >
            <WarningAmber />
          </span>
        );
      default:
        return null;
    }
  };

  return (
    <Grid container spacing={2}>
      {airbagMeasures.map(
        (measure: Maybe<AirBagSensorMeasures>, index: number) => {
          if (measure === null) {
            return null;
          }
          const airBagPosition = getAirBagPositionLabel(
            measure.location_code_hex_str
          );

          return (
            <Grid item xs={12} lg={6} key={`measure-${index}`}>
              <Box
                className="flex p-4 mb-4 mr-4 border border-gainsboro rounded-lg"
                sx={{
                  border:
                    "2px solid var(--asset-toggle-view-button-background-light)",
                }}
              >
                <Box className="flex flex-col">
                  <SensorHeader
                    title={axleLabels.get(index) ?? "No data"}
                    secondTitle={airBagPosition}
                    noBorder={true}
                  >
                    {isLightTheme ? (
                      <AirbagSensorDark style={svgIconSettings} />
                    ) : (
                      <AirbagSensorLight style={svgIconSettings} />
                    )}
                  </SensorHeader>
                  <Typography
                    data-testid="airbag-condition"
                    className="!text-md gap-1 text-battery-label"
                  >
                    {(measure?.statusTire_pressure_mbar ||
                      measure?.statusTire_pressure_mbar ===
                        SensorStatusUppercase.Unknown) ??
                      "No data"}
                    {measure?.statusTire_pressure_mbar
                      ? renderIcon(measure.statusTire_pressure_mbar)
                      : null}
                  </Typography>

                  <Box
                    className="flex gap-1 text-battery-label"
                    data-testid="airbagCard-value"
                  >
                    <Typography
                      data-testid="airbag-pressure"
                      className="!text-md"
                    >
                      Pressure:
                    </Typography>
                    <Typography className="!text-md !font-bold">
                      {getConvertedPressureValueWithUnit(
                        measure.tire_pressure_mbar ?? 0,
                        pressureUnit
                      )}
                    </Typography>
                  </Box>
                  <Box
                    className="flex gap-1 text-battery-label"
                    data-testid="airbagCard-lastReported"
                  >
                    <Typography
                      data-testid="airbag-last-reported"
                      className="!text-md"
                    >
                      Last Reported:
                    </Typography>
                    <Typography className="!text-md">{timezoneDate}</Typography>
                  </Box>
                </Box>
              </Box>
            </Grid>
          );
        }
      )}
    </Grid>
  );
};

export default AirBagCard;
