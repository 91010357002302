import { MediaTableData } from "../../../../../graphql/operations";
import { MediaActivityItem } from "../../Assets/CargoTabPanel/InternalCameraStatus/interface";
import { MediaTabTableFloorUsageStatus } from "./interface";
import { generateUniqueId } from "./utils";

export const processMediaTableData = (
  data: MediaTableData[]
): MediaActivityItem[] =>
  data.map((item) => {
    const isLoaded = item.floorUsageStatus?.toLowerCase().includes("loaded");

    return {
      floorUsageStatus: isLoaded
        ? MediaTabTableFloorUsageStatus.Loaded
        : MediaTabTableFloorUsageStatus.Empty,
      id: generateUniqueId(),
      imageUrl: item?.imageUrl ?? "",
      address: item?.address ?? "",
      floorUsagePercentage: item?.floorUsagePercentage ?? "",
      loadedEmptyConfidence: item.loadedEmptyConfidence ?? "",
      uploadDate: item?.uploadDate ?? "",
      geofenceName: item.geofenceName ?? "",
      geofenceEntry: item?.geofenceEntry ?? "",
      longitude: item.longitude ?? 0,
      latitude: item.latitude ?? 0,
    };
  });
