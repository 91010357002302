import { Box, Typography, Grid } from "@mui/material";
import { TooltipProps } from "recharts";
import { useGetTemperatureUnitPreference } from "../../../../../../../../../shared/hooks/useGetTemperatureUnitPreference";
import { getTemperatureUnitLabel } from "../../../../../../../../../utils/convertTemperature";
import type { PsiWheelEndAxleTemperatureValues } from "./PsiWheelEndChartBody";

export interface PsiWheelEndChartTooltipProps {
  selectedWheelEnd: PsiWheelEndAxleTemperatureValues | undefined;
  payload?: TooltipProps<string, string>["payload"];
}

export const PsiWheelEndChartTooltip: React.FC<
  PsiWheelEndChartTooltipProps
> = ({ selectedWheelEnd, payload }) => {
  const tempUnitPreference = useGetTemperatureUnitPreference();

  if (!payload?.length || !selectedWheelEnd) {
    return null;
  }

  const tooltipTitle = payload[0].payload?.tooltipDate;

  const selectedWheelEndData = payload.find(
    (item) => item.dataKey === selectedWheelEnd
  );

  // We want to display data only for the hovered tile
  const visibleWheelEndsData = payload
    .filter((item) => {
      if (!item || !item.dataKey || !selectedWheelEnd) {
        return false;
      }

      // Show the selected tire for sure
      if (item.dataKey === selectedWheelEnd) {
        return true;
      }

      // But also, show the other tires if they have the same value as the selected tire
      if (
        selectedWheelEndData?.payload?.[selectedWheelEnd] ===
        item.payload?.[item.dataKey]
      ) {
        return true;
      }

      return false;
    })
    .map((item) => item.dataKey);

  return (
    <Box
      className="rounded-lg bg-secondary border border-sub-header-border p-3 w-3/5"
      sx={{ minWidth: visibleWheelEndsData.length === 1 ? "200px" : "400px" }}
    >
      <Typography
        className="!text-xs !text-sub-header-text !font-medium !leading-3 p-1"
        data-testid={`psi-wheel-end-chart-tooltip-label`}
      >
        {tooltipTitle}
      </Typography>
      <Grid
        container
        spacing={1}
        alignItems="center"
        data-testid="psi-wheel-end-chart-tooltip"
      >
        {payload.map((item, index) => {
          if (!item || !item.dataKey) {
            return null;
          }

          // It comes in the form of "${PsiWheelEndAxle}-${PsiWheelEnd}-Temperature", so we need to split it into sections
          const [axle, wheelEnd] = (item.dataKey as string).split("-");
          const label = `${axle} ${wheelEnd}`;
          const value = `Temperature: ${item.value} ${getTemperatureUnitLabel(
            tempUnitPreference,
            true
          )}`;

          return (
            <Grid
              item
              lg={visibleWheelEndsData.length === 1 ? 12 : 6}
              sx={{
                display: visibleWheelEndsData.includes(item.dataKey)
                  ? "block"
                  : "none",
              }}
              key={item.dataKey}
              data-testid={`psi-wheel-end-chart-tooltip-value-${index}`}
            >
              <Typography className="!text-xs !text-brand !font-bold !leading-3 p-1">
                {label}
              </Typography>
              <Typography className="!text-xs !text-sub-header-text !font-medium !leading-3 p-1">
                {value}
              </Typography>
            </Grid>
          );
        })}
      </Grid>
    </Box>
  );
};
