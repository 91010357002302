import { memo } from "react";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import { PAGE_SNACKBAR } from "../../../../../constants";
import { useAppContext } from "../../../../../context/AppContext";
import type { CustomerSupportDataType } from "../SideMenu";

export type CustomerSupportProps = {
  customerSupportData: CustomerSupportDataType;
};

const CustomerSupport = ({ customerSupportData }: CustomerSupportProps) => {
  const { dispatch } = useAppContext();

  const onCopy = () => {
    const copyText = document.getElementById("supportEmail")!.innerText;
    navigator.clipboard.writeText(copyText).then(() => {
      dispatch({
        type: PAGE_SNACKBAR,
        payload: {
          title: "Email copied successfully!",
          text: " ",
          severity: "success",
        },
      });
    });
  };

  return (
    <div data-testid="customer-support-links" className="px-2 text-center">
      <div className="border border-solid border-primary p-4 text-typography">
        <a href={`mailto:${customerSupportData.email}`}>
          <b>Email Customer Support</b>
        </a>

        <div className="flex" onClick={onCopy}>
          <p className="text-sm mr-2" id="supportEmail">
            {customerSupportData.email}
          </p>
          <button className="text-sm" data-testid="copy-email-btn">
            <FileCopyIcon sx={{ fontSize: "0.875rem" }} />
          </button>
        </div>
      </div>
      <div className="p-4 text-typography">
        <a href={`tel:${customerSupportData.phone}`}>
          or Call <b>{customerSupportData.phone}</b>
        </a>
      </div>
    </div>
  );
};

export default memo(CustomerSupport);
