import { memo, useState } from "react";
import { FieldValues, useForm } from "react-hook-form";
import { TextFieldElement } from "react-hook-form-mui";
import { useNavigate, useSearchParams } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import { Box, ThemeProvider, Typography } from "@mui/material";
import { isEmpty } from "lodash";
import * as yup from "yup";
import { VALID_PASSWORD_REGEX } from "../../../constants/authentication";
import { useAppContext } from "../../../context/AppContext";
import {
  confirmForgotPassword,
  forgotPassword,
} from "../../../services/aws/auth";
import { NavigationRoutes } from "../../../utils/routes/routesUtils";
import { AuthButton } from "../components/AuthButton";
import { AuthenticationBox } from "../components/AuthenticationBox";
import { useAuthFormTheme } from "../hooks/useAuthFormTheme";

export const schema = yup.object().shape({
  confirmationCode: yup
    .string()
    .length(6, `Confirmation code must be exactly 6 characters`)
    .required("Confirmation code is required"),
  newPassword: yup
    .string()
    .matches(
      VALID_PASSWORD_REGEX,
      "Password must contain at least 6 characters, one uppercase letter, one lowercase letter, one number, and one special character (!@#$%^&*)"
    )
    .required("New password is required"),
  confirmNewPassword: yup
    .string()
    .oneOf([yup.ref("newPassword"), null], "Passwords do not match")
    .required("Confirm new password is required"),
});

export enum ConfirmPasswordReason {
  Forgot = "forgot",
  Reset = "reset",
}

const ConfirmForgotPassword = () => {
  const formTheme = useAuthFormTheme();
  const { dispatch } = useAppContext();
  const {
    control,
    handleSubmit,
    formState: { errors, dirtyFields, isValid, isSubmitted },
    setError,
    clearErrors,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      confirmationCode: "",
      newPassword: "",
      confirmNewPassword: "",
    },
  });
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const username = searchParams.get("username") ?? "";
  const reason = searchParams.get("reason") ?? ConfirmPasswordReason.Forgot;

  const [isLoadingReset, setIsLoadingReset] = useState(false);
  const [isLoadingVerify, setIsLoadingVerify] = useState(false);

  const isSubmitDisabled =
    isLoadingReset || isEmpty(dirtyFields) || (isSubmitted && !isValid);

  const handleConfirmPasswordReset = async ({
    confirmationCode,
    newPassword,
  }: FieldValues) => {
    setIsLoadingReset(true);

    if (!username) {
      setError("root", {
        type: "manual",
        message: "Username is missing",
      });
      setIsLoadingReset(false);
      return false;
    }

    try {
      await confirmForgotPassword(username, confirmationCode, newPassword);
      navigate(NavigationRoutes.CustomLogin);
    } catch (error) {
      setIsLoadingReset(false);
      setError("root", {
        type: "manual",
        message:
          error instanceof Error && !isEmpty(error.message)
            ? error.message
            : "An unknown error occurred",
      });
    }
  };

  const sendVerificationCode = async () => {
    setIsLoadingVerify(true);
    await forgotPassword({ username, dispatch });
    setIsLoadingVerify(false);
  };

  const handleResetRootError = () => {
    clearErrors("root");
  };

  const title =
    reason === ConfirmPasswordReason.Forgot
      ? "Forgot your password?"
      : "Reset your password";

  return (
    <AuthenticationBox>
      <ThemeProvider theme={formTheme}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            gap: "40px",
            width: "100%",
            height: "100%",
          }}
        >
          <Typography sx={{ fontWeight: 500 }}>{title}</Typography>
          <form onSubmit={handleSubmit(handleConfirmPasswordReset)}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "20px",
                justifyContent: "center",
                alignItems: "center",
                width: "234px",
              }}
            >
              <Typography sx={{ fontSize: "14px" }}>
                Please enter the verification code from your E-mail together
                with your new password
              </Typography>
              {errors.root && (
                <Typography
                  sx={{
                    fontSize: "12px",
                    color: "var(--error)",
                    textAlign: "center",
                  }}
                >
                  {errors.root.message}
                </Typography>
              )}
              <TextFieldElement
                name="confirmationCode"
                control={control}
                onChange={handleResetRootError}
                required
                fullWidth
                inputProps={{
                  "data-testid": "confirm-forgot-password--confirmation-code",
                  placeholder: "Confirmation code",
                  autoFocus: true,
                  autoComplete: "new-password", // This disables the browser's password manager autocomplete
                }}
              />
              <TextFieldElement
                name="newPassword"
                type="password"
                control={control}
                onChange={handleResetRootError}
                required
                fullWidth
                inputProps={{
                  "data-testid": "confirm-forgot-password--new-password",
                  placeholder: "New password",
                  autoComplete: "new-password", // This disables the browser's password manager autocomplete
                }}
                sx={{
                  marginBottom: errors.newPassword ? "3rem" : "0",
                }}
              />
              <TextFieldElement
                name="confirmNewPassword"
                type="password"
                control={control}
                onChange={handleResetRootError}
                required
                fullWidth
                inputProps={{
                  "data-testid":
                    "confirm-forgot-password--confirm-new-password",
                  placeholder: "Confirm new password",
                }}
              />
              <AuthButton
                variant="ghost"
                text="Send verification code"
                onClick={sendVerificationCode}
                disabled={isLoadingVerify}
                iconPosition={isLoadingVerify ? "right" : "none"}
              />
              <AuthButton
                text="Reset my password"
                type="submit"
                disabled={isSubmitDisabled}
                iconPosition={isLoadingReset ? "right" : "none"}
              />
            </Box>
          </form>
        </Box>
      </ThemeProvider>
    </AuthenticationBox>
  );
};

export default memo(ConfirmForgotPassword);
