import { FC, useMemo } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import { Box } from "@mui/material";
import * as yup from "yup";
import { FILTERS_LIST_WIDTH } from "../../../../../constants/map";
import useBreakpoint from "../../../../../shared/hooks/useBreakpoint";
import type { TFieldValues } from "../../../AssetsViewContainer";
import { useAssetsDataContext } from "../../../shared/AssetsDataContext";
import {
  ARROW_COMMON_CLASSES,
  AssetPagesUrlTypes,
  getViewFromUrl,
} from "../../../utils";
import FiltersDrawerContent from "./FiltersDrawerContent";

export const schema = yup.object({
  maxDays: yup.number().min(0),
  minDays: yup
    .number()
    .min(0)
    .test({
      name: "min-max-validation",
      message: "Min. days cannot be greater than max days.",
      test: (minDays = 0, { parent: { maxDays = 0 } }) => minDays <= maxDays,
    }),
});

// z-index is set to 20 here to be over the drawer itself (which is 10)
const ARROW_LEFT_CLASSES = `${ARROW_COMMON_CLASSES} rounded-tl-none rounded-tr-lg rounded-br-lg rounded-bl-none z-20`;

interface ArrowButtonProps {
  onToggleFiltersDrawer: () => void;
  isFiltersDrawerOpen: boolean;
}

const ArrowButton: FC<ArrowButtonProps> = ({
  onToggleFiltersDrawer,
  isFiltersDrawerOpen,
}) => (
  <Box
    className={ARROW_LEFT_CLASSES}
    onClick={onToggleFiltersDrawer}
    sx={{
      // The 0.5 is subtracted to account for the border (so the button sits above the border)
      left: isFiltersDrawerOpen ? FILTERS_LIST_WIDTH - 0.5 : 0,
      boxShadow: isFiltersDrawerOpen
        ? "none"
        : "0 0 16px 0px var(--box-shadow)",
    }}
  >
    {isFiltersDrawerOpen ? (
      <KeyboardArrowLeft sx={{ color: "var(--typography)" }} />
    ) : (
      <KeyboardArrowRight sx={{ color: "var(--typography)" }} />
    )}
  </Box>
);

const FiltersDrawer = () => {
  const {
    isFiltersDrawerOpen,
    isDrawingGeofence,
    addGeofenceDrawerOpen,
    isFiltersDrawerVisible,
    setIsAssetsDrawerOpen,
    setIsFiltersDrawerOpen,
  } = useAssetsDataContext();
  const isMobile = useBreakpoint("down", "sm");
  const methods = useForm<TFieldValues>({
    resolver: yupResolver(schema),
    mode: "onChange",
  });

  const onToggleFiltersDrawer = () => {
    if (isMobile && !isFiltersDrawerOpen) {
      setIsAssetsDrawerOpen(false);
    }
    setIsFiltersDrawerOpen(!isFiltersDrawerOpen);
  };

  const shouldHideArrowButton = useMemo(
    () => addGeofenceDrawerOpen && isDrawingGeofence,
    [addGeofenceDrawerOpen, isDrawingGeofence]
  );

  return (
    <>
      {isFiltersDrawerVisible && (
        <>
          <FormProvider {...methods}>
            <FiltersDrawerContent />
          </FormProvider>
          {!shouldHideArrowButton && (
            <ArrowButton
              onToggleFiltersDrawer={onToggleFiltersDrawer}
              isFiltersDrawerOpen={isFiltersDrawerOpen}
            />
          )}
        </>
      )}
    </>
  );
};

export default FiltersDrawer;
