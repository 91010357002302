import { useEffect, useState } from "react";
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import {
  Asset,
  SensorStatusUppercase,
} from "../../../../../../../../graphql/operations";
import useBreakpoint from "../../../../../../../../shared/hooks/useBreakpoint";
import { useGetPressureUnitPreference } from "../../../../../../../../shared/hooks/useGetPressureUnitPreference";
import { useGetTemperatureUnitPreference } from "../../../../../../../../shared/hooks/useGetTemperatureUnitPreference";
import { usePreferredTimezone } from "../../../../../../../../shared/hooks/usePreferredTimezone";
import { getConvertedPressureValue } from "../../../../../../../../utils/convertPressure";
import {
  getConvertedTemperatureValue,
  getTemperatureUnitLabel,
} from "../../../../../../../../utils/convertTemperature";
import {
  DATE_TIME_FORMAT_SHORT,
  formatDateInTimezone,
  mapTimezoneToIANAValue,
} from "../../../../../../../../utils/date";
import { useGetSensorStateWidget } from "../../../../AssetShortTooltip/hooks/useGetSensorStateWidget";
import { TIRE_CARD_PADDING } from "../../../constants";
import {
  iconSettings,
  mapTPMSSummaryToTableData,
  parseTPMSSummary,
} from "../../../helpers";
import { useTiresTabLayout } from "../../../hooks/useTiresTabLayout";
import { TireSummaryData } from "../../../interfaces";

export type TireSummaryTableData = Pick<
  TireSummaryData,
  | "pressure"
  | "temperature"
  | "status"
  | "lastReported"
  | "dualImbalanceStatus"
  | "gap"
> & {
  name: string;
};

export interface TiresSummaryTableProps {
  asset: Asset;
}

// Note: This is a potential candidate for a shared component
const TiresSummaryTable: React.FC<TiresSummaryTableProps> = ({ asset }) => {
  const pressureUnit = useGetPressureUnitPreference();

  const [rows, setRows] = useState<TireSummaryTableData[]>([]);
  const { tpmsTableHeight: tableHeight } = useTiresTabLayout(asset);
  const isDesktop = useBreakpoint("up", "xl");
  const { getSensorStateWidget, getTireSensorChip } = useGetSensorStateWidget();
  const timezone = usePreferredTimezone();

  useEffect(() => {
    if (asset?.sensors?.tpmsBeta && rows.length === 0) {
      const sensorsSummary = parseTPMSSummary(asset);
      const tableData = mapTPMSSummaryToTableData(sensorsSummary);

      setRows(tableData);
    }
  }, [asset, rows]);

  const tempUnitPreference = useGetTemperatureUnitPreference();

  return (
    <Box
      className="bg-card__bg rounded-lg"
      sx={{
        borderColor: "var(--asset-card-border)",
      }}
      style={tableHeight > 0 ? { height: tableHeight } : {}}
      data-testid="asset-dashboard--tires-tab--tires-table-summary-section"
    >
      <TableContainer
        component={Paper}
        className="border"
        sx={{
          boxShadow: "none",
          borderRadius: "8px",
          borderColor: isDesktop ? "var(--asset-table-border)" : "transparent",
        }}
        // The height is fixed to match the axles cards, but exclude the padding defined in the parent container
        style={
          tableHeight > 0
            ? { maxHeight: tableHeight - 2 * TIRE_CARD_PADDING }
            : {}
        }
      >
        <Table stickyHeader aria-label="Tire Sensors Table">
          <TableHead
            data-testid="asset-dashboard--tires-tab--tires-table-summary-section--header"
            sx={{
              backgroundColor: "var(--dashboard_subheader__bg)",
            }}
          >
            <TableRow>
              <TableCell sx={{ fontWeight: "700" }}>Tire</TableCell>
              <TableCell sx={{ fontWeight: "700" }}>Status</TableCell>
              <TableCell sx={{ fontWeight: "700" }}>Pressure</TableCell>
              <TableCell
                sx={{ fontWeight: "700" }}
              >{`Temp (${getTemperatureUnitLabel(
                tempUnitPreference,
                true
              )})`}</TableCell>
              <TableCell sx={{ fontWeight: "700" }}>Dual Gap</TableCell>
              <TableCell sx={{ fontWeight: "700" }}>Dual Status</TableCell>
              <TableCell sx={{ fontWeight: "700" }}>Last Updated</TableCell>
            </TableRow>
          </TableHead>
          <TableBody data-testid="asset-dashboard--tires-tab--tires-table-summary-section--body">
            {rows.map((row) => (
              <TableRow
                key={row.name}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                data-testid="asset-dashboard--tires-tab--tires-table-summary-section--row"
              >
                <TableCell component="th" scope="row">
                  {row.name}
                </TableCell>
                <TableCell sx={{ textTransform: "capitalize" }}>
                  {getTireSensorChip(row.status, iconSettings).icon}
                </TableCell>
                <TableCell>
                  {getConvertedPressureValue(row.pressure ?? 0, pressureUnit)}
                </TableCell>
                <TableCell>
                  {getConvertedTemperatureValue(
                    Number(row.temperature ?? 0),
                    tempUnitPreference
                  )}
                </TableCell>
                <TableCell>
                  {row.gap && getConvertedPressureValue(row?.gap, pressureUnit)}
                </TableCell>
                <TableCell align="center">
                  {row.dualImbalanceStatus !== SensorStatusUppercase.Unknown &&
                    getSensorStateWidget({
                      type: undefined,
                      label: undefined,
                      state: row.dualImbalanceStatus as SensorStatusUppercase,
                      justifyBetween: false,
                    } as any)}
                </TableCell>

                <TableCell>
                  {row.lastReported &&
                    formatDateInTimezone(
                      row.lastReported,
                      DATE_TIME_FORMAT_SHORT,
                      mapTimezoneToIANAValue(timezone)
                    )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default TiresSummaryTable;
