export enum StatusCardsType {
  Chassis = "Cargo",
  CameraVision = "CargoVision",
  Regulator = "Regulator",
  AirBag = "Air Bag",
  Door = "Door",
  ABSFaultCodes = "ABS Fault Code",
  TPMS = "TPMS",
  AirTank = "Air Tank",
  AirSupply = "Air Supply",
  AtisAlpha = "ATIS",
  LiteSentryGamma = "Light Circuit",
  TemperatureInternal = "Temperature (Internal)",
  WheelEndTemperature = "Wheel End Temperature",
  DualImbalance = "Dual Imbalance",
  Liftgate = "Liftgate",
}

export enum SensorLabels {
  Battery = "Battery",
  Dwell = "Dwell",
  Cargo = "Cargo",
  CargoVision = "CargoVision",
  AirBag = "Air Bag",
  Door = "Door",
  TPMS = "TPMS",
  AirSupply = "Air Supply",
  TemperatureInterval = "Temperature (int)",
  ATIS = "ATIS",
  WheelEndTemperature = "Wheel End Temperature",
  LightCircuit = "Light Circuit",
  Regulator = "Regulator",
  AirTank = "Air Tank",
}
