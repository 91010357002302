import { FC, useEffect, useMemo, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  Button as MUIButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
} from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import { isEmpty } from "lodash";
import {
  PAGE_SNACKBAR,
  ROOT_ORGANIZATION_NAME,
} from "../../../../../constants";
import {
  ORGANIZATION_CREATE_FAILED_PAYLOAD,
  ORGANIZATION_CREATE_SUCCESS_PAYLOAD,
  ORGANIZATION_UPDATE_FAILED_PAYLOAD,
  ORGANIZATION_UPDATE_SUCCESS_PAYLOAD,
  ORGANIZATION_DELETE_SUCCESS_PAYLOAD,
  ORGANIZATION_DELETE_FAILED_PAYLOAD,
  ORGANIZATION_CREATE_FAILED_DUPLICATE_ACC_NUMBER_PAYLOAD,
} from "../../../../../constants/organizations";
import { useAppContext } from "../../../../../context/AppContext";
import { useAuthContext } from "../../../../../context/AuthContext";
import { FormModes } from "../../../../../enums/formModes";
import {
  useCreateOrgMutation,
  useUpdateOrgMutation,
  useDeleteOrgMutation,
  useGetDashboardNamesQuery,
} from "../../../../../graphql/operations";
import { TextButton } from "../../../../../shared/components/Button";
import Drawer from "../../../../../shared/components/Drawer";
import DrawerActions from "../../../../../shared/components/Drawer/DrawerActions";
import DrawerContent from "../../../../../shared/components/Drawer/DrawerContent";
import DrawerFooter from "../../../../../shared/components/Drawer/DrawerFooter";
import DrawerHeader from "../../../../../shared/components/Drawer/DrawerHeader";
import {
  isAdmin,
  isSuperAdmin,
} from "../../../../../shared/components/WithPermissions";
import { useCurrentOrg } from "../../../../../shared/hooks/useCurrentOrg";
import {
  AutocompleteOption,
  FormFieldDropdownOption,
} from "../../../../../types";
import {
  useNomenclatures,
  NOMENCLATURE_NAMES,
} from "../../../../AssetsView/TableView/hooks/useNomenclatures";
import {
  CreateEditOrgProps,
  EditOrg,
  FormFieldsData,
  LogoData,
} from "../types";
import OrgForm from "./OrgForm";
import UploadLogo from "./UploadLogo";
import { useBrands } from "./hooks/useBrands";
import { useCompanyLogo } from "./hooks/useCompanyLogo";
import useFormDefaultValues from "./hooks/useFormDefaultValues";
import { useOrgFormValidationSchema } from "./hooks/useOrgFormValidationSchema";
import { useParentOrgs } from "./hooks/useParentOrgs";
import {
  createOrgPayload,
  handleLogoUpload,
  updateOrgPayload,
} from "./utils/CreateOrgFormUtils";

const BREAKPOINTS = { xs: 12 };

const CreateEditOrg: FC<CreateEditOrgProps> = ({
  open,
  setOpen,
  onOrgCreate,
  onOrgUpdate,
  organizations = [],
  formMode = FormModes.create,
  organizationToEdit,
}) => {
  const isEditMode = useMemo(() => formMode === FormModes.edit, [formMode]);
  const [deleteOrgPopup, setDeleteOrgPopup] = useState(false);
  const [isLogoModified, setIsLogoModified] = useState(false);
  const queryClient = useQueryClient();

  const timezonesOptions = useNomenclatures(
    NOMENCLATURE_NAMES.timeZones
  ) as FormFieldDropdownOption[];
  const orgTypeOptions = useNomenclatures(
    NOMENCLATURE_NAMES.organizationType
  ) as FormFieldDropdownOption[];
  const distanceUnitsOptions = useNomenclatures(
    NOMENCLATURE_NAMES.distanceUnits
  );
  const pressureUnitsOptions = useNomenclatures(
    NOMENCLATURE_NAMES.pressureUnits
  );
  const temperatureUnitsOptions = useNomenclatures(
    NOMENCLATURE_NAMES.temperatureUnits
  );

  const [dashboardOptions, setDashboardOptions] = useState<
    AutocompleteOption[]
  >([]);
  const [orgToEdit, setOrgToEdit] = useState<EditOrg | null>(() => {
    if (organizationToEdit && isEditMode) {
      return {
        ...organizationToEdit,
        company_logos: organizationToEdit?.company_logos ?? [],
        parent_company:
          organizationToEdit?.org_key &&
          organizationToEdit.org_key.split("/").length >= 2
            ? organizationToEdit.org_key.split("/").reverse()[1]
            : undefined,
      };
    }

    return organizationToEdit;
  });

  const {
    logoIsDeleted,
    companyLogoInput,
    setCompanyLogoInput,
    setLogoIsDeleted,
    uploadCompanyLogoHandler,
    dispatchUploadFailure,
  } = useCompanyLogo();

  const { dispatch } = useAppContext();
  const { userInfo } = useAuthContext();
  const dataCurrentOrg = useCurrentOrg();

  const isPhillipsConnectMember =
    dataCurrentOrg?.name === ROOT_ORGANIZATION_NAME ||
    dataCurrentOrg?.org_key === ROOT_ORGANIZATION_NAME;

  const isNotAnAdmin = useMemo(() => {
    if (userInfo) {
      return !isAdmin(userInfo.groups) && !isSuperAdmin(userInfo.groups);
    }
  }, [userInfo]);

  const { brandDropdownOptions, defaultBrandForDropdown } = useBrands();
  const { parentOrgs: parentOrgOptions } = useParentOrgs(
    isEditMode,
    orgToEdit,
    organizations
  );
  const { orgSchema: orgFormValidationSchema } = useOrgFormValidationSchema(
    orgToEdit,
    organizations,
    isNotAnAdmin,
    formMode,
    isPhillipsConnectMember
  );
  const defaultValues = useFormDefaultValues({
    orgToEdit,
    defaultBrandForDropdown,
  });
  const {
    control,
    formState: { dirtyFields, isValid, errors, isDirty },
    getValues,
    setValue,
    handleSubmit,
  } = useForm({
    mode: "onTouched",
    resolver: yupResolver(orgFormValidationSchema),
    defaultValues,
  });

  const { data: dashboardNamesData } = useGetDashboardNamesQuery(
    {
      input: {
        orgId: dataCurrentOrg?._id ?? "",
      },
    },
    {
      enabled: Boolean(dataCurrentOrg?._id),
    }
  );

  const { mutate: createOrg, isLoading: isCreating } = useCreateOrgMutation({
    onSuccess: () => {
      onOrgCreate();
      setOpen(false);
      dispatch({
        type: PAGE_SNACKBAR,
        payload: ORGANIZATION_CREATE_SUCCESS_PAYLOAD,
      });
    },
    onError: (error: unknown) => {
      const errorString = String(error);
      // Errors regarding duplicate keys
      if (errorString.includes("duplicate key error")) {
        // Account number duplication
        if (errorString.includes("account_number")) {
          dispatch({
            type: PAGE_SNACKBAR,
            payload: ORGANIZATION_CREATE_FAILED_DUPLICATE_ACC_NUMBER_PAYLOAD,
          });
        }
      } else {
        dispatch({
          type: PAGE_SNACKBAR,
          payload: ORGANIZATION_CREATE_FAILED_PAYLOAD,
        });
      }
    },
  });

  const { mutate: updateOrg, isLoading: isUpdating } = useUpdateOrgMutation({
    onSuccess: () => {
      onOrgUpdate();
      setOpen(false);
      queryClient.invalidateQueries({ queryKey: ["getUserData"] });

      dispatch({
        type: PAGE_SNACKBAR,
        payload: ORGANIZATION_UPDATE_SUCCESS_PAYLOAD,
      });
    },
    onError: () => {
      dispatch({
        type: PAGE_SNACKBAR,
        payload: ORGANIZATION_UPDATE_FAILED_PAYLOAD,
      });
    },
  });

  const {
    mutate: deleteOrg,
    isLoading: isDeleting,
    isSuccess: deleteOrgIsSuccess,
  } = useDeleteOrgMutation({
    onSuccess: () => {
      dispatch({
        type: PAGE_SNACKBAR,
        payload: ORGANIZATION_DELETE_SUCCESS_PAYLOAD,
      });
      onOrgUpdate();
      setOpen(false);
    },
    onError: () => {
      dispatch({
        type: PAGE_SNACKBAR,
        payload: ORGANIZATION_DELETE_FAILED_PAYLOAD,
      });
    },
  });

  // used only in create mode to load the default brand
  useEffect(() => {
    if (!isEditMode) {
      setValue("brand", defaultBrandForDropdown);
    }
  }, [isEditMode, defaultBrandForDropdown, setValue]);

  useEffect(() => {
    if (dashboardNamesData?.getDashboards?.dashboards?.length) {
      setDashboardOptions(
        dashboardNamesData.getDashboards.dashboards.map(({ id, name }) => {
          return {
            id: id?.toString() ?? "",
            label: name ?? "",
          };
        })
      );
    }
  }, [dashboardNamesData]);

  useEffect(() => {
    // Make sure we only render the dashboards that are available in the options
    if (dashboardOptions.length) {
      const currentValues: string[] = getValues("favorite_dashboards") ?? [];
      const validatedValues: string[] = currentValues.filter((value) =>
        dashboardOptions.some((option) => option.id === value)
      );

      setValue("favorite_dashboards", validatedValues);
    }
  }, [dashboardOptions, getValues, setValue]);

  const formValueSubmit = (data: FormFieldsData, company_logo?: LogoData) => {
    // dispatch post api call to create organization with payload as formValues
    const formValues = {
      ...data,
    };

    if (formMode === FormModes.create) {
      formValueCreateSubmit(formValues, company_logo);
    }
    if (isEditMode && orgToEdit) {
      const updateOrgPayload: EditOrg = {
        _id: orgToEdit._id,
        name: orgToEdit.name,
        account_number: orgToEdit.account_number,
      };
      formValueEditSubmit(updateOrgPayload, formValues, company_logo);
    }
  };

  const formValueCreateSubmit = (
    formValues: FormFieldsData,
    logo?: LogoData
  ) => {
    const createOrganizationPayload = createOrgPayload(formValues, logo);
    createOrg({
      organization: createOrganizationPayload,
    });
  };

  const formValueEditSubmit = (
    orgPayload: EditOrg,
    value: FormFieldsData,
    logo?: LogoData
  ) => {
    orgPayload = updateOrgPayload(
      orgPayload,
      value,
      logo,
      dirtyFields,
      logoIsDeleted
    );
    updateOrg({ organization: orgPayload });
  };

  const onSubmit: SubmitHandler<FormFieldsData> = (data) => {
    handleLogoUpload(
      data,
      companyLogoInput,
      uploadCompanyLogoHandler,
      formValueSubmit,
      dispatchUploadFailure
    );
  };

  const handleFileInput = (fileInfo: { file: File }) => {
    setCompanyLogoInput(fileInfo);
    setIsLogoModified(!!fileInfo);
  };

  const handleDeleteLogo = () => {
    if (orgToEdit?.company_logos?.[0]) {
      setOrgToEdit({ ...orgToEdit, company_logos: [] });
      setLogoIsDeleted(true);
    }
    setIsLogoModified(true);
  };

  const toggleDeleteOrgPopup = () => {
    setDeleteOrgPopup(!deleteOrgPopup);
  };

  const handleDeleteOrg = () => {
    if (isDeleting || deleteOrgIsSuccess) {
      return;
    }

    deleteOrg({
      org: { id: orgToEdit?._id as string },
    });
  };

  const handleClose = () => setOpen(false);

  const isLoading = useMemo(
    () => isCreating || isUpdating || isDeleting,
    [isCreating, isUpdating, isDeleting]
  );

  const formHasErrors = useMemo(() => !isEmpty(errors), [errors]);

  const formIsInvalid = useMemo(
    () => !isDirty || !isValid || formHasErrors,
    [isDirty, isValid, formHasErrors]
  );

  const logoHasBeenModifiedAndFormIsNotLoading = useMemo(
    () => isLogoModified && !isLoading,
    [isLogoModified, isLoading]
  );

  /* 
    Disable the submit button when:
      - Something is loading or there is something wrong with the form (errors, not dirty, not valid)
      - The logo has been modified and the form is loading
  */
  const isSubmitDisabled = useMemo(
    () =>
      (isLoading || formIsInvalid) && !logoHasBeenModifiedAndFormIsNotLoading,
    [isLoading, formIsInvalid, logoHasBeenModifiedAndFormIsNotLoading]
  );

  const headerText = useMemo(
    () => `${isEditMode ? "Edit" : "Create"} Organization`,
    [isEditMode]
  );

  return (
    <Drawer testId="org-drawer" isOpen={open} onRequestClose={handleClose}>
      <DrawerHeader text={headerText} onClose={handleClose} />

      <DrawerContent>
        <OrgForm
          control={control}
          breakpoints={BREAKPOINTS}
          brandOptions={brandDropdownOptions}
          distanceUnitsOptions={distanceUnitsOptions}
          pressureUnitsOptions={pressureUnitsOptions}
          temperatureUnitsOptions={temperatureUnitsOptions}
          parentOrganizationOptions={parentOrgOptions}
          timezonesOptions={timezonesOptions}
          orgTypeOptions={orgTypeOptions}
          dashboardOptions={dashboardOptions}
          isEditMode={isEditMode}
          isNotAnAdmin={Boolean(isNotAnAdmin)}
          isPctMember={isPhillipsConnectMember}
        />
        <Grid container>
          {
            <UploadLogo
              onFileInput={handleFileInput}
              disableFileInput={isLoading}
              company_logos={orgToEdit?.company_logos ?? []}
            />
          }
          {orgToEdit?.company_logos?.[0] && (
            <Grid container className="justify-center bg-background pb-10">
              <Grid item>
                <TextButton
                  className="!text-error"
                  text="Delete logo"
                  size="medium"
                  onClick={handleDeleteLogo}
                  theme="black"
                  data-testid="btn-org-form-delete-logo"
                />
              </Grid>
            </Grid>
          )}
        </Grid>

        <DrawerActions
          deleteBtnTestId="btn-delete-org"
          cancelBtnTestId="btn-org-form-cancel"
          disabled={isLoading}
          showDeleteBtn={isEditMode}
          onCancel={handleClose}
          onDelete={toggleDeleteOrgPopup}
        />

        {isEditMode && deleteOrgPopup && (
          <Dialog
            open={deleteOrgPopup}
            onClose={toggleDeleteOrgPopup}
            aria-labelledby="delete-org-alert-dialog-title"
            aria-describedby="delete-org-alert-dialog-description"
            data-testid="delete-org-confirmation-dialog"
          >
            <DialogTitle id="delete-org-alert-dialog-title">
              Delete Organization
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="delete-org-alert-dialog-description">
                You are about to delete this organization. This cannot be
                undone.
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <MUIButton
                data-testid="delete-org-confirmation-dialog-cancel-btn"
                sx={{ fontWeight: "bold" }}
                onClick={toggleDeleteOrgPopup}
                autoFocus
                disabled={isDeleting}
              >
                Cancel
              </MUIButton>
              <MUIButton
                data-testid="delete-org-confirmation-dialog-remove-btn"
                className="!text-error"
                sx={{ fontWeight: "bold" }}
                variant="text"
                onClick={handleDeleteOrg}
                disabled={isDeleting}
              >
                {isDeleting ? "Removing..." : "Remove"}
              </MUIButton>
            </DialogActions>
          </Dialog>
        )}
      </DrawerContent>

      <DrawerFooter
        text={isCreating || isUpdating ? "Saving..." : "Save"}
        disabled={isSubmitDisabled}
        testId="btn-org-form-submit"
        submit={handleSubmit(onSubmit)}
      />
    </Drawer>
  );
};

export default CreateEditOrg;
