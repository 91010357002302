import { DWELL_STATUS_RANGE_MAP } from "../../../../../constants/map";
import {
  Asset,
  AssetOs,
  Maybe,
  OrgData,
} from "../../../../../graphql/operations";
import { detailItem } from "../../Shared/DashboardWidgets/DetailListWidget";

export function getAssetInfo(
  asset: Asset | undefined | null,
  odometerReading: string | undefined,
  productName: string | undefined,
  currentAssetType: Maybe<string>,
  currentOrgId: string | undefined,
  availableOrgs: OrgData[],
  timezone: string
) {
  const detailList: Array<detailItem<string | number>> = [];
  const assetOwnerOrgName = asset?.org_name;

  // load data for detailList
  detailList.push({
    label: "Asset Id",
    detailItemvalue: asset?.asset_id ?? "",
  });
  detailList.push({
    label: "Nickname",
    detailItemvalue: asset?.name ?? "",
  });
  detailList.push({
    label: "Dwell",
    detailItemvalue:
      typeof asset?.dwelling?.dwellingDays === "number"
        ? `${asset?.dwelling.dwellingDays} Day(s)`
        : "",
  });
  detailList.push({
    label: "Odometer",
    detailItemvalue: odometerReading ? odometerReading : "",
  });
  detailList.push({
    label: "Vin",
    detailItemvalue: (asset?.vin ?? "").toUpperCase(),
    truncateText: true,
  });
  detailList.push({
    label: "Device ID",
    detailItemvalue: asset?.imei ?? "",
    truncateText: true,
  });
  detailList.push({
    label: "Product Name",
    detailItemvalue: productName ?? "",
    truncateText: true,
  });
  detailList.push({
    label: "Asset Type 1",
    detailItemvalue: currentAssetType ?? "",
  });
  detailList.push({
    label: "Sub Asset Type",
    detailItemvalue: asset?.sub_asset_type ?? "",
  });
  detailList.push({
    label: "Assets Tags",
    detailItemvalue: asset?.tags ?? "",
    truncateText: true,
  });
  asset?.shared?.status === "Shared" &&
    detailList.push(
      {
        label: "Share Type",
        detailItemvalue: (asset as AssetOs).shared?.shareType ?? "",
        truncateText: true,
      },
      {
        label: "Share Organization",
        detailItemvalue: (asset as AssetOs).shared?.shareOrganizationName ?? "",
        truncateText: true,
      },
      {
        label: "Share End Date",
        detailItemvalue: (asset as AssetOs).shared?.shareEndDateFormated ?? "",
        truncateText: true,
      }
    );

  return detailList;
}

export const getDwellRange = (dwell_days: number): string => {
  return (
    Object.entries(DWELL_STATUS_RANGE_MAP).find(
      ([key, { minDays, maxDays }]) =>
        dwell_days >= minDays && dwell_days < maxDays && key
    )?.[0] ?? ""
  );
};
