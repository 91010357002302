import { useState } from "react";
import { FieldValues } from "react-hook-form";
import { TextFieldElement } from "react-hook-form-mui";
import { AuthenticationResultType } from "@aws-sdk/client-cognito-identity-provider";
import { Box, ThemeProvider, Typography } from "@mui/material";
import { isEmpty } from "lodash";
import { useAppContext } from "../../../context/AppContext";
import { useAuthContext } from "../../../context/AuthContext";
import { SessionStorageItem } from "../../../enums/sessionStorage";
import { mfaSignIn } from "../../../services/aws/auth";
import { navigateToRoute } from "../../../utils";
import { NavigationRoutes } from "../../../utils/routes/routesUtils";
import { AuthButton } from "../components/AuthButton";
import { AuthenticationBox } from "../components/AuthenticationBox";
import { useAuthFormTheme } from "../hooks/useAuthFormTheme";
import { useMfaForm } from "./useMfaForm";

const MfaVerifyAuthAppCode = () => {
  const { dispatch } = useAppContext();
  const { login } = useAuthContext();
  const formTheme = useAuthFormTheme();
  const {
    control,
    handleSubmit,
    formState: { errors, dirtyFields, isSubmitted, isValid },
    setError,
    clearErrors,
  } = useMfaForm();

  const [isLogging, setIsLogging] = useState(false);

  const isSubmitDisabled =
    isLogging || isEmpty(dirtyFields) || (isSubmitted && !isValid);

  const handleSignIn = async ({ mfaCode }: FieldValues) => {
    setIsLogging(true);
    clearErrors("root");

    const username = sessionStorage.getItem(SessionStorageItem.Username) ?? "";
    const session = sessionStorage.getItem(SessionStorageItem.Session) ?? "";
    try {
      const result = await mfaSignIn({
        session,
        username,
        code: mfaCode,
        dispatch,
      });
      const { AccessToken, ExpiresIn, IdToken, RefreshToken } =
        result as AuthenticationResultType;

      if (AccessToken && IdToken && RefreshToken && ExpiresIn && session) {
        const tokens = {
          accessToken: AccessToken,
          idToken: IdToken,
          refreshToken: RefreshToken,
          expiresIn: ExpiresIn,
        };

        login(tokens);

        return navigateToRoute(NavigationRoutes.Home, false);
      } else {
        console.error("SignIn session or AccessToken is undefined.");
      }
    } catch (error) {
      setError("root", {
        type: "manual",
        message: "The code you provided seems to be invalid.",
      });
    }

    setIsLogging(false);
  };

  return (
    <AuthenticationBox>
      <ThemeProvider theme={formTheme}>
        <Box
          sx={{
            display: "flex",
            gap: "3rem",
            flexDirection: "column",
            alignItems: "center",
            width: "234px",
          }}
        >
          <form onSubmit={handleSubmit(handleSignIn)}>
            <Box sx={{ display: "flex", flexDirection: "column", gap: "20px" }}>
              <Typography
                sx={{ fontSize: "14px", lineHeight: "20px", fontWeight: 100 }}
              >
                Enter code from authentication app
              </Typography>
              {errors.root && (
                <Typography
                  sx={{
                    fontSize: "12px",
                    color: "var(--error)",
                    textAlign: "center",
                  }}
                >
                  {errors.root.message}
                </Typography>
              )}
              <TextFieldElement
                name="mfaCode"
                control={control}
                fullWidth
                autoComplete="off"
                onChange={() => clearErrors("root")}
                inputProps={{
                  "data-testid": "mfa-verify-auth-app--mfa-code",
                  placeholder: "Code",
                  autoFocus: true,
                }}
              />

              <AuthButton
                text="Sign In"
                type="submit"
                disabled={isSubmitDisabled}
                iconPosition={isLogging ? "right" : "none"}
              />
            </Box>
          </form>
        </Box>
      </ThemeProvider>
    </AuthenticationBox>
  );
};

export default MfaVerifyAuthAppCode;
