import { useMemo } from "react";
import { QueryObserverResult } from "@tanstack/react-query";
import { isEmpty } from "lodash";
import {
  AccountsReportDataResponse,
  AssetAbsFaultCodesDataResponseOs,
  AssetDetentionReportDataResponseOs,
  AssetInstallTableDataResponse,
  AssetReportDataRow,
  AssetReportRowDataResponse,
  AssetTransferTableDataResponse,
  DwellHierarchyReportDataResponseOs,
  GetDwellHierarchyTableDataResponse,
  GetTableDataInput,
  MediaActivitiesReportDataResponse,
  MileageReportDataResponse,
  Pagination,
  ReportAlertHistoryTableDataResponse,
  ReportType,
  SensorsReportDataResponse,
  SensorsReportDataResponseOs,
  SortOrder,
  TableFilterLinkOperator,
  useFindAssetAbsFaultCodesReportDataOsQuery,
  useFindAssetDetentionReportDataOsQuery,
  useFindAssetInventoryOsQuery,
  useFindAssetInventoryQuery,
  useGetAssetTransferTableDataQuery,
  useFindAssetYardOsQuery,
  useFindAssetYardQuery,
  useFindDwellHierarchyOsQuery,
  useFindMediaActivitiesQuery,
  useFindMileageQuery,
  useFindOutsideGeofenceOsQuery,
  useFindOutsideGeofenceQuery,
  useFindSensorsOsQuery,
  useFindSensorsQuery,
  useGetAccountsReportDataQuery,
  useGetAssetInstallTableDataQuery,
  useGetDwellHierarchyTableDataQuery,
  useGetMileageReportDataOsQuery,
  useGetReportAlertHistoryTableDataQuery,
} from "../../../graphql/operations";
import { useFeatureFlag } from "../../../utils";
import { FeatureFlags } from "../../../utils/featureFlagsConstants";
import { prepareDefaultDwellHierarchyFilter } from "../helpers/helpers";

interface IUseReportHistoryApi {
  orgId?: string;
  reportType: string;
  reportQueryParameters: Record<string, any>;
  trigger?: string;
  queryInput: GetTableDataInput;
}

interface IUseReportHistoryApiResult {
  data:
    | AssetTransferTableDataResponse
    | AssetAbsFaultCodesDataResponseOs
    | AssetReportRowDataResponse
    | AccountsReportDataResponse
    | ReportAlertHistoryTableDataResponse
    | GetDwellHierarchyTableDataResponse
    | DwellHierarchyReportDataResponseOs
    | MediaActivitiesReportDataResponse
    | MileageReportDataResponse
    | SensorsReportDataResponse
    | SensorsReportDataResponseOs
    | AssetDetentionReportDataResponseOs
    | AssetInstallTableDataResponse;
  isLoading: boolean;
  isRefetching: boolean;
  isSuccess: boolean;
  refetch: () => Promise<QueryObserverResult>;
}

export const useReportHistoryApi = (
  {
    orgId,
    reportType,
    reportQueryParameters,
    trigger = "",
    queryInput,
  }: IUseReportHistoryApi,
  shouldFetch = true
): IUseReportHistoryApiResult => {
  const defaultReportQueryParameters = {
    orgIds: reportQueryParameters.orgIds ?? null,
    searchText: reportQueryParameters.searchText,
    filterList: reportQueryParameters.filterList,
    sorting: reportQueryParameters.sorting,
    pagination: reportQueryParameters.pagination,
  };
  const fetchAssetsFromOpenSearchFeatureFlag = useFeatureFlag(
    FeatureFlags.Connect1FetchAssetsFromOpenSearch
  );
  const paramTableFilters = queryInput.tableFilters;

  const defaultReportTableDataInput = {
    // searchText, sorting, pagination
    ...queryInput,
    tableFilters: {
      linkOperator:
        paramTableFilters?.linkOperator ?? TableFilterLinkOperator.And,
      filters: paramTableFilters?.filters ?? [],
    },
  };

  const defaultDateRangeQueryParameters = {
    startDate: reportQueryParameters.startDate ?? null,
    endDate: reportQueryParameters.endDate ?? null,
  };
  const defaultPaginationResponse: Pagination = useMemo(
    () => ({ total: 0, limit: 0, skip: 0 }),
    []
  );

  const assetInstallHistoryQuery = useGetAssetInstallTableDataQuery(
    {
      input: {
        ...defaultReportTableDataInput,
        ...defaultDateRangeQueryParameters,
        orgIds: reportQueryParameters.orgIds,
        jsonLogic: trigger,
      },
    },
    {
      keepPreviousData: true,
      cacheTime: 0,
      enabled: reportType === ReportType.AssetInstall && !!orgId && shouldFetch,
      select: ({ getAssetInstallTableData }) => getAssetInstallTableData,
    }
  );

  // Toggle between results based on the feature flag
  const {
    data: installQueryData,
    isLoading: isInstallReportLoading,
    isRefetching: isInstallReportRefetching,
    isSuccess: isInstallReportSuccess,
    refetch: refetchInstallReport,
  } = assetInstallHistoryQuery;

  // should be enabled only for asset transfer report
  const assetTransferHistoryQuery = useGetAssetTransferTableDataQuery(
    {
      input: {
        ...defaultReportTableDataInput,
        ...defaultDateRangeQueryParameters,
        orgIds: reportQueryParameters.orgIds,
        jsonLogic: trigger,
      },
    },
    {
      keepPreviousData: true,
      cacheTime: 0,
      enabled:
        reportType === ReportType.AssetTransfer && !!orgId && shouldFetch,
      select: ({ getAssetTransferTableData }) => getAssetTransferTableData,
    }
  );
  const {
    data: transferQueryData,
    isLoading: isTransferReportLoading,
    isRefetching: isTransferReportRefetching,
    isSuccess: isTransferReportSuccess,
    refetch: refetchTransferReport,
  } = assetTransferHistoryQuery;

  const {
    data: accountsReportData,
    isLoading: isAccountsReportLoading,
    isRefetching: isAccountsReportRefetching,
    isSuccess: isAccountsReportSuccess,
    refetch: refetchAccountsReport,
  } = useGetAccountsReportDataQuery(
    {
      input: {
        ...defaultReportQueryParameters,
        searchText: queryInput?.searchText,
      },
    },
    {
      keepPreviousData: true,
      cacheTime: 0,
      enabled: reportType === ReportType.Accounts && !!orgId && shouldFetch,
    }
  );

  // should be enabled only for alert history report
  const {
    data: alertHistoryReportData,
    isLoading: isAlertHistoryReportLoading,
    isRefetching: isAlertHistoryReportRefetching,
    isSuccess: isAlertHistoryReportSuccess,
    refetch: refetchAlertHistoryReport,
  } = useGetReportAlertHistoryTableDataQuery(
    {
      input: {
        ...defaultReportTableDataInput,
        ...defaultDateRangeQueryParameters,
        orgIds: reportQueryParameters.orgIds,
        jsonLogic: trigger,
      },
    },
    {
      keepPreviousData: true,
      cacheTime: 0,
      enabled: reportType === ReportType.AlertHistory && !!orgId && shouldFetch,
      refetchOnMount: true,
      staleTime: 5000,
      select: ({ getReportAlertHistoryTableData }) =>
        getReportAlertHistoryTableData,
    }
  );
  const assetInventoryQuery = useFindAssetInventoryQuery(
    { input: { ...defaultReportQueryParameters, query: trigger } },
    {
      keepPreviousData: true,
      cacheTime: 0,
      enabled:
        fetchAssetsFromOpenSearchFeatureFlag === false &&
        reportType === ReportType.AssetInventory &&
        !!orgId &&
        shouldFetch,
    }
  );

  const assetInventoryQueryOS = useFindAssetInventoryOsQuery(
    {
      input: {
        ...defaultReportQueryParameters,
        query: trigger,
        searchText: queryInput.searchText,
      },
    },
    {
      keepPreviousData: true,
      cacheTime: 0,
      enabled:
        fetchAssetsFromOpenSearchFeatureFlag === true &&
        reportType === ReportType.AssetInventory &&
        !!orgId &&
        shouldFetch,
    }
  );

  const {
    isLoading: isInventoryReportLoading,
    isRefetching: isInventoryReportRefetching,
    isSuccess: isInventoryReportSuccess,
    refetch: refetchInventoryReport,
  } = fetchAssetsFromOpenSearchFeatureFlag
    ? assetInventoryQueryOS
    : assetInventoryQuery;

  const assetYardQuery = useFindAssetYardQuery(
    { input: { ...defaultReportQueryParameters, query: trigger } },
    {
      keepPreviousData: true,
      cacheTime: 0,
      enabled:
        fetchAssetsFromOpenSearchFeatureFlag === false &&
        reportType === ReportType.AssetYard &&
        !!orgId &&
        shouldFetch,
    }
  );

  const assetYardQueryOS = useFindAssetYardOsQuery(
    {
      input: {
        ...defaultReportQueryParameters,
        query: trigger,
        searchText: queryInput.searchText,
      },
    },
    {
      keepPreviousData: true,
      cacheTime: 0,
      enabled:
        fetchAssetsFromOpenSearchFeatureFlag === true &&
        reportType === ReportType.AssetYard &&
        !!orgId &&
        shouldFetch,
    }
  );

  const {
    isLoading: yardReportLoading,
    isRefetching: yardReportRefetching,
    isSuccess: yardReportSuccess,
    refetch: refetchYardReport,
  } = fetchAssetsFromOpenSearchFeatureFlag ? assetYardQueryOS : assetYardQuery;

  const outsideGeofenceQuery = useFindOutsideGeofenceQuery(
    {
      input: {
        ...defaultReportQueryParameters,
        query: trigger,
        searchText: queryInput.searchText,
        sorting: queryInput.sorting ?? [
          {
            field: "lst_evnt_t",
            order: SortOrder.Desc,
          },
        ],
      },
    },
    {
      keepPreviousData: true,
      cacheTime: 0,
      enabled:
        fetchAssetsFromOpenSearchFeatureFlag === false &&
        reportType === ReportType.OutsideGeofence &&
        !!orgId &&
        shouldFetch,
    }
  );

  const outsideGeofenceQueryOS = useFindOutsideGeofenceOsQuery(
    {
      input: {
        ...defaultReportQueryParameters,
        query: trigger,
        searchText: queryInput.searchText,
      },
    },
    {
      keepPreviousData: true,
      cacheTime: 0,
      enabled:
        fetchAssetsFromOpenSearchFeatureFlag === true &&
        reportType === ReportType.OutsideGeofence &&
        !!orgId &&
        shouldFetch,
    }
  );

  const {
    isLoading: outsideGeofenceReportLoading,
    isRefetching: outsideGeofenceReportRefetching,
    isSuccess: outsideGeofenceReportSuccess,
    refetch: outsideGeofenceYardReport,
  } = fetchAssetsFromOpenSearchFeatureFlag
    ? outsideGeofenceQueryOS
    : outsideGeofenceQuery;

  const outsideGeofenceAssets = useMemo(() => {
    if (fetchAssetsFromOpenSearchFeatureFlag) {
      const outsideGeofenceQueryDataOS =
        outsideGeofenceQueryOS?.data?.findOutsideGeofenceOS;

      return {
        data:
          outsideGeofenceQueryDataOS?.data?.map((outsideGeofenceAsset) => ({
            ...outsideGeofenceAsset,
            device_associated: "No",
          })) ?? [],
        pagination:
          outsideGeofenceQueryDataOS?.pagination ?? defaultPaginationResponse,
      };
    } else {
      const outsideGeofenceQueryData =
        outsideGeofenceQuery?.data?.findOutsideGeofence;

      return {
        data:
          outsideGeofenceQueryData?.data?.map((outsideGeofenceAsset) => ({
            ...outsideGeofenceAsset,
            device_associated: isEmpty(outsideGeofenceAsset?.device?.imei)
              ? "No"
              : "Yes",
          })) ?? [],
        pagination:
          outsideGeofenceQueryData?.pagination ?? defaultPaginationResponse,
      };
    }
  }, [
    outsideGeofenceQuery,
    outsideGeofenceQueryOS,
    fetchAssetsFromOpenSearchFeatureFlag,
    defaultPaginationResponse,
  ]);

  const mileageQuery = useFindMileageQuery(
    { input: { ...defaultReportQueryParameters, query: trigger } },
    {
      keepPreviousData: true,
      cacheTime: 0,
      enabled:
        fetchAssetsFromOpenSearchFeatureFlag === false &&
        reportType === ReportType.Mileage &&
        !!orgId &&
        shouldFetch,
    }
  );
  const mileageQueryOS = useGetMileageReportDataOsQuery(
    {
      input: {
        ...defaultReportQueryParameters,
        query: trigger,
        searchText: queryInput.searchText,
      },
    },
    {
      keepPreviousData: true,
      cacheTime: 0,
      enabled:
        fetchAssetsFromOpenSearchFeatureFlag === true &&
        reportType === ReportType.Mileage &&
        !!orgId &&
        shouldFetch,
    }
  );

  const {
    isLoading: isMileageReportLoading,
    isRefetching: isMileageReportRefetching,
    isSuccess: isMileageReportSuccess,
    refetch: refetchMileageReport,
  } = fetchAssetsFromOpenSearchFeatureFlag ? mileageQueryOS : mileageQuery;

  const sensorsQuery = useFindSensorsQuery(
    { input: { ...defaultReportQueryParameters, query: trigger } },
    {
      keepPreviousData: true,
      cacheTime: 0,
      enabled:
        fetchAssetsFromOpenSearchFeatureFlag === false &&
        reportType === ReportType.Sensors &&
        !!orgId &&
        shouldFetch,
    }
  );

  const sensorsQueryOS = useFindSensorsOsQuery(
    {
      input: {
        ...defaultReportQueryParameters,
        query: trigger,
        searchText: queryInput.searchText,
      },
    },
    {
      keepPreviousData: true,
      cacheTime: 0,
      enabled:
        fetchAssetsFromOpenSearchFeatureFlag === true &&
        reportType === ReportType.Sensors &&
        !!orgId &&
        shouldFetch,
    }
  );
  const {
    isLoading: isSensorsReportLoading,
    isRefetching: isSensorsReportRefetching,
    isSuccess: isSensorsReportSuccess,
    refetch: refetchSensorsReport,
  } = fetchAssetsFromOpenSearchFeatureFlag ? sensorsQueryOS : sensorsQuery;

  const {
    data: mediaActivitiesReportData,
    isLoading: isMediaActivitiesReportLoading,
    isRefetching: isMediaActivitiesReportRefetching,
    isSuccess: isMediaActivitiesReportSuccess,
    refetch: refetchMediaActivitiesReport,
  } = useFindMediaActivitiesQuery(
    {
      input: {
        ...defaultReportQueryParameters,
        ...defaultDateRangeQueryParameters,
        query: trigger,
        searchText: queryInput.searchText,
      },
    },
    {
      keepPreviousData: true,
      cacheTime: 0,
      enabled:
        reportType === ReportType.MediaActivities && !!orgId && shouldFetch,
    }
  );
  const dwellHierarchyReportQuery = useGetDwellHierarchyTableDataQuery(
    {
      input: {
        ...defaultReportTableDataInput,
        jsonLogic: trigger,
        orgIds: reportQueryParameters.orgIds,
      },
    },
    {
      keepPreviousData: true,
      cacheTime: 0,
      enabled:
        fetchAssetsFromOpenSearchFeatureFlag === false &&
        reportType === ReportType.DwellHierarchy &&
        !!orgId &&
        shouldFetch,
      refetchOnMount: true,
      staleTime: 5000,
      select: ({ getDwellHierarchyTableData }) => getDwellHierarchyTableData,
    }
  );

  const {
    data: AssetAbsFaultCodesReportData,
    isLoading: isAssetAbsFaultCodesReportLoading,
    isRefetching: isAssetAbsFaultCodesReportRefetching,
    isSuccess: isAssetAbsFaultCodesReportSuccess,
    refetch: refetchAssetAbsFaultCodesReport,
  } = useFindAssetAbsFaultCodesReportDataOsQuery(
    {
      input: {
        ...defaultReportQueryParameters,
        ...defaultDateRangeQueryParameters,
        query: trigger,
        searchText: queryInput.searchText,
      },
    },
    {
      keepPreviousData: true,
      cacheTime: 0,
      enabled:
        fetchAssetsFromOpenSearchFeatureFlag === true &&
        reportType === ReportType.AssetAbsFaultHistory &&
        !!orgId &&
        shouldFetch,
      refetchOnMount: true,
      staleTime: 5000,
      select: ({ findAssetABSFaultCodesReportDataOS }) =>
        findAssetABSFaultCodesReportDataOS,
    }
  );

  const {
    data: assetDetentionReportData,
    isLoading: isAssetDetentionReportLoading,
    isRefetching: isAssetDetentionReportRefetching,
    isSuccess: isAssetDetentionReportReportSuccess,
    refetch: refetchAssetDetentionReportReport,
  } = useFindAssetDetentionReportDataOsQuery(
    {
      input: {
        ...defaultReportQueryParameters,
        query: trigger,
        searchText: queryInput.searchText,
      },
    },
    {
      keepPreviousData: true,
      cacheTime: 0,
      enabled:
        fetchAssetsFromOpenSearchFeatureFlag === true &&
        reportType === ReportType.AssetDetention &&
        !!orgId &&
        shouldFetch,
      refetchOnMount: true,
      staleTime: 5000,
      select: ({ findAssetDetentionReportDataOS }) =>
        findAssetDetentionReportDataOS,
    }
  );

  const dwellHierarchyReportQueryOS = useFindDwellHierarchyOsQuery(
    {
      input: {
        ...defaultReportQueryParameters,
        searchText: queryInput.searchText,
        filterList: {
          linkOperator:
            defaultReportQueryParameters.filterList?.linkOperator ||
            TableFilterLinkOperator.And,
          filters: defaultReportQueryParameters.filterList
            ? [
                ...defaultReportQueryParameters.filterList.filters,
                prepareDefaultDwellHierarchyFilter(),
              ]
            : [prepareDefaultDwellHierarchyFilter()],
        },
      },
    },
    {
      keepPreviousData: true,
      cacheTime: 0,
      enabled:
        fetchAssetsFromOpenSearchFeatureFlag === true &&
        reportType === ReportType.DwellHierarchy &&
        !!orgId &&
        shouldFetch,
      refetchOnMount: true,
      staleTime: 5000,
    }
  );

  const {
    isLoading: isDwellHierarchyReportLoading,
    isRefetching: isDwellHierarchyReportRefetching,
    isSuccess: isDwellHierarchyReportSuccess,
    refetch: refetchDwellHierarchyReport,
  } = fetchAssetsFromOpenSearchFeatureFlag
    ? dwellHierarchyReportQueryOS
    : dwellHierarchyReportQuery;

  const transferHistory = useMemo(() => {
    return {
      data: transferQueryData?.data ?? [],
      pagination: transferQueryData?.pagination ?? defaultPaginationResponse,
    };
  }, [
    defaultPaginationResponse,
    transferQueryData?.data,
    transferQueryData?.pagination,
  ]);

  const installHistory = useMemo(() => {
    return {
      data: installQueryData?.data ?? [],
      pagination: installQueryData?.pagination ?? defaultPaginationResponse,
    };
  }, [
    defaultPaginationResponse,
    installQueryData?.data,
    installQueryData?.pagination,
  ]);

  const alertHistoryReportHistory = useMemo(
    () => ({
      data: alertHistoryReportData?.data ?? [],
      pagination:
        alertHistoryReportData?.pagination ?? defaultPaginationResponse,
    }),
    [alertHistoryReportData, defaultPaginationResponse]
  );
  const assetInventory = useMemo(() => {
    const data: AssetReportDataRow[] = [];

    if (fetchAssetsFromOpenSearchFeatureFlag) {
      assetInventoryQueryOS?.data?.findAssetInventoryOS.data.forEach(
        (assetInventory) => {
          data.push({
            ...assetInventory,
            device_associated: "No",
          } as any);
        }
      );

      return {
        data,
        pagination:
          assetInventoryQueryOS?.data?.findAssetInventoryOS?.pagination ??
          defaultPaginationResponse,
      };
    } else {
      assetInventoryQuery?.data?.findAssetInventory.data.forEach(
        (assetInventory) => {
          data.push({
            ...assetInventory,
            device_associated: isEmpty(assetInventory?.device?.imei)
              ? "No"
              : "Yes",
          } as AssetReportDataRow);
        }
      );

      return {
        data,
        pagination:
          assetInventoryQuery?.data?.findAssetInventory?.pagination ??
          defaultPaginationResponse,
      };
    }
  }, [
    assetInventoryQueryOS,
    assetInventoryQuery,
    fetchAssetsFromOpenSearchFeatureFlag,
    defaultPaginationResponse,
  ]);

  const assetYard = useMemo(() => {
    if (fetchAssetsFromOpenSearchFeatureFlag) {
      return {
        data:
          assetYardQueryOS?.data?.findAssetYardOS?.data?.map((assetYard) => ({
            ...assetYard,
            device_associated: "No",
          })) ?? [],
        pagination:
          assetYardQueryOS?.data?.findAssetYardOS?.pagination ??
          defaultPaginationResponse,
      };
    } else {
      return {
        data:
          assetYardQuery?.data?.findAssetYard?.data?.map((assetYard) => ({
            ...assetYard,
            device_associated: isEmpty(assetYard?.device?.imei) ? "No" : "Yes",
          })) ?? [],
        pagination:
          assetYardQuery?.data?.findAssetYard?.pagination ??
          defaultPaginationResponse,
      };
    }
  }, [
    assetYardQueryOS,
    assetYardQuery,
    fetchAssetsFromOpenSearchFeatureFlag,
    defaultPaginationResponse,
  ]);

  const mileageReportHistory = useMemo(() => {
    if (fetchAssetsFromOpenSearchFeatureFlag) {
      const mileageQueryDataOS = mileageQueryOS?.data?.getMileageReportDataOS;

      return {
        data: mileageQueryDataOS?.data ?? [],
        pagination: mileageQueryDataOS?.pagination ?? defaultPaginationResponse,
      };
    } else {
      const mileageQueryData = mileageQuery?.data?.findMileage;

      return {
        data: mileageQueryData?.data ?? [],
        pagination: mileageQueryData?.pagination ?? defaultPaginationResponse,
      };
    }
  }, [
    mileageQuery,
    mileageQueryOS,
    fetchAssetsFromOpenSearchFeatureFlag,
    defaultPaginationResponse,
  ]);

  const sensorsReportHistory = useMemo(() => {
    if (fetchAssetsFromOpenSearchFeatureFlag) {
      const sensorsQueryDataOS = sensorsQueryOS?.data?.findSensorsOS;

      return {
        data: sensorsQueryDataOS?.data ?? [],
        pagination: sensorsQueryDataOS?.pagination ?? defaultPaginationResponse,
      };
    } else {
      const sensorsQueryData = sensorsQuery?.data?.findSensors;

      return {
        data: sensorsQueryData?.data ?? [],
        pagination: sensorsQueryData?.pagination ?? defaultPaginationResponse,
      };
    }
  }, [
    sensorsQueryOS,
    sensorsQuery,
    fetchAssetsFromOpenSearchFeatureFlag,
    defaultPaginationResponse,
  ]);

  const mediaActivitiesReportHistory = useMemo(
    () => ({
      data: mediaActivitiesReportData?.findMediaActivities?.data ?? [],
      pagination:
        mediaActivitiesReportData?.findMediaActivities?.pagination ??
        defaultPaginationResponse,
    }),
    [mediaActivitiesReportData?.findMediaActivities, defaultPaginationResponse]
  );

  const accountsReport = useMemo(
    () => ({
      data: accountsReportData?.getAccountsReportData?.data ?? [],
      pagination:
        accountsReportData?.getAccountsReportData?.pagination ??
        defaultPaginationResponse,
    }),
    [accountsReportData?.getAccountsReportData, defaultPaginationResponse]
  );

  const dwellHierarchyReportHistory = useMemo(() => {
    if (fetchAssetsFromOpenSearchFeatureFlag) {
      return {
        data:
          dwellHierarchyReportQueryOS?.data?.findDwellHierarchyOS?.data ?? [],
        pagination:
          dwellHierarchyReportQueryOS?.data?.findDwellHierarchyOS.pagination ??
          defaultPaginationResponse,
      };
    } else {
      return {
        data: dwellHierarchyReportQuery?.data?.data ?? [],
        pagination:
          dwellHierarchyReportQuery?.data?.pagination ??
          defaultPaginationResponse,
      };
    }
  }, [
    dwellHierarchyReportQueryOS,
    dwellHierarchyReportQuery,
    fetchAssetsFromOpenSearchFeatureFlag,
    defaultPaginationResponse,
  ]);

  const assetABSFaultCodesReportHistory = useMemo(() => {
    return {
      data: AssetAbsFaultCodesReportData?.data ?? [],
      pagination:
        AssetAbsFaultCodesReportData?.pagination ?? defaultPaginationResponse,
    };
  }, [
    AssetAbsFaultCodesReportData?.data,
    AssetAbsFaultCodesReportData?.pagination,
    defaultPaginationResponse,
  ]);

  const assetDetentionReportDataHistory = useMemo(() => {
    return {
      data: assetDetentionReportData?.data ?? [],
      pagination:
        assetDetentionReportData?.pagination ?? defaultPaginationResponse,
    };
  }, [
    assetDetentionReportData?.data,
    assetDetentionReportData?.pagination,
    defaultPaginationResponse,
  ]);

  switch (reportType) {
    case ReportType.AssetTransfer: {
      return {
        data: transferHistory,
        isLoading: isTransferReportLoading,
        isRefetching: isTransferReportRefetching,
        isSuccess: isTransferReportSuccess,
        refetch: refetchTransferReport,
      };
    }
    case ReportType.AssetInstall: {
      return {
        data: installHistory,
        isLoading: isInstallReportLoading,
        isRefetching: isInstallReportRefetching,
        isSuccess: isInstallReportSuccess,
        refetch: refetchInstallReport,
      };
    }
    case ReportType.AssetInventory: {
      return {
        data: assetInventory,
        isLoading: isInventoryReportLoading,
        isRefetching: isInventoryReportRefetching,
        isSuccess: isInventoryReportSuccess,
        refetch: refetchInventoryReport,
      };
    }
    case ReportType.AlertHistory: {
      return {
        data: alertHistoryReportHistory,
        isLoading: isAlertHistoryReportLoading,
        isRefetching: isAlertHistoryReportRefetching,
        isSuccess: isAlertHistoryReportSuccess,
        refetch: refetchAlertHistoryReport,
      };
    }
    case ReportType.Mileage: {
      return {
        data: mileageReportHistory,
        isLoading: isMileageReportLoading,
        isRefetching: isMileageReportRefetching,
        isSuccess: isMileageReportSuccess,
        refetch: refetchMileageReport,
      };
    }
    case ReportType.Sensors: {
      return {
        data: sensorsReportHistory,
        isLoading: isSensorsReportLoading,
        isRefetching: isSensorsReportRefetching,
        isSuccess: isSensorsReportSuccess,
        refetch: refetchSensorsReport,
      };
    }
    case ReportType.AssetYard: {
      return {
        data: assetYard as any,
        isLoading: yardReportLoading,
        isRefetching: yardReportRefetching,
        isSuccess: yardReportSuccess,
        refetch: refetchYardReport,
      };
    }
    case ReportType.OutsideGeofence: {
      return {
        data: outsideGeofenceAssets as any,
        isLoading: outsideGeofenceReportLoading,
        isRefetching: outsideGeofenceReportRefetching,
        isSuccess: outsideGeofenceReportSuccess,
        refetch: outsideGeofenceYardReport,
      };
    }
    case ReportType.Accounts: {
      return {
        data: accountsReport,
        isLoading: isAccountsReportLoading,
        isRefetching: isAccountsReportRefetching,
        isSuccess: isAccountsReportSuccess,
        refetch: refetchAccountsReport,
      };
    }
    case ReportType.DwellHierarchy: {
      return {
        data: dwellHierarchyReportHistory,
        isLoading: isDwellHierarchyReportLoading,
        isRefetching: isDwellHierarchyReportRefetching,
        isSuccess: isDwellHierarchyReportSuccess,
        refetch: refetchDwellHierarchyReport,
      };
    }
    case ReportType.MediaActivities: {
      return {
        data: mediaActivitiesReportHistory,
        isLoading: isMediaActivitiesReportLoading,
        isRefetching: isMediaActivitiesReportRefetching,
        isSuccess: isMediaActivitiesReportSuccess,
        refetch: refetchMediaActivitiesReport,
      };
    }
    case ReportType.AssetAbsFaultHistory: {
      return {
        data: assetABSFaultCodesReportHistory,
        isLoading: isAssetAbsFaultCodesReportLoading,
        isRefetching: isAssetAbsFaultCodesReportRefetching,
        isSuccess: isAssetAbsFaultCodesReportSuccess,
        refetch: refetchAssetAbsFaultCodesReport,
      };
    }
    case ReportType.AssetDetention: {
      return {
        data: assetDetentionReportDataHistory,
        isLoading: isAssetDetentionReportLoading,
        isRefetching: isAssetDetentionReportRefetching,
        isSuccess: isAssetDetentionReportReportSuccess,
        refetch: refetchAssetDetentionReportReport,
      };
    }

    default: {
      return {
        data: { data: [], pagination: defaultPaginationResponse },
        isLoading: false,
        isRefetching: false,
        isSuccess: false,
        refetch: () => Promise.resolve({} as QueryObserverResult),
      };
    }
  }
};
