import { useCallback, useEffect, useMemo, useState } from "react";
import { Box } from "@mui/system";
import { useGridApiRef } from "@mui/x-data-grid-premium";
import { useAppContext } from "../../../../context/AppContext";
import {
  AutomationTask,
  useGetAutomationTasksQuery,
} from "../../../../graphql/operations";
import { TroubleshootingScreen } from "../../../../shared/components/ActionDialog/TroubleshootingScreenActionDialog";
import { getRows, Table } from "../../../../shared/components/Table";
import { useExportedFileName } from "../../../../shared/hooks/useExportedFileName";
import { usePreferredTimezone } from "../../../../shared/hooks/usePreferredTimezone";
import {
  automationsVisibleColumns,
  getAutomationTaskColumns,
  TaskSearchKeys,
} from "./AutomationsUtils";

interface AutomationTasksProps {
  automationId?: string;
}

export type DataAutomationTask = {
  id: string;
  triggerInformation: string;
  deviceId: string;
  assetId: String;
};

export const AutomationTasks = ({ automationId }: AutomationTasksProps) => {
  const {
    state: { appConfig },
  } = useAppContext();
  const gridApiRef = useGridApiRef();
  const timezone = usePreferredTimezone();
  const [troubleshootingOpen, setTroubleshootingOpen] = useState(false);
  const [troubleshootingData, setTroubleshootingData] =
    useState<DataAutomationTask>();

  const handleOpenTroubleshooting = useCallback((data: DataAutomationTask) => {
    setTroubleshootingData(data);
    setTroubleshootingOpen(true);
  }, []);

  const columns = useMemo(
    () => getAutomationTaskColumns(timezone, handleOpenTroubleshooting),
    [timezone, handleOpenTroubleshooting]
  );

  const [paginationSkip, setPaginationSkip] = useState(0);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [tableRows, setTableRows] = useState<AutomationTask[]>([]);

  const pageSize = appConfig.table.defaultRowsPerPage;

  const fileName = useExportedFileName("AutomationTasks");

  const { data, isSuccess, isLoading } = useGetAutomationTasksQuery({
    input: {
      automationId: automationId ?? "",
      pagination: { limit: pageSize, skip: paginationSkip },
    },
  });

  const onPageChange = useCallback(
    (page: number) => {
      setCurrentPage(page);
      setPaginationSkip(pageSize * (page - 1));
    },
    [pageSize]
  );

  useMemo(() => {
    const automationTasks = data?.getAutomationTasks ?? [];

    setTableRows(
      getRows<typeof automationTasks>(automationTasks, columns, "", "", "_id")
    );
    return automationTasks;
  }, [data?.getAutomationTasks, columns]);

  return (
    <Box className="h-[100%] pb-4 px-16">
      <Table
        onPageChange={onPageChange}
        currentPage={currentPage}
        data-testid="automationTasks-table"
        // TODO Use TableViewType.AutomationTasks when GraphQL schema will be updated on the back-end
        tableType={"automationTasks" as any}
        showToolbar
        rows={tableRows ?? []}
        apiRef={gridApiRef}
        rowCount={tableRows?.length ?? 0}
        pageSize={pageSize}
        columns={columns}
        loading={isLoading}
        error={!isLoading && !isSuccess ? true : null}
        searchThreshold={0.1}
        columnVisibilityModel={automationsVisibleColumns}
        searchExactMatch
        enableSearch
        disableRowGrouping
        pagination={true}
        searchKeys={TaskSearchKeys}
        sortKeys={TaskSearchKeys}
        searchMinLength={appConfig.searchMinLength}
        tableName="automationTasks"
        exportProps={{
          csvOptions: { fileName },
          excelOptions: { fileName },
          printOptions: { fileName },
        }}
      />
      <TroubleshootingScreen
        open={troubleshootingOpen}
        onClose={() => setTroubleshootingOpen(!troubleshootingOpen)}
        title="Information"
        troubleshootingData={troubleshootingData}
        name="troubleshooting-screen"
        onSubmit={() => {}}
        submitButtonText=""
        hideSubmitButton
      />
    </Box>
  );
};
