import { UseFormReturn, useWatch } from "react-hook-form";
import { AutocompleteElement } from "react-hook-form-mui";
import AddIcon from "@mui/icons-material/Add";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { Box, Button, Grid, Paper, Typography } from "@mui/material";
import { v4 } from "uuid";
import { useAvailableOrgs } from "../../../../shared/hooks/useAvailableOrgs";
import {
  AutomationAction,
  AutomationInitialValues,
} from "./hooks/useAutomationForm";

interface AutomationActionsProps {
  form: UseFormReturn<Partial<AutomationInitialValues>>;
}

const automationsActions = [
  {
    id: "SEND_DEVICE_COMMAND",
    label: "Send Device Command",
  },
  {
    id: "TRANSFER_ASSET",
    label: "Transfer Asset",
  },
  {
    id: "TRANSFER_DEVICE",
    label: "Transfer Device",
  },
];

const commandOptions = [
  {
    id: "SEND_DEVICE_COMMAND__REQUEST_PHOTO",
    label: "Request Photo",
  },
  {
    id: "SEND_DEVICE_COMMAND__DEVICE_PRECHECK",
    label: "Device Precheck",
  },
  {
    id: "SEND_DEVICE_COMMAND__DEVICE_REPORT_NOW",
    label: "Device Report Now",
  },
  {
    id: "SEND_DEVICE_COMMAND__SMARTLOCK_DOOR_LOCK",
    label: "SmartLock Door Lock",
  },
  {
    id: "SEND_DEVICE_COMMAND__SMARTLOCK_DOOR_UNLOCK",
    label: "SmartLock Door Unlock",
  },
  {
    id: "SEND_DEVICE_COMMAND__SMARTLOCK_GLANDHAND_LOCK",
    label: "SmartLock Gladhand Lock",
  },
  {
    id: "SEND_DEVICE_COMMAND__SMARTLOCK_GLANDHAND_UNLOCK",
    label: "SmartLock Gladhand Unlock",
  },
];

export const AutomationActions = ({ form }: AutomationActionsProps) => {
  const orgs = useAvailableOrgs();
  const orgOptions = orgs.map((org) => ({
    id: org._id,
    label: org.name,
  }));
  const watchActions = useWatch({
    name: "actions",
    control: form.control,
  });

  const handleAddAction = () => {
    const actions = watchActions || [];
    form.setValue("actions", [
      ...actions,
      {
        name: "",
        command: "",
        parameters: null,
        _id: v4(), // assign a random id
      },
    ]);
  };

  const handleDeleteAction = (id: string) => {
    const actions = watchActions || [];
    const newActions = actions.filter((action) => action._id !== id);
    form.setValue("actions", newActions);
  };

  const renderActionForm = (action: AutomationAction, i: number) => {
    switch (action?.name) {
      case "SEND_DEVICE_COMMAND": {
        // in the future we will fetch options from nomenclatures
        // that's why we filter the options here by action name
        const options = commandOptions.filter((option) => {
          return option.id.includes("SEND_DEVICE_COMMAND");
        });
        return (
          <AutocompleteElement
            matchId={true}
            name={`actions[${i}].command` as keyof AutomationInitialValues}
            data-testid={`automation-actions-${i + 1}-command`}
            label="Choose Command"
            control={form.control}
            options={options}
          />
        );
      }
      case "TRANSFER_ASSET":
      case "TRANSFER_DEVICE": {
        return (
          <AutocompleteElement
            matchId={true}
            name={
              `actions[${i}].parameters.orgId` as keyof AutomationInitialValues
            }
            data-testid={`automation-actions-${i + 1}-newOrg`}
            label="New Organization"
            control={form.control}
            options={orgOptions}
          />
        );
      }
    }
  };

  return (
    <Paper elevation={0} className={`mb-6`} data-testid="automation-actions">
      <Box className="p-12">
        <Box className="flex justify-between pr-2">
          <Typography className="!pb-8 !text-lg" variant="h6" component="div">
            Actions
          </Typography>
          <Button
            data-testid="add-action-btn"
            className="global-text-btn global-text-btn--medium global-text-btn__theme--blue !font-bold !capitalize !text-brand"
            onClick={handleAddAction}
            disabled={watchActions?.length === automationsActions.length}
          >
            <AddIcon />
            Add Action
          </Button>
        </Box>
        <Box>
          <Grid container rowSpacing={6}>
            {watchActions?.map((action, i) => (
              <Grid container item xs={12} key={action._id}>
                <Grid item container xs={12} columnSpacing={6}>
                  <Grid
                    item
                    container
                    xs={12}
                    sm={12}
                    md={4}
                    lg={4}
                    className="items-center"
                  >
                    <Grid
                      item
                      xs={10}
                      data-testid={`automation-actions-${i + 1}`}
                    >
                      <AutocompleteElement
                        autocompleteProps={{
                          disableClearable: true,
                          getOptionDisabled: (option) => {
                            return watchActions?.some(
                              (watchAction) => watchAction.name === option.id
                            );
                          },
                          onChange: (e, value) => {
                            if (!value) return;
                            if (
                              value.id === "TRANSFER_ASSET" ||
                              value.id === "TRANSFER_DEVICE"
                            ) {
                              form.setValue(
                                `actions[${i}].parameters` as keyof AutomationInitialValues,
                                {
                                  orgId: "",
                                } as any
                              );
                              form.setValue(
                                `actions[${i}].command` as keyof AutomationInitialValues,
                                null
                              );
                            } else {
                              form.setValue(
                                `actions[${i}].parameters` as keyof AutomationInitialValues,
                                null
                              );
                            }
                          },
                        }}
                        matchId={true}
                        name={
                          `actions[${i}].name` as keyof AutomationInitialValues
                        }
                        data-testid={`automation-actions-${i + 1}`}
                        label={`Action ${i + 1}`}
                        control={form.control}
                        options={automationsActions}
                      />
                    </Grid>
                    <Grid item xs={2} className="text-end">
                      <DeleteOutlineIcon
                        className="text-light-charcoal cursor-pointer"
                        data-testid={`delete-action-${i + 1}`}
                        onClick={() => handleDeleteAction(action._id as string)}
                      />
                    </Grid>
                  </Grid>
                  <Grid item xs={12} sm={12} md={4} lg={4}>
                    {renderActionForm(action, i)}
                  </Grid>
                </Grid>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Box>
    </Paper>
  );
};
