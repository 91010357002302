import { capitalize } from "lodash";
import { Maybe } from "yup/lib/types";
import { roleToHumanReadableMapper } from "../../../../../constants/users";
import { TableColumnFormat as ReportColumnFormat } from "../../../../../graphql/operations";
import { columnTimezoneDateTimeFormatParams } from "../../../../../utils";
import { ReportGridColDef } from "../../../helpers/helpers";

export const defaultColumnVisibilityModel = {
  name: false,
  firstName: false,
  lastName: false,
  email: false,
  orgName: false,
  phoneNumber: false,
  updatedAt: false,
  addedAt: false,
  lastLoginDate: false,
  status: false,
  updated_by: false,
  roles: false,
  added_by: false,
};

export const getColumns = (
  timezone: Maybe<string> | undefined
): ReportGridColDef[] => {
  const commonColumnConfig = {
    flex: 1,
    minWidth: 120,
  };

  const createColumn = (
    field: string,
    headerName: string,
    format: ReportColumnFormat,
    additionalConfig = {}
  ) => ({
    field,
    headerName,
    format,
    ...commonColumnConfig,
    ...additionalConfig,
  });

  const columns = [
    createColumn("name", "Username", ReportColumnFormat.String),
    createColumn("firstName", "First Name", ReportColumnFormat.String),
    createColumn("lastName", "Last Name", ReportColumnFormat.String),
    createColumn("email", "Email", ReportColumnFormat.String),
    createColumn("orgName", "Organization", ReportColumnFormat.String, {
      filterable: false,
    }),
    createColumn("updatedAt", "Last Updated", ReportColumnFormat.IsoDateTime, {
      ...columnTimezoneDateTimeFormatParams(timezone as string),
    }),
    createColumn("addedAt", "Created", ReportColumnFormat.IsoDateTime, {
      ...columnTimezoneDateTimeFormatParams(timezone as string),
    }),
    createColumn(
      "lastLoginDate",
      "Last Login",
      ReportColumnFormat.IsoDateTime,
      {
        ...columnTimezoneDateTimeFormatParams(timezone as string),
      }
    ),
    createColumn("status", "Status", ReportColumnFormat.String, {
      renderCell: (params: { value: string }) => capitalize(params.value),
    }),
    createColumn("updated_by", "Updated By", ReportColumnFormat.String),
    createColumn("roles", "Role", ReportColumnFormat.String, {
      renderCell: (params: { value: string }) =>
        roleToHumanReadableMapper[params.value[0].toLowerCase()],
    }),
    createColumn("added_by", "Created By", ReportColumnFormat.String),
  ];
  return columns;
};

export const SEARCH_KEYS = [
  "name",
  "firstName",
  "lastName",
  "email",
  "orgName",
];
