import { FC, memo } from "react";
import { Box, Grid, ThemeProvider } from "@mui/material";
import { ColorsPalette } from "../../../../../design-system/colors-palette";
import {
  GetGeofenceHistoryQuery,
  GetGeofenceHistoryQueryVariables,
  useGetGeofenceHistoryQuery,
} from "../../../../../graphql/operations";
import { useAssetManagementTheme } from "../../../TableView/hooks/useAssetManagementTheme";
import { getReferenceAreas } from "../GeofenceHistoryCharts/components/GeofenceReferenceArea";
import { GeofenceVerticalBarChartWidget } from "../GeofenceHistoryCharts/components/GeofenceVerticalBarChart/GeofenceVerticalBarChart";
import {
  calculateDateRange,
  calculateHeatmapRanges,
  parseHistoricalData,
} from "../GeofenceHistoryCharts/helpers";
import { GeofenceHistoryTile, GeofenceTileTypes } from "./GeofenceHistoryTile";

type GeofenceHistoryTabProps = {
  geofenceId: string;
};

export const GeofenceHistoryTab: FC<GeofenceHistoryTabProps> = memo(
  ({ geofenceId }) => {
    const assetManagementTheme = useAssetManagementTheme();
    const variables: GetGeofenceHistoryQueryVariables = {
      input: {
        geofenceId,
      },
    };

    const { data } = useGetGeofenceHistoryQuery<
      GetGeofenceHistoryQuery,
      unknown
    >(variables);

    const geofenceHistory = data?.getGeofenceHistory;

    const geofenceSummary30d = geofenceHistory
      ? geofenceHistory.geofenceSummary30d
      : undefined;
    const geofenceSummary365d = geofenceHistory
      ? geofenceHistory.geofenceSummary365d
      : undefined;

    const data30d = geofenceHistory
      ? parseHistoricalData(
          geofenceHistory.geofenceAssetHistory30d,
          geofenceHistory.geofenceDwellingHistory30d,
          geofenceHistory.geofenceAssetInFreePeriodHistory30d,
          geofenceHistory.geofenceAssetInDetentionHistory30d,
          geofenceHistory.geofenceAssetInPostDetentionHistory30d
        )
      : null;

    const data365d = geofenceHistory
      ? parseHistoricalData(
          geofenceHistory.geofenceAssetHistory365d,
          geofenceHistory.geofenceDwellingHistory365d,
          geofenceHistory.geofenceAssetInFreePeriodHistory365d,
          geofenceHistory.geofenceAssetInDetentionHistory365d,
          geofenceHistory.geofenceAssetInPostDetentionHistory365d
        )
      : null;

    const { minValue: min30d, maxValue: max30d } = data30d
      ? calculateHeatmapRanges(data30d)
      : { minValue: 0, maxValue: 0 };

    const { minValue: min365d, maxValue: max365d } = data365d
      ? calculateHeatmapRanges(data365d)
      : { minValue: 0, maxValue: 0 };

    const referenceAreas30d = data30d
      ? getReferenceAreas(min30d, max30d)
      : undefined;
    const referenceAreas365d = data365d
      ? getReferenceAreas(min365d, max365d)
      : undefined;

    const dateRange30d = data30d
      ? calculateDateRange(data30d)
      : "No data available";
    const dateRange365d = data365d
      ? calculateDateRange(data365d)
      : "No data available";

    return (
      <Box className="bg-background" data-testid="geofence-history-tab-table">
        <Box className="flex h-full flex-col w-full p-8 pt-2 md:px-6 md:pb-10 lg:px-4 lg:pb-20 bg-panel_content__bg">
          <ThemeProvider theme={assetManagementTheme}>
            <Grid container style={{ marginBottom: 16 }} spacing={2}>
              <Grid item xs={12} sm={12} md={12} lg={6} xl={4}>
                <GeofenceHistoryTile
                  type={GeofenceTileTypes.AverageAssetsPresent}
                  valueOne={
                    geofenceSummary30d?.averageAssetsPresent?.toFixed(2) ?? "0"
                  }
                  valueTwo={
                    geofenceSummary365d?.averageAssetsPresent?.toFixed(2) ?? "0"
                  }
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={6} xl={4}>
                <GeofenceHistoryTile
                  type={GeofenceTileTypes.AssetsWereHealthy}
                  valueOne={
                    geofenceSummary30d?.assetsWereHealthy?.toFixed(2) ?? "0"
                  }
                  valueTwo={
                    geofenceSummary365d?.assetsWereHealthy?.toFixed(2) ?? "0"
                  }
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={6} xl={4}>
                <GeofenceHistoryTile
                  type={GeofenceTileTypes.AssetsAreLoaded}
                  valueOne={
                    geofenceSummary30d?.assetsAreLoaded?.toFixed(2) ?? "0"
                  }
                  valueTwo={
                    geofenceSummary365d?.assetsAreLoaded?.toFixed(2) ?? "0"
                  }
                />
              </Grid>

              <Grid item xs={12} sm={12} md={12} lg={6} xl={4}>
                <GeofenceHistoryTile
                  type={GeofenceTileTypes.AverageAssetsInFreePeriod}
                  valueOne={geofenceSummary30d?.freeDays?.toFixed(2) ?? "0"}
                  valueTwo={geofenceSummary365d?.freeDays?.toFixed(2) ?? "0"}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={6} xl={4}>
                <GeofenceHistoryTile
                  type={GeofenceTileTypes.AverageAssetsInDetention}
                  valueOne={geofenceSummary30d?.inDetention?.toFixed(2) ?? "0"}
                  valueTwo={geofenceSummary365d?.inDetention?.toFixed(2) ?? "0"}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={6} xl={4}>
                <GeofenceHistoryTile
                  type={GeofenceTileTypes.AverageAssetsInPostDetention}
                  valueOne={
                    geofenceSummary30d?.postDetention?.toFixed(2) ?? "0"
                  }
                  valueTwo={
                    geofenceSummary365d?.postDetention?.toFixed(2) ?? "0"
                  }
                />
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <GeofenceVerticalBarChartWidget
                  xAsisKey="name"
                  data={data30d || []}
                  title="History Trending Graph 30 Days"
                  barKey="bar"
                  dateRange={dateRange30d}
                  lineDataKey="avg"
                  yAxisLabel={"Assets"}
                  barColor={ColorsPalette.ExtraLightBlue}
                  lineColor={ColorsPalette.LightBlue}
                  width="100%"
                  height={400}
                  ReferenceArea={referenceAreas30d}
                />
              </Grid>

              <Grid item xs={12}>
                <GeofenceVerticalBarChartWidget
                  xAsisKey="name"
                  data={data365d || []}
                  title="History Trending Graph 365 Days"
                  dateRange={dateRange365d}
                  barKey="bar"
                  lineDataKey="avg"
                  yAxisLabel={"Assets"}
                  barColor={ColorsPalette.ExtraLightBlue}
                  lineColor={ColorsPalette.LightBlue}
                  width="100%"
                  height={400}
                  ReferenceArea={referenceAreas365d}
                />
              </Grid>
            </Grid>
          </ThemeProvider>
        </Box>
      </Box>
    );
  }
);
