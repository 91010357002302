import { isNil } from "lodash";
import {
  Asset,
  GeofenceData,
  AssetCluster,
  AssetResult,
  Location,
  AssetClusterOs,
  AssetStatusGroup,
} from "../../graphql/operations";
import type {
  AssetsCountPerHealthStatus,
  Feature,
} from "../../views/AssetsView/shared/AssetsDataContext";

export const mapAssetToMapFeature = (asset: Asset): Feature => {
  const { location, _id, tripStatus, dwelling, ...properties } = asset;
  return {
    type: "Feature",
    properties: {
      ...properties,
      dwellingDays: Number(dwelling?.dwellingDays) || 0,
      id: _id,
      cluster: false,
      status: tripStatus?.tripState ?? undefined,
      signal: Boolean(tripStatus?.signal),
      load: asset?.sensors?.chassis?.data?.cargoState ?? "unknown", // must default on UI as db is unable to handle this :D
    },
    geometry: {
      type: "Point",
      coordinates: [
        location?.coordinates?.[0] ?? 0,
        location?.coordinates?.[1] ?? 0,
      ],
    },
  };
};

export const abbreviateNumber = (num: number) =>
  new Intl.NumberFormat("en-US", {
    maximumFractionDigits: 1,
    //@ts-ignore
    notation: "compact",
    compactDisplay: "short",
  }).format(num);

export const mapClusterToMapFeatureV2 = (
  assetOrCluster: AssetClusterOs
): Feature => {
  let properties = {};
  let coordinates = [0, 0];
  if (
    assetOrCluster?.asset === null &&
    (assetOrCluster?.count as number) >= 1
  ) {
    // means it is cluster
    const { location, key, count, assetStatusGroup } = assetOrCluster;
    coordinates = location as number[];
    properties = {
      cluster: true,
      cluster_id: key,
      point_count: count,
      point_count_abbreviated: abbreviateNumber(count as number),
      assetsCountPerHealthStatus: mapAssetStatusGroupToAssetsCount(
        (assetStatusGroup ?? []) as AssetStatusGroup[],
        count as number
      ),
    };
  } else {
    // means it is asset
    const { location, _id, tripStatus, dwelling, sensors, ...rest } =
      assetOrCluster?.asset as Asset;

    properties = {
      //tripStatus,
      cluster_id: assetOrCluster.key,
      dwellingDays: Number(dwelling?.dwellingDays) || 0,
      id: _id,
      cluster: false,
      status: tripStatus?.tripState ?? undefined,
      signal: Boolean(tripStatus?.signal),
      load: sensors?.chassis?.data?.cargoState ?? "unknown", // must default on UI as db is unable to handle this :D
      ...rest,
    };
    coordinates = [
      location?.coordinates?.[0] ?? 0,
      location?.coordinates?.[1] ?? 0,
    ];
  }

  return {
    type: "Feature",
    properties,
    geometry: {
      type: "Point",
      coordinates,
    },
  };
};

export const mapAssetStatusGroupToAssetsCount = (
  assetStatusGroup: AssetStatusGroup[],
  totalCount: number
): AssetsCountPerHealthStatus => {
  const defaultAssetsCount = {
    healthy: 0,
    warning: 0,
    alert: 0,
    critical: 0,
    unknown: 0,
  };
  let totalGroupedAssets = assetStatusGroup.reduce(
    (sum, group) => sum + (group?.count ?? 0),
    0
  );
  const result = assetStatusGroup.reduce((assetsCount, group) => {
    const { status, count } = group;
    const statusProp = (status ?? "").toLocaleLowerCase();
    if (status && count && statusProp in assetsCount) {
      assetsCount[statusProp as keyof AssetsCountPerHealthStatus] = count;
    }

    return assetsCount;
  }, defaultAssetsCount);

  return {
    ...result,
    unknown: totalCount - totalGroupedAssets,
  };
};

export function mapAssetsToMapFeatures(assetsResult: AssetResult) {
  const clusterWithAssets =
    assetsResult.clusters?.filter((cluster) => cluster?.asset !== null) ?? [];
  const features = clusterWithAssets.map((cluster) =>
    mapAssetToMapFeature(cluster?.asset as Asset)
  );

  return features;
}

export function mapClustersToMapFeaturesV2({ clusters }: AssetResult) {
  const features = ((clusters || []) as AssetClusterOs[]).map(
    (cluster: AssetClusterOs) => {
      return mapClusterToMapFeatureV2(cluster);
    }
  );

  return features;
}

export type AssetOrClusterListItem = {
  asset?: {
    location?: {
      coordinates?: Location["coordinates"] | null;
    };
  };
  count?: number;
  location?: AssetCluster["location"] | null;
};

export function extractAssetOrClusterCoordinatesData(
  assetsAndClustersList: (AssetOrClusterListItem | null)[] = []
): number[][] {
  const coordinates = assetsAndClustersList.map((entity) => {
    // Cluster
    if (!entity?.asset && (entity?.count as number) >= 1) {
      return entity?.location;
    }

    // Asset
    return [
      entity?.asset?.location?.coordinates?.[0],
      entity?.asset?.location?.coordinates?.[1],
    ];
  });

  // Filter out nullish values and zero-ish coordinates (those aren't useful to us)
  return coordinates.filter(
    (coords) =>
      Boolean(coords?.length) && // Ensure array exists
      !coords?.some(isNil) // Ensure array has only non-null values
  ) as number[][];
}

export const getItemsPerPage = (
  items: Feature[] | GeofenceData[] | any[],
  currentPage: number,
  pageSize: number
) => {
  // slice will return a new array from index to index
  return items.length > 25
    ? items.slice(currentPage * pageSize - pageSize, currentPage * pageSize)
    : items;
};

export const getAssetFromCluster = (
  data: AssetCluster[],
  cluster_id: string
): Asset => {
  const asset = data.find((cluster) => cluster.key === cluster_id)
    ?.asset as Asset;
  return asset;
};
