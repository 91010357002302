import { useCallback, useEffect } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { omitBy, isNil } from "lodash";
import * as yup from "yup";
import { Maybe, ReportType } from "../../../../../../graphql/operations";
import { transformers } from "../../../../../../utils";
import { isEndBeforeStart, isStartAfterEnd } from "../../../utils";

const dateFormatRegEx = /^(0[1-9]|1[0-2])\/(0[1-9]|1\d|2\d|3[01])\/\d{4}$/;

export const useRunReportForm = (
  initialValues: Maybe<Record<string, any>> = { orgIds: [] },
  isCreateReport?: boolean,
  reportType?: ReportType
) => {
  const orgIds = isCreateReport
    ? yup.array(yup.string())
    : yup
        .array(yup.string().required())
        .min(1, "Organization is required")
        .required("Organization is required");
  const schema = yup.object().shape({
    orgIds: orgIds,
    startDate: yup
      .string()
      .nullable()
      .optional()
      .transform(transformers.date)
      .matches(dateFormatRegEx, { message: "Date format is invalid" })
      .test(
        "startDate",
        "Start date can't be after end date",
        (value, { parent }) => isStartAfterEnd(value, parent)
      ),
    endDate: yup
      .string()
      .nullable()
      .optional()
      .transform(transformers.date)
      .matches(dateFormatRegEx, { message: "Date format is invalid" })
      .test(
        "endDate",
        "End date can't be before start date",
        (value, { parent }) => isEndBeforeStart(value, parent)
      ),
    ...(reportType === ReportType.AssetBalance
      ? {
          assetTypes: yup
            .array(yup.string().required())
            .min(1, "Asset type is required")
            .required("Asset type is required"),
        }
      : {}),
  });

  const form = useForm({
    resolver: yupResolver(schema),
    defaultValues: omitBy(initialValues, isNil),
  });

  // as orgIds are receiving by query - to prevent cached empty value we have to reset form value manually, when it's received
  useEffect(() => {
    if (initialValues?.orgIds?.length) {
      form.reset({
        orgIds: initialValues?.orgIds,
        startDate: initialValues?.startDate,
        endDate: initialValues?.endDate,
        assetTypes: initialValues?.assetType,
      });
    }
  }, [initialValues, form]);

  const getValues = useCallback(
    () => schema.cast(form.getValues(), { assert: false }),
    [form, schema]
  );

  return { form, getValues, schema };
};
