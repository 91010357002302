import { FC, useMemo } from "react";
import { Box, Grid, CircularProgress } from "@mui/material";
import { useAppContext } from "../../../../../context/AppContext";
import { useAuthContext } from "../../../../../context/AuthContext";
import {
  DistanceUnit,
  GeofenceEventInfo,
  SensorStatusUppercase,
} from "../../../../../graphql/operations";
import { isAdmin } from "../../../../../shared/components/WithPermissions";
import { useFindAssetById } from "../../../../../shared/hooks/openSearchMongoPolyfillHooks/useFindAssetById";
import { useAssetTypes } from "../../../../../shared/hooks/useAssetTypes";
import { useAvailableOrgs } from "../../../../../shared/hooks/useAvailableOrgs";
import useBreakpoint from "../../../../../shared/hooks/useBreakpoint";
import { useCurrentOrg } from "../../../../../shared/hooks/useCurrentOrg";
import { usePreferredTimezone } from "../../../../../shared/hooks/usePreferredTimezone";
import { useProductsList } from "../../../../../shared/hooks/useProductsList";
import { useUserData } from "../../../../../shared/hooks/useUserData";
import { getFormattedOdometerValue } from "../../../helpers/helpers";
import { useAssetsDataContext } from "../../../shared/AssetsDataContext";
import AssetsErrorMessage from "../../Shared/AssetsErrorMessage";
import DetailListWidget, {
  detailItem,
} from "../../Shared/DashboardWidgets/DetailListWidget";
import { useDOTInfoByVin } from "../../hooks/useDOTData";
import { getAssetDetails } from "./AssetDetailsHelper";
import { getAssetInfo } from "./AssetInfoHelper";
import BatterySummaryWidget from "./BatterySummaryWidget";
import StatusCard from "./Cards/StatusCard";
import OdometerTrends from "./Charts/OdometerTrends";
import TripDetails from "./Charts/TripDetails";
import { getLocationInfo } from "./LocationSummaryHelper";
import MapWidget from "./MapWidget";
import { StatusCardsType } from "./interfaces";

const SummaryTabPanel: FC = () => {
  const currentOrg = useCurrentOrg();
  const { selectedAssetId } = useAssetsDataContext();
  const { userInfo } = useAuthContext();
  const {
    state: {
      selectedOrganization: { selectedOrganization },
    },
  } = useAppContext();
  const availableOrgs = useAvailableOrgs();
  const user = useUserData();
  const timezone = usePreferredTimezone();

  const isMobile = useBreakpoint("down", "sm");

  const { getAssetTypeLabel } = useAssetTypes();

  const { data: selectedAsset } = useFindAssetById(
    {
      assetId: selectedAssetId ?? "",
      timezone: timezone ?? "",
      currentOrgId: selectedOrganization.value ?? "",
    },
    { enabled: !!selectedAssetId && !!timezone && !!currentOrg?._id }
  );
  const isCurrentUserClientOrSuperAdmin = useMemo(
    () => isAdmin(userInfo?.groups ?? []),
    [userInfo?.groups]
  );

  const voltageData = selectedAsset?.sensors?.voltage;
  const addressData = selectedAsset?.fullAddressFormatted;
  const locationData = selectedAsset?.location?.coordinates as number[];
  const lastReportedDate = voltageData?.readingDate
    ? new Date(voltageData?.readingDate)
    : null;
  const geofenceData = (selectedAsset?.geofence as GeofenceEventInfo[]) ?? [];
  const odometerReading = selectedAsset?.tripStatus?.odometer;
  const odometerUnit =
    currentOrg?.distance_unit_preference ?? DistanceUnit.Kilometers; // TODO; Replace with DEFAULT_DISTANCE_UNIT;
  const locationDetail: {
    locationDetailList: Array<detailItem<string | number>>;
    title: string;
    subTitle: string;
  } = {
    locationDetailList: getLocationInfo(
      addressData ?? "",
      locationData ?? [],
      lastReportedDate,
      selectedAsset?.tripStatus?.tripState ?? "",
      geofenceData
    ),
    title: "Asset Location",
    subTitle: "Current Location Information About Your Asset",
  };

  const currentAssetType = getAssetTypeLabel(selectedAsset?.category);

  const hideMileage = useMemo(
    () =>
      selectedAsset?.shared?.organizationId === user?.customerOrg._id &&
      selectedAsset?.shared?.hideProperties?.mileage,
    [
      selectedAsset?.shared?.hideProperties?.mileage,
      selectedAsset?.shared?.organizationId,
      user?.customerOrg._id,
    ]
  );

  const { productsMap } = useProductsList();
  const assetInfoDetail: {
    AssetInfoDetailList: Array<detailItem<string | number>>;
    title: string;
    subTitle: string;
  } = useMemo(
    () => ({
      AssetInfoDetailList: getAssetInfo(
        selectedAsset,
        hideMileage
          ? undefined
          : getFormattedOdometerValue(odometerReading, odometerUnit) ||
              undefined,
        productsMap.get(selectedAsset?.prd_cde as string)?.product_name,
        currentAssetType,
        selectedOrganization.value,
        availableOrgs,
        timezone
      ),
      title: "Asset Information",
      subTitle: "Detailed Information About Your Asset",
    }),
    [
      selectedAsset,
      hideMileage,
      odometerReading,
      odometerUnit,
      productsMap,
      currentAssetType,
      selectedOrganization.value,
      availableOrgs,
      timezone,
    ]
  );
  const dotInfo = useDOTInfoByVin(selectedAsset?.vin);

  const dotInfoResults = useMemo(() => dotInfo, [dotInfo]);
  const assetDetails: {
    AssetDetailsList: Array<detailItem<string | number | boolean>>;
    title: string;
    subTitle: string;
  } = {
    AssetDetailsList: getAssetDetails(selectedAsset, dotInfoResults),
    title: "Asset Details",
    subTitle: "Details About Your Asset",
  };

  const isLoadingSummaryTabData = !selectedAsset;
  const isConnectedToDevice = selectedAsset?.imei;

  const isAssetPrecheckEnabled =
    selectedAsset?.uiConfig?.preCheckEnabled ?? false;

  return (
    <Box className="flex flex-col gap-4" data-testid="summary-tab-panel">
      {isLoadingSummaryTabData ? (
        <Box
          className="flex h-full w-full items-center justify-center"
          data-testid="asset-summary-loader"
        >
          <CircularProgress />
        </Box>
      ) : (
        <>
          {!isConnectedToDevice ? (
            <AssetsErrorMessage />
          ) : (
            <Grid container spacing={2}>
              {/****** Status Cards ******/}
              <Grid
                container
                className="p-4 pb-0 pr-0"
                spacing={2}
                justifyContent="flex-start"
              >
                {selectedAsset.sensors?.chassis && (
                  <StatusCard
                    statusInfo={selectedAsset.sensors?.chassis.data?.cargoState}
                    cardType={StatusCardsType.Chassis}
                    lastEventData={selectedAsset.sensors?.chassis?.readingDate}
                  />
                )}
                {selectedAsset.sensors?.cargoCamera && (
                  <StatusCard
                    statusInfo={
                      selectedAsset.sensors?.cargoCamera.data
                        ?.floorUsagePercentage
                    }
                    cardType={StatusCardsType.CameraVision}
                    lastEventData={
                      selectedAsset.sensors?.cargoCamera?.readingDate
                    }
                  />
                )}
                {selectedAsset?.sensors?.airbag?.statusAirbag &&
                  selectedAsset?.sensors?.airbag?.statusAirbag !==
                    SensorStatusUppercase.Unknown && (
                    <StatusCard
                      statusInfo={selectedAsset?.sensors?.airbag?.statusAirbag}
                      cardType={StatusCardsType.AirBag}
                    />
                  )}
                {selectedAsset.sensors?.bleDoorSensor && (
                  <StatusCard
                    statusInfo={
                      selectedAsset.sensors?.bleDoorSensor.data?.doorState
                    }
                    cardType={StatusCardsType.Door}
                  />
                )}
                {/* place for ABS Fault Codes status card */}
                {selectedAsset?.sensors?.tpmsBeta?.statusTpmsBeta &&
                  selectedAsset?.sensors?.tpmsBeta?.statusTpmsBeta !==
                    SensorStatusUppercase.Unknown && (
                    <StatusCard
                      statusInfo={
                        selectedAsset?.sensors?.tpmsBeta?.statusTpmsBeta
                      }
                      cardType={StatusCardsType.TPMS}
                    />
                  )}
                {selectedAsset.sensors?.psiAirSupply?.data
                  ?.statusTankPressure &&
                  selectedAsset.sensors?.psiAirSupply?.data
                    ?.statusTankPressure !== SensorStatusUppercase.Unknown && (
                    <StatusCard
                      statusInfo={selectedAsset.sensors?.psiAirSupply?.data?.statusTankPressure.toLowerCase()}
                      cardType={StatusCardsType.AirTank}
                    />
                  )}
                {selectedAsset.sensors?.psiAirSupply?.data
                  ?.statusSupplyPressure &&
                  selectedAsset.sensors?.psiAirSupply?.data
                    ?.statusSupplyPressure !==
                    SensorStatusUppercase.Unknown && (
                    <StatusCard
                      statusInfo={selectedAsset.sensors?.psiAirSupply?.data?.statusSupplyPressure.toLowerCase()}
                      cardType={StatusCardsType.Regulator}
                      lastEventData={
                        selectedAsset.sensors?.psiAirSupply?.readingDate
                      }
                    />
                  )}
                {selectedAsset?.sensors?.atisAlpha?.statusAtisAlpha &&
                  selectedAsset?.sensors?.atisAlpha?.statusAtisAlpha !==
                    SensorStatusUppercase.Unknown && (
                    <StatusCard
                      statusInfo={
                        selectedAsset?.sensors?.atisAlpha?.statusAtisAlpha
                      }
                      cardType={StatusCardsType.AtisAlpha}
                      tripStatus={selectedAsset.tripStatus?.tripState ?? ""}
                    />
                  )}
                {selectedAsset?.sensors?.liteSentryGamma
                  ?.statusLiteSentryGamma &&
                  selectedAsset?.sensors?.liteSentryGamma
                    ?.statusLiteSentryGamma !==
                    SensorStatusUppercase.Unknown && (
                    <StatusCard
                      statusInfo={
                        selectedAsset?.sensors?.liteSentryGamma
                          ?.statusLiteSentryGamma
                      }
                      cardType={StatusCardsType.LiteSentryGamma}
                    />
                  )}
                {selectedAsset?.sensors?.temperature?.data?.statusInternal &&
                  selectedAsset?.sensors?.temperature?.data?.statusInternal !==
                    SensorStatusUppercase.Unknown && (
                    <StatusCard
                      statusInfo={
                        selectedAsset?.sensors?.temperature?.data
                          ?.statusInternal
                      }
                      cardType={StatusCardsType.TemperatureInternal}
                    />
                  )}
                {selectedAsset?.sensors?.psiWheelEnd?.statusPsiWheelEnd &&
                  selectedAsset?.sensors?.psiWheelEnd?.statusPsiWheelEnd !==
                    SensorStatusUppercase.Unknown && (
                    <StatusCard
                      statusInfo={
                        selectedAsset?.sensors?.psiWheelEnd?.statusPsiWheelEnd
                      }
                      cardType={StatusCardsType.WheelEndTemperature}
                    />
                  )}

                {selectedAsset?.sensors?.tpmsBeta?.statusImbalance &&
                  selectedAsset?.sensors?.tpmsBeta?.statusImbalance !==
                    SensorStatusUppercase.Unknown && (
                    <StatusCard
                      statusInfo={
                        selectedAsset?.sensors?.tpmsBeta?.statusImbalance
                      }
                      cardType={StatusCardsType.DualImbalance}
                    />
                  )}
                {selectedAsset.sensors?.liftgateAdvertisement && (
                  <StatusCard
                    statusInfo={
                      selectedAsset.sensors?.liftgateAdvertisement
                        ?.statusLiftgate
                    }
                    cardType={StatusCardsType.Liftgate}
                    lastEventData={
                      selectedAsset.sensors?.liftgateAdvertisement
                        ?.readingDate || undefined
                    }
                  />
                )}
                {/****** Status Cards END ******/}
              </Grid>

              <Grid item xl={6} xs={12} className="mapWidgetContainer">
                {selectedAsset && <MapWidget selectedAsset={selectedAsset} />}
              </Grid>
              <Grid item xl={6} xs={12} container spacing={2}>
                <Grid item xl={6} lg={6} xs={12}>
                  <DetailListWidget
                    title={locationDetail.title}
                    subTitle={locationDetail.subTitle}
                    detailList={locationDetail.locationDetailList}
                  />
                </Grid>
                <Grid item xl={6} lg={6} xs={12}>
                  <BatterySummaryWidget
                    battery={voltageData?.data?.battery}
                    primaryVoltage={voltageData?.data?.main}
                    lastReportedDate={lastReportedDate}
                    secondaryVoltage={voltageData?.data?.auxiliary}
                    solarAmperage={voltageData?.data?.solarAmperage}
                    batteryStatusString={
                      selectedAsset?.sensors?.voltage?.data?.statusBattery ?? ""
                    }
                    assetUIConfig={selectedAsset.uiConfig}
                  />
                </Grid>
                <Grid item xl={6} lg={6} xs={12}>
                  <DetailListWidget
                    title={assetInfoDetail.title}
                    subTitle={assetInfoDetail.subTitle}
                    detailList={assetInfoDetail.AssetInfoDetailList}
                    showSendDeviceCommandButtons={
                      isAssetPrecheckEnabled && isCurrentUserClientOrSuperAdmin
                    }
                    deviceId={selectedAsset?.imei ?? ""}
                  />
                </Grid>
                <Grid item xl={6} lg={6} xs={12}>
                  <DetailListWidget
                    title={assetDetails.title}
                    subTitle={assetDetails.subTitle}
                    detailList={assetDetails.AssetDetailsList}
                  />
                </Grid>
              </Grid>
              {!isMobile && <OdometerTrends selectedAsset={selectedAsset} />}
              {!isMobile && <TripDetails selectedAsset={selectedAsset} />}
            </Grid>
          )}
        </>
      )}
    </Box>
  );
};

export default SummaryTabPanel;
