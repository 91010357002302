import { formatDate as format } from "../../../../../utils/date";

export interface HistoricalData {
  name: string;
  max: number;
  avg: number;
  bar: number;
}
export const calculateDateRange = (data: HistoricalData[]): string => {
  if (!data || data.length === 0) {
    return "No data";
  }

  const dates = data.map((item) => new Date(item.name).getTime());
  const minDate = new Date(Math.min(...dates));
  const maxDate = new Date(Math.max(...dates));
  return `${format(minDate, "dd/MM/yyyy")} - ${format(maxDate, "dd/MM/yyyy")}`;
};

const formatDate = (date: Date): string => {
  const month = `${date.getMonth() + 1}`.padStart(2, "0");
  const day = `${date.getDate()}`.padStart(2, "0");
  const year = date.getFullYear();
  return `${month}/${day}/${year}`;
};

export function calculateHeatmapRanges(data: HistoricalData[]): {
  minValue: number;
  maxValue: number;
} {
  if (data.length === 0) {
    return { minValue: 0, maxValue: 0 };
  }

  const minValue = Math.min(...data.map((d) => d.avg));
  const maxValue = Math.max(...data.map((d) => d.avg));

  return { minValue, maxValue };
}

export const parseDate = (date: string): string => {
  const [year, month, day] = date.split("-");
  return `${month}/${day}`;
};

export const parseHistoricalData = (
  assetHistory: string,
  dwellingHistory: string,
  freePeriodHistory: string,
  inDetentionHistory: string,
  postDetentionHistory: string
): HistoricalData[] => {
  const assetData = JSON.parse(assetHistory);
  const dwellingData = JSON.parse(dwellingHistory);
  const freePeriodHistoryData = JSON.parse(freePeriodHistory);
  const inDetentionHistoryData = JSON.parse(inDetentionHistory);
  const postDetentionHistoryData = JSON.parse(postDetentionHistory);

  const allDates = new Set([
    ...Object.keys(assetData),
    ...Object.keys(dwellingData),
    ...Object.keys(freePeriodHistoryData),
    ...Object.keys(inDetentionHistoryData),
    ...Object.keys(postDetentionHistoryData),
  ]);

  return Array.from(allDates).map((date) => ({
    name: date,
    max: assetData[date] || 0,
    avg: assetData[date] || 0,
    bar: dwellingData[date] || 0,
  }));
};
