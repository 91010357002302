import {
  useCallback,
  useEffect,
  useMemo,
  useState,
  ReactElement,
  MutableRefObject,
} from "react";
import {
  GridFilterModel,
  GridRowParams,
  GridSortModel,
  GridCellParams,
  useGridApiRef,
  GridColumnOrderChangeParams,
  GridColumnVisibilityModel,
} from "@mui/x-data-grid-premium";
import { GridApiPremium } from "@mui/x-data-grid-premium/models/gridApiPremium";
import { useAppContext } from "../../../context/AppContext";
import {
  GetTableDataInput,
  Pagination,
  PaginationInput,
  TableDomain,
  TableSortingInput,
  TableViewType,
} from "../../../graphql/operations";
import type { TableViewMenuRef } from "../../../views/ReportView/AssetReport";
import { usePreferredTimezone } from "../../hooks/usePreferredTimezone";
import { SendTableDataToEmailDialog } from "./SendTableDataToEmailDialog/SendTableDataToEmailDialog";
import Table from "./Table";
import {
  ServerSideExportFormat,
  StringTableFilterValue,
  TableGridColDef,
  TableGridData,
} from "./types";
import {
  dataGridFiltersToTableFilters,
  muiDataGridSortToTableSort,
  TableDataModes,
  tableSortToMuiDataGridSort,
} from "./utils";

type QueryInput = GetTableDataInput & Record<string, unknown>;

export type BackEndProcessingTableProps<TData extends TableGridData> = {
  tableName: string;
  baseFilters?: Record<string, unknown>;
  tableType?: TableViewType;
  mobileBreakpoint?: number;
  columns: TableGridColDef<TData>[];
  allowFilters?: boolean;
  columnVisibilityModel: Record<string, boolean>;
  sorting: TableSortingInput[] | undefined;
  data: {
    pagination: Pagination | undefined;
    rows: TData[];
  };
  updateQueryInput: (data: Partial<QueryInput>) => void;
  isLoading: boolean;
  isSuccess: boolean;
  onExport?: (format: ServerSideExportFormat, domain: TableDomain) => void;
  rowsPerPage?: number;
  searchMinLength?: number;
  onRowClick?: (data: TData) => void;
  onCellClick?: (cell: GridCellParams) => void;
  actions?: ReactElement | null;
  updateSelectedRowsHandler?: (...args: any[]) => any;
  showToolbar?: boolean;
  isDataRefetching?: boolean;
  checkboxSelection?: boolean;
  disableSelectionOnClick?: boolean;
  apiRef?: React.MutableRefObject<GridApiPremium>;
  initialSearch?: string;
  domain: TableDomain;
  queryInput: GetTableDataInput;
  totalCount?: number;
  isSendingEmail?: boolean;
  setSendingEmail?: (arg: boolean) => void;
  enableSearch?: boolean;
  onColumnOrderChange?: (colData: GridColumnOrderChangeParams) => void;
  changeColumnsVisibility?: (model: GridColumnVisibilityModel) => void;
  tableViewsMenuRef?: MutableRefObject<TableViewMenuRef | null>;
  tableFiltersToSkip?: StringTableFilterValue[] | undefined;
  getRowId?: (row: any) => string | number;
};

export const BackEndProcessingTable = <TData extends TableGridData>({
  tableName,
  baseFilters,
  tableType,
  mobileBreakpoint,
  columnVisibilityModel,
  columns,
  rowsPerPage,
  sorting,
  data,
  updateQueryInput,
  onExport,
  isLoading,
  isSuccess,
  searchMinLength,
  onRowClick,
  onCellClick,
  actions,
  updateSelectedRowsHandler,
  isDataRefetching,
  checkboxSelection,
  disableSelectionOnClick,
  apiRef,
  initialSearch,
  domain,
  queryInput,
  totalCount,
  allowFilters = true,
  isSendingEmail,
  setSendingEmail,
  enableSearch = true,
  onColumnOrderChange,
  changeColumnsVisibility,
  tableViewsMenuRef,
  tableFiltersToSkip,
  getRowId,
}: BackEndProcessingTableProps<TData>) => {
  const {
    state: { appConfig },
  } = useAppContext();

  const timezone = usePreferredTimezone();
  const rowsCountPerPage = rowsPerPage ?? appConfig.table.defaultRowsPerPage;
  const defaultPagination = useMemo<PaginationInput>(
    () => ({ skip: 0, limit: rowsCountPerPage }),
    [rowsCountPerPage]
  );

  let gridApiRef = useGridApiRef();

  if (apiRef) {
    gridApiRef = apiRef;
  }

  const [pagination, setPagination] = useState<Pagination>({
    total: 0,
    skip: 0,
    limit: 0,
  });

  useEffect(() => {
    if (data.pagination) {
      setPagination(data.pagination);
    }
  }, [data.pagination]);

  const onSearch = useCallback(
    (searchText: string) => {
      updateQueryInput({
        searchText,
        pagination: defaultPagination,
      });
    },
    [updateQueryInput, defaultPagination]
  );

  const onSort = useCallback(
    (sortModel: GridSortModel) => {
      updateQueryInput({
        sorting: muiDataGridSortToTableSort(sortModel),
      });
    },
    [updateQueryInput]
  );

  const onTableRowClick = useCallback(
    (params: GridRowParams<TData>) => {
      onRowClick && onRowClick(params.row);
    },
    [onRowClick]
  );

  const onFilter = useCallback(
    (filterModel: GridFilterModel) => {
      updateQueryInput({
        tableFilters: dataGridFiltersToTableFilters(
          filterModel,
          columns,
          timezone
        ),
        pagination: defaultPagination,
      });
    },
    [timezone, updateQueryInput, columns, defaultPagination]
  );

  const onPageChange = useCallback(
    (page: number) => {
      updateQueryInput({
        pagination: {
          limit: pagination.limit,
          skip: (page - 1) * rowsCountPerPage,
        },
      });
    },
    [pagination, updateQueryInput, rowsCountPerPage]
  );

  const onExportClick = useCallback(
    (format: ServerSideExportFormat) => {
      onExport && onExport(format, domain);
    },
    [onExport, domain]
  );

  const onDialogClose = () => {
    setSendingEmail && setSendingEmail(false);
  };

  return (
    <>
      <Table
        handleDataMode={TableDataModes.FullServer}
        apiRef={gridApiRef}
        tableName={tableName}
        tableType={tableType}
        mobileBreakpoint={mobileBreakpoint}
        columns={columns}
        pageSize={rowsCountPerPage}
        initialSearch={initialSearch}
        rows={data.rows}
        loading={isLoading}
        error={!isLoading && !isSuccess ? true : null}
        onRowClick={onTableRowClick}
        onCellClick={onCellClick}
        columnVisibilityModel={columnVisibilityModel}
        changeColumnsVisibility={changeColumnsVisibility}
        showToolbar
        allowFilters={allowFilters}
        allowExport
        sortModel={tableSortToMuiDataGridSort(sorting)}
        enableSearch={enableSearch}
        disableRowGrouping
        searchMinLength={searchMinLength}
        searchExactMatch={true}
        sx={{ "& .MuiDataGrid-row": { cursor: "pointer" } }}
        rowCount={pagination.total}
        offset={pagination.skip as number}
        onSearch={onSearch}
        onSortModelChange={onSort}
        onFilterModelChange={onFilter}
        onPageChange={onPageChange}
        onExportClick={onExportClick}
        actions={actions}
        updateSelectedRowsHandler={updateSelectedRowsHandler}
        isDataRefetching={isDataRefetching}
        checkboxSelection={checkboxSelection}
        disableSelectionOnClick={disableSelectionOnClick}
        onColumnOrderChange={onColumnOrderChange}
        tableViewsMenuRef={tableViewsMenuRef}
        tableFiltersToSkip={tableFiltersToSkip}
        getRowId={getRowId}
      />
      <SendTableDataToEmailDialog
        baseFilters={baseFilters}
        gridApiRef={gridApiRef}
        tableDomain={domain}
        getDataInput={queryInput}
        open={!!isSendingEmail}
        selectedRowsCount={totalCount ?? 0}
        onClose={onDialogClose}
      />
    </>
  );
};
