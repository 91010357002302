import { FC, memo, useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import DeleteIcon from "@mui/icons-material/Delete";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import { Box, CircularProgress } from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import { useAppContext } from "../../../../../context/AppContext";
import {
  useDeleteGeofenceMutation,
  useFindGeofenceIncidentsQuery,
} from "../../../../../graphql/operations";
import { TextButton } from "../../../../../shared/components/Button";
import { ConfirmationDialog } from "../../../../../shared/components/ConfirmationDialog";
import { WithPermissions } from "../../../../../shared/components/WithPermissions";
import { NavigationRoutes } from "../../../../../utils/routes/routesUtils";
import { useAssetsDataContext } from "../../../shared/AssetsDataContext";
import { onErrorDeletingGeofence, onSuccessDeletingGeofence } from "./helpers";

export type ManageGeofenceProps = {
  id: string;
  onBack: () => void;
};

export const ManageGeofence: FC<ManageGeofenceProps> = memo(
  ({ id, onBack }) => {
    const { dispatch } = useAppContext();
    const queryClient = useQueryClient();
    const {
      setSelectedGeofence,
      setSelectedAssetId,
      allGeofences,
      setGeofenceForUpdate,
      selectedGeofence,
      setIsDrawingGeofence,
      resetGeofenceFiltersToDefault,
      setIsAssetsDrawerOpen,
      setIsFiltersDrawerOpen,
      setAddGeofenceDrawerOpen,
      setIsAssetsDrawerVisible,
      setIsFiltersDrawerVisible,
    } = useAssetsDataContext();
    const navigate = useNavigate();

    const [isDeleteGeofenceDialogOpen, setIsDeleteGeofenceDialogOpen] =
      useState(false);
    const [isReadyToGoBack, setIsReadyToGoBack] = useState(false);

    const hasChild = useMemo(
      () =>
        allGeofences?.find(
          (geofence) =>
            geofence?.configuration?.parentId === selectedGeofence?._id
        ),
      [allGeofences, selectedGeofence?._id]
    );

    const { data } = useFindGeofenceIncidentsQuery({
      input: { _id: id, typeId: selectedGeofence?.configuration?.typeId },
    });

    const onSuccess = useCallback(
      async () =>
        onSuccessDeletingGeofence(queryClient, dispatch, setIsReadyToGoBack),
      [dispatch, queryClient]
    );

    const onError = useCallback(
      (error: unknown) =>
        onErrorDeletingGeofence(dispatch, setIsReadyToGoBack, error),
      [dispatch]
    );

    const openDeleteGeofenceModal = useCallback(() => {
      setIsDeleteGeofenceDialogOpen(true);
    }, [setIsDeleteGeofenceDialogOpen]);

    const openUpdateGeofenceView = useCallback(() => {
      if (selectedGeofence !== null) {
        setIsDrawingGeofence(true);
        setIsAssetsDrawerOpen(false);
        setIsFiltersDrawerOpen(false);
        setAddGeofenceDrawerOpen(true);
        setIsAssetsDrawerVisible(false);
        setIsFiltersDrawerVisible(false);

        resetGeofenceFiltersToDefault();
        setGeofenceForUpdate(selectedGeofence);
      }
      setSelectedGeofence(null);
      setSelectedAssetId(null);
      navigate(NavigationRoutes.Geofences);
    }, [
      selectedGeofence,
      setSelectedGeofence,
      setSelectedAssetId,
      navigate,
      setIsDrawingGeofence,
      setIsAssetsDrawerOpen,
      setIsFiltersDrawerOpen,
      setAddGeofenceDrawerOpen,
      setIsAssetsDrawerVisible,
      setIsFiltersDrawerVisible,
      resetGeofenceFiltersToDefault,
      setGeofenceForUpdate,
    ]);

    const { mutate: deleteGeofence, isLoading: isDeletingGeofence } =
      useDeleteGeofenceMutation({ onSuccess, onError });

    useEffect(() => {
      if (isReadyToGoBack) {
        onBack?.();
      }
    }, [isReadyToGoBack, onBack]);

    const handleConfirmationResult = (isConfirmed: boolean) => {
      if (isConfirmed) {
        deleteGeofence({ id });
        setIsDeleteGeofenceDialogOpen(false);
      } else {
        setIsDeleteGeofenceDialogOpen(isConfirmed);
      }
    };

    return (
      <>
        <Box
          className="mx-auto md:w-full md:flex items-baseline md:justify-between"
          data-testid="manage-geofence-buttons"
        >
          <Box className="flex">
            <Box>
              <WithPermissions accessScope="geofences.remove">
                <TextButton
                  text="Delete Geofence"
                  size="medium"
                  className="!text-error"
                  icon={<DeleteIcon />}
                  iconPosition={"left"}
                  onClick={openDeleteGeofenceModal}
                  data-testid="delete-geofence-button"
                />
              </WithPermissions>
            </Box>
            <Box className="ml-0.5">
              <WithPermissions accessScope="geofences.remove">
                <TextButton
                  theme="blue"
                  text="Edit Geofence"
                  size="medium"
                  icon={<ModeEditIcon />}
                  iconPosition={"left"}
                  onClick={openUpdateGeofenceView}
                  data-testid="edit-geofence-button"
                />
              </WithPermissions>
            </Box>
          </Box>
        </Box>

        <ConfirmationDialog
          title={
            hasChild
              ? "Geofence cannot be deleted"
              : data?.findGeofenceIncidents.total
              ? "The Geofence has active alerts"
              : "Delete Geofence?"
          }
          message={
            hasChild
              ? "First update the subGeofences"
              : data?.findGeofenceIncidents.total
              ? "You have to update the alerts before delete this Geofence."
              : "Are you sure you want to delete this Geofence? "
          }
          open={isDeleteGeofenceDialogOpen}
          confirmButtonText={
            hasChild || data?.findGeofenceIncidents.total ? undefined : "Delete"
          }
          cancelButtonText={"Cancel"}
          handleConfirmationResult={handleConfirmationResult}
        />
        {isDeletingGeofence && (
          <Box
            data-testid="delete-geofence-loading-spinner"
            className="flex h-full w-full items-center justify-center"
            sx={{
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
              backgroundColor: "rgba(0, 0, 0, 0.7)",
              zIndex: 999,
            }}
          >
            <CircularProgress />
          </Box>
        )}
      </>
    );
  }
);
