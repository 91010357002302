import { capitalize } from "lodash";
import { ConfirmationDialog } from "../../../../shared/components/ConfirmationDialog";

export interface UnableToDeleteDialogProps {
  open: boolean;
  onConfirm: () => void;
  type: "region" | "zone";
}

export const UnableToDeleteDialog: React.FC<UnableToDeleteDialogProps> = ({
  open,
  onConfirm,
  type,
}) => (
  <ConfirmationDialog
    open={open}
    handleConfirmationResult={onConfirm}
    title={`Unable To Delete ${capitalize(type)}`}
    message={`This ${type} is in use by some alerts and can't be deleted.`}
    confirmButtonText="Ok"
  />
);
