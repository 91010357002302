import { useCallback, useMemo } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { isNil, omitBy } from "lodash";
import { v4 } from "uuid";
import * as yup from "yup";
import { AutomationMode } from "../../../../../graphql/operations";
import { AlertTypeTitle } from "../../../../AlertsView/constants";

export type AutomationAction = {
  command: string;
  name: string;
  parameters: string | null | Record<string, any>;
  _id?: string;
};

export type AutomationInitialValues = {
  mode: AutomationMode;
  parameters: {
    alertIds: string[] | undefined | null;
    alertType: typeof AlertTypeTitle | undefined | null;
  };
  trigger: string[] | undefined | null;
  actions: AutomationAction[];
};

export const useAutomationForm = (
  initialValues: AutomationInitialValues = {
    mode: AutomationMode.Pause,
    parameters: {
      alertIds: null,
      alertType: null,
    },
    trigger: null,
    actions: [],
  }
) => {
  const schema = yup.object().shape({
    mode: yup
      .string()
      .oneOf([AutomationMode.Pause, AutomationMode.Run])
      .required(),
    parameters: yup
      .object()
      .shape({
        alertIds: yup
          .array()
          .of(yup.string())
          .notRequired()
          .optional()
          .nullable(),
        alertType: yup
          .string()
          .oneOf([...Object.keys(AlertTypeTitle), null])
          .notRequired()
          .optional()
          .nullable(),
      })
      .notRequired()
      .optional(),
    trigger: yup.mixed().nullable().notRequired().optional(),
    actions: yup
      .array()
      .of(
        yup.object().shape({
          command: yup
            .string()
            .optional()
            .nullable()
            .test(
              "isValidCommand",
              "Command is required",
              (value, { parent }) => {
                if (parent.name === "SEND_DEVICE_COMMAND") {
                  return !!value;
                }
                return true;
              }
            ),
          name: yup.string().nullable().required("Action name is required"),
          parameters: yup
            .object()
            .nullable()
            .shape({
              orgId: yup
                .string()
                .nullable()
                .test(
                  "isValidParameters",
                  "Organization is required",
                  (value, context: Record<string, any>) => {
                    if (
                      context?.from?.[1].value.name === "TRANSFER_ASSET" ||
                      context?.from?.[1].value.name === "TRANSFER_DEVICE"
                    ) {
                      return !!value;
                    }
                    return true;
                  }
                ),
            }),
        })
      )
      .notRequired()
      .optional(),
  });

  const preparedInitialValues = useMemo(() => {
    return {
      ...initialValues,
      actions: initialValues.actions.map((action) => {
        const parameters =
          action.parameters && typeof action.parameters === "string"
            ? JSON.parse(action.parameters)
            : "";
        return {
          ...action,
          parameters: parameters,
          _id: v4(), // assign a random id
        };
      }),
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const form = useForm({
    resolver: yupResolver(schema),
    values: omitBy(
      preparedInitialValues,
      isNil
    ) as Partial<AutomationInitialValues>,
  });

  const getValues = useCallback(
    () => schema.cast(form.getValues(), { assert: false }),
    [form, schema]
  );

  return { schema, form, getValues };
};
