import { useState, FC, useMemo, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Box } from "@mui/material";
import { PAGE_SNACKBAR } from "../../../../../constants";
import { ASSET_SETTINGS_TAB_TYPES } from "../../../../../constants/assets";
import { useAppContext } from "../../../../../context/AppContext";
import DashboardFrame, {
  SectionsItem,
} from "../../../../../shared/components/DashboardFrame/DashboardFrame";
import { generateSectionsListItems } from "../../../../../shared/components/DashboardFrame/components/DashboardSectionsMenu/utils";
import { useFindAssetById } from "../../../../../shared/hooks/openSearchMongoPolyfillHooks/useFindAssetById";
import { themes } from "../../../../../shared/hooks/theme/utils";
import { useGetOrgById } from "../../../../../shared/hooks/useGetOrgById";
import { usePreferredTimezone } from "../../../../../shared/hooks/usePreferredTimezone";
import { NavigationRoutes } from "../../../../../utils/routes/routesUtils";
import { BatteryTabPanel } from "../../../MapView/Assets/BatteryTabPanel/BatteryTabPanel";
import BreadcrumbsTabPanel from "../../../MapView/Assets/Breadcrumbs/Breadcrumbs";
import { BreadcrumbsContextProvider } from "../../../MapView/Assets/Breadcrumbs/context/BreadCrumbsContext";
import CargoTabPanel from "../../../MapView/Assets/CargoTabPanel";
import { LiftgateTabPanel } from "../../../MapView/Assets/LiftgateTabPanel/LiftgateTabPanel";
import LightsTabPanel from "../../../MapView/Assets/LightsTabPanel/LightsTabPanel";
import OtherSensorsTabPanel from "../../../MapView/Assets/OtherSensorsTabPanel";
import SummaryTabPanel from "../../../MapView/Assets/SummaryTabPanel/SummaryTabPanel";
import TiresTabPanel from "../../../MapView/Assets/TiresTabPanel/TiresTabPanel";
import AssetActivityLog from "../../../MapView/Shared/AssetActivityLog";
import { AssetAlertsList } from "../../../MapView/Shared/AssetAlertsList";
import { BrakesTab } from "../../../MapView/Shared/BrakesTab";
import DetentionHistory from "../../../MapView/Shared/DetentionTab/DetentionHistory";
import DwellTab from "../../../MapView/Shared/DwellTab";
import EventHistory from "../../../MapView/Shared/EventHistoryView";
import MediaTab from "../../../MapView/Shared/MediaTabView/MediaTab";
import { Settings } from "../../../MapView/Shared/Settings";
import { TemperaturesTab } from "../../../MapView/Shared/TemperatureTab/TemperaturesTab";
import { assetsTabsList } from "../../../helpers/helpers";
import { useAssetsDataContext } from "../../../shared/AssetsDataContext";

export interface AssetDashboardProps {
  isAssetRefetching: boolean;
  backButtonText: string;
}

// TODO: Update the tabs mapping, export it as an input configuration
export const AssetDashboard: FC<AssetDashboardProps> = ({
  isAssetRefetching,
  backButtonText,
}) => {
  const [selectedAssetDetailTab, setSelectedAssetDetailTab] = useState(0);
  const {
    setSelectedAssetId,
    setIsFiltersDrawerOpen,
    setShouldShowFiltersStripAndViewToggle,
  } = useAssetsDataContext();
  const { id: assetId } = useParams();
  const {
    dispatch,
    state: {
      theme,
      selectedOrganization: { selectedOrganization },
    },
  } = useAppContext();

  /*
    This weird workaround is needed because in tests sometimes "theme" is undefined...
   */
  const isLightTheme =
    (theme?.theme ?? localStorage.getItem("theme")) === themes.light;
  const navigate = useNavigate();
  const timezone = usePreferredTimezone();
  const {
    data: selectedAsset,
    isError,
    error,
  } = useFindAssetById(
    {
      assetId: assetId ?? "",
      timezone: timezone,
      currentOrgId: selectedOrganization.value,
    },
    {
      enabled: Boolean(assetId && timezone && selectedOrganization.value),
    }
  );

  const tabIndex = useMemo(
    () =>
      assetsTabsList?.findIndex((tab) => tab.value === selectedAssetDetailTab),
    [selectedAssetDetailTab]
  );

  const sectionItemsList = useMemo<SectionsItem[]>(() => {
    const currentTabLabel = assetsTabsList[tabIndex]?.label;
    return selectedAsset
      ? generateSectionsListItems(selectedAsset, isLightTheme, currentTabLabel)
      : [];
  }, [selectedAsset, isLightTheme, tabIndex]);

  const selectedAssetOrg = useGetOrgById(selectedAsset?.customer_orgs_id);

  // TODO: PRJIND-9343 On mount - ensure the FiltersDrawer is hidden (should not be an issue once the new Asset Dashboard page is in place)
  useEffect(() => {
    setIsFiltersDrawerOpen(false);
  }, [setIsFiltersDrawerOpen]);

  useEffect(() => {
    setSelectedAssetId(assetId && selectedAsset ? selectedAsset._id : null);
  }, [assetId, selectedAsset, setSelectedAssetId]);

  useEffect(() => {
    if (isError) {
      const errorData = error as Error;

      if (errorData.message === "ERROR_MISSING_ASSET_WITH_GIVEN_ID") {
        navigate(NavigationRoutes.AssetTable);

        dispatch({
          type: PAGE_SNACKBAR,
          payload: {
            title: "Asset Not Found!",
            text: "The asset you are looking for does not exist.",
            severity: "error",
          },
        });
      }
    }
  }, [isError, error, dispatch, navigate]);
  useEffect(() => {
    setShouldShowFiltersStripAndViewToggle(false);
  }, [setShouldShowFiltersStripAndViewToggle]);

  const tabRequiresSectionsMenu = useMemo(
    () =>
      assetsTabsList.find(({ value }) => value === selectedAssetDetailTab)
        ?.showSectionsMenu,
    [selectedAssetDetailTab]
  );

  return (
    <Box
      className="absolute inset-0 z-[100]"
      data-testid="dashboardFrame-wrap-asset"
    >
      {selectedAsset && (
        <DashboardFrame
          title={selectedAsset.asset_id ?? ""}
          backButtonText={backButtonText}
          subtitle={selectedAssetOrg?.name ?? ""}
          assetId={selectedAsset._id}
          shouldDisableTabs={!selectedAsset._id}
          activeTab={selectedAssetDetailTab}
          tabs={assetsTabsList}
          sectionItemsList={sectionItemsList}
          onTabChange={setSelectedAssetDetailTab}
          showSectionsMenu={tabRequiresSectionsMenu}
          onBack={() => navigate(-1)}
        >
          <SummaryTabPanel key={0} />
          <BatteryTabPanel key={1} />
          <CargoTabPanel key={2} selectedAsset={selectedAsset} />
          <TiresTabPanel key={3} />
          <MediaTab
            key={4}
            selectedAssetId={selectedAsset._id}
            imei={selectedAsset.imei ?? ""}
          />
          <BrakesTab
            key={5}
            selectedAssetId={selectedAsset.asset_id ?? ""}
            internalId={selectedAsset._id}
            imei={selectedAsset.imei}
            orgId={selectedAsset.customer_orgs_id ?? ""}
          />
          <TemperaturesTab
            key={6}
            assetImei={selectedAsset.imei ?? ""}
            selectedAssetId={selectedAsset.asset_id ?? ""}
            internalId={selectedAsset._id}
          />
          <LightsTabPanel key={7} />
          <LiftgateTabPanel key={8} />
          <OtherSensorsTabPanel key={9} selectedAsset={selectedAsset} />
          <EventHistory
            key={10}
            imei={selectedAsset.imei ?? ""}
            selectedAssetId={selectedAsset._id}
            customerOrgId={selectedAsset.customer_orgs_id ?? ""}
          />
          <DetentionHistory
            key={11}
            selectedAssetId={selectedAsset.asset_id ?? ""}
            customerOrgId={selectedAsset.customer_orgs_id ?? ""}
          />
          <DwellTab key={12} selectedAssetId={selectedAsset._id} />
          <BreadcrumbsContextProvider key={13}>
            <BreadcrumbsTabPanel imei={selectedAsset.imei as string} />
          </BreadcrumbsContextProvider>
          <AssetAlertsList key={14} selectedAssetId={selectedAsset._id} />
          <AssetActivityLog
            key={15}
            selectedAssetId={selectedAsset._id}
            selectedAssetOrgName={selectedAsset.org_name ?? ""}
          />
          <Settings
            key={16}
            onBack={() => navigate(-1)}
            settingsTabTypes={ASSET_SETTINGS_TAB_TYPES}
            isAssetRefetching={isAssetRefetching}
          />
        </DashboardFrame>
      )}
    </Box>
  );
};
