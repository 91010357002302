import { useMemo } from "react";
import { ReportType, TableDomain } from "../../../../graphql/operations";

export const useGetTableDomain = (reportType: string): TableDomain => {
  return useMemo(() => {
    switch (reportType) {
      case ReportType.AssetInventory:
        return TableDomain.ReportAssetInventory;
      case ReportType.AlertHistory:
        return TableDomain.ReportAlertHistory;
      case ReportType.DwellHierarchy:
        return TableDomain.DwellHierarchy;
      case ReportType.AssetInstall:
        return TableDomain.AssetInstall;
      case ReportType.AssetTransfer:
        return TableDomain.AssetTransfer;
      default:
        return "" as TableDomain;
    }
  }, [reportType]);
};
