import {
  GridColDef,
  GridRenderCellParams,
  GridValueGetterParams,
} from "@mui/x-data-grid-premium";
import startCase from "lodash/startCase";
import * as yup from "yup";
import { ColorsPalette } from "../../../../design-system/colors-palette";
import { Asset, DetentionRuleValue } from "../../../../graphql/operations";
import { transformers } from "../../../../utils";
import AssetIcon from "../../../AssetsView/TableView/components/AssetIcon/AssetIcon";
import { getIconType } from "../../../AssetsView/TableView/components/AssetIcon/utils";
import { IconTypeComplete } from "../../../AssetsView/TableView/components/AssetsTable/columns";

// For future tables extend this type to union, like: "assets" | "products" | etc
export type ConfigurationTypes =
  | "assets"
  | "geofenceTypes"
  | "products"
  | "faultCodes"
  | "detentionRules";

export const ERROR_GEOFENCE_CONFIGURATION_SET_DUPLICATE =
  "ERROR_GEOFENCE_CONFIGURATION_SET_DUPLICATE";
export const ERROR_PRODUCT_CODE_CONFIGURATION_SET_DUPLICATE =
  "ERROR_PRODUCT_CODE_CONFIGURATION_SET_DUPLICATE";
export const ERROR_DETENTION_RULE_CONFIGURATION_SET_DUPLICATE =
  "ERROR_DETENTION_RULE_CONFIGURATION_SET_DUPLICATE";
export const ERROR_CONFIGURATION_SET_NAME_DUPLICATE =
  "ERROR_CONFIGURATION_SET_NAME_DUPLICATE";
export const ERROR_ARCHIVING_CONFIGURATION_SET_IS_USED_IN_ALERT =
  "ERROR_ARCHIVING_CONFIGURATION_SET_IS_USED_IN_ALERT";

export const MAX_DETENTION_RULES_PER_ORGANIZATION_LIMIT = 25;

export enum ConfigurationOptions {
  Assets = "assets",
  GeofenceTypes = "geofenceTypes",
  Products = "products",
  FaultCodes = "faultCodes",
  DetentionRules = "detentionRules",
}

export enum AssetDrawers {
  Edit = "editAsset",
  Create = "createAsset",
}

export enum ProductDrawers {
  Edit = "editProduct",
  Create = "createProduct",
}

export enum GeofenceTypeDrawers {
  Edit = "editGeofenceType",
  Create = "createGeofenceType",
}

export enum FaultCodesDrawers {
  Edit = "editFaultCode",
  Create = "createFaultCode",
}

export enum DetentionRulesDrawers {
  Edit = "editDetentionRule",
  Create = "createDetentionRule",
}

export type DrawerType =
  | "createAsset"
  | "editAsset"
  | "createProduct"
  | "editProduct"
  | "createGeofenceType"
  | "editGeofenceType"
  | "createFaultCode"
  | "editFaultCode"
  | "createDetentionRule"
  | "editDetentionRule";

// Used to determine which drawer to open
export const drawersTypeMapping = {
  assets: {
    create: AssetDrawers.Create,
    edit: AssetDrawers.Edit,
  },
  geofenceTypes: {
    create: GeofenceTypeDrawers.Create,
    edit: GeofenceTypeDrawers.Edit,
  },
  products: {
    create: ProductDrawers.Create,
    edit: ProductDrawers.Edit,
  },
  faultCodes: {
    create: FaultCodesDrawers.Create,
    edit: FaultCodesDrawers.Edit,
  },
  detentionRules: {
    create: DetentionRulesDrawers.Create,
    edit: DetentionRulesDrawers.Edit,
  },
};

const getIconValue = (params: GridValueGetterParams<any, Asset>) => {
  const iconType = getIconType(
    params.row.tripStatus?.tripState,
    params.row.dwelling?.dwellingDays,
    params.row.tripStatus?.signal,
    params.row.sensors?.chassis?.data?.cargoState
  );
  const value = IconTypeComplete[iconType as keyof typeof IconTypeComplete];
  return value;
};

const convertToBoolean = (value: string | boolean) => {
  if (typeof value === "boolean") {
    return value;
  } else {
    return value === "true";
  }
};

// TODO: Fix type, type hint the GridColDef<AssetTypeConfigurationSetData>
export const assetsTableColumns: GridColDef[] = [
  {
    field: "icon",
    headerName: "Icon",
    minWidth: 120,
    groupable: false,
    valueGetter: getIconValue,
    renderCell: (params: GridRenderCellParams) => {
      const { colorReflectsAsset, outerRingReflectsDwell } = params.row;

      return (
        <AssetIcon
          dwellingDays={30}
          signal
          trip_st={
            convertToBoolean(outerRingReflectsDwell) ? "Parked" : "Moving"
          }
          customColor={
            convertToBoolean(colorReflectsAsset)
              ? ColorsPalette.AccentGreen
              : undefined
          }
        />
      );
    },
    disableExport: true,
    filterable: false,
  },
  { field: "name", headerName: "Name", flex: 1, minWidth: 120 },
  {
    field: "colorReflectsAsset",
    headerName: "Color Reflects Asset Health",
    flex: 1,
    minWidth: 120,
    renderCell: ({ row }: GridRenderCellParams) =>
      convertToBoolean(row?.colorReflectsAsset) ? "Yes" : "No",
  },
  {
    field: "outerRingReflectsDwell",
    headerName: "Outer Ring Reflects Dwell Status",
    flex: 1,
    minWidth: 120,
    renderCell: ({ row }: GridRenderCellParams) =>
      convertToBoolean(row?.outerRingReflectsDwell) ? "Yes" : "No",
  },
  {
    field: "preCheckEnabled",
    headerName: "Precheck enabled",
    flex: 1,
    minWidth: 120,
    renderCell: ({ row }: GridRenderCellParams) =>
      convertToBoolean(row?.preCheckEnabled) ? "Yes" : "No",
  },
  {
    field: "lostCommunicationTime",
    headerName: "Time For Lost Communication",
    flex: 1,
    minWidth: 120,
  },
  { field: "type", headerName: "Center Sensor", flex: 1, minWidth: 120 },
  {
    field: "centerTextDescriptionLabel",
    headerName: "Center Text",
    flex: 1,
    minWidth: 120,
  },
  {
    field: "main",
    headerName: "Primary Voltage",
    flex: 1,
    minWidth: 120,
    renderCell: ({ row }: GridRenderCellParams) =>
      convertToBoolean(row?.main) ? "Yes" : "No",
  },
  {
    field: "auxiliary",
    headerName: "Secondary Voltage",
    flex: 1,
    minWidth: 120,
    renderCell: ({ row }: GridRenderCellParams) =>
      convertToBoolean(row?.auxiliary) ? "Yes" : "No",
  },
  {
    field: "solarAmperage",
    headerName: "Solar Amperage",
    flex: 1,
    minWidth: 120,
    renderCell: ({ row }: GridRenderCellParams) =>
      convertToBoolean(row?.solarAmperage) ? "Yes" : "No",
  },
  {
    field: "battery",
    headerName: "Battery",
    flex: 1,
    minWidth: 120,
    renderCell: ({ row }: GridRenderCellParams) =>
      convertToBoolean(row?.battery) ? "Yes" : "No",
  },
];

export const productTableColumns: any[] = [
  { field: "productName", headerName: "Name", minWidth: 120, flex: 1 },
  { field: "productCode", headerName: "Code", minWidth: 120, flex: 1 },
  {
    field: "assetName",
    headerName: "Asset Configuration",
    minWidth: 120,
    flex: 1,
  },
  {
    field: "icon",
    headerName: "Icon",
    groupable: false,
    valueGetter: getIconValue,
    renderCell: (params: any) => {
      const { colorReflectsHealth, outerRingReflectsDwell } = params.row;

      return (
        <AssetIcon
          dwellingDays={30}
          signal
          trip_st={outerRingReflectsDwell ? "Parked" : "Moving"}
          customColor={colorReflectsHealth ? ColorsPalette.AccentGreen : ""}
        />
      );
    },
    disableExport: true,
    filterable: false,
  },
  { field: "type", headerName: "Center Sensor", minWidth: 120, flex: 1 },
  {
    field: "colorReflectsHealth",
    headerName: "Color Reflects Asset Health",
    minWidth: 120,
    flex: 1,
    renderCell: ({ row }: any) =>
      row?.colorReflectsHealth === true ? "Yes" : "No",
  },
  {
    field: "outerRingReflectsDwell",
    headerName: "Outer Ring Reflects Dwell Status",
    minWidth: 120,
    flex: 1,
    renderCell: ({ row }: any) =>
      row?.outerRingReflectsDwell === true ? "Yes" : "No",
  },
  {
    field: "lostCommunicationTime",
    headerName: "Time For Lost Communication",
    minWidth: 120,
    flex: 1,
  },
];

export const productTableKeysList = productTableColumns.map(
  (column) => column.field
);

export const detentionRulesColumns: GridColDef<DetentionRuleValue>[] = [
  {
    field: "priority",
    headerName: "Priority",
    flex: 1,
    hideable: false,
    sortable: false,
    disableColumnMenu: true,
  },
  {
    field: "name",
    headerName: "Name",
    minWidth: 120,
    flex: 1,
    sortable: false,
  },
  {
    field: "company",
    headerName: "Company",
    minWidth: 120,
    flex: 1,
    sortable: false,
  },
  {
    field: "geofenceType",
    headerName: "Geofence Type",
    minWidth: 120,
    flex: 1,
    sortable: false,
  },
  {
    field: "geofenceSubType",
    headerName: "Geofence Sub Type",
    minWidth: 120,
    flex: 1,
    sortable: false,
  },
  {
    field: "geofenceName",
    headerName: "Geofence Name",
    minWidth: 120,
    flex: 1,
    sortable: false,
  },
  {
    field: "clockStarts",
    headerName: "Clock Starts",
    minWidth: 120,
    flex: 1,
    sortable: false,
    renderCell: ({ row: { clockStarts } }) =>
      clockStarts
        ? startCase(clockStarts.replaceAll("_", " ").toLowerCase())
        : "",
  },
  {
    field: "dayOfDrop",
    headerName: "Day of Drop",
    minWidth: 120,
    flex: 1,
    sortable: false,
    renderCell: ({ row: { dayOfDrop } }) =>
      dayOfDrop ? startCase(dayOfDrop.replaceAll("_", " ").toLowerCase()) : "",
  },
  {
    field: "endOnEmpty",
    headerName: "End on Empty",
    minWidth: 120,
    flex: 1,
    sortable: false,
    renderCell: ({ row: { endOnEmpty } }) =>
      endOnEmpty
        ? startCase(endOnEmpty.replaceAll("_", " ").toLowerCase())
        : "",
  },
  {
    field: "endOnLoaded",
    headerName: "End on Loaded",
    minWidth: 120,
    flex: 1,
    sortable: false,
    renderCell: ({ row: { endOnLoaded } }) =>
      endOnLoaded
        ? startCase(endOnLoaded.replaceAll("_", " ").toLowerCase())
        : "",
  },
];

export type Option = { value: string; label: string; id: string };

const patternOneDigitAfterComma = /^\d+(\.\d{1})?$/; // this one accepts 0. , 1. , etc needs to be updated
const validateAgaistPattern = (value?: number) => {
  if (value !== undefined) {
    return patternOneDigitAfterComma.test(value + "");
  }
  return true;
};
const emptyStringToUndefined = (
  value?: number | string,
  originalValue?: number | string
) => {
  if (typeof originalValue === "string" && originalValue === "") {
    return;
  }
  return value;
};
export const assetsConfigSchema = {
  name: yup
    .string()
    .required("Field is required!")
    .nullable()
    .transform(transformers.string),
  color: yup.string().required("Field is required!").nullable(),
  colorReflectsAsset: yup.string().notRequired(),
  outerRingReflectsDwell: yup.string().notRequired(),
  preCheckEnabled: yup.string().notRequired(),
  lostCommunicationTime: yup.string().required("Field is required!"),
  centerSensor: yup.object().shape({
    type: yup.string(),
    centerTextDescriptionLabel: yup
      .string()
      .notRequired()
      .max(3, "No more than 3 symbols!"),
    positiveReadingLabel: yup.string().notRequired(),
    negativeReadingLabel: yup.string().notRequired(),
  }),
  power: yup.object().shape(
    {
      main: yup.string().notRequired(),
      solarAmperage: yup.string().notRequired(),
      auxiliary: yup.string().notRequired(),
      battery: yup.string().notRequired(),
      minVoltageLevel: yup
        .number()
        .notRequired()
        .typeError("Must be a number!")
        .transform(emptyStringToUndefined)
        .min(0, "Must be greater than or equal to 0")
        .max(4.2, "Must be less than or equal to 4.2")
        .test(
          "has-one-digit-or-less",
          "Must have 0 or 1 decimal digits",
          validateAgaistPattern
        )
        .when("maxVoltageLevel", {
          is: undefined,
          then: yup
            .number()
            .notRequired()
            .typeError("Must be a number!")
            .transform(emptyStringToUndefined)
            .equals(
              [yup.ref("maxVoltageLevel")],
              "Both fields muist be empty!"
            ),
        }),
      maxVoltageLevel: yup
        .number()
        .notRequired()
        .typeError("Must be a number!")
        .transform(emptyStringToUndefined)
        .min(0, "Must be greater than or equal to 0")
        .max(4.2, "Must be less than or equal to 4.2")
        .test(
          "has-one-digit-or-less",
          "Must have 0 or 1 decimal digits",
          validateAgaistPattern
        )
        .when("minVoltageLevel", {
          is: (minVoltageLevel: number) => typeof minVoltageLevel === "number",
          then: yup
            .number()
            .notRequired()
            .typeError("Must be a number!")
            .transform(emptyStringToUndefined)
            .min(
              yup.ref("minVoltageLevel"),
              "Max Voltage must be greater or equal to Min Voltage!"
            ),
        })
        .when("minVoltageLevel", {
          is: undefined,
          then: yup
            .number()
            .notRequired()
            .typeError("Must be a number!")
            .transform(emptyStringToUndefined)
            .equals(
              [yup.ref("minVoltageLevel")],
              "Both fields muist be empty!"
            ),
        }),
    },
    [["maxVoltageLevel", "minVoltageLevel"]]
  ),
};
