import {
  memo,
  FC,
  useState,
  useCallback,
  useMemo,
  HTMLAttributes,
  useRef,
  SyntheticEvent,
} from "react";
import { useNavigate } from "react-router-dom";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import HubOutlinedIcon from "@mui/icons-material/HubOutlined";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import MapIcon from "@mui/icons-material/Map";
import PersonIcon from "@mui/icons-material/Person";
import SearchIcon from "@mui/icons-material/Search";
import SensorsIcon from "@mui/icons-material/Sensors";
import {
  CircularProgress,
  TextField,
  InputAdornment,
  Divider,
  Button,
  Box,
  TextFieldVariants,
} from "@mui/material";
import Autocomplete, {
  AutocompleteRenderInputParams,
  AutocompleteRenderGroupParams,
} from "@mui/material/Autocomplete";
import Fuse from "fuse.js";
import { get } from "lodash";
import { useDebounce } from "use-debounce";
import { LOCATION_CHANGE, LOCATION_RESET } from "../../../../constants";
import {
  LOCATION_ICON_STYLE,
  SHOW_LESS,
  SHOW_MORE,
  SMART_SEARCH_MATCH_AGAINST,
} from "../../../../constants/map";
import { useAppContext } from "../../../../context/AppContext";
import { useAuthContext } from "../../../../context/AuthContext";
import { NAV_ITEMS } from "../../../../shared/components/Header/SideMenu/NavList/NavList";
import { useGeoSearch } from "../../../../shared/components/Selector/Selector.queries";
import { useRecentSearch } from "../../../../shared/components/Selector/hooks";
import { NavigationRoutes } from "../../../../utils/routes/routesUtils";
import { PageTypes, useAssetsDataContext } from "../AssetsDataContext";
import { useGlobalSearchQueries } from "./smartSearchUtils";

//-----------------------------------
// Types declaration
//-----------------------------------

export interface OptionType {
  id?: string | null;
  name?: string | null;
  formatted?: string;
  matchedAgainst?:
    | "asset"
    | "device"
    | "location"
    | "user"
    | "organization"
    | "page"
    | "geofences";
}

interface IconOptionMapType {
  [key: string]: JSX.Element;
}

//-----------------------------------
// Local constants
//-----------------------------------
const optionLabel = (option: OptionType | string) =>
  ((option as OptionType)?.name || "") as string;

const groupByMatchedAgainst = (option: OptionType): string =>
  option?.matchedAgainst as string;

const IconOptionMap: IconOptionMapType = {
  location: <LocationOnIcon sx={LOCATION_ICON_STYLE} />,
  asset: <MapIcon sx={LOCATION_ICON_STYLE} />,
  geofence: <MapIcon sx={LOCATION_ICON_STYLE} />,
  device: <SensorsIcon sx={LOCATION_ICON_STYLE} />,
  organization: <HubOutlinedIcon sx={LOCATION_ICON_STYLE} />,
  user: <PersonIcon sx={LOCATION_ICON_STYLE} />,
  page: <DescriptionOutlinedIcon sx={LOCATION_ICON_STYLE} />,
};

export interface SmartSearchProps {
  placeholder?: string;
  variant?: TextFieldVariants;
  handleChange?: (
    value: OptionType,
    options: any,
    event: SyntheticEvent<Element, Event>
  ) => void;
  adornmentPosition?: "start" | "end";
  size?: "small";
}

const defaultProps: SmartSearchProps = {
  placeholder: "Search",
  variant: "standard",
  adornmentPosition: "start",
  handleChange: () => undefined,
};

const SmartSearch: FC<SmartSearchProps> = ({
  placeholder,
  variant,
  handleChange,
  adornmentPosition,
  size,
}) => {
  const ref = useRef<HTMLElement | null>(null);
  const [value, setValue] = useState<OptionType | null>(null);
  const [inputValue, setInputValue] = useState<string>("");
  const [showMore, setShowMore] = useState<string[]>([]);
  const { userInfo, userRolePermissions } = useAuthContext();
  const { state, dispatch } = useAppContext();
  const { debounceTime, searchLimit, searchOptionsLimit } = state.appConfig;
  const navigate = useNavigate();
  const storageKey = `search-items-${userInfo ? userInfo.username : "tmp"}`;
  const { handleHistoryItemAdd } = useRecentSearch(storageKey);
  const { pageType, currentFilter, setSelectedAssetId } =
    useAssetsDataContext();

  const [debouncedInputValue] = useDebounce(inputValue, debounceTime);
  const keyword = debouncedInputValue.toLowerCase();

  const { data: geoSearchResult, isFetching: isLoadingGeoSearch } =
    useGeoSearch({ searchText: keyword, limit: searchLimit }, !!keyword);

  const {
    organizations,
    users,
    assets,
    devices,
    geofences,
    isLoading: isLoadingGlobalSearch,
  } = useGlobalSearchQueries(keyword, searchLimit);
  const fuse = useMemo(() => {
    const navListOptions = {
      keys: ["name"], // Specify the property to search against
      threshold: 0.2,
    };

    return new Fuse(
      // Filter out nav items that the user does not have access to
      NAV_ITEMS.filter(
        ({ viewRights }) => get(userRolePermissions, viewRights)?.view
      ),
      navListOptions
    );
  }, [userRolePermissions]);
  const pages: any = fuse.search(keyword).map((item) => {
    return {
      id: item.item.route,
      name: item.item.name,
      matchedAgainst: "page",
    } as any;
  });

  const isLoading = isLoadingGeoSearch || isLoadingGlobalSearch;
  const options = useMemo(() => {
    const assetOptions = (
      assets?.map<OptionType>((item) => ({
        id: item.id,
        name: item.name,
        matchedAgainst: "asset",
      })) ?? []
    ).slice(0, showMore.includes("asset") ? undefined : searchOptionsLimit);
    const deviceOptions = (
      devices?.map<OptionType>((item) => ({
        id: item.id,
        name: item.name,
        matchedAgainst: "device",
      })) ?? []
    ).slice(0, showMore.includes("device") ? undefined : searchOptionsLimit);
    const locationOptions = (
      geoSearchResult?.map<OptionType>((item) => ({
        name: item.formatted,
        matchedAgainst: "location",
      })) ?? []
    ).slice(0, showMore.includes("location") ? undefined : searchOptionsLimit);
    const organizationOptions = (
      organizations?.map<OptionType>((item) => ({
        id: item.id,
        name: item.name,
        matchedAgainst: "organization",
      })) ?? []
    ).slice(
      0,
      showMore.includes("organization") ? undefined : searchOptionsLimit
    );
    const userOptions = (
      users?.map<OptionType>((item) => ({
        id: item.id,
        name: item.name,
        matchedAgainst: "user",
      })) ?? []
    ).slice(0, showMore.includes("user") ? undefined : searchOptionsLimit);
    const geofenceOptions = (
      geofences?.map<OptionType>((item) => ({
        id: item.id,
        name: item.name,
        matchedAgainst: "geofences",
      })) ?? []
    ).slice(0, showMore.includes("geofences") ? undefined : searchOptionsLimit);

    const pageOptions: OptionType[] = (
      pages?.map(
        (item: OptionType): OptionType => ({
          id: item.id,
          name: item.name,
          matchedAgainst: "page",
        })
      ) ?? []
    ).slice(0, showMore.includes("page") ? undefined : searchOptionsLimit);
    return [
      ...assetOptions,
      ...deviceOptions,
      ...locationOptions,
      ...userOptions,
      ...geofenceOptions,
      ...organizationOptions,
      ...pageOptions,
    ];
  }, [
    assets,
    devices,
    geofences,
    geoSearchResult,
    organizations,
    showMore,
    users,
    pages,
    searchOptionsLimit,
  ]);

  const groupCounts: Record<string, number> = useMemo(
    () => ({
      asset: assets?.length ?? 0,
      device: devices?.length ?? 0,
      location: geoSearchResult?.length ?? 0,
      user: users?.length ?? 0,
      geofences: geofences?.length ?? 0,
      organization: organizations?.length ?? 0,
      page: pages?.length ?? 0,
    }),
    [
      assets?.length,
      devices?.length,
      geoSearchResult?.length,
      organizations?.length,
      users?.length,
      pages?.length,
      geofences?.length,
    ]
  );

  const handleChangeLocation = useCallback(
    (value: string) => {
      handleHistoryItemAdd(value);
      dispatch({
        type: LOCATION_CHANGE,
        payload: value,
      });
      const url =
        pageType === PageTypes.AssetMap
          ? NavigationRoutes.AssetMap
          : pageType === PageTypes.AssetTable
          ? NavigationRoutes.AssetTable
          : NavigationRoutes.Geofences;

      navigate(url);
    },
    [pageType, navigate, handleHistoryItemAdd, dispatch]
  );

  const onChange = useCallback(
    (
      _e: React.SyntheticEvent<Element, Event>,
      changedValue: OptionType | string | null
    ) => {
      if (changedValue) {
        const { matchedAgainst, id, name } = changedValue as OptionType;
        if (handleChange) {
          handleChange(changedValue as OptionType, options as any, _e);
        }
        if (matchedAgainst === SMART_SEARCH_MATCH_AGAINST.location && name) {
          handleChangeLocation(name);
        } else if (
          id &&
          currentFilter?.assetId !== id &&
          matchedAgainst === SMART_SEARCH_MATCH_AGAINST.asset
        ) {
          setSelectedAssetId(id);
        }
      } else {
        dispatch({
          type: LOCATION_RESET,
          payload: undefined,
        });
      }

      setValue(changedValue as OptionType);
    },
    [
      handleChange,
      currentFilter?.assetId,
      options,
      handleChangeLocation,
      setSelectedAssetId,
      dispatch,
    ]
  );

  const isShortInput = inputValue?.length < 3;

  const renderInput = useCallback(
    (params: AutocompleteRenderInputParams) => (
      <TextField
        {...params}
        variant={variant}
        placeholder={placeholder}
        data-testid="smart-search-input"
        sx={{
          padding: "4px 8px 4px 8px",
          boxShadow: "0px 2px 4px 0px rgba(0, 0, 0, 0.16)",
          borderRadius: isShortInput ? "8px" : "8px 8px 0px 0px",
        }}
        InputProps={{
          ...params.InputProps,
          ...(adornmentPosition === "start"
            ? {
                startAdornment: (
                  <InputAdornment
                    position="start"
                    data-testid="smart-search-icon-start"
                  >
                    <SearchIcon />
                  </InputAdornment>
                ),
              }
            : {
                endAdornment: (
                  <InputAdornment
                    position="end"
                    data-testid="smart-search-icon-end"
                  >
                    <SearchIcon />
                  </InputAdornment>
                ),
              }),
          style:
            size === "small"
              ? {
                  fontSize: "12px",
                  flexWrap: "nowrap",
                  paddingRight: adornmentPosition === "end" ? 12 : undefined,
                }
              : undefined,
        }}
        size={size}
        autoFocus
      />
    ),
    [adornmentPosition, isShortInput, placeholder, size, variant]
  );

  const onClickShowMore = useCallback(
    (value: string) => {
      if (!showMore.includes(value)) {
        setShowMore([...showMore, value]);
      } else {
        // toggle
        const newShowMore = showMore.filter((x) => x !== value);
        setShowMore(newShowMore);
      }
    },
    [showMore]
  );

  const renderGroup = useCallback(
    (params: AutocompleteRenderGroupParams) => {
      const hasMore =
        Array.isArray(params?.children) &&
        ((params?.children?.length <= searchLimit &&
          groupCounts[params.group] > params?.children?.length) ||
          params?.children?.length > searchOptionsLimit);
      const collapsed = !showMore.includes(params.group);
      return (
        <li
          className="my-2 flex flex-col text-primary"
          key={`key-${params.key}`}
        >
          <p
            className="pl-meas4 font-bold capitalize-first-letter"
            data-testid="smart-search-option-section"
          >
            {`${params.group} (${groupCounts[params.group]})`}
          </p>
          <ul className="p-0" data-testid="smart-search-option-list">
            {isLoading ? (
              <Box
                className="flex justify-center"
                data-testid="smart-search-loader"
              >
                <CircularProgress />
              </Box>
            ) : (
              params.children
            )}
          </ul>
          {hasMore && (
            <Button
              variant="text"
              onClick={() => onClickShowMore(params.group)}
              data-testid="smart-search-show-more-less"
              className="!w-full self-center !capitalize !text-brand"
              endIcon={
                collapsed ? <KeyboardArrowDownIcon /> : <KeyboardArrowUpIcon />
              }
            >
              {collapsed ? SHOW_MORE : SHOW_LESS}
            </Button>
          )}
          {params.key !== "2" && <Divider />}
        </li>
      );
    },
    [
      groupCounts,
      isLoading,
      onClickShowMore,
      showMore,
      searchLimit,
      searchOptionsLimit,
    ]
  );

  const renderOption = useCallback(
    (props: HTMLAttributes<HTMLLIElement>, option: OptionType) => {
      return (
        <li {...props} key={`${option.id}${option.name}`}>
          {IconOptionMap[option.matchedAgainst as keyof typeof IconOptionMap]}
          <p className="ml-1" data-testid="smart-search-option">
            {option?.formatted || option?.name}
          </p>
        </li>
      );
    },
    []
  );

  const inputWidth = ref.current?.getBoundingClientRect()?.width;

  const freeSolo = !!(
    isLoading ||
    isShortInput ||
    assets?.length ||
    devices?.length ||
    geoSearchResult?.length
  );

  return (
    <Autocomplete
      ref={ref}
      data-testid="smart-search"
      freeSolo={freeSolo}
      noOptionsText={"We were not able to find a match."}
      includeInputInList
      blurOnSelect
      fullWidth
      options={options}
      onInputChange={(_, value) => setInputValue(value)}
      onChange={onChange}
      renderInput={renderInput}
      value={value}
      inputValue={inputValue}
      sx={{
        "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
          {
            border: "2px solid var(--brand) !important",
          },
        borderRadius: "0px",
      }}
      componentsProps={{
        popper: {
          style: {
            width: inputWidth,
            overflowY: "auto",
            filter: "drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))",
            margin: 0,
            padding: 0,
            overflowX: "hidden",
            display: isShortInput ? "none" : "",
            borderRadius: "0px 0px 8px 8px",
          },
        },
        paper: {
          style: {
            borderRadius: "0px",
          },
        },
      }}
      ListboxProps={{
        style: {
          maxHeight: `100%`,
          width: inputWidth ? inputWidth - 15 : 0,
        },
      }}
      getOptionLabel={optionLabel}
      groupBy={groupByMatchedAgainst}
      renderGroup={renderGroup}
      renderOption={renderOption}
      loading={isLoading}
      filterOptions={(options) => options}
    />
  );
};

SmartSearch.defaultProps = defaultProps;

export default memo(SmartSearch);
