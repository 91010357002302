import { Dispatch } from "react";
import { QueryClient } from "@tanstack/react-query";
import { PAGE_SNACKBAR } from "../../../../../constants";
import { Action } from "../../../../../context/commonTypes";
import { mapServerErrorCodeToHumanReadableMessage } from "../../../../../utils";

export const onSuccessDeletingGeofence = async (
  queryClient: QueryClient,
  dispatch: Dispatch<Action>,
  setOnSuccess: (flag: boolean) => void
) => {
  dispatch({
    type: PAGE_SNACKBAR,
    payload: {
      title: `Geofence successfully deleted`,
      text: "Success!",
      severity: "success",
    },
  });
  await queryClient.invalidateQueries(["findGeofences"], {
    refetchType: "all",
  });
  await queryClient.invalidateQueries(["findGeofencesByViewPort"], {
    refetchType: "all",
  });
  setOnSuccess(true);
};

export const onErrorDeletingGeofence = (
  dispatch: Dispatch<Action>,
  setOnError: (flag: boolean) => void,
  error: unknown
) => {
  dispatch({
    type: PAGE_SNACKBAR,
    payload: {
      title: "Geofence cannot be deleted",
      text: mapServerErrorCodeToHumanReadableMessage((error as Error).message),
      severity: "error",
    },
  });
  setOnError(false);
};
