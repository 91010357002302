import React, {
  FC,
  memo,
  useEffect,
  useMemo,
  useState,
  ChangeEvent,
  useCallback,
} from "react";
import SearchIcon from "@mui/icons-material/Search";
import {
  ThemeProvider,
  Select,
  Box,
  CircularProgress,
  TextField,
  InputAdornment,
} from "@mui/material";
import debounce from "lodash/debounce";
import { useAppContext } from "../../../context/AppContext";
import {
  FlattenHierarchy,
  flattenHierarchy,
  HierarchyNode,
} from "../../../utils";
import type { ChipOption } from "../react-hook-form-mui";
import { Hierarchy } from "./components/Hierarchy";
import { useTheme } from "./hooks/useTheme";
import { HierarchyStateItem, SelectedValues } from "./types";
import {
  buildHierarchyState,
  SelectAllOption,
  mutateHierarchyState,
  containsText,
} from "./utils";

export type HierarchySelectProps = {
  hierarchy: HierarchyNode[];
  loading?: boolean;
  values?: SelectedValues;
  onChange: (values: SelectedValues) => void;
  onBlur: () => void;
  setChipsList: (values: ChipOption[]) => void;
  required?: boolean;
  invalid?: boolean;
  hideValue?: boolean;
  handleUpdateField?: (value: any, field?: string) => void;
  fieldPath?: string;
  isSelectOpen?: boolean;
  setIsSelectOpen?: (open: boolean) => void;
  disabled?: boolean;
  readOnly?: boolean;
};

export const HierarchySelect: FC<HierarchySelectProps> = memo(
  ({
    hierarchy,
    loading,
    values,
    onChange,
    onBlur,
    required,
    invalid,
    hideValue,
    setChipsList,
    handleUpdateField,
    fieldPath,
    isSelectOpen,
    setIsSelectOpen,
    disabled,
    readOnly,
  }: HierarchySelectProps) => {
    const {
      state: { appConfig },
    } = useAppContext();
    const theme = useTheme();

    // <editor-fold desc="State">
    const [selectedValues, setSelectedValues] = useState<SelectedValues>(
      values ? [...values] : []
    );

    const [state, setState] = useState<HierarchyStateItem[]>([]);
    // A map of all the items in the hierarchy, keyed by their value
    const stateValuesMap: Record<
      string,
      FlattenHierarchy<HierarchyStateItem>
    > = useMemo(
      () =>
        flattenHierarchy(state).reduce(
          (acc, item) => ({ ...acc, [item.value]: item }),
          {}
        ),
      [state]
    );
    // The total number of items in the hierarchy
    const totalItemsInHierarchy = useMemo(
      () => flattenHierarchy(hierarchy).length,
      [hierarchy]
    );
    // </editor-fold>

    // <editor-fold desc="Handlers">
    // Handle search box input changes and update the visibility of the hierarchy items
    const updateHierarchyVisibility = useCallback(
      (searchText: string) => {
        setState(
          mutateHierarchyState(state, (item: HierarchyStateItem) => {
            // Check if the item or any of its children match the search text
            const itemSearchMatch = containsText(item.label, searchText);
            const parentSearchMatch = item.allChildrenLabels.some((val) =>
              containsText(val, searchText)
            );
            return {
              ...item,
              // If the item or any of its children match the search text, it should be visible
              visible: itemSearchMatch || parentSearchMatch,
              // If the item or any of its children match the search text, it should be expanded along with its parents
              // Otherwise, it should retain its current expanded state
              expanded: searchText ? parentSearchMatch : item.expanded,
            };
          })
        );
      },
      [state, setState]
    );
    // A debounced version of updateHierarchyVisibility to prevent excessive re-renders when typing in the search box
    // The debounce time is set to 300ms for the search box input
    const debouncedUpdateHierarchyVisibility = useMemo(
      () =>
        debounce(
          (e: ChangeEvent<HTMLInputElement>) =>
            updateHierarchyVisibility(e.target.value),
          appConfig.debounceTimeShort
        ),
      [updateHierarchyVisibility, appConfig.debounceTimeShort]
    );
    // Handle expanding/collapsing of the hierarchy items
    const expandedStateChangeHandler = (
      { value, allChildrenValues }: HierarchyStateItem,
      expanded: boolean
    ) => {
      setState(
        mutateHierarchyState(state, (item: HierarchyStateItem) => {
          // If the item is the one that was expanded/collapsed, update its expanded state
          if (item.value === value) {
            return { ...item, expanded };
          }
          // If the item is a child of the one that was expanded/collapsed, update its visibility
          if (allChildrenValues.includes(item.value)) {
            if (expanded) {
              // If the item is a child of the one that was expanded, it should be visible.
              // It covers a case when the item was previously hidden by the search box input change
              // But it should be visible again when the parent is expanded
              return { ...item, visible: true };
            } else {
              !!setIsSelectOpen && setIsSelectOpen(true);
              // Otherwise, it should retain its current visibility state
              return { ...item, expanded };
            }
          }
          return item;
        })
      );
    };
    // Handle selection of the hierarchy items
    const valueChangeHandler = (item: HierarchyStateItem) => {
      // The new values to be set in the state
      let values: string[];
      // Handle "Select all" click
      if (item.value === SelectAllOption.value) {
        if (selectedValues.includes(item.value)) {
          // Deselect all companies
          values = [];
        } else {
          // Select all companies including "Select All" option
          values = state.flatMap((item) => [
            item.value,
            ...item.allChildrenValues,
          ]);
        }
      }
      // Handle click on the company with children
      else if (item.children.length > 0) {
        if (selectedValues.includes(item.value)) {
          // Deselect all sub-companies
          values = selectedValues.filter(
            (v) => !item.allChildrenValues.includes(v) && v !== item.value
          );
        } else {
          // Select all sub-companies including the company itself
          values = [
            ...new Set([
              item.value,
              ...selectedValues,
              ...item.allChildrenValues,
            ]),
          ];
        }
      }
      // Handle click on the company without children
      else if (selectedValues.includes(item.value)) {
        // Deselect company
        values = selectedValues.filter((value) => item.value !== value);
      } else {
        // Select company
        values = [...selectedValues, item.value];
      }

      // Get array of values without "Select All" option
      const valuesWithoutAll = values.filter(
        (value) => value !== SelectAllOption.value
      );
      // Selected values array length equals the total number of items in the hierarchy,
      // then add "Select All" option to the selected values
      if (valuesWithoutAll.length === totalItemsInHierarchy) {
        values.unshift(SelectAllOption.value);
      }
      // If "Select All" option is selected, but not all companies are selected,
      // then remove "Select All" option from the selected values
      else if (
        values.includes(SelectAllOption.value) &&
        valuesWithoutAll.length !== totalItemsInHierarchy
      ) {
        values = values.filter((value) => value !== SelectAllOption.value);
      }

      // Update the selected values in the state
      setState(
        mutateHierarchyState(state, (item: HierarchyStateItem) => ({
          ...item,
          selected: values.includes(item.value),
        }))
      );
      setSelectedValues(values);
    };

    const handleSelectClose = useCallback(() => {
      // Trigger the onChange event with the new values excluding "Select All" option
      const values = selectedValues.filter((v) => v !== SelectAllOption.value);
      onChange && onChange(values);
      handleUpdateField && handleUpdateField(values, fieldPath);

      // Clear the search box input
      updateHierarchyVisibility("");
      !!setIsSelectOpen && setIsSelectOpen(false);
    }, [
      selectedValues,
      onChange,
      updateHierarchyVisibility,
      fieldPath,
      handleUpdateField,
      setIsSelectOpen,
    ]);
    // </editor-fold>
    // <editor-fold desc="useEffect">
    useEffect(() => {
      // If the hierarchy is loaded, update the state with the hierarchy items
      if (!loading) {
        const selected = values ? [...values] : [];
        // If all items are pre-selected, make "Select All" option selected as well
        if (selected.length === totalItemsInHierarchy) {
          selected.unshift(SelectAllOption.value);
          setState([
            { ...SelectAllOption, selected: true },
            ...buildHierarchyState(hierarchy, selected),
          ]);
        }
        // Otherwise, update the state with the hierarchy items
        else {
          setState([
            SelectAllOption,
            ...buildHierarchyState(hierarchy, selected),
          ]);
        }
        setSelectedValues(selected);
      }
    }, [loading, hierarchy, values, totalItemsInHierarchy]);
    // </editor-fold>

    // Prepare the string to be displayed in the select box
    const buildSelectedValuesString = (values: SelectedValues) =>
      loading || Object.keys(stateValuesMap).length === 0
        ? ""
        : values
            .reduce((acc, value) => {
              // Ignore "Select All" option value
              if (value !== SelectAllOption.value) {
                // Get HierarchyStateItem object for the given value
                const item = stateValuesMap[value];
                if (item) {
                  acc.push(item);
                }
              }
              return acc;
            }, [] as FlattenHierarchy<HierarchyStateItem>[])
            // Sort the labels by level and order
            .sort((a, b) =>
              a.level - b.level === 0 ? a.order - b.order : a.level - b.level
            )
            // Join the labels with comma
            .map(({ label }) => label)
            .join(", ");

    useEffect(() => {
      const chipsOptions: ChipOption[] = [];
      values?.forEach((value) => {
        const node = stateValuesMap[value];
        if (node)
          chipsOptions.push({
            value: node.value,
            label: node.label,
          });
      });
      setChipsList(chipsOptions);
    }, [values, loading, state, setChipsList, stateValuesMap]);

    return (
      <ThemeProvider theme={theme}>
        <Box>
          <Box
            className="absolute bottom-0 right-6"
            data-testid="hierarchy-select-loader"
          >
            {loading && <CircularProgress size={20} />}
          </Box>
          <Select
            data-testid="hierarchy-select"
            disabled={loading || disabled}
            open={isSelectOpen}
            MenuProps={{
              autoFocus: false,
              sx: {
                "& .MuiMenu-paper": {
                  boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.16) !important",
                  maxWidth: "min-content",
                },
              },
            }}
            value={selectedValues}
            multiple
            error={invalid}
            required={required}
            onBlur={onBlur}
            fullWidth
            displayEmpty
            onOpen={() => {
              !!setIsSelectOpen && setIsSelectOpen(true);
            }}
            onClose={handleSelectClose}
            renderValue={(values) =>
              hideValue ? "" : buildSelectedValuesString(values)
            }
            readOnly={readOnly}
          >
            <Box className="pt-16">
              <Box
                className="min-h-48 max-h-80 max-w-[327px] overflow-y-auto px-4 bg-dashboard_subheader__bg min-w-[100%]"
                sx={{ maxWidth: { xs: "200px" } }}
              >
                <Box className="absolute left-0 right-0 top-0 z-10 w-full bg-dashboard_subheader__bg p-4">
                  <TextField
                    size="medium"
                    autoFocus={false}
                    placeholder="Search"
                    fullWidth
                    data-testid="hierarchy-select-search"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon />
                        </InputAdornment>
                      ),
                    }}
                    onChange={debouncedUpdateHierarchyVisibility}
                  />
                </Box>
                <Box className=" pb-5 bg-dashboard_subheader__bg">
                  {state.map((item) => (
                    <Hierarchy
                      key={item.value}
                      item={item}
                      onValueChange={valueChangeHandler}
                      onExpandedStateChange={expandedStateChangeHandler}
                    />
                  ))}
                </Box>
              </Box>
            </Box>
          </Select>
        </Box>
      </ThemeProvider>
    );
  }
);
