import { GridColDef, GridValueGetterParams } from "@mui/x-data-grid-premium";
import {
  Dwell,
  DwellHistoricalEventHistory,
  SearchEventHistoryResultData,
  TableColumnFormat,
} from "../../../../../graphql/operations";
import {
  getTableColumn,
  TableColumnProps,
  TableGridColDef,
} from "../../../../../shared/components/Table";
import {
  columnTimezoneDateTimeFormatParams,
  columnTimezoneTimeFormatParams,
} from "../../../../../utils";
import { DwellGridColDef } from "../../Geofences/GeofenceDwellHistory/helpers";

export interface TableRowEventData extends Dwell {
  totalDwell?: any;
  dateTime?: any;
}

const getBaseColumn = (
  field: string,
  headerName: string,
  minWidth: number = 120,
  options?: Partial<GridColDef>
) => ({
  field,
  headerName,
  flex: 1,
  minWidth,
  cellClassName: "capitalize",
  ...options,
});

const getTimezoneColumn = (
  field: string,
  headerName: string,
  timezone: string,
  minWidth = 120,
  isDateTimeNeeded: boolean = true
) => {
  const dateField = isDateTimeNeeded
    ? columnTimezoneDateTimeFormatParams(timezone)
    : columnTimezoneTimeFormatParams(timezone);

  return {
    ...getBaseColumn(field, headerName, minWidth),
    ...dateField,
  };
};

export const getColumnsForDwellTabTable = (
  timezone: string
): TableGridColDef<DwellHistoricalEventHistory>[] => {
  const columns: TableColumnProps<DwellHistoricalEventHistory>[] = [
    {
      field: "geofenceName",
      headerName: "Name",
      options: {
        flex: 1,
        minWidth: 215,
      },
    },

    {
      field: "category",
      headerName: "Category",

      options: {
        flex: 1,
        cellClassName: "capitalize",

        minWidth: 130,
      },
    },
    {
      field: "arrivalTime",
      headerName: "Arrival Time",
      type: "date",
      format: TableColumnFormat.IsoDateTime,
      options: {
        flex: 1,
        minWidth: 180,
        ...columnTimezoneDateTimeFormatParams(timezone),
      },
    },
    {
      field: "currentCargoState",
      headerName: "Current Cargo State",
      options: {
        flex: 1,
        minWidth: 140,
        cellClassName: "capitalize",
        valueGetter: ({
          row,
        }: GridValueGetterParams<SearchEventHistoryResultData>) => {
          return row.currentCargoState === "empty" ? "Empty" : "Loaded";
        },
        sortable: true,
      },
    },
    {
      field: "dateTime",
      headerName: "Date and Time",
      type: "date",
      format: TableColumnFormat.IsoDateTime,
      options: {
        flex: 1,
        minWidth: 190,
        ...columnTimezoneDateTimeFormatParams(timezone),
      },
    },
    {
      field: "unloadDuration",
      headerName: "Unload Duration",
      type: "number",
      options: {
        filterable: false,
        flex: 1,
        minWidth: 140,
        valueGetter: ({
          row,
        }: GridValueGetterParams<SearchEventHistoryResultData>) => {
          const totalDwellInHours = row.unloadDuration / 3600;
          if (totalDwellInHours >= 24) {
            const totalDwellInDays = Math.floor(totalDwellInHours / 24);
            return `${totalDwellInDays} Days`;
          } else {
            return `${Math.round(totalDwellInHours)} Hours`;
          }
        },
      },
    },
    {
      field: "departureTime",
      headerName: "Departure Time",
      type: "date",
      format: TableColumnFormat.IsoDateTime,
      options: {
        flex: 1,
        minWidth: 180,
        ...columnTimezoneDateTimeFormatParams(timezone),
      },
    },
    {
      field: "arrivalCargoState",
      headerName: "Cargo Status Upon Arrival",
      options: {
        flex: 1,
        minWidth: 200,
        cellClassName: "capitalize",
        valueGetter: ({
          row,
        }: GridValueGetterParams<SearchEventHistoryResultData>) => {
          return row.arrivalCargoState === "empty" ? "Empty" : "Loaded";
        },
        sortable: true,
      },
    },
    {
      field: "departureCargoState",
      headerName: "Cargo Status Upon Exit",
      options: {
        flex: 1,
        minWidth: 200,
        cellClassName: "capitalize",
        valueGetter: ({
          row,
        }: GridValueGetterParams<SearchEventHistoryResultData>) => {
          return row.departureCargoState === "empty" ? "Empty" : "Loaded";
        },
        sortable: true,
      },
    },
    {
      field: "totalDwell",
      headerName: "Total Dwell",
      type: "number",
      options: {
        flex: 1,
        filterable: false,
        minWidth: 150,
        valueGetter: ({
          row,
        }: GridValueGetterParams<SearchEventHistoryResultData>) => {
          const totalDwellInDays = Math.floor(row.totalDwell);
          return `${totalDwellInDays} Days`;
        },
      },
    },
  ];

  return columns.map(getTableColumn);
};

export const columnVisibilityModel = {
  geofenceName: true,
  category: true,
  arrivalTime: true,
  currentCargoState: true,
  dateTime: true,
  unloadDuration: true,
  departureTime: true,
  arrivalCargoState: true,
  departureCargoState: true,
  totalDwell: true,
};

export const getColumns = (
  timezone: string,
  typeOfTable: string
): GridColDef<TableRowEventData>[] | DwellGridColDef[] => {
  const assetDwellColumns = [
    getBaseColumn("geofenceName", "Name", 215),
    getBaseColumn("category", "Category", 150),
    getTimezoneColumn("arrivalTime", "Arrival Time", timezone, 150, false),
    getBaseColumn("currentCargoState", "Current Cargo State", 150),
    getTimezoneColumn("dateTime", "Date and Time", timezone, 180),
    getBaseColumn("unloadDuration", "Unload Duration", 150, {
      renderCell: (option) => Math.round(option.value / 60) + "h",
    }),
    getTimezoneColumn("departureTime", "Departure Time", timezone, 150, false),
    getBaseColumn("arrivalCargoState", "Cargo Status Upon Arrival", 215),
    getBaseColumn("departureCargoState", "Cargo Status Upon Exit", 215),
    getBaseColumn("totalDwell", "Total Dwell", 150),
  ];

  const geofenceDwellColumns = [
    getBaseColumn("assetId", "Asset ID", 150),
    getBaseColumn("imei", "Imei", 150),
    getTimezoneColumn("timestamp", "Date And Time", timezone, 180),
    getBaseColumn("enteredAtDays", "Days In Detention", 150),
    getBaseColumn("detentionStatus", "Detention Status", 150),
    getTimezoneColumn("arrivalTime", "Entered At", timezone, 150, false),
    getTimezoneColumn("departureTime", "Exited At", timezone, 150, false),
    getTimezoneColumn(
      "unloadDuration",
      "Unload Duration",
      timezone,
      150,
      false
    ),
    getBaseColumn("arrivalCargoState", "Cargo Status On Enter", 150),
    getBaseColumn("departureCargoState", "Cargo Status On Exit", 150),
    getBaseColumn("totalDwell", "Total Dwell", 150),
  ];
  return typeOfTable === "asset" ? assetDwellColumns : geofenceDwellColumns;
};
