export function generateUniqueId() {
  const array = new Uint8Array(16);
  window.crypto.getRandomValues(array);

  const id = `id_${Array.from(array, (byte) =>
    byte.toString(36).padStart(2, "0")
  ).join("")}`;

  return id;
}
