import React, {
  FC,
  memo,
  MutableRefObject,
  useRef,
  useState,
  useEffect,
} from "react";
import { useWatch } from "react-hook-form";
import { CheckboxElement, SwitchElement } from "react-hook-form-mui";
import {
  Box,
  Divider,
  Grid,
  Select,
  ThemeProvider,
  Typography,
} from "@mui/material";
import { ReportScheduleFrequency } from "../../../../graphql/operations";
import { SelectedValues } from "../../../../shared/components/HierarchySelect/types";
import { SelectElement } from "../../../../shared/components/react-hook-form-mui/SelectElement";
import { unitOptions } from "../../constants";
import { useRemindersTheme } from "../../hooks/useRemindersTheme";
import { buildUintIntervalOptions } from "../../utils";

export type AlertRemindersSelectProps = {
  loading?: boolean;
  values: SelectedValues;
  onChange: (values: ReportScheduleFrequency) => void;
  onBlur: () => void;
  required?: boolean;
  invalid?: boolean;
  form: any;
};

export const AlertRemindersSelect: FC<AlertRemindersSelectProps> = memo(
  ({ values, onBlur, required, invalid, form }: AlertRemindersSelectProps) => {
    const theme = useRemindersTheme();
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const select: MutableRefObject<HTMLInputElement | null | undefined> =
      useRef();
    const formValues = form.watch();
    const handleClose = () => {
      onBlur();
      setIsOpen(false);
    };
    const [frequencySelectIsActive, setFrequencySelectIsActive] =
      useState<boolean>(false);

    // Prepare the string to be displayed in the select box
    const buildSelectedValuesString = () => {
      const values = form.getValues();

      const interval = values?.reminder?.schedule?.interval || "";
      const unit = values?.reminder?.schedule?.unit || "";
      return `${interval} ${unit.length ? unit.slice(0, -1) + "(s)" : unit}`;
    };

    useEffect(() => {
      const unit = formValues?.reminder?.schedule?.unit || "";
      setFrequencySelectIsActive(!!unit);
    }, [formValues]);

    const selectWidth = select?.current?.clientWidth;

    const watchUnits = useWatch({
      name: "reminder.schedule.unit",
      control: form.control,
    });
    const watchIntervals = useWatch({
      name: "reminder.schedule.interval",
      control: form.control,
    });

    const showNote = !!watchUnits && !!watchIntervals;
    const noteText = `Note: this alert will run every ${watchIntervals} ${
      watchUnits?.slice(0, -1) + "(s)"
    }.`;

    const intervalOptions = buildUintIntervalOptions(watchUnits);

    return (
      <ThemeProvider theme={theme}>
        <Box>
          <Select
            data-testid="alert-reminders-select"
            MenuProps={{ autoFocus: false }}
            value={values}
            error={invalid}
            required={required}
            onBlur={onBlur}
            multiple
            fullWidth
            displayEmpty
            open={isOpen}
            onOpen={() => setIsOpen(true)}
            onClose={handleClose}
            renderValue={() => buildSelectedValuesString()}
            ref={select}
          >
            <Box
              sx={{
                width: selectWidth ? `${selectWidth}px` : "320px",
              }}
            >
              <Grid container className="p-4" data-testid="alert-reminders">
                <Grid
                  item
                  xs={12}
                  className="!mb-2"
                  data-testid="alert-reminders-receiveWhenTriggerActive"
                >
                  <CheckboxElement
                    name="reminder.schedule.props.receiveWhenTriggerActive"
                    control={form.control}
                    label="Receive Only When Trigger is Active"
                    labelProps={{
                      slotProps: { typography: { fontSize: "14px" } },
                    }}
                  />
                </Grid>
                <Divider className="w-full !mb-6" />
                <Typography className="!text-sm !font-bold !mb-2.5">
                  Repeat
                </Typography>
                <Grid item xs={12} className="!mb-4">
                  <SelectElement
                    name="reminder.schedule.unit"
                    label="Unit of Measure"
                    placeholder="reminders-units"
                    labelClassName="!text-[10px] !font-medium"
                    handleChange={() => {
                      form.setValue("reminder.schedule.interval", null);
                    }}
                    control={form.control}
                    options={unitOptions}
                    required={required}
                    rules={{ required: true }}
                  />
                </Grid>
                <Divider className="w-full !mb-4" />
                <Typography className="!text-sm !font-bold !mb-4">
                  Frequency
                </Typography>
                <Grid item xs={12} className={!showNote ? "!mb-4" : "!mb-2"}>
                  <SelectElement
                    name="reminder.schedule.interval"
                    label="Intervals"
                    placeholder="reminders-intervals"
                    labelClassName="!text-[10px] !font-medium"
                    control={form.control}
                    options={intervalOptions}
                    required={required}
                    rules={{ required: true }}
                    disabled={!frequencySelectIsActive}
                  />
                  {!frequencySelectIsActive && (
                    <Typography className="!text-[10px] !font-medium !mb-1">
                      Please choose Unit of Measure first
                    </Typography>
                  )}
                </Grid>
                <Typography className="!text-[10px] !font-medium !mb-6">
                  {showNote && watchIntervals && noteText}
                </Typography>
                <Divider className="w-full !mb-8" />
                <Grid
                  container
                  className="items-baseline justify-between !mb-9"
                >
                  <Grid item xs={9}>
                    <Typography className="!font-normal !text-sm">
                      Send reminder when parked
                    </Typography>
                  </Grid>
                  <Grid item data-testid="alert-schedule-sendWhenParked">
                    <SwitchElement
                      sx={{ marginRight: "0px !important" }}
                      label=""
                      name="reminder.schedule.props.sendWhenParked"
                      control={form.control}
                    />
                  </Grid>
                </Grid>
                <Grid
                  container
                  className="items-baseline justify-between !mb-9"
                >
                  <Grid item xs={9}>
                    <Typography className="!font-normal !text-sm">
                      Send reminder when in trip status is active
                    </Typography>
                  </Grid>
                  <Grid item data-testid="alert-schedule-sendWhenActive">
                    <SwitchElement
                      sx={{ marginRight: "0px !important" }}
                      label=""
                      name="reminder.schedule.props.sendWhenActive"
                      control={form.control}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          </Select>
        </Box>
      </ThemeProvider>
    );
  }
);
