import { GridValueGetterParams } from "@mui/x-data-grid-premium";
import { round } from "lodash";
import {
  AlertHistoryTableData,
  TableColumnFormat,
} from "../../../../graphql/operations";
import { BatteryIcon } from "../../../../shared/components/BatteryIcon";
import { DoorTypeLabel } from "../../../../shared/components/DoorTypeLabel/DoorTypeLabel";
import {
  TableColumnProps,
  TableGridColDef,
  getTableColumn,
} from "../../../../shared/components/Table";
import {
  columnTimezoneDateTimeFormatParams,
  imeiValueFormatter,
} from "../../../../utils";
import { stripNAValue } from "../../../AssetsView/TableView/assetUtils";
import { AlertNotificationModal } from "../../../AssetsView/TableView/components";
import AssetIcon from "../../../AssetsView/TableView/components/AssetIcon/AssetIcon";
import { getIconType } from "../../../AssetsView/TableView/components/AssetIcon/utils";
import type { AlertHistoryMap } from "./AlertHistoryTable";

export const columnVisibilityModel = {
  issue: true,
  trigger: true,
  orgName: true,
  icon: true,
  batteryVoltage: true,
  assetIdentifier: true,
  name: true,
  imei: true,
  prdName: true,
  lastEventDate: true,
  deviceAssociated: true,
  tripStatus: true,
  city: true,
  state: true,
  postcode: true,
  vin: true,
  longitude: true,
  latitude: true,
  category: true,
  tags: true,
  wheelConfig: true,
  numberOfAxles: true,
  length: true,
  doorType: true,
  lastGpsDate: true,
  assetName: true,
  assetType: true,
  primaryVoltage: true,
  secondaryVoltage: true,
};

export const trimmedSorting = (a: string, b: string) =>
  a.trim().localeCompare(b.trim());

enum IconTypeComplete {
  EMPTY,
  MOVING,
  LOW_DWELL,
  MEDIUM_DWELL,
  HIGH_DWELL,
  MEGA_DWELL,
  MOVING_ONLINE,
  LOW_DWELL_ONLINE,
  MEDIUM_DWELL_ONLINE,
  HIGH_DWELL_ONLINE,
  MEGA_DWELL_ONLINE,
  MOVING_EMPTY,
  LOW_DWELL_EMPTY,
  MEDIUM_DWELL_EMPTY,
  HIGH_DWELL_EMPTY,
  MEGA_DWELL_EMPTY,
  MOVING_ONLINE_EMPTY,
  LOW_DWELL_ONLINE_EMPTY,
  MEDIUM_DWELL_ONLINE_EMPTY,
  HIGH_DWELL_ONLINE_EMPTY,
  MEGA_DWELL_ONLINE_EMPTY,
  MOVING_LOADED,
  LOW_DWELL_LOADED,
  MEDIUM_DWELL_LOADED,
  HIGH_DWELL_LOADED,
  MEGA_DWELL_LOADED,
  MOVING_ONLINE_LOADED,
  LOW_DWELL_ONLINE_LOADED,
  MEDIUM_DWELL_ONLINE_LOADED,
  HIGH_DWELL_ONLINE_LOADED,
  MEGA_DWELL_ONLINE_LOADED,
}

export const getIconValue = (
  params: GridValueGetterParams<AlertHistoryMap>
) => {
  const iconType = getIconType(
    params.row.tripStatus,
    params.row.dwelling?.dwellingDays,
    params.row.signal,
    params.row.cargoState
  );
  const value = IconTypeComplete[iconType as keyof typeof IconTypeComplete];
  return value;
};

export const getColumns = (
  timezone: string
): TableGridColDef<AlertHistoryTableData>[] => {
  const columns: TableColumnProps<AlertHistoryTableData>[] = [
    { field: "trigger", headerName: "Trigger" },
    {
      field: "orgName",
      headerName: "Organization Name",
      options: {
        filterable: false,
      },
    },
    {
      field: "alertNotification", // TODO: is field name correct ?
      headerName: "Alert Notification",
      options: {
        renderCell: (params) => {
          return (
            <AlertNotificationModal
              alertNotificationId={String(params.row.incidentId)}
            />
          );
        },
        filterable: false,
        sortable: false,
      },
    },
    {
      field: "icon", // TODO: is field name correct ?
      headerName: "Dwell Status",
      options: {
        groupable: false,
        valueGetter: getIconValue,
        renderCell: (params) => (
          <AssetIcon
            dwellingDays={Number(params.row.dwellingDays)}
            signal={Boolean(params.row.signal)}
            cargo_state={String(params.row.cargoState)}
            trip_st={String(params.row.tripStatus)}
          />
        ),
        disableExport: true,
        filterable: false,
      },
    },
    {
      field: "batteryVoltage",
      headerName: "Battery",
      options: {
        groupable: false,
        renderCell: (params) => (
          <BatteryIcon
            chargeLevel={params.value ? round(params.value, 2) : undefined}
            svgProps={{ width: "24px", height: "24px" }}
            status={params.row.batteryStatus ? params.row.batteryStatus : ""}
          />
        ),
        disableExport: true,
        filterable: false,
      },
    },
    {
      field: "assetIdentifier",
      headerName: "Asset ID",
      options: {
        groupable: false,
        valueFormatter: imeiValueFormatter,
        sortComparator: trimmedSorting,
      },
    },
    {
      field: "assetName",
      headerName: "Asset Nickname",
      options: {
        valueFormatter: imeiValueFormatter,
        sortComparator: trimmedSorting,
      },
    },
    {
      field: "imei",
      headerName: "Device ID",
      options: {
        groupable: false,
        valueFormatter: imeiValueFormatter,
      },
    },
    {
      field: "prdName",
      headerName: "Product Name",
    },
    {
      field: "lastEventDate",
      headerName: "Last Reported Date",
      type: "date",
      format: TableColumnFormat.IsoDateTime,
      options: {
        ...columnTimezoneDateTimeFormatParams(timezone),
      },
    },
    {
      field: "deviceAssociated",
      headerName: "Associated",
      options: {
        filterOperators: [
          {
            label: "Yes",
            value: "isNotEmpty",
            getApplyFilterFn: (filterItem, column) => null,
          },
          {
            label: "No",
            value: "isEmpty",
            getApplyFilterFn: (filterItem, column) => null,
          },
        ],
      },
    },
    {
      field: "tripStatus",
      headerName: "Trip Status",
      options: {
        valueGetter: (params) => stripNAValue(params.value) ?? "",
      },
    },
    { field: "city", headerName: "City" },
    { field: "state", headerName: "State" },
    { field: "postcode", headerName: "Zip" },
    { field: "assetType", headerName: "Asset Type" },
    { field: "tags", headerName: "Asset Tags" },
    {
      field: "latitude",
      headerName: "Latitude",
      type: "number",
      options: {
        groupable: false,
        valueGetter: (params) => params.row.latitude,
      },
    },
    {
      field: "longitude",
      headerName: "Longitude",
      type: "number",
      options: {
        groupable: false,
        valueGetter: (params) => params.row.longitude,
      },
    },
    { field: "vin", headerName: "VIN" },
    {
      field: "wheelConfig",
      headerName: "# of Tires",
      type: "number",
      options: {
        valueGetter: (params) => {
          const value = stripNAValue(params.value);
          if (value) {
            return Number.parseInt(value, 10);
          }
          return undefined;
        },
      },
    },
    {
      field: "numberOfAxles",
      headerName: "# of Axles",
      type: "number",
      options: {
        valueGetter: (params) => {
          const value =
            params.value === -1 ? undefined : stripNAValue(params.value);
          if (value) {
            return Number.parseInt(value, 10);
          }
          return undefined;
        },
      },
    },
    {
      field: "length",
      headerName: "Length",
      type: "number",
      options: {
        renderCell: (params) =>
          !params.value || params.value === -1 ? "" : params.value + "'",
      },
    },
    {
      field: "doorType",
      headerName: "Door Type",
      options: {
        renderCell: (params) => <DoorTypeLabel value={params.value} />,
      },
    },
    {
      field: "lastGpsDate",
      headerName: "GPS Time",
      type: "date",
      format: TableColumnFormat.IsoDateTime,
      options: {
        ...columnTimezoneDateTimeFormatParams(timezone),
      },
    },
    {
      field: "primaryVoltage",
      headerName: "Primary Voltage",
      type: "number",
    },
    {
      field: "secondaryVoltage",
      headerName: "Secondary Voltage",
      type: "number",
    },
  ];

  return columns.map((column) => getTableColumn(column));
};
