import { CircularProgress, Skeleton } from "@mui/material";
import Button, { ButtonProps } from "../../../shared/components/Button/Button";
import { useAuthenticationViewBrand } from "../hooks/useAuthenticationViewBrand";

const authButtonSx = {
  width: "100%",
  height: "38px !important",
};

export const AuthButton: React.FC<ButtonProps> = (props) => {
  const brand = useAuthenticationViewBrand();
  const isGhostType = props.variant === "ghost";

  return !brand ? (
    <Skeleton
      variant="rounded"
      animation="wave"
      data-testid={"auth-button-skeleton"}
      sx={authButtonSx}
    />
  ) : (
    <Button
      variant="default"
      sx={{
        ...authButtonSx,
        backgroundColor: isGhostType
          ? "transparent"
          : `${brand.color} !important`,
        width: "100%",
        fontSize: "14px !important",
      }}
      iconPosition="right"
      icon={<CircularProgress size={15} style={{ color: "white" }} />}
      {...props}
    />
  );
};
