import React from "react";
import { ThemeProvider } from "@mui/material";
import { TooltipProps } from "recharts";
import { useTooltipTheme } from "../../../../../../../shared/components/CustomTooltip/useTooltipTheme";

const formatValue = (value: number) => {
  return (Math.floor(value * 10) / 10).toFixed(1);
};

export const CustomTooltip = ({
  active,
  payload,
  label,
}: TooltipProps<number, string>) => {
  const theme = useTooltipTheme();
  if (active && payload?.length) {
    return (
      <ThemeProvider theme={theme}>
        <div
          className="custom-tooltip"
          style={{
            backgroundColor: theme.palette.background.paper,
            border: `1px solid ${theme.palette.divider}`,
            padding: "10px",
            color: theme.palette.text.primary,
          }}
        >
          <p className="label">{`Date: ${label}`}</p>
          <p className="intro">{`Assets: ${formatValue(
            payload[1]?.value ?? 0
          )}`}</p>
          <p className="intro">{`Dwelling: ${formatValue(
            payload[0]?.value ?? 0
          )}`}</p>
        </div>
      </ThemeProvider>
    );
  }

  return null;
};
