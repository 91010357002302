import { FC, useEffect, useState } from "react";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorIcon from "@mui/icons-material/Error";
import WarningIcon from "@mui/icons-material/Warning";
import { Box, Typography } from "@mui/material";
import { ColorsPalette } from "../../../../../design-system/colors-palette";
import { BatteryIcon } from "../../../../../shared/components/BatteryIcon";
import AssetIcon from "../../../TableView/components/AssetIcon/AssetIcon";

interface GeofenceHistoryTileProps {
  type: GeofenceTileTypes;
  valueOne: string;
  valueTwo: string;
}

export enum GeofenceTileTypes {
  AverageAssetsPresent = "AverageAssetsPresent",
  AssetsWereHealthy = "AssetsWereHealthy",
  AssetsAreLoaded = "AssetsAreLoaded",
  AverageAssetsInFreePeriod = "AverageAssetsInFreePeriod",
  AverageAssetsInDetention = "AverageAssetsInDetention",
  AverageAssetsInPostDetention = "AverageAssetsInPostDetention",
  UnknownType = "UnknownType",
}

export const GeofenceHistoryTile: FC<GeofenceHistoryTileProps> = ({
  type,
  valueOne,
  valueTwo,
}) => {
  const [titleState, setTitleState] = useState("");
  const [dayTimeState, setDayTimeState] = useState("assets");
  const [isLoaded, setIsLoaded] = useState("noLoaded");
  const [isParked, setIsParked] = useState("pass");
  const [isPercentage, setIsPercentage] = useState(false);
  const [isBattery, setIsBattery] = useState(false);

  useEffect(() => {
    switch (type) {
      case GeofenceTileTypes.AverageAssetsPresent:
        setTitleState("Average Assets Present");
        setDayTimeState("assets");
        setIsLoaded("loaded");
        break;
      case GeofenceTileTypes.AssetsWereHealthy:
        setTitleState("Assets Were Healthy");
        setDayTimeState("of the time");
        setIsPercentage(true);
        setIsBattery(true);
        break;
      case GeofenceTileTypes.AssetsAreLoaded:
        setTitleState("Assets Are Loaded");
        setDayTimeState("of the time");
        setIsLoaded("loaded");
        setIsParked("Moving");
        setIsPercentage(true);
        break;
      case GeofenceTileTypes.AverageAssetsInFreePeriod:
        setTitleState("Average Assets in Free Period");
        setDayTimeState("assets");
        setIsLoaded("loaded");
        setIsParked("Moving");
        setIsPercentage(false);
        break;
      case GeofenceTileTypes.AverageAssetsInDetention:
        setTitleState("Average Assets in Detention");
        setDayTimeState("");
        setIsLoaded("loaded");
        setIsParked("Moving");
        setIsPercentage(false);
        break;
      case GeofenceTileTypes.AverageAssetsInPostDetention:
        setTitleState("Average Assets in Post Detention");
        setDayTimeState("");
        setIsLoaded("loaded");
        setIsParked("Moving");
        setIsPercentage(false);
        break;
      default:
        console.log("Haven't data to render Tile");
    }
  }, [type]);

  const getIcon = (geofenceType: GeofenceTileTypes) => {
    switch (geofenceType) {
      case GeofenceTileTypes.AverageAssetsInFreePeriod:
        return <CheckCircleIcon color="success" />;
      case GeofenceTileTypes.AverageAssetsInDetention:
        return <WarningIcon style={{ color: ColorsPalette.Caution }} />;
      case GeofenceTileTypes.AverageAssetsInPostDetention:
        return <ErrorIcon color="warning" />;
      case GeofenceTileTypes.AverageAssetsPresent:
      case GeofenceTileTypes.AssetsAreLoaded:
      default:
        return (
          <AssetIcon
            dwellingDays={12}
            signal={true}
            cargo_state={isLoaded}
            trip_st={isParked}
            svgProps={{ height: 24, width: 24 }}
          />
        );
    }
  };

  return (
    <Box
      className="grid text-start gap-2 text-typography-tooltip p-4 сursor-pointer grow"
      style={{
        backgroundColor: "var(--gallery-cards-background)",
        borderRadius: "8px",
      }}
    >
      <Box className="flex flex-start ">
        <div className="flex items-end mr-2" data-testid="asset-icon">
          {isBattery ? (
            <BatteryIcon
              chargeLevel={12}
              svgProps={{ width: "24px", height: "24px" }}
              status={"healthy"}
            />
          ) : (
            getIcon(type)
          )}
        </div>
        <Box
          mt={2}
          sx={{
            color: "var(--Brand-Colors-Primary-Blue, #3C88CC)",
            fontSize: "18px",
            fontStyle: "normal",
            fontWeight: 600,
            lineHeight: "26px",
            letterSpacing: "-0.18px",
            textTransform: "capitalize",
          }}
        >
          {titleState}
        </Box>
      </Box>
      <Box
        display="flex"
        justifyContent="flex-start"
        mb={2}
        sx={{
          "@media (min-width: 1105px)": {
            minHeight: "86px",
          },
          "@media (min-width: 1280px)": {
            minHeight: "86px",
          },
          "@media (min-width: 1640px)": {
            minHeight: "56px",
          },
          "@media (min-width: 1920px)": {
            minHeight: "56px",
          },
          "@media (min-width: 2560px)": {
            minHeight: "56px",
          },
        }}
      >
        <Box textAlign="left" flex={1}>
          <Typography
            variant="body2"
            sx={{ fontWeight: 400, color: "var(--impersonate-container)" }}
          >
            30 D
          </Typography>
          <Typography
            variant="h4"
            component="div"
            sx={{
              fontWeight: 600,
              display: "inline",
              color: "var(--impersonate-container)",
            }}
          >
            {valueOne}
            {isPercentage && "%"}
          </Typography>
          <Typography
            variant="body2"
            color="textSecondary"
            sx={{
              display: "inline",
              marginLeft: "4px",
              fontWeight: 400,
              color: "var(--impersonate-container)",
            }}
          >
            {dayTimeState}
          </Typography>
        </Box>
        <Box textAlign="left" flex={1}>
          <Typography
            variant="body2"
            sx={{ fontWeight: 400, color: "var(--impersonate-container)" }}
          >
            365 D
          </Typography>
          <Typography
            variant="h4"
            component="div"
            sx={{
              fontWeight: 600,
              display: "inline",
              color: "var(--impersonate-container)",
            }}
          >
            {valueTwo}
            {isPercentage && "%"}
          </Typography>
          <Typography
            variant="body2"
            color="textSecondary"
            sx={{
              display: "inline",
              marginLeft: "4px",
              fontWeight: 400,
              color: "var(--impersonate-container)",
            }}
          >
            {dayTimeState}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};
