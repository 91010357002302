import { useCallback } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { isNil, omitBy } from "lodash";
import * as yup from "yup";
import { DistanceUnit, PressureUnit } from "../../../graphql/operations";
import { FormFieldDropdownOption } from "../../../types";
import {
  DATE_FORMAT_GLUED,
  formatDate,
  parseFileContent,
  ParseFileContentError,
  transformers,
} from "../../../utils";
import { BatchFormFieldsNames } from "../../BatchesView/BatchManagementUtils";

export interface CustomerOrgsUploadData {
  name: string;
  account_number: string;
  type: string;
  time_zones: string;
  distance_unit_preference: DistanceUnit;
  pressure_unit_preference: PressureUnit;
  temperature_unit_preference: string;
}

export type ValidatedOrg = {
  org: CustomerOrgsUploadData;
  row: number;
};

export const CustomerOrgsUploadSchema = yup.object().shape({
  [BatchFormFieldsNames.BatchName]: yup
    .string()
    .required("Batch Name is required")
    .transform(transformers.string),
  [BatchFormFieldsNames.ParentOrganization]: yup
    .object()
    .required("Parent Organization Name is required")
    .transform(transformers.string),
});

export const getCustomerOrgsUploadDataSchema = (
  organizationTypes: FormFieldDropdownOption[],
  timeZones: FormFieldDropdownOption[],
  distanceUnits: FormFieldDropdownOption[],
  pressureUnits: FormFieldDropdownOption[],
  temperatureUnits: FormFieldDropdownOption[]
) => {
  return yup.object().shape({
    name: yup
      .string()
      .required("Name is required")
      .transform(transformers.string),
    account_number: yup
      .string()
      .required("Account Number is required")
      .transform((value) => transformers.string(value.replace(/\s+/g, ""))),
    type: yup
      .mixed<string>()
      .required("Type is required")
      .transform(
        (value) =>
          organizationTypes.find((unit) => unit.label === value)?.value ?? value
      )
      .test("isValidType", "Type is not one of the accepted values", (value) =>
        Boolean(organizationTypes.find((type) => type.value === value))
      ),
    time_zones: yup
      .mixed<string>()
      .required("Time Zone is required")
      .transform(
        (value) =>
          timeZones.find((unit) => unit.label === value)?.value ?? value
      )
      .test(
        "isValidTimeZone",
        "Time Zone is not one of the accepted values",
        (value) => Boolean(timeZones.find((type) => type.value === value))
      ),
    distance_unit_preference: yup
      .mixed<string>()
      .required("Distance Unit is required")
      .transform(
        (value) =>
          distanceUnits.find((unit) => unit.label === value)?.value ?? value
      )
      .test(
        "isValid",
        "Distance Unit Preference is not one of the accepted values",
        (value) => Boolean(distanceUnits.find((type) => type.value === value))
      ),
    pressure_unit_preference: yup
      .mixed<string>()
      .required("Pressure Unit is required")
      .transform(
        (value) =>
          pressureUnits.find((unit) => unit.label === value)?.value ?? value
      )
      .test(
        "isValidType",
        "Pressure Unit Preference is not one of the accepted values",
        (value) => Boolean(pressureUnits.find((type) => type.value === value))
      ),
    temperature_unit_preference: yup
      .mixed<string>()
      .required("Temperature Unit is required")
      .transform(
        (value) =>
          temperatureUnits.find((unit) => unit.label === value)?.value ?? value
      )
      .test(
        "isValidType",
        "Temperature Unit Preference is not one of the accepted values",
        (value) =>
          Boolean(temperatureUnits.find((type) => type.value === value))
      ),
  });
};

export const useOrgsBatchUploadForm = (action: string, orgName?: string) => {
  const batch_name = orgName
    ? `${orgName} ${action} - ${formatDate(new Date(), DATE_FORMAT_GLUED)}`
    : "";
  const form = useForm({
    resolver: yupResolver(CustomerOrgsUploadSchema),
    values: omitBy(
      {
        [BatchFormFieldsNames.BatchName]: batch_name,
        [BatchFormFieldsNames.ParentOrganization]: "",
      },
      isNil
    ),
  });

  const getValues = useCallback(
    () => CustomerOrgsUploadSchema.cast(form.getValues(), { assert: false }),
    [form]
  );

  return { form, getValues, CustomerOrgsUploadSchema };
};

export const parseOrgFiles = async (
  file: File
): Promise<{
  orgs: CustomerOrgsUploadData[];
  errors: ParseFileContentError[];
}> => {
  const map: { [key: string]: keyof CustomerOrgsUploadData } = {
    "Name*": "name",
    "Account Number*": "account_number",
    "Type*": "type",
    "Time Zone*": "time_zones",
    "Distance Unit Preference*": "distance_unit_preference",
    "Pressure Unit Preference*": "pressure_unit_preference",
    "Temperature Unit Preference*": "temperature_unit_preference",
  };

  try {
    const { data, errors } = await parseFileContent<CustomerOrgsUploadData>({
      file,
      map,
      dynamicTyping: false,
    });
    return { orgs: data, errors };
  } catch (error) {
    let message = "Error parsing file.";
    if (error instanceof Error) {
      message = error.message;
    }
    return { orgs: [], errors: [{ row: 0, message }] };
  }
};

export const mapAndValidateCustomerOrgFile = (
  data: CustomerOrgsUploadData[],
  organizationTypes: FormFieldDropdownOption[],
  timeZones: FormFieldDropdownOption[],
  distanceUnits: FormFieldDropdownOption[],
  pressureUnits: FormFieldDropdownOption[],
  temperatureUntis: FormFieldDropdownOption[]
) => {
  const errors: ParseFileContentError[] = [];
  const customerOrgSchema = getCustomerOrgsUploadDataSchema(
    organizationTypes,
    timeZones,
    distanceUnits,
    pressureUnits,
    temperatureUntis
  );

  if (!data.length) {
    errors.push({ row: 0, message: "Uploaded file is empty" });
  }

  const mapped = data.map((fileData, index) => {
    const row = index + 1; // Add 1 to index to get row number

    try {
      const org = customerOrgSchema.validateSync(fileData, {
        abortEarly: false,
      });

      return org;
    } catch (error: any) {
      if (error.inner) {
        error.inner.forEach((err: yup.ValidationError) => {
          errors.push({
            row,
            message: err.message ?? "Error validating Customer Organization",
          });
        });
      } else {
        errors.push({
          row,
          message:
            error instanceof Error
              ? error.message
              : "Error validating Customer Organization",
        });
      }

      return null;
    }
  });

  return {
    validatedOrgs: mapped.filter((org) => Boolean(org)),
    validationErrors: errors,
  };
};
