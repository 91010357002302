import { memo, useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  SET_APP_CONFIG,
  SET_PARENT_GEOFENCE,
  USE_PARENT_GEOFENCE_ORGID,
} from "../../../constants";
import { useAppContext } from "../../../context/AppContext";
import { TableFiltersInput } from "../../../graphql/operations";
import { useAssetsClustersCoordinatesData } from "../../../shared/hooks/openSearchMongoPolyfillHooks/useGetAssetsClustersCoordinatesData";
import { useAssetsClustersData } from "../../../shared/hooks/openSearchMongoPolyfillHooks/useGetAssetsClustersData";
import { useOrgsDownwardHierarchy } from "../../../shared/hooks/openSearchMongoPolyfillHooks/useOrgsDownwardHierarchy";
import { useTheme } from "../../../shared/hooks/theme/useTheme";
import { useUserData } from "../../../shared/hooks/useUserData";
import { useFeatureFlag } from "../../../utils";
import { FeatureFlags } from "../../../utils/featureFlagsConstants";
import { NavigationRoutes } from "../../../utils/routes/routesUtils";
import { mapAssetFilters } from "../TableView/components/AssetsTable/utils";
import {
  DEFAULT_VIEWPORT_BBOX_COORDINATES,
  splitViewportAtMeridian,
} from "../helpers/helpers";
import { PageTypes, useAssetsDataContext } from "../shared/AssetsDataContext";
import { useAssetsComplexFilters } from "../shared/hooks/useAssetsComplexFilters";
import { AssetPagesUrlTypes } from "../utils";
import GeofenceDashboard from "./Geofences/GeofenceDashboard/GeofenceDashboard";
import GoogleMapComponent, {
  getDefaultMapViewport,
} from "./GoogleMapComponent";

const MapView = memo(() => {
  const { theme } = useTheme();
  const navigate = useNavigate();
  const { id } = useParams();
  const {
    dispatch,
    state: {
      selectedOrganization: { selectedOrganization },
    },
  } = useAppContext();

  const {
    assetsSearchInput,
    currentFilter: { complexFilters, complexFiltersMongodb },
    filters,
    currentFilter,
    isDrawingGeofence,
    currentGeofenceFilter,
    isGeofenceDashboardVisible,
    isGeofencesFetching,
    isGeofencesFetchingSuccess,
    isGeofencesLoading,
    lastFetchedAllCoordinatesForOrg,
    pageType,
    searchParams,
    selectedGeofence,
    showDashboard,
    setDetailId,
    setSelectedAssetId,
    setSelectedGeofence,
    setDrawnGeofenceArea,
    setDrawnGeofenceType,
    setGeofenceForUpdate,
    setIsDrawingGeofence,
    setShouldRecenterMap,
    onChangeGeofenceFilters,
    setDrawnGeofenceAreaInSqKm,
    resetGeofenceFiltersToDefault,
    setIsGeofenceDashboardVisible,
    restoreGeofencePreviousFilters,
    setIsLoadingAllAssetsCoordinates,
    setIsFiltersDrawerOpen,
    setAddGeofenceDrawerOpen,
    setIsAssetsDrawerOpen,
    setIsAssetsDrawerVisible,
    setIsFiltersDrawerVisible,
    setShouldFitBounds,
    setShouldShowFiltersStripAndViewToggle,
  } = useAssetsDataContext();
  const userData = useUserData();

  // TODO: Cleanup with PRJIND-9218
  const fetchAssetsFromOpenSearchFeatureFlag = useFeatureFlag(
    FeatureFlags.Connect1FetchAssetsFromOpenSearch
  );

  const [firstRenderState, setFirstRenderState] = useState<boolean>(false);
  const [loadingIndicatorState, setLoadingIndicatorState] = useState(false);
  const [tableFilters, setTableFilters] = useState<{
    filterList: TableFiltersInput | undefined;
  }>();

  const orgs = useOrgsDownwardHierarchy();

  useEffect(() => {
    const filters = mapAssetFilters(
      currentFilter,
      userData,
      orgs,
      selectedOrganization,
      fetchAssetsFromOpenSearchFeatureFlag,
      currentFilter?.sharedGroupName?.type
    );
    if (JSON.stringify(filters) !== JSON.stringify(tableFilters?.filterList)) {
      setTableFilters({ filterList: filters });
    }
  }, [
    selectedOrganization,
    currentFilter,
    assetsSearchInput,
    userData,
    fetchAssetsFromOpenSearchFeatureFlag,
    tableFilters,
    orgs,
  ]);

  useEffect(() => {
    const indexOfCurrentOrgInFilter = currentGeofenceFilter?.orgIds.findIndex(
      ({ id }) => id.toString() === selectedOrganization?.value.toString()
    );
    const currentFilterDoesNotIncludeCurrentOrg = indexOfCurrentOrgInFilter < 0;

    // Only trigger change of filters if they're not already set correctly to prevent looping
    if (currentFilterDoesNotIncludeCurrentOrg)
      onChangeGeofenceFilters({
        orgIds: selectedOrganization.value
          ? [
              {
                id: selectedOrganization.value,
                label: selectedOrganization.label,
              },
            ]
          : [],
      });
  }, [selectedOrganization, currentGeofenceFilter, onChangeGeofenceFilters]);

  //------------------------------
  // useGetAssetsClustersQuery
  //------------------------------
  const zoomLevelUrlParam = searchParams.get("zoomLevel");
  const zoom = zoomLevelUrlParam
    ? { zoom: parseFloat(zoomLevelUrlParam) }
    : { zoom: 3 }; //default init map loading

  const viewport = useMemo(() => {
    const viewportFromUrl = JSON.parse(searchParams.get("viewport") ?? "[]");
    return viewportFromUrl?.length
      ? { viewport: viewportFromUrl }
      : { viewport: getDefaultMapViewport() };
  }, [searchParams]);

  const processedViewport = useMemo(() => {
    if (viewport.viewport) {
      return splitViewportAtMeridian(viewport.viewport);
    }
    return null;
  }, [viewport.viewport]);

  const { complexFiltersQuery } = useAssetsComplexFilters();

  // Fetch only the coordinates for all assets of the current organization
  /*
    There is a problem here with organizations with lots of assets
    around the world because we can't fit the entire world in
    a single polygon in order to fetch absolutely everything.

    Also because our zoom level is limited to 3 we cannot show
    all assets/clusters because we're focused on the middle of a big polygon.

    The above problem is especially prominent on mobile where the map
    is not wide enough.
  */
  const { refetch: refetchAllAssetsCoordinatesData } =
    useAssetsClustersCoordinatesData(
      {
        ...(fetchAssetsFromOpenSearchFeatureFlag ? tableFilters : filters),
        complexFilters: JSON.stringify(complexFiltersQuery),
        search: "",
        cluster: true,
        zoom: 3,
        viewport: DEFAULT_VIEWPORT_BBOX_COORDINATES,
        limit: 100,
      },
      {
        keepPreviousData: true,
        enabled: false, // Only execute the query lazily
      }
    );

  const refetchCoordinatesAndCenterMap = useCallback(
    (doNotRefetch: boolean = false) => {
      // Set loading to true to ensure we don't recenter before the request starts
      setIsLoadingAllAssetsCoordinates(true);
      setShouldRecenterMap(true);
      if (!doNotRefetch) refetchAllAssetsCoordinatesData();
    },
    [
      setIsLoadingAllAssetsCoordinates,
      setShouldRecenterMap,
      refetchAllAssetsCoordinatesData,
    ]
  );
  // Trigger refetching of the coordinates only when needed
  useEffect(() => {
    // Only run this after first render (the first render case is handled by the next useEffect)

    if (firstRenderState) {
      // When the organization changes - fetch the new coordinates but do not force recenter
      refetchAllAssetsCoordinatesData();
    }
  }, [
    firstRenderState,
    selectedOrganization,
    lastFetchedAllCoordinatesForOrg,
    complexFiltersQuery,
    setShouldRecenterMap,
    refetchAllAssetsCoordinatesData,
    setIsLoadingAllAssetsCoordinates,
    refetchCoordinatesAndCenterMap,
  ]);

  // Set the selected view to map and show the filters strip on initial load
  useEffect(() => {
    if (firstRenderState && pageType === PageTypes.AssetMap) {
      dispatch({
        type: SET_APP_CONFIG,
        payload: { selectedAssetsView: AssetPagesUrlTypes.Map },
      });
      setShouldShowFiltersStripAndViewToggle(true);
    }
  }, [
    firstRenderState,
    pageType,
    dispatch,
    setShouldShowFiltersStripAndViewToggle,
  ]);

  useEffect(() => {
    if (pageType === PageTypes.Geofences && !isGeofenceDashboardVisible) {
      setShouldShowFiltersStripAndViewToggle(true);
    }
  }, [
    pageType,
    isGeofenceDashboardVisible,
    setShouldShowFiltersStripAndViewToggle,
  ]);

  useEffect(() => {
    // Only on first render
    if (!firstRenderState) {
      /*
        On first render fetch the coordinates because we're going to
        need them later anyway when the focus button gets clicked.
      */
      refetchAllAssetsCoordinatesData();
      if (!searchParams.size) {
        /*
            In case there are no filters, viewport, etc. specified (fresh first load)
            we want to trigger the recenter manually but not re-run the query since
            the call is already made above.
          */
        refetchCoordinatesAndCenterMap(true);
      }
    }
  }, [
    firstRenderState,
    complexFiltersMongodb,
    filters,
    searchParams,
    complexFilters,
    setShouldRecenterMap,
    refetchCoordinatesAndCenterMap,
    refetchAllAssetsCoordinatesData,
    setIsLoadingAllAssetsCoordinates,
    fetchAssetsFromOpenSearchFeatureFlag,
  ]);

  const filterInput = fetchAssetsFromOpenSearchFeatureFlag
    ? tableFilters
    : filters;
  const {
    data: serverSideMapFeatures,
    isLoading: isAssetDataMapLoading,
    isFetching: isAssetDataMapFetching,
    isSuccess: isAssetDataMapSuccess,
    isRefetching: isAssetDataMapRefetching,
  } = useAssetsClustersData(
    {
      ...filterInput,
      complexFilters: JSON.stringify(complexFiltersQuery),
      cluster: true,
      search: assetsSearchInput,
      limit: 100,
      ...zoom,
      viewport: processedViewport,
    },
    {
      keepPreviousData: true,
      // Only execute the query if it's the first render and a processed viewport is available.
      enabled:
        firstRenderState &&
        processedViewport !== null &&
        Boolean(complexFiltersQuery),
    }
  );

  useEffect(() => {
    if (id) {
      setDetailId(id);
    } else {
      setDetailId(null);
    }
  }, [id, setDetailId]);

  useEffect(() => {
    setFirstRenderState(true);
  }, []);

  useEffect(() => {
    setIsGeofenceDashboardVisible(Boolean(id));
  }, [setIsGeofenceDashboardVisible, id]);

  useEffect(() => {
    if (isGeofenceDashboardVisible) {
      setIsFiltersDrawerVisible(false);
      setIsAssetsDrawerOpen(false);
    }
  }, [
    isGeofenceDashboardVisible,
    setIsFiltersDrawerVisible,
    setIsAssetsDrawerOpen,
  ]);

  useEffect(() => {
    const isLoaded =
      pageType === PageTypes.AssetMap
        ? !isAssetDataMapFetching &&
          !isAssetDataMapLoading &&
          isAssetDataMapSuccess
        : !isGeofencesFetching &&
          !isGeofencesLoading &&
          isGeofencesFetchingSuccess;
    if (isLoaded) {
      setLoadingIndicatorState(false);
    } else {
      setLoadingIndicatorState(true);
    }
  }, [
    isAssetDataMapFetching,
    isAssetDataMapLoading,
    isAssetDataMapSuccess,
    isGeofencesFetching,
    isGeofencesLoading,
    isGeofencesFetchingSuccess,
    pageType,
  ]);

  useEffect(() => {
    // Reset state before deciding what to show on dashboard
    setSelectedAssetId(null);
    setSelectedGeofence(null);
  }, [pageType, setSelectedAssetId, setSelectedGeofence]);

  const handleNavigateBackGeofenceDashboard = () => {
    setSelectedGeofence(null);
    setGeofenceForUpdate(null);
    setDrawnGeofenceType(null);
    setDrawnGeofenceArea(null);
    setDrawnGeofenceAreaInSqKm(null);
    setIsDrawingGeofence(false);
    setIsGeofenceDashboardVisible(false);
    setIsFiltersDrawerOpen(true);
    setIsFiltersDrawerVisible(true);
    navigate(NavigationRoutes.Geofences);
    setIsFiltersDrawerVisible(true);
    setShouldFitBounds(true);
    setIsAssetsDrawerOpen(true);
  };

  const handleAddSubGeofence = () => {
    dispatch({
      type: SET_PARENT_GEOFENCE,
      payload: selectedGeofence,
    });
    dispatch({
      type: USE_PARENT_GEOFENCE_ORGID,
      payload: true,
    });
    setIsGeofenceDashboardVisible(false);
    restoreGeofencePreviousFilters();
    setIsDrawingGeofence(true);
    resetGeofenceFiltersToDefault();
    setGeofenceForUpdate(null);
    setAddGeofenceDrawerOpen(true);
    setIsAssetsDrawerOpen(false);
    setIsAssetsDrawerVisible(false);
    setIsFiltersDrawerVisible(false);
  };

  return (
    <main className="flex flex-1">
      <section
        className={`${theme} relative grow ${showDashboard ? "hidden" : ""}`}
      >
        <GoogleMapComponent
          serverSideMapFeatures={serverSideMapFeatures}
          loadingIndicatorState={loadingIndicatorState}
        />
        {isGeofenceDashboardVisible &&
          pageType === PageTypes.Geofences &&
          !isDrawingGeofence && (
            <GeofenceDashboard
              onClose={handleNavigateBackGeofenceDashboard}
              addSubGeofence={handleAddSubGeofence}
              isAssetDataMapRefetching={isAssetDataMapRefetching}
            />
          )}
      </section>
    </main>
  );
});

// we should add displayName property manually to memoized components because
// there is no other way to access the name of the component if we need it
MapView.displayName = "MapView";
export default MapView;
