import { RespondToAuthChallengeCommandOutput } from "@aws-sdk/client-cognito-identity-provider";
import { PAGE_SNACKBAR } from "../../constants";
import { SessionStorageItem } from "../../enums/sessionStorage";
import {
  GENERIC_ERROR_SNACK_PAYLOAD,
  GENERIC_LOGIN_ERROR_SNACK_PAYLOAD,
} from "./constants";
import { Dispatch } from "./types";

export const retrieveParameterFromSessionStorage = (
  parameter: SessionStorageItem
): string | null => sessionStorage.getItem(parameter);

export const handleMissingCognitoSession = (dispatch: Dispatch): void => {
  console.error(
    "Attempted to respond to Cognito Challenge but no session was present. Aborting..."
  );
  dispatch({
    type: PAGE_SNACKBAR,
    payload: GENERIC_LOGIN_ERROR_SNACK_PAYLOAD,
  });
};

export const handleMissingRequiredParameter = (
  parameter: SessionStorageItem,
  dispatch: Dispatch
): void => {
  // Keeping this for now in order to implement in future debugging mechanism
  // console.error(
  //   `Attempted to retrieve ${parameter} from session storage but it was missing. Aborting...`
  // );
  dispatch({
    type: PAGE_SNACKBAR,
    payload: GENERIC_LOGIN_ERROR_SNACK_PAYLOAD,
  });
};

export const handleNegativeCognitoResponse = (
  dispatch: Dispatch,
  statusCode?: number
): void => {
  // Keeping this for now in order to implement in future debugging mechanism
  // console.error(
  //   `Cognito responded with a non-200 status code (Actual status code: ${statusCode}).`
  // );
  dispatch({
    type: PAGE_SNACKBAR,
    payload: GENERIC_ERROR_SNACK_PAYLOAD,
  });
};

export const handleFailedMfaLogin = (
  dispatch: Dispatch,
  response?: RespondToAuthChallengeCommandOutput,
  error?: unknown
): void => {
  console.error(
    "Logging in via MFA failed. There was an issue with the Cognito response.",
    response
  );

  console.error(error);

  dispatch({
    type: PAGE_SNACKBAR,
    payload: GENERIC_LOGIN_ERROR_SNACK_PAYLOAD,
  });
};
