import { useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { ThemeProvider } from "@mui/material";
import { Asset } from "../../../graphql/operations";
import { useGetAssetsGallery } from "../../../shared/hooks/openSearchMongoPolyfillHooks/useGetAssetsGallery";
import { useSpinner } from "../../../shared/hooks/useSpinner";
import { NavigationRoutes } from "../../../utils/routes/routesUtils";
import { useAssetManagementTheme } from "../TableView/hooks/useAssetManagementTheme";
import { useAssetsDataContext } from "../shared/AssetsDataContext";
import { AssetsGallery } from "./components/AssetsGallery";

export const GalleryView = () => {
  const assetManagementTheme = useAssetManagementTheme();

  const [totalAssetsLength, setTotalAssetsLength] = useState<number>(0);
  const [assetsList, setAssetsList] = useState<Asset[]>([]);
  const { setSelectedAssetId, setShouldShowFiltersStripAndViewToggle } =
    useAssetsDataContext();

  const navigate = useNavigate();
  const location = useLocation();

  const {
    data: assetDataTable,
    isLoading: isAssetDataTableLoading,
    isRefetching: isAssetDataTableRefetching,
  } = useGetAssetsGallery();

  useEffect(() => {
    if (assetDataTable?.assets) {
      setAssetsList((assetDataTable.assets as Asset[]) ?? []);
      setTotalAssetsLength(assetDataTable.total ?? 0);
    }
  }, [assetDataTable, location.pathname]);

  useEffect(() => {
    setShouldShowFiltersStripAndViewToggle(true);
  }, [setShouldShowFiltersStripAndViewToggle]);

  const handleAssetClick = useCallback(
    (asset: Asset) => {
      setSelectedAssetId(asset._id ?? null);
      navigate(`${NavigationRoutes.Assets}/${asset._id}`);
    },
    [navigate, setSelectedAssetId]
  );

  // show spinner only on first loading
  useSpinner(
    (isAssetDataTableLoading || isAssetDataTableRefetching) &&
      assetsList.length === 0
  );

  return (
    <ThemeProvider theme={assetManagementTheme}>
      <AssetsGallery
        assetsList={assetsList}
        isAssetDataTableRefetching={isAssetDataTableRefetching}
        isAssetDataTableLoading={isAssetDataTableLoading}
        handleAssetClick={handleAssetClick}
        totalAssetsLength={totalAssetsLength}
      />
    </ThemeProvider>
  );
};
