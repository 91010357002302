import { useAppContext } from "../../../../../context/AppContext";
import BatteryChartLegend from "../BatteryChart/BatteryChartLegend";
import {
  AIR_BAG_TRENDING_GRAPH_COLORS_DARK,
  AIR_BAG_TRENDING_GRAPH_COLORS_LIGHT,
  AirBagTrendingGraphLegendKeys,
} from "./constants";

export interface AirBagTrendingGraphLegendProps {
  visibleLines: AirBagTrendingGraphLegendKeys[];
  onClick: (key: AirBagTrendingGraphLegendKeys) => void;
}

export const AirBagTrendingGraphLegend: React.FC<
  AirBagTrendingGraphLegendProps
> = ({ visibleLines, onClick }) => {
  const {
    state: { theme },
  } = useAppContext();
  const isLightTheme = theme.theme === "light";

  const chartColors = isLightTheme
    ? AIR_BAG_TRENDING_GRAPH_COLORS_LIGHT
    : AIR_BAG_TRENDING_GRAPH_COLORS_DARK;

  return (
    <>
      <BatteryChartLegend
        key={AirBagTrendingGraphLegendKeys.AxleOneLeftAirBag}
        label="Axle One Left Air Bag"
        checked={visibleLines.includes(
          AirBagTrendingGraphLegendKeys.AxleOneLeftAirBag
        )}
        color={chartColors[0]}
        onClick={() => onClick(AirBagTrendingGraphLegendKeys.AxleOneLeftAirBag)}
      />

      <BatteryChartLegend
        key={AirBagTrendingGraphLegendKeys.AxleOneRightAirBag}
        label="Axle One Right Air Bag"
        checked={visibleLines.includes(
          AirBagTrendingGraphLegendKeys.AxleOneRightAirBag
        )}
        color={chartColors[1]}
        onClick={() =>
          onClick(AirBagTrendingGraphLegendKeys.AxleOneRightAirBag)
        }
      />
    </>
  );
};
