import { FC } from "react";
import { UseFormReturn } from "react-hook-form";
import {
  CheckboxElement,
  SelectElement,
  TextFieldElement,
} from "react-hook-form-mui";
import { Grid } from "@mui/material";
import { useAssetParameters } from "../../hooks/useAssetParameters";
import { AlertFormValues } from "../../interfaces";
import { AlertParametersContainer } from "../AlertParametersContainer";
import { AssetsParameters } from "./AssetsParameters";

const THRESHOLD_UNIT_OPTIONS = [
  { id: "hours", label: "Hours" },
  { id: "days", label: "Days" },
];

interface AlertDwellParametersProps {
  form: UseFormReturn<Partial<AlertFormValues>>;
}
export const AlertDwellParameters: FC<AlertDwellParametersProps> = ({
  form,
}: AlertDwellParametersProps) => {
  const {
    assetTypeOptions,
    setAssetNameSearch,
    watchAssetsValue,
    watchAssetTypeValue,
    isFetching,
    hideInputValue,
    setSelectedAsset,
    assetsOptionsList,
    setAssetsOptionsList,
  } = useAssetParameters({ form });

  return (
    <AlertParametersContainer>
      <AssetsParameters
        form={form}
        setAssetNameSearch={setAssetNameSearch}
        setSelectedAsset={setSelectedAsset}
        assetsOptionsList={assetsOptionsList}
        setAssetsOptionsList={setAssetsOptionsList}
        assetTypeOptions={assetTypeOptions}
        watchAssetsValue={watchAssetsValue}
        watchAssetTypeValue={watchAssetTypeValue}
        isFetching={isFetching}
        hideInputValue={hideInputValue}
        assetsSelectTestId="dwell-assets-select"
        assetTypeSelectTestId="dwell-asset-type-select"
      />
      <Grid
        item
        xs={12}
        md={6}
        lg={4}
        data-testid="alert-parameters-dwell-threshold"
      >
        <TextFieldElement
          fullWidth
          control={form.control}
          name="parameters.dwellThreshold"
          label="Dwell Threshold"
          type="number"
          InputProps={{ inputProps: { step: "0.1" } }}
        />
      </Grid>
      <Grid
        item
        xs={12}
        md={6}
        lg={4}
        data-testid="alert-parameters-dwell-threshold-format"
      >
        <SelectElement
          fullWidth
          placeholder="Select"
          name="parameters.dwellThresholdUnit"
          data-testid="alert-parameters-dwell-threshold-unit"
          label="Threshold Unit"
          control={form.control}
          options={THRESHOLD_UNIT_OPTIONS}
        />
      </Grid>
      <Grid
        item
        xs={12}
        md={6}
        lg={4}
        data-testid="alert-parameters-dwell-inside-geofence"
      >
        <CheckboxElement
          name="parameters.insideGeofence"
          control={form.control}
          label="Inside Geofence"
        />
      </Grid>
      <Grid
        item
        xs={12}
        md={6}
        lg={4}
        data-testid="alert-parameters-dwell-outside-geofence"
      >
        <CheckboxElement
          name="parameters.outsideGeofence"
          control={form.control}
          label="Outside Geofence"
        />
      </Grid>
    </AlertParametersContainer>
  );
};
