import { useEffect, useState } from "react";
import { UseFormReturn, useWatch } from "react-hook-form";
import { useAppContext } from "../../../context/AppContext";
import { useFindAssetsByIdsOsQuery } from "../../../graphql/operations";
import { useFindAssetListOptions } from "../../../shared/hooks/useFindAssetListOptions";
import {
  NOMENCLATURE_NAMES,
  useNomenclatures,
} from "../../AssetsView/TableView/hooks";
import { AlertFormValues } from "../interfaces";
import {
  getAlertMultiselectIds,
  isFindAssetByIdQueryEnabled,
  onFindAssetsByIdsOSCallback,
} from "../utils";

interface AlertAssetParametersProps {
  form: UseFormReturn<Partial<AlertFormValues>>;
}

export const useAssetParameters = ({ form }: AlertAssetParametersProps) => {
  const { state } = useAppContext();
  const [selectedAsset, setSelectedAsset] = useState<any>(null);
  const [assetsOptionsList, setAssetsOptionsList] = useState<
    { id: string; label: string }[]
  >([]);
  const assetTypeOptions = useNomenclatures(NOMENCLATURE_NAMES.assetType);
  const [assetNameSearch, setAssetNameSearch] = useState("");

  const watchAssetsValue = useWatch({
    name: "parameters.assetIds",
    control: form.control,
  });
  const watchAssetTypeValue = useWatch({
    name: "parameters.assetType",
    control: form.control,
  });
  let shouldBeEnabled = isFindAssetByIdQueryEnabled(watchAssetsValue);

  const {
    data: asset,
    isLoading: findAssetLoading,
    isSuccess: findAssetSuccess,
  } = useFindAssetsByIdsOsQuery(
    { assetIds: getAlertMultiselectIds(watchAssetsValue) ?? [] },
    {
      enabled: shouldBeEnabled,
    }
  );

  useEffect(() => {
    if (asset && !findAssetLoading && findAssetSuccess) {
      onFindAssetsByIdsOSCallback({ data: asset, form, setSelectedAsset });
    }
  }, [asset, findAssetLoading, findAssetSuccess, form, shouldBeEnabled]);

  useEffect(() => {
    if (findAssetSuccess && selectedAsset) {
      form.setValue("parameters.assetIds", selectedAsset);
    }
  }, [form, selectedAsset, findAssetSuccess]);

  const { options, isFetching } = useFindAssetListOptions(
    assetNameSearch,
    state.appConfig.searchOptionsLimit
  );

  useEffect(() => {
    setAssetsOptionsList(options);
  }, [options]);

  const hideInputValue =
    !selectedAsset &&
    !!watchAssetsValue?.length &&
    findAssetLoading &&
    !findAssetSuccess;

  return {
    assetTypeOptions,
    setAssetNameSearch,
    watchAssetsValue,
    watchAssetTypeValue,
    isFetching,
    hideInputValue,
    setSelectedAsset,
    assetsOptionsList,
    setAssetsOptionsList,
  };
};
