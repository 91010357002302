import { KeyboardEventHandler } from "react";
import { UseFormReturn } from "react-hook-form";
import { SelectElement, TextFieldElement } from "react-hook-form-mui";
import { Grid } from "@mui/material";
import { Asset } from "../../../../../../graphql/operations";
import type { Breakpoints } from "../AssetForm";
import {
  AssetTCUFields,
  ASSET_TCU_FORM_FIELDS,
  yesNoOptions,
  booleanOptions,
  AssetFormSectionTitles,
} from "../assetFormUtils";
import { AccordionWrapper } from "../components/AccordionWrapper";

interface SummaryProps {
  showDetailsHeader?: boolean;
  form: UseFormReturn<Partial<Asset>>;
  breakpoints: Breakpoints;
  asset?: Partial<Asset>;
  disableAllFields?: boolean;
  numberChangeHandler: KeyboardEventHandler<HTMLDivElement>;
}

export const TemperatureControlledUnit = ({
  showDetailsHeader = false,
  form,
  breakpoints,
  disableAllFields = false,
  numberChangeHandler,
}: SummaryProps) => {
  return (
    <AccordionWrapper
      accordionSummaryTitle={AssetFormSectionTitles.TemperatureControlledUnit}
      showDetailsHeader={showDetailsHeader}
      isDashboardSettings={showDetailsHeader}
    >
      <Grid
        container
        rowGap={showDetailsHeader ? 3 : 0}
        className={`!text-primary ${
          showDetailsHeader ? "formSection" : "secondDrawerSection"
        }`}
        spacing={6}
      >
        <Grid item {...breakpoints}>
          <TextFieldElement
            disabled={disableAllFields}
            fullWidth
            control={form.control}
            name={ASSET_TCU_FORM_FIELDS[AssetTCUFields.CARB].name}
            label={ASSET_TCU_FORM_FIELDS[AssetTCUFields.CARB].label}
          />
        </Grid>
        <Grid item {...breakpoints}>
          <SelectElement
            disabled={disableAllFields}
            fullWidth
            control={form.control}
            name={
              ASSET_TCU_FORM_FIELDS[AssetTCUFields.ReeferCARBCompliant].name
            }
            label={
              ASSET_TCU_FORM_FIELDS[AssetTCUFields.ReeferCARBCompliant].label
            }
            options={booleanOptions}
          />
        </Grid>

        <Grid item {...breakpoints}>
          <TextFieldElement
            disabled={disableAllFields}
            fullWidth
            control={form.control}
            name={ASSET_TCU_FORM_FIELDS[AssetTCUFields.ReeferSerial].name}
            label={ASSET_TCU_FORM_FIELDS[AssetTCUFields.ReeferSerial].label}
          />
        </Grid>

        <Grid item {...breakpoints}>
          <TextFieldElement
            disabled={disableAllFields}
            fullWidth
            control={form.control}
            name={ASSET_TCU_FORM_FIELDS[AssetTCUFields.ReeferManufacturer].name}
            label={
              ASSET_TCU_FORM_FIELDS[AssetTCUFields.ReeferManufacturer].label
            }
          />
        </Grid>

        <Grid item {...breakpoints}>
          <TextFieldElement
            disabled={disableAllFields}
            fullWidth
            control={form.control}
            name={ASSET_TCU_FORM_FIELDS[AssetTCUFields.ReeferModel].name}
            label={ASSET_TCU_FORM_FIELDS[AssetTCUFields.ReeferModel].label}
          />
        </Grid>

        <Grid item {...breakpoints}>
          <TextFieldElement
            type="number"
            onKeyDown={numberChangeHandler}
            disabled={disableAllFields}
            fullWidth
            control={form.control}
            name={ASSET_TCU_FORM_FIELDS[AssetTCUFields.ReeferYear].name}
            label={ASSET_TCU_FORM_FIELDS[AssetTCUFields.ReeferYear].label}
          />
        </Grid>
      </Grid>
    </AccordionWrapper>
  );
};
