import { FC, useCallback, useEffect, useState, useMemo } from "react";
import { FieldValues } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { Box, ThemeProvider } from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import { isEmpty } from "lodash";
import { useAppContext } from "../../../../context/AppContext";
import { useAuthContext } from "../../../../context/AuthContext";
import {
  Alert,
  AlertReminderV2,
  AlertType,
  AlertTypeV2,
  AlertV2,
  CreateAlertV2Mutation,
  DistanceUnit,
  UpdateAlertV2Mutation,
} from "../../../../graphql/operations";
import { ActionDialog } from "../../../../shared/components/ActionDialog/ActionDialog";
import { LeaveModal } from "../../../../shared/components/LeaveModal";
import { NameWithBackButton } from "../../../../shared/components/NameWithBackButton";
import { ChangeNameDialog } from "../../../../shared/components/NameWithBackButton/ChangeNameDialog";
import { SubHeaderActionButton } from "../../../../shared/components/SubHeader/components/SubHeaderActionButton/SubHeaderActionButton";
import {
  isSuperAdmin,
  UserAccessScope,
} from "../../../../shared/components/WithPermissions";
import { useCurrentOrg } from "../../../../shared/hooks/useCurrentOrg";
import { usePrompt } from "../../../../shared/hooks/usePrompt";
import { useSpinner } from "../../../../shared/hooks/useSpinner";
import { NavigationRoutes } from "../../../../utils/routes/routesUtils";
import { useAlertApi } from "../../hooks/useAlertApi";
import { useAlertTheme } from "../../hooks/useAlertTheme";
import { useCurrentForm } from "../../hooks/useCurrentForm";
import {
  createAlertOnErrorCallback,
  createAlertTemplateOnErrorCallback,
  deleteAlertOnErrorCallback,
  getAlertInitialValues,
  getAlertParametersByType,
  onSuccessCreateCallback,
  onSuccessCreateTemplateCallback,
  onSuccessDeleteCallback,
  onSuccessUpdateCallback,
  prepareAlertReminderForSubmit,
} from "../../utils";
import { AlertHistoryTable } from "../AlertEventHistoryTable";
import { AlertFooter } from "../AlertFooter/AlertFooter";
import {
  AlertCargoForm,
  AlertDwellForm,
  AlertGeofenceForm,
  AlertMetricsForm,
  AlertSpeedForm,
  AlertCustomForm,
  AlertAirTankForm,
  AlertMovingWithoutPrimOrSecPowerForm,
  AlertDoorOpenOutsideOfGeofenceForm,
  TemperatureForm,
} from "../AlertForm";
import { ATISNotFunctioningForm } from "../AlertForm/ATISNotFunctioningForm";
import { AlertABSFaultForm } from "../AlertForm/AlertABSFaultForm";
import { AlertATISForm } from "../AlertForm/AlertATISForm";
import { AlertLightCircuitForm } from "../AlertForm/AlertLightCircuitForm";
import { AlertReeferForm } from "../AlertForm/AlertReeferForm";
import { AlertRegulatorForm } from "../AlertForm/AlertRegulatorForm";
import { AlertSensorHealthForm } from "../AlertForm/AlertSensorHealthForm";
import { AlertTPMSForm } from "../AlertForm/AlertTPMSForm";
import { AlertTpmsCatastrophicForm } from "../AlertForm/AlertTpmsCatastrophic";
import { AlertWheelEndForm } from "../AlertForm/AlertWheelEndForm";
import { DoorOpenAndMovingForm } from "../AlertForm/DoorOpenMovingForm";
import { AlertHistoryGraph } from "../AlertHistoryGraph/AlertHistoryGraph";
import { AlertTab } from "../AlertTab/AlertTab";
import { NewAlertsType } from "../NewAlertsType/NewAlertsType";

export enum AlertTabs {
  SETTINGS = "Settings",
  INCIDENTS = "Incidents",
}
interface AlertProps {
  alert: AlertV2;
  alertType: AlertTypeV2;
  isTemplate?: boolean;
}

export const AlertComponent: FC<AlertProps> = ({
  alert: alertProp,
  isTemplate,
  alertType,
}: AlertProps) => {
  const alertTheme = useAlertTheme();
  const [alert, setAlert] = useState(alertProp);
  const [alertMessage, setAlertMessage] = useState<string>("");
  const [alertName, setAlertName] = useState<string>(
    isTemplate ? `${alertProp.name} Alert` : alertProp.name
  );
  const { dispatch } = useAppContext();
  const org = useCurrentOrg();
  const { userInfo } = useAuthContext();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
  const [shouldPrompt, setShouldPrompt] = useState(false);
  const [shouldCreateAlert, setShouldCreateAlert] = useState(false);
  const [shouldCreateAlertTemplate, setShouldCreateAlertTemplate] =
    useState(false);
  const [unsavedChanges, setUnsavedChanges] = useState(false);
  const [isArchiving, setIsArchiving] = useState(false);
  const [showCustomValidationModal, setShowCustomValidationModal] =
    useState(false);
  const [isArchiveDialogOpen, setIsArchiveDialogOpen] = useState(false);
  const [activeTab, setActiveTab] = useState(
    isTemplate ? AlertTabs.SETTINGS : AlertTabs.INCIDENTS
  );

  const promptResult = usePrompt(shouldPrompt ?? false);

  // Fallback to an empty object if promptResult is undefined
  const { isLeaveModalOpen, setIsLeaveModalOpen, setIsConfirmed } =
    promptResult || {};

  const speedValueUnit =
    org?.distance_unit_preference ?? DistanceUnit.Kilometers; // TODO: Replace with DEFAULT_DISTANCE_UNIT
  const initialValues = getAlertInitialValues(
    alert,
    speedValueUnit,
    userInfo?.email
  );
  const isSuperAdminUser = isSuperAdmin(userInfo?.groups || []);
  const { form } = useCurrentForm(alertType, initialValues);

  const dirtyFields = useMemo(
    () =>
      form?.formState?.dirtyFields
        ? Object.keys(form.formState.dirtyFields)
        : [],
    [form?.formState?.dirtyFields]
  );

  const formValues = useMemo(() => (form?.watch ? form.watch() : {}), [form]);

  const updatePromptState = useCallback(() => {
    // When there are some unsaved changes on the alert, when clicking on “Archive“ - dismiss all changes without showing the “Unsaved changes“ dialog and archive the alert
    if (isArchiving) {
      setShouldPrompt(false);
      return;
    }
    const isAlertNameChanged = isTemplate ? false : alert.name !== alertName;
    const condition =
      (!!dirtyFields.length || isAlertNameChanged) &&
      !shouldCreateAlert &&
      !shouldCreateAlertTemplate;
    setShouldPrompt(condition);
    return condition;
  }, [
    isArchiving,
    dirtyFields,
    alert.name,
    alertName,
    shouldCreateAlert,
    shouldCreateAlertTemplate,
    isTemplate,
  ]);

  useEffect(() => {
    updatePromptState();
  }, [
    alertName,
    alert.name,
    alertProp,
    formValues,
    dirtyFields,
    shouldCreateAlert,
    shouldCreateAlertTemplate,
    updatePromptState,
  ]);

  const createAlertOnSuccess = async (data: CreateAlertV2Mutation) => {
    await onSuccessCreateCallback({
      alertMessage,
      dispatch,
      created: data.createAlertV2,
      queryClient,
      navigate,
      setAlert,
      setLoading,
      setShouldCreateAlert,
    });
  };

  const createAlertTemplateOnSuccess = async () => {
    await onSuccessCreateTemplateCallback({
      dispatch,
      setLoading,
      navigate,
      queryClient,
    });
  };

  const updateAlertOnSuccess = (data: UpdateAlertV2Mutation) => {
    onSuccessUpdateCallback({
      alert: data.updateAlertV2,
      setAlert,
      queryClient,
      dispatch,
      setLoading,
      loadingState: formValues?.saveAsNewAlertsType,
    });
  };

  const deleteAlertOnSuccess = async () => {
    await onSuccessDeleteCallback({
      queryClient,
      dispatch,
      setLoading,
      navigate,
    });
  };

  const createAlertOnError = (error: unknown) => {
    createAlertOnErrorCallback({ error, dispatch, setLoading });
  };

  const createAlertTemplateOnError = (error: unknown) => {
    createAlertTemplateOnErrorCallback({ error, dispatch, setLoading });
  };

  const updateAlertOnError = (error: unknown) => {
    updateAlertOnError({ error, dispatch, setLoading });
  };

  const deleteAlertOnError = (error: unknown) => {
    deleteAlertOnErrorCallback({ error, dispatch, setLoading });
  };
  // Temporary solution
  const alertApiResult = useAlertApi({
    createAlertOnSuccess,
    createAlertTemplateOnSuccess,
    updateAlertOnSuccess,
    deleteAlertOnSuccess,
    createAlertOnError,
    createAlertTemplateOnError,
    updateAlertOnError,
    deleteAlertOnError,
  });

  // Fallback to an empty object if alertApiResult is undefined
  const { createAlert, updateAlert, deleteAlert, createAlertTemplate } =
    alertApiResult || {};

  const handleFormSubmit = async (data: FieldValues, name?: string) => {
    const valid = await form?.trigger();
    if (!valid) {
      return;
    }
    if (data.saveAsNewAlertsType) {
      setShouldCreateAlertTemplate(true);
      createAlertTemplate({
        type: data.type,
        name: name ?? alertName,
        orgIds: data.alertTemplateOrgIds,
        message: data.message,
        query: data.trigger,
      });
      setLoading(true);
      return;
    }
    if (data.type === AlertType.Custom && !data.trigger) {
      setShowCustomValidationModal(true);
      return;
    }
    const reminder = prepareAlertReminderForSubmit(data) as AlertReminderV2;
    const trigger = isEmpty(data.trigger) ? null : data.trigger;

    if (isTemplate) {
      // create alert
      setShouldCreateAlert(true);
      setAlertMessage("Alert was created successfully!");
      createAlert(
        {
          message: data.message,
          name: name ?? alertName,
          orgId: org!._id,
          reminder,
          type: data.type,
          trigger,
          escalationTime: data.escalationTime,
          escalationUsers: data.escalationUsers,
          escalationSendSms: data.escalationSendSms,
        },
        JSON.stringify(
          getAlertParametersByType(data.type, speedValueUnit, data as Alert)
        )
      );
    } else {
      // update alert
      updateAlert(
        {
          _id: alert._id,
          message: data.message,
          name: name ?? alertName,
          reminder,
          trigger,
          escalationTime: data.escalationTime,
          escalationUsers: data.escalationUsers,
          escalationSendSms: data.escalationSendSms,
        },
        JSON.stringify(
          getAlertParametersByType(data.type, speedValueUnit, data as Alert)
        )
      );
    }
    setLoading(true);
  };

  const handleDuplicateAlert = () => {
    const data = form?.getValues();
    // check if everything is saved
    const condition = updatePromptState();
    if (condition || isTemplate) {
      setUnsavedChanges(true);
      return;
    }
    const reminder = prepareAlertReminderForSubmit(data) as AlertReminderV2;
    const trigger = isEmpty(data.trigger) ? null : data.trigger;

    const escalationTime = data.escalationTime;
    const escalationUsers = data.escalationUsers;
    const escalationSendSms = data.escalationSendSms;

    setShouldCreateAlert(true);
    setLoading(true);
    setAlertMessage("Alert was duplicated successfully!");
    createAlert(
      {
        message: data.message,
        name: `Copy of ${alertName}`,
        orgId: org!._id,
        reminder,
        type: data.type,
        trigger,
        escalationTime,
        escalationUsers,
        escalationSendSms,
      },
      JSON.stringify(
        getAlertParametersByType(data.type, speedValueUnit, data as Alert)
      )
    );
  };

  const handleSubmitClick = (name?: string) => {
    const newAlertName = typeof name === "string" ? name : undefined;
    form?.handleSubmit((data) => handleFormSubmit(data, newAlertName))();
  };

  const handleCloseEditNameDialog = () => {
    setIsDialogOpen(false);
  };
  const handleSubmitEditNameDialog = (name: string) => {
    setAlertName(name);
    handleSubmitClick(name);
    setIsDialogOpen(false);
  };

  const handleLeaveModalClose = () => {
    setIsLeaveModalOpen(false);
    setIsConfirmed(false);
  };

  const handleArchiveClick = () => {
    setIsArchiving(true);
    deleteAlert(alert._id);
    setIsArchiveDialogOpen(false);
    setLoading(true);
  };

  const archiveActionClick = () => {
    setIsArchiveDialogOpen(true);
  };

  const renderForm = () => {
    switch (alertType) {
      case AlertTypeV2.Cargo:
        return <AlertCargoForm form={form} />;
      case AlertTypeV2.Geofence:
        return <AlertGeofenceForm form={form} />;
      case AlertTypeV2.TirePressureMonitorSystem:
        return <AlertTPMSForm form={form} />;
      case AlertTypeV2.Atis:
        return <AlertATISForm form={form} />;
      case AlertTypeV2.Dwell:
        return <AlertDwellForm form={form} />;
      case AlertTypeV2.AirTank:
        return <AlertAirTankForm form={form} />;
      case AlertTypeV2.Custom:
        return <AlertCustomForm form={form} />;
      case AlertTypeV2.SensorHealth:
        return <AlertSensorHealthForm form={form} />;
      case AlertTypeV2.MovingWithoutPrimaryOrSecondaryPower:
        return <AlertMovingWithoutPrimOrSecPowerForm form={form} />;
      case AlertTypeV2.Speeding:
        return <AlertSpeedForm form={form} />;
      case AlertTypeV2.WheelEnd:
        return <AlertWheelEndForm form={form} />;
      case AlertTypeV2.TirePressureMonitorSystemCatastrophic:
        return <AlertTpmsCatastrophicForm form={form} />;
      case AlertTypeV2.LightCircuit:
        return <AlertLightCircuitForm form={form} />;
      case AlertTypeV2.Metrics:
        return <AlertMetricsForm form={form} />;
      case AlertTypeV2.DoorOpenOutsideOfGeofence:
        return <AlertDoorOpenOutsideOfGeofenceForm form={form} />;
      case AlertTypeV2.DoorOpenAndMoving:
        return <DoorOpenAndMovingForm form={form} />;
      case AlertTypeV2.AtisNotFunctioning:
        return <ATISNotFunctioningForm form={form} />;
      case AlertTypeV2.Regulator:
        return <AlertRegulatorForm form={form} />;
      case AlertTypeV2.Reefer:
        return <AlertReeferForm form={form} />;
      case AlertTypeV2.AbsFault:
        return <AlertABSFaultForm form={form} />;
      case AlertTypeV2.Temperature:
        return <TemperatureForm form={form} />;
      default:
        return "";
    }
  };

  const renderContent = () => {
    if (activeTab === AlertTabs.SETTINGS || isTemplate) {
      return (
        <Box data-testid="alert-settings-tab-content">
          {isSuperAdminUser && <NewAlertsType form={form} />}
          {renderForm()}
          <AlertFooter
            disabled={isTemplate ?? false}
            saveHandler={handleSubmitClick}
            deleteHandler={archiveActionClick}
          />
        </Box>
      );
    }
    if (activeTab === AlertTabs.INCIDENTS) {
      return (
        <>
          <AlertHistoryGraph alertId={alert._id} />
          <AlertHistoryTable alertId={alert._id} />
        </>
      );
    }
  };

  // Show spinner while alert is loading
  useSpinner(loading);

  return (
    <Box
      data-testid={isTemplate ? "alert-template" : "alert-record"}
      className="pb-8"
    >
      <NameWithBackButton
        name={alertName}
        moveBackRoute={NavigationRoutes.Alerts}
        setIsDialogOpen={setIsDialogOpen}
        backBtnClassName="my-4"
        headerClassName="group my-4 w-[80%] min-h-[2rem] flex items-center text-2xl font-semibold leading-10 text-header-text"
      >
        <SubHeaderActionButton
          title="Duplicate"
          onClick={handleDuplicateAlert}
          icon={<ContentCopyIcon />}
          accessScope={"alerts.create" as UserAccessScope}
          primary={false}
        />
      </NameWithBackButton>

      {!isTemplate && (
        <Box className="px-4 md:px-8 lg:px-16 border-t border-datepicker-hover-border">
          <Box className="mt-6 mb-8 h-12 flex items-center justify-center bg-table_row_bg border border-datepicker-hover-border">
            <AlertTab
              label="Incidents"
              isActive={activeTab === AlertTabs.INCIDENTS}
              data-testid="alert-incidents-tab"
              onClick={() => setActiveTab(AlertTabs.INCIDENTS)}
            />
            <AlertTab
              label="Settings"
              isActive={activeTab === AlertTabs.SETTINGS}
              data-testid="alert-settings-tab"
              onClick={() => setActiveTab(AlertTabs.SETTINGS)}
            />
          </Box>
        </Box>
      )}

      <ThemeProvider theme={alertTheme}>{renderContent()}</ThemeProvider>
      <ChangeNameDialog
        name={alertName}
        nameType="alert"
        open={isDialogOpen}
        onClose={handleCloseEditNameDialog}
        onSubmit={handleSubmitEditNameDialog}
      />
      <LeaveModal
        isOpen={isLeaveModalOpen}
        confirmNavigation={() => setIsConfirmed(true)}
        onClose={handleLeaveModalClose}
      />
      <ActionDialog
        open={unsavedChanges}
        onClose={() => setUnsavedChanges(false)}
        title="Unsaved Changes"
        textContent="You have unsaved changes. Please save Alert before duplicating."
        name="unsaved-changes"
        onSubmit={() => {}}
        submitButtonText=""
        cancelButtonText="Close"
        hideSubmitButton
      />
      <ActionDialog
        open={showCustomValidationModal}
        onClose={() => setShowCustomValidationModal(false)}
        title="Query is empty"
        textContent="Please add at least one rule."
        name="query-is-empty"
        onSubmit={() => {}}
        submitButtonText=""
        cancelButtonText="Close"
        hideSubmitButton
      />
      <ActionDialog
        open={isArchiveDialogOpen}
        onClose={() => setIsArchiveDialogOpen(false)}
        onSubmit={handleArchiveClick}
        name="archive-alert"
        title="Confirm Alert Deleting"
        textContent="Are you sure?"
        submitButtonText="Confirm"
        cancelButtonText="Cancel"
      />
    </Box>
  );
};
