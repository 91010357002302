import {
  GridRenderCellParams,
  GridValueFormatterParams,
} from "@mui/x-data-grid-premium";
import {
  formatDistanceToNowStrict,
  format as dateFnsFormat,
  isValid,
  parseISO,
  isDate,
  parse,
  format,
  endOfToday,
  startOfToday,
  subDays,
  startOfDay,
  endOfYesterday,
  startOfWeek,
  endOfWeek,
  subMonths,
  addDays,
  subWeeks,
  isBefore,
  differenceInDays,
  isSameHour,
  subHours,
  subMinutes,
  subSeconds,
  subYears,
  Duration,
  formatDuration,
  formatISO9075,
  Interval,
  intervalToDuration,
  isAfter,
  isEqual,
  startOfYesterday,
  sub,
  endOfDay,
  endOfMonth,
  startOfMonth,
} from "date-fns";
import dayjs from "dayjs";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import {
  DateTime,
  DateTimeOptions,
  Duration as LuxonDuration,
  IANAZone,
  Settings,
} from "luxon";
import { TIME_ZONE_OPTIONS } from "../constants/account";
import { Maybe } from "../graphql/operations";

export const DATE_TIME_FORMAT = "MM/dd/yyyy hh:mm:ss a";
export const DATE_TIME_FORMAT_SHORT = "MM/dd/yy hh:mm a";
export const DATE_FORMAT = "MM/dd/yyyy";
export const QUERYBUILDER_DATE_FORMAT = "MM/DD/yyyy";
export const DATE_FORMAT_GLUED = "MMddyyyy";
export const DAY_MONTH_FORMAT = "MM/dd";
export const DEFAULT_TIMEZONE: string = "UTC";

export const shortenTimeUnits = (str: string) => {
  const replacer = [
    { full: "second", short: "sec" },
    { full: "minute", short: "min" },
    { full: "hour", short: "hr" },
    { full: "day", short: "d" },
    { full: "month", short: "mo" },
    { full: "year", short: "yr" },
  ];

  let result = str;

  replacer.forEach(({ full, short }) => {
    const regex = new RegExp(`${full}s?`, "gi");
    result = result.replace(regex, short);
  });

  return result;
};

export const getStaleTime = (date: Date | undefined | null | string) => {
  const parsedDate = date
    ? typeof date === "string"
      ? new Date(date)
      : date
    : new Date();
  return shortenTimeUnits(formatDistanceToNowStrict(parsedDate));
};

/**
 * Formats a date string or a date object to a given format. Defaults to "MM/dd/yyyy"
 * @param date Date string
 * @param format Date format
 */
export const formatDate = (date: string | Date, format = "MM/dd/yyyy") => {
  // date could be null, so have to be checked and return it only if is not null, otherwise retune empty string
  const correctStringValue = typeof date === "string" ? date : "";
  const parsed = isDate(date) ? (date as Date) : parseISO(date as string);
  return isValid(parsed) ? dateFnsFormat(parsed, format) : correctStringValue;
};

export const formatDateWithoutTimeZone = (date: string | null | undefined) => {
  // Check if date is a valid date string
  if (!date || !Date.parse(date)) {
    return ""; // Return empty string if date is null, undefined, or not a valid date string
  }

  return DateTime.fromISO(date, { zone: "utc" }).toFormat("MM/dd");
};

export const adjustForUTCOffset = (date: Date) => {
  // remove timezone to show correct data in date pickers
  return new Date(
    date.getUTCFullYear(),
    date.getUTCMonth(),
    date.getUTCDate(),
    date.getUTCHours(),
    date.getUTCMinutes(),
    date.getUTCSeconds()
  );
};

const renderColumnDateAndTime = (dateTime: string) => {
  const [date, time, aa] = dateTime.split(" ");

  return (
    <div className="flex !min-w-[165px] justify-between">
      <span>{date}</span>
      <span>{time}</span>
      <span>{aa}</span>
    </div>
  );
};

const renderColumnTime = (dateTime: string) => {
  const [_, time, aa] = dateTime.split(" ");
  return (
    <div className="flex !min-w-[85px] justify-between">
      <span>{time}</span>
      <span>{aa}</span>
    </div>
  );
};

export const columnTimeFormatParams = {
  valueFormatter: (params: GridValueFormatterParams) =>
    formatDate(params.value, DATE_TIME_FORMAT),
  renderCell: (params: GridRenderCellParams) =>
    renderColumnTime(formatDate(params.value, DATE_TIME_FORMAT)),
  type: "date",
};

export const columnDateTimeFormatParams = {
  valueFormatter: (params: GridValueFormatterParams) =>
    formatDate(params.value, DATE_TIME_FORMAT),
  renderCell: (params: GridRenderCellParams) =>
    renderColumnDateAndTime(formatDate(params.value, DATE_TIME_FORMAT)),
  type: "date",
};

export const columnDateFormatParams = {
  valueFormatter: (params: GridValueFormatterParams) =>
    formatDate(params.value, DATE_FORMAT),
  renderCell: (params: GridRenderCellParams) => (
    <span>{formatDate(params.value, DATE_FORMAT)}</span>
  ),
  type: "date",
};

/**
 * !@note Will always use prefered timezone to format otherwise dates will be in UTC
 */
export const columnTimezoneDateTimeFormatParams = (timezone: string) => ({
  valueFormatter: (params: GridValueFormatterParams) =>
    params.value &&
    formatDate(
      formatDateInTimezone(
        params.value,
        DATE_TIME_FORMAT,
        mapTimezoneToIANAValue(timezone)
      )
    ),
  renderCell: (params: GridRenderCellParams) =>
    params.value &&
    renderColumnDateAndTime(
      formatDateInTimezone(
        params.value,
        DATE_TIME_FORMAT,
        mapTimezoneToIANAValue(timezone)
      )
    ),
  type: "date",
});

export const columnTimezoneTimeFormatParams = (timezone: string) => ({
  valueFormatter: (params: GridValueFormatterParams) =>
    params.value &&
    formatDate(
      formatDateInTimezone(
        params.value,
        DATE_TIME_FORMAT,
        mapTimezoneToIANAValue(timezone)
      )
    ),
  renderCell: (params: GridRenderCellParams) =>
    params.value &&
    renderColumnTime(
      formatDateInTimezone(
        params.value,
        DATE_TIME_FORMAT,
        mapTimezoneToIANAValue(timezone)
      )
    ),
  type: "date",
});

export const formatToUTC = (date: Date, format: string) =>
  DateTime.fromJSDate(date).toUTC().toFormat(format);

/**
 * Format given datetime into UTC and return the DateTime object directly
 *
 * @param date Date format
 */
export const formatDateToUTC = (date: Date) =>
  DateTime.fromJSDate(date).toUTC();

/**
 * Returns a date with timezone and local time
 *
 * @param startDateLocal start date in local timezone
 * @param endDateLocal end date in local timezone
 */
export const getDateRangeWithTimeZone = (
  startDateLocal: Date | null,
  endDateLocal: Date | null,
  timeZone: string
): [Date | null, Date | null] => {
  if (!startDateLocal || !endDateLocal) {
    return [null, null];
  }

  let startDate = DateTime.fromJSDate(startDateLocal).setZone(timeZone, {
    keepLocalTime: true,
  });
  let endDate = DateTime.fromJSDate(endDateLocal).setZone(timeZone, {
    keepLocalTime: true,
  });

  return [startDate.toJSDate(), endDate.toJSDate()];
};

/**
 * Return a valid timezone value according to IANA spec. Defaults to UTC.
 * If given value is not valid, it will return UTC.
 * @see https://www.iana.org/time-zones
 * @param timezone
 */
export const mapTimezoneToIANAValue = (timezone: Maybe<string> | undefined) => {
  if (!timezone) {
    return DEFAULT_TIMEZONE;
  }
  if (IANAZone.isValidSpecifier(timezone)) {
    return timezone;
  }
  return (
    TIME_ZONE_OPTIONS.find(
      ({ value, label }) => label === timezone || value === timezone
    )?.value ?? DEFAULT_TIMEZONE
  );
};

const convertDateToLuxonDateTime = (date: Date | string) =>
  typeof date === "string"
    ? DateTime.fromISO(date)
    : DateTime.fromISO(date.toISOString());

/**
 * Format given datetime into given timezone or UTC if not provided as a UTC ISO String
 * @example
 * input: {
 *   date: "2021-08-10T00:00:00.000Z",
 *   timezone: "America/New_York" (GMT-5)
 * }
 * output: "2021-08-10T05:00:00.000Z"
 * @param date
 * @param timezone
 */
export const formatDateToTimezoneAsUTCISOString = (
  date: Date | string,
  timezone: Maybe<string> | undefined
) => {
  const luxonDate = DateTime.fromJSDate(
    typeof date === "string" ? new Date(date) : date,
    { zone: timezone ?? DEFAULT_TIMEZONE }
  );

  const { offset } = luxonDate;

  return luxonDate.toUTC().minus({ minutes: offset }).toISO() as string;
};

/**
 * Format given UTC datetime into given timezone as an ISO String
 * @example
 * input: {
 *   date: "2021-08-10T10:00:00.000Z",
 *   timezone: "America/New_York" (GMT-5)
 * }
 * output: "2021-08-10T15:00:00.000Z"
 * @param date
 * @param timezone
 */
export const formatUTCDateToTimezoneAsISOString = (
  date: Date | string,
  timezone: Maybe<string> | undefined
) => {
  const luxonDate = DateTime.fromJSDate(
    typeof date === "string" ? new Date(date) : date,
    { zone: timezone ?? DEFAULT_TIMEZONE }
  );

  const { offset } = luxonDate;

  return luxonDate
    .toUTC()
    .plus({ minutes: offset })
    .toISO({ includeOffset: false }) as string;
};

/**
 * Format datetime into given timezone with provided shape
 * @example
 * input: {
 *   date: "2021-08-10T10:00:00.000Z",
 *   format: "MM/dd/yyyy hh:mm:ss aa",
 *   timezone: "America/New_York" (GMT-5)
 * }
 * output: "08/10/2021 05:00:00 AM"
 * @param date
 * @param format
 * @param timezone
 */
export const formatDateInTimezone = (
  date: Date | string,
  format: string,
  timezone: string
) => {
  if (!date) {
    return "";
  }
  const dateTime = convertDateToLuxonDateTime(date);
  return dateTime.isValid
    ? DateTime.fromISO(dateTime.toISO() as string, {
        zone: timezone,
      }).toFormat(format)
    : "";
};

export const getBeginningOfDayISOString = (date: string) =>
  `${dateFnsFormat(new Date(date), "yyyy-MM-dd")}T00:00:00.000Z`;

export const getEndingOfDayISOString = (date: string) =>
  `${dateFnsFormat(new Date(date), "yyyy-MM-dd")}T23:59:59.999Z`;

/**
 * Return the distance between the given date and now in words. Work like formatDistanceToNowStrict but with different suffixes and using luxon. PRJIND-5249
 * @example
 * input: {
 *    date: Sat Dec 17 2022 22:57:00 GMT+0200
 * }
 * output:  "10 months"
 * @param date Date format
 */
export const formatDistanceToNowStrictCustom = (date: Date): string => {
  const targetDate = DateTime.fromJSDate(date);
  const now = DateTime.now();
  const duration = now.diff(targetDate);

  if (duration.as("minutes") < 1) {
    return "1 min";
  } else if (duration.as("hours") < 1) {
    const minutes = Math.floor(duration.as("minutes"));
    return `${minutes} min${minutes === 1 ? "" : "s"}`;
  } else if (duration.as("days") < 1) {
    const hours = Math.floor(duration.as("hours"));
    return `${hours} hour${hours === 1 ? "" : "s"}`;
  } else if (duration.as("months") < 1) {
    const days = Math.floor(duration.as("days"));
    return `${days} day${days === 1 ? "" : "s"}`;
  } else if (duration.as("years") < 1) {
    const months = Math.floor(duration.as("months"));
    return `${months} month${months === 1 ? "" : "s"}`;
  } else {
    const years = Math.floor(duration.as("years"));
    return `${years} year${years === 1 ? "" : "s"}`;
  }
};

export const validateYear = (value: Date) => {
  const year = value.getFullYear();
  // We should avoid two digits year, as initially it has wrong value i.e(12/12/12 => 12/12/0012)
  return !(!isNaN(year) && (year < 1000 || year > 9999));
};

export const validateAlertScheduleTimeRange = (value: {
  timeFrom: string;
  timeTo: string;
  timeFormatFrom: string;
  timeFormatTo: string;
}) => {
  const { timeFormatFrom, timeFormatTo, timeFrom, timeTo } = value;
  if (
    !timeFormatFrom ||
    !timeFormatTo ||
    !timeFrom ||
    !timeTo ||
    (timeFormatFrom === "AM" && timeFormatTo === "PM") ||
    (timeFormatFrom === timeFormatTo && timeFrom < timeTo)
  ) {
    return true;
  }
  return false;
};

export const humanizeDateString = (inputDate: string) => {
  if (!inputDate) return;

  const dateFormat = "MM/dd/yyyy hh:mm:ss a";
  const parsedDate = parse(inputDate, dateFormat, new Date());
  const now = new Date();
  const diffInMs = now.getTime() - parsedDate.getTime();
  const diffInDays = Math.floor(diffInMs / (1000 * 60 * 60 * 24));

  if (diffInDays > 0) {
    return `${diffInDays} day${diffInDays > 1 ? "s" : ""} ago`;
  } else {
    return format(parsedDate, "hh:mm:ss a");
  }
};

/**
 * Takes in a date object and returns a date string, precise to seconds (YYYY-MM-DDThh:mm:ss).
 * @param date
 * @returns {string}
 */

export const dateToExactDateTimeStringNoTz = (date: Date): string => {
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Account for 0-based months number
  const day = date.getDate().toString().padStart(2, "0");
  const hours = date.getHours().toString().padStart(2, "0");
  const minutes = date.getMinutes().toString().padStart(2, "0");
  const seconds = date.getSeconds().toString().padStart(2, "0");

  return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`;
};

// Force the start and end of the day in UTC by resetting the time to 00:00:00 for the start and 23:59:59 for the end, respectively.
// By explicitly converting to UTC and setting the time to start or end of the day, we eliminate any confusion between local timezone and UTC.
// The .toISOString() method will then provide the date in the desired format (correctly aligned with UTC).
// Returns the start of the day in UTC to be used in API calls no matter the local timezone.
export const convertToUTCStartOfDay = (date: Date | null) => {
  if (!date) return null;
  const utcDate = new Date(
    Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0)
  );
  return utcDate.toISOString();
};
// Returns the end of the day in UTC to be used in API calls no matter the local timezone.
export const convertToUTCEndOfDay = (date: Date | null) => {
  if (!date) return null;
  const utcDate = new Date(
    Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), 23, 59, 59)
  );
  return utcDate.toISOString();
};

/**
 * Returns the start of yesterday.
 *
 * @returns {Date} The start of yesterday.
 */
export const getStartOfYesterday = () => {
  return startOfYesterday();
};

/**
 * Returns the end of today.
 *
 * @returns {Date} The end of today.
 */
export const getEndOfToday = () => {
  return endOfToday();
};

/**
 * Returns the start of today.
 *
 * @returns {Date} The start of today.
 */
export const getStartOfToday = () => {
  return startOfToday();
};

/**
 * Returns a new Date object that is `days` number of days before the given `date`.
 * @param date - The date to subtract days from.
 * @param days - The number of days to subtract.
 * @returns A new Date object that is `days` number of days before the given `date`.
 */
export const getSubDays = (date: Date, days: number) => {
  return subDays(date, days);
};

/**
 * Returns the start of the day for the given date.
 *
 * @param date - The date for which to get the start of the day.
 * @returns The start of the day for the given date.
 */
export const getStartOfDay = (date: Date) => {
  return startOfDay(date);
};

/**
 * Returns the end of yesterday.
 *
 * @returns {Date} The end of yesterday.
 */
export const getEndOfYesterday = () => {
  return endOfYesterday();
};

/**
 * Returns the start of the week for a given date.
 *
 * @param date - The date for which to calculate the start of the week.
 * @returns The start of the week for the given date.
 */
export const getStartOfWeek = (date: Date) => {
  return startOfWeek(date);
};

/**
 * Returns the end of the week for a given date.
 *
 * @param date - The date for which to calculate the end of the week.
 * @returns The end of the week as a Date object.
 */
export const getEndOfWeek = (date: Date) => {
  return endOfWeek(date);
};

/**
 * Returns a new Date object that is `months` months before the given `date`.
 *
 * @param date - The date to subtract months from.
 * @param months - The number of months to subtract.
 * @returns A new Date object.
 */
export const getSubMonths = (date: Date, months: number) => {
  return subMonths(date, months);
};

/**
 * Parses a string representation of a date in ISO format and returns a Date object.
 *
 * @param date - The string representation of the date in ISO format.
 * @returns A Date object representing the parsed date.
 */
export const parseISODate = (date: string) => {
  return parseISO(date);
};

/**
 * Adds the specified number of days to the given date.
 *
 * @param date - The date to which the days should be added.
 * @param days - The number of days to add.
 * @returns The new date after adding the specified number of days.
 */
export const addDaysToDate = (date: Date, days: number) => {
  return addDays(date, days);
};

/**
 * Subtracts a specified number of weeks from a given date.
 *
 * @param date - The date to subtract weeks from.
 * @param weeks - The number of weeks to subtract.
 * @returns The new date after subtracting the specified number of weeks.
 */
export const subWeeksFromDate = (date: Date, weeks: number) => {
  return subWeeks(date, weeks);
};

/**
 * Checks if a given date string is valid.
 *
 * @param {string} date - The date string to check.
 * @returns {boolean} - Returns true if the date is valid, otherwise false.
 */
export const checkIsValidDate = (date: unknown) => {
  return isValid(date);
};

/**
 * Checks if a given date is before another date.
 *
 * @param {Date | number} date - The date to check.
 * @param {Date | number} dateToCompare - The date to compare against.
 * @returns {boolean} - Returns true if the first date is before the second date, otherwise returns false.
 */
export const checkIsBefore = (
  date: Date | number,
  dateToCompare: Date | number
) => {
  return isBefore(date, dateToCompare);
};

/**
 * Checks if a given date is after another date.
 *
 * @param {Date | number} date - The date to check.
 * @param {Date | number} dateToCompare - The date to compare against.
 * @returns {boolean} - Returns true if the first date is after the second date, otherwise returns false.
 */
export const checkIsAfter = (
  date: Date | number,
  dateToCompare: Date | number
) => {
  return isAfter(date, dateToCompare);
};

/**
 * Calculates the difference in days between two dates.
 *
 * @param {Date | number} dateLeft - The first date.
 * @param {Date | number} dateRight - The second date.
 * @returns {number} The difference in days between the two dates.
 */
export const getDifferenceInDays = (
  dateLeft: Date | number,
  dateRight: Date | number
) => {
  return differenceInDays(dateLeft, dateRight);
};

/**
 * Checks if two dates are the same hour.
 * @param dateLeft - The first date to compare.
 * @param dateRight - The second date to compare.
 * @returns A boolean indicating whether the two dates are the same hour.
 */
export const checkIsSameHour = (
  dateLeft: Date | number,
  dateRight: Date | number
) => {
  return isSameHour(dateLeft, dateRight);
};

/**
 * Returns a new Date object that is `hours` hours before the given `date`.
 *
 * @param date - The date to subtract hours from.
 * @param hours - The number of hours to subtract.
 * @returns A new Date object that is `hours` hours before the given `date`.
 */
export const getSubHours = (date: Date, hours: number) => {
  return subHours(date, hours);
};

/**
 * Returns a new Date object that is `minutes` minutes before the given `date`.
 *
 * @param date - The date to subtract minutes from.
 * @param minutes - The number of minutes to subtract.
 * @returns A new Date object that is `minutes` minutes before the given `date`.
 */
export const getSubMinutes = (date: Date, minutes: number) => {
  return subMinutes(date, minutes);
};

/**
 * Returns a new Date object that is `seconds` seconds before the given `date`.
 *
 * @param date - The date to subtract seconds from.
 * @param seconds - The number of seconds to subtract.
 * @returns A new Date object that is `seconds` seconds before the given `date`.
 */
export const getSubSeconds = (date: Date, seconds: number) => {
  return subSeconds(date, seconds);
};

/**
 * Returns a new Date object that is `years` number of years before the given `date`.
 *
 * @param date - The date to subtract years from.
 * @param years - The number of years to subtract.
 * @returns A new Date object that is `years` number of years before the given `date`.
 */
export const getSubYears = (date: Date, years: number) => {
  return subYears(date, years);
};

/**
 * Returns a formatted duration string.
 *
 * @param duration - The duration to format.
 * @param options - The formatting options (optional).
 * @returns The formatted duration string.
 */
type DurationOptions = {
  format?: string[];
  zero?: boolean;
  delimiter?: string;
  locale?: Locale;
};
export const getFormattedDuration = (
  duration: Duration,
  options?: DurationOptions
) => {
  return formatDuration(duration, options);
};

/**
 * Returns the ISO 9075 formatted string representation of the given date.
 *
 * @param {Date} date - The date to format.
 * @returns {string} The ISO 9075 formatted string.
 */
export const getFormatISO9075 = (date: Date) => {
  return formatISO9075(date);
};

/**
 * Converts an interval to a duration.
 *
 * @param interval - The interval to convert.
 * @returns The duration of the interval.
 */
export const convertIntervalToDuration = (interval: Interval) => {
  return intervalToDuration(interval);
};

/**
 * Parses a date string using the specified format and reference date.
 *
 * @param dateString - The date string to parse.
 * @param formatString - The format string specifying the expected format of the date string.
 * @param referenceDate - The reference date used for parsing relative dates.
 * @param options - Optional parameters for parsing.
 * @param options.locale - The locale to use for parsing. Defaults to the system's default locale.
 * @param options.weekStartsOn - The index of the first day of the week. Defaults to 0 (Sunday).
 * @param options.firstWeekContainsDate - The index of the first day of the first week of the year. Defaults to 1.
 * @param options.useAdditionalWeekYearTokens - Whether to use additional week year tokens in the format string. Defaults to false.
 * @param options.useAdditionalDayOfYearTokens - Whether to use additional day of year tokens in the format string. Defaults to false.
 * @returns The parsed date object.
 */
export const parseDate = (
  dateString: string,
  formatString: string,
  referenceDate: Date | number,
  options?: {
    locale?: Locale;
    weekStartsOn?: 0 | 1 | 2 | 3 | 4 | 5 | 6;
    firstWeekContainsDate?: 1 | 2 | 3 | 4 | 5 | 6 | 7;
    useAdditionalWeekYearTokens?: boolean;
    useAdditionalDayOfYearTokens?: boolean;
  }
) => {
  return parse(dateString, formatString, referenceDate, options);
};

/**
 * Checks if two dates are equal.
 *
 * @param date1 - The first date to compare.
 * @param date2 - The second date to compare.
 * @returns True if the dates are equal, false otherwise.
 */
export const isEqualDates = (date1: Date, date2: Date) => {
  return isEqual(date1, date2);
};

/**
 * Returns the end of the day for the given date.
 *
 * @param date - The date for which to get the end of the day.
 * @returns The end of the day for the given date.
 */
export const getEndOfDay = (date: Date) => {
  return endOfDay(date);
};

/**
 * Returns the end of the month for the given date.
 *
 * @param date - The date for which to get the end of the month.
 * @returns The end of the month as a Date object.
 */
export const getEndOfMonth = (date: Date) => {
  return endOfMonth(date);
};

/**
 * Returns the start of the month for the given date.
 *
 * @param date - The date for which to get the start of the month.
 * @returns The start of the month for the given date.
 */
export const getStartOfMonth = (date: Date) => {
  return startOfMonth(date);
};

/**
 * Parses a string representation of a date and time using the specified format and options.
 * @param text - The string to parse.
 * @param fmt - The format string.
 * @param opts - The options for parsing.
 * @returns A DateTime object representing the parsed date and time.
 */
export const getDateTimeFromFormat = (
  text: string,
  fmt: string,
  opts?: DateTimeOptions
) => {
  return DateTime.fromFormat(text, fmt, opts);
};

/**
 * Returns the current date and time.
 *
 * @returns {DateTime} The current date and time.
 */
export const getDateTimeNow = () => {
  return DateTime.now();
};

/**
 * Calculates the duration from milliseconds.
 *
 * @param milliseconds - The number of milliseconds.
 * @returns The duration calculated from the given milliseconds.
 */
export const getDurationFromMillis = (milliseconds: number) => {
  return LuxonDuration.fromMillis(milliseconds);
};

/**
 * Returns the DateTime object.
 *
 * @returns {DateTime} The DateTime object.
 */
export const getDateTime = () => {
  return DateTime;
};

/**
 * Retrieves the Luxon settings.
 *
 * @returns The Luxon settings.
 */
export const getLuxonSettings = () => {
  return Settings;
};

/**
 * Returns the `dayjs` library.
 *
 * @returns The `dayjs` library.
 */
export const getDayjs = () => {
  return dayjs;
};

/**
 * Returns the `isSameOrAfter` function.
 *
 * @returns The `isSameOrAfter` function.
 */
export const getIsSameOrAfter = () => {
  return isSameOrAfter;
};

/**
 * Returns the `isSameOrBefore` function.
 *
 * @returns The `isSameOrBefore` function.
 */
export const getIsSameOrBefore = () => {
  return isSameOrBefore;
};
