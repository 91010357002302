import { FC, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  ReportType,
  useGetReportParamsOrgsQuery,
  useGetReportQuery,
} from "../../../../graphql/operations";
import { useSpinner } from "../../../../shared/hooks/useSpinner";
import { NavigationRoutes } from "../../../../utils/routes/routesUtils";
import { AssetReport } from "../../AssetReport";
import {
  defaultColumnVisibilityModel as defaultAccountColumnVisibilityModel,
  getColumns as getAccountsColumns,
  SEARCH_KEYS as ACCOUNTS_SEARCH_KEYS,
} from "../../AssetReport/components/AccountsTable/columns";
import {
  defaultColumnVisibilityModel as defaultAlertHistoryColumnVisibilityModel,
  getColumns as getAlertHistoryColumns,
} from "../../AssetReport/components/AlertHistoryTable/columns";
import {
  defaultColumnVisibilityModel as defaultABSFaultColumnVisibilityModel,
  getColumns as getABSFaultColumns,
  SEARCH_KEYS as ABS_FAULT_SEARCH_KEYS,
} from "../../AssetReport/components/AssetABSFaultTable/columns";
import {
  defaultColumnVisibilityModel as assetDetentionColumnVisibilityModel,
  getColumns as getAssetDetentionColumns,
  SEARCH_KEYS as ASSET_DETENTION_SEARCH_KEYS,
} from "../../AssetReport/components/AssetDetentionTable/columns";
import {
  defaultColumnVisibilityModel as defaultInstallColumnVisibilityModel,
  getColumns as getInstallColumns,
} from "../../AssetReport/components/AssetInstallTable/columns";
import {
  defaultColumnVisibilityModel as defaultAssetColumnVisibilityModel,
  getColumns as getCommonAssetColumns,
  getInventoryColumns,
  SEARCH_KEYS as COMMON_ASSET_SEARCH_KEYS,
} from "../../AssetReport/components/AssetInventoryTable/columns";
import {
  defaultColumnVisibilityModel as defaultTransferColumnVisibilityModel,
  getColumns as getTransferColumns,
} from "../../AssetReport/components/AssetTransferTable/columns";
import { SEARCH_KEYS as YARD_SEARCH_KEYS } from "../../AssetReport/components/AssetYardTable/columns";
import {
  defaultColumnVisibilityModel as defaultDwellHierarchyColumnVisibilityModel,
  getColumns as getDwellHierarchyColumns,
} from "../../AssetReport/components/DwellHierarchyTable/columns";
import {
  defaultColumnVisibilityModel as defaultMediaActivitiesColumnVisibilityModel,
  getColumns as getMediaActivitiesColumns,
  SEARCH_KEYS as MEDIA_ACTIVITIES_SEARCH_KEYS,
} from "../../AssetReport/components/MediaActivitiesTable/columns";
import {
  defaultColumnVisibilityModel as defaultMileageColumnVisibilityModel,
  getColumns as getMileageColumns,
  SEARCH_KEYS as MILEAGE_SEARCH_KEYS,
} from "../../AssetReport/components/MileageTable/columns";
import {
  defaultColumnVisibilityModel as defaultSensorsColumnVisibilityModel,
  getColumns as getSensorsColumns,
  SEARCH_KEYS as SENSORS_SEARCH_KEYS,
} from "../../AssetReport/components/SensorsTable/columns";
import { ReportWithParameters } from "../../interfaces";

export type ReportRecordProps = {
  id: string;
};

export const ReportRecord: FC<ReportRecordProps> = ({
  id,
}: ReportRecordProps) => {
  const navigate = useNavigate();
  const [report, setReport] = useState<ReportWithParameters>();

  const {
    data: reportQueryData,
    isLoading: isReportDataLoading,
    isRefetching: isReportDataRefetching,
  } = useGetReportQuery({ id });
  const {
    data: reportParamsOrgsQueryData,
    isLoading: isReportParamsOrgsDataLoading,
  } = useGetReportParamsOrgsQuery({
    input: { reportId: id },
  });

  const isLoading =
    isReportDataLoading ||
    isReportParamsOrgsDataLoading ||
    isReportDataRefetching;

  useEffect(() => {
    const reportData = reportQueryData?.getReport;
    const reportParamsOrgsData = reportParamsOrgsQueryData?.getReportParamsOrgs;

    if (!isLoading) {
      if (reportData) {
        const parameters =
          reportData.parameters && JSON.parse(reportData.parameters);
        setReport({
          ...reportData,
          parameters: reportData.parameters
            ? {
                ...parameters,
                orgIds: reportParamsOrgsData
                  ? reportParamsOrgsData.map(({ _id }) => _id)
                  : undefined,
              }
            : undefined,
        });
      } else {
        navigate(NavigationRoutes.Reports);
      }
    }
  }, [
    navigate,
    isLoading,
    reportQueryData?.getReport,
    reportParamsOrgsQueryData?.getReportParamsOrgs,
  ]);

  useSpinner(isLoading || !report);

  return (
    <>
      {report && (
        <>
          {report.type === ReportType.Accounts && (
            <AssetReport
              report={report}
              reportType={ReportType.Accounts}
              getColumns={getAccountsColumns}
              defaultColumnVisibilityModel={defaultAccountColumnVisibilityModel}
              searchKeys={ACCOUNTS_SEARCH_KEYS}
              initialSortModel={[
                {
                  field: "updatedAt",
                  sort: "desc",
                },
              ]}
            />
          )}
          {report.type === ReportType.AssetTransfer && (
            <AssetReport
              report={report}
              reportType={ReportType.AssetTransfer}
              getColumns={getTransferColumns}
              defaultColumnVisibilityModel={
                defaultTransferColumnVisibilityModel
              }
              searchKeys={[]}
              initialSortModel={[
                {
                  field: "date",
                  sort: "desc",
                },
              ]}
            />
          )}
          {report.type === ReportType.AssetInstall && (
            <AssetReport
              report={report}
              reportType={ReportType.AssetInstall}
              getColumns={getInstallColumns}
              defaultColumnVisibilityModel={defaultInstallColumnVisibilityModel}
              searchKeys={[]}
              initialSortModel={[
                {
                  field: "installDate",
                  sort: "desc",
                },
              ]}
            />
          )}
          {report.type === ReportType.AssetInventory && (
            <AssetReport
              report={report}
              reportType={ReportType.AssetInventory}
              getColumns={getInventoryColumns}
              defaultColumnVisibilityModel={defaultAssetColumnVisibilityModel}
              searchKeys={COMMON_ASSET_SEARCH_KEYS}
              initialSortModel={[
                {
                  field: "device.lst_evnt_t",
                  sort: "desc",
                },
              ]}
            />
          )}
          {report.type === ReportType.AlertHistory && (
            <AssetReport
              report={report}
              reportType={ReportType.AlertHistory}
              getColumns={getAlertHistoryColumns}
              defaultColumnVisibilityModel={
                defaultAlertHistoryColumnVisibilityModel
              }
              searchKeys={[]}
              initialSortModel={[
                {
                  field: "startedAt",
                  sort: "desc",
                },
              ]}
            />
          )}
          {report.type === ReportType.Mileage && (
            <AssetReport
              report={report}
              reportType={ReportType.Mileage}
              getColumns={getMileageColumns}
              defaultColumnVisibilityModel={defaultMileageColumnVisibilityModel}
              searchKeys={MILEAGE_SEARCH_KEYS}
              initialSortModel={[
                {
                  field: "dateAndTime",
                  sort: "desc",
                },
              ]}
            />
          )}
          {report.type === ReportType.AssetYard && (
            <AssetReport
              report={report}
              reportType={ReportType.AssetYard}
              getColumns={getCommonAssetColumns}
              defaultColumnVisibilityModel={defaultAssetColumnVisibilityModel}
              searchKeys={YARD_SEARCH_KEYS}
              initialSortModel={[
                {
                  field: "device.lst_evnt_t",
                  sort: "desc",
                },
              ]}
            />
          )}
          {report.type === ReportType.OutsideGeofence && (
            <AssetReport
              report={report}
              reportType={ReportType.OutsideGeofence}
              getColumns={getCommonAssetColumns}
              defaultColumnVisibilityModel={defaultAssetColumnVisibilityModel}
              searchKeys={YARD_SEARCH_KEYS}
              initialSortModel={[
                {
                  field: "device.lst_evnt_t",
                  sort: "desc",
                },
              ]}
            />
          )}
          {report.type === ReportType.DwellHierarchy && (
            <AssetReport
              report={report}
              reportType={ReportType.DwellHierarchy}
              getColumns={getDwellHierarchyColumns}
              defaultColumnVisibilityModel={
                defaultDwellHierarchyColumnVisibilityModel
              }
              searchKeys={[]} // remove when all reports are moved to table data
              initialSortModel={[
                {
                  field: "geofenceName",
                  sort: "asc",
                },
              ]}
            />
          )}
          {report.type === ReportType.MediaActivities && (
            <AssetReport
              report={report}
              reportType={ReportType.MediaActivities}
              getColumns={getMediaActivitiesColumns}
              defaultColumnVisibilityModel={
                defaultMediaActivitiesColumnVisibilityModel
              }
              searchKeys={MEDIA_ACTIVITIES_SEARCH_KEYS}
              initialSortModel={[
                {
                  field: "dateAndTime",
                  sort: "desc",
                },
              ]}
            />
          )}
          {report.type === ReportType.Sensors && (
            <AssetReport
              report={report}
              reportType={ReportType.Sensors}
              getColumns={getSensorsColumns}
              defaultColumnVisibilityModel={defaultSensorsColumnVisibilityModel}
              searchKeys={SENSORS_SEARCH_KEYS}
              initialSortModel={[
                {
                  field: "assetId",
                  sort: "asc",
                },
              ]}
            />
          )}
          {report.type === ReportType.AssetAbsFaultHistory && (
            <AssetReport
              report={report}
              reportType={ReportType.AssetAbsFaultHistory}
              getColumns={getABSFaultColumns}
              defaultColumnVisibilityModel={
                defaultABSFaultColumnVisibilityModel
              }
              searchKeys={ABS_FAULT_SEARCH_KEYS}
              initialSortModel={[
                {
                  field: "assetId",
                  sort: "desc",
                },
              ]}
            />
          )}
          {report.type === ReportType.AssetDetention && (
            <AssetReport
              report={report}
              reportType={ReportType.AssetDetention}
              getColumns={getAssetDetentionColumns}
              defaultColumnVisibilityModel={assetDetentionColumnVisibilityModel}
              searchKeys={ASSET_DETENTION_SEARCH_KEYS}
              initialSortModel={[
                {
                  field: "assetId",
                  sort: "desc",
                },
              ]}
            />
          )}
          {report.type === ReportType.AssetBalance && (
            <AssetReport
              report={report}
              reportType={ReportType.AssetBalance}
              getColumns={() => []}
              defaultColumnVisibilityModel={{}}
              searchKeys={[]}
              initialSortModel={[]}
            />
          )}
        </>
      )}
    </>
  );
};
