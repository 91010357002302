import { Box, Divider, Icon, Typography } from "@mui/material";
import { isNaN, isNumber } from "lodash";
import { TooltipProps } from "recharts";
import { Payload } from "recharts/types/component/DefaultTooltipContent";
import { TooltipLabelMapping } from "../../../../../enums/chartsTooltipMapping";
import { DATE_FORMAT, formatDateToUTC } from "../../../../../utils";
import { formatNumber } from "../../../../../utils/formatters";

export enum DistanceUnitShort {
  Kilometers = "km",
  Miles = "mi",
}

export enum ChartType {
  TripDetails = "tripDetails",
  OdometerTrends = "odometerTrends",
  ReportingDevicesPerPeriod = "reportingDevicesPerPeriod",
  AlertsHistogram = "alertsHistogram",
  Common = "common",
}

export interface ChartTooltipProps {
  type: ChartType;
  payload?: TooltipProps<string, string>["payload"];
  isDistanceUnitMiles?: boolean;
  colorMap?: Record<string, string>;
  formatter?: () => string;
}

export const ChartTooltip: React.FC<ChartTooltipProps> = ({
  type,
  payload,
  isDistanceUnitMiles,
  colorMap,
  formatter,
}) => {
  if (!payload?.length) {
    return null;
  }

  const getReportingDevicesPerPeriodTooltipTitle = () => {
    const value = payload?.reduce((acc, { value }) => {
      acc += Number(value ?? 0);
      return acc;
    }, 0);
    return formatNumber(value ?? 0);
  };

  const generateTooltipTitle = () => {
    switch (type) {
      case ChartType.ReportingDevicesPerPeriod: {
        payload = payload?.reverse();
        return (
          <>
            <Typography
              className="!text-xs !ml-1"
              data-testid={`chart-tooltip-label-${type}`}
            >
              {formatDateToUTC(new Date(payload?.[0]?.payload?.date)).toFormat(
                DATE_FORMAT
              )}
            </Typography>

            <Typography
              className="!text-xs !ml-1 !mt-2"
              data-testid={`chart-tooltip-label-${type}`}
            >
              Number of Devices:{" "}
              <span className="!font-semibold">
                {getReportingDevicesPerPeriodTooltipTitle()}
              </span>
            </Typography>
            <Divider className="w-full !mt-2" />
          </>
        );
      }
      case ChartType.AlertsHistogram:
        return (
          <Typography
            className="!text-xs !text-sub-header-text !font-medium !leading-3"
            data-testid={`chart-tooltip-label-${type}-title`}
          >
            {formatDateToUTC(new Date(payload?.[0].payload?.date)).toFormat(
              DATE_FORMAT
            )}
          </Typography>
        );
      case ChartType.Common:
        const dateKey =
          Object?.keys(payload?.[0].payload).find((key) =>
            key?.toLocaleLowerCase()?.includes("date")
          ) || "date";
        const dateValue = payload?.[0].payload?.[dateKey];
        const dateToShow =
          dateValue?.length > 10 ? dateValue?.slice(0, 10) : dateValue;
        return (
          <Typography
            className="!text-xs !text-sub-header-text !leading-3"
            data-testid={`chart-tooltip-label-${type}-title`}
          >
            {formatDateToUTC(new Date(dateToShow)).toFormat(DATE_FORMAT)}
          </Typography>
        );
      default:
        return (
          <Typography
            className="!text-xs !text-sub-header-text !font-medium !leading-3"
            data-testid={`chart-tooltip-label-${type}-title`}
          >
            {payload?.[0].payload?.date}
          </Typography>
        );
    }
  };

  const generateTooltipValue = (item: Payload<string, string>) => {
    switch (type) {
      case ChartType.ReportingDevicesPerPeriod: {
        return (
          <Typography className="!text-xs !mr-4">
            <Icon
              sx={{
                width: 10,
                height: 10,
                borderRadius: "50%",
                bgcolor: colorMap
                  ? `var(--${colorMap[item?.dataKey as string]})`
                  : "var(--primary)",
                marginRight: "8px",
              }}
            />
            {`${item?.dataKey} - ${formatNumber(Number(item?.value ?? 0))}
            devices`}
          </Typography>
        );
      }
      case ChartType.TripDetails: {
        const value = Number(item?.value);
        const hours = (!isNaN(value) ? value : 0).toFixed(1);
        return `${hours} hours`;
      }
      case ChartType.OdometerTrends: {
        const distanceConvertedToNumber = Number(item?.value);
        const distance =
          isNumber(distanceConvertedToNumber) &&
          !isNaN(distanceConvertedToNumber)
            ? distanceConvertedToNumber
            : 0;
        return `${distance.toFixed(1)} ${
          isDistanceUnitMiles
            ? DistanceUnitShort.Miles
            : DistanceUnitShort.Kilometers
        }`;
      }
      case ChartType.AlertsHistogram: {
        return formatNumber(Number(item?.value ?? 0));
      }
      case ChartType.Common: {
        return !isNaN(Number(item?.value))
          ? formatNumber(Number(item?.value ?? 0))
          : "0";
      }
      default:
        return "";
    }
  };

  return (
    <Box className="rounded-lg bg-custom-tooltip-background border border-sub-header-border p-3 flex flex-col gap-3">
      <Typography
        className="!text-xs !text-sub-header-text !font-medium !leading-3"
        data-testid={`chart-tooltip-label-${type}`}
      >
        {generateTooltipTitle()}
      </Typography>

      {payload.map((item, index) => {
        if (!item) return null;
        const formattedLabel = formatter ? formatter() : null;

        const label =
          formattedLabel ||
          TooltipLabelMapping[
            item?.dataKey as keyof typeof TooltipLabelMapping
          ];
        return (
          <Box
            key={item.dataKey}
            className="flex items-center gap-1"
            data-testid={`chart-tooltip-value-${type}-${index}`}
          >
            <Typography className="!text-xs !text-sub-header-text !leading-3">
              {label}
            </Typography>
            <Typography className="!text-xs !text-sub-header-text !font-black !leading-3">
              {generateTooltipValue(item)}
            </Typography>
          </Box>
        );
      })}
    </Box>
  );
};
