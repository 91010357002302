import {
  CognitoIdentityProviderClient,
  InitiateAuthCommand,
  InitiateAuthCommandInput,
  AuthenticationResultType,
  ChallengeNameType,
  RespondToAuthChallengeCommand,
  RespondToAuthChallengeCommandInput,
  VerifySoftwareTokenCommand,
  VerifySoftwareTokenCommandInput,
  AssociateSoftwareTokenCommand,
  AssociateSoftwareTokenCommandInput,
  RespondToAuthChallengeCommandOutput,
  ForgotPasswordCommand,
  ConfirmForgotPasswordCommand,
  AuthFlowType,
} from "@aws-sdk/client-cognito-identity-provider";
import { SessionStorageItem } from "../../enums/sessionStorage";
import { env } from "../../env";
import { MfaOption } from "../../graphql/operations";
import { navigateToRoute } from "../../utils";
import { NavigationRoutes } from "../../utils/routes/routesUtils";
import {
  handleFailedMfaLogin,
  handleMissingCognitoSession,
  handleMissingRequiredParameter,
  handleNegativeCognitoResponse,
  retrieveParameterFromSessionStorage,
} from "./helpers";
import {
  CodeVerificationParams,
  ForgotPasswordParams,
  HandleMfaChallengeResponseParams,
  HandleMfaSetupChallengeResponseParams,
  MfaSelectChallengeParams,
  MfaSignInParams,
  RespondWithNewPasswordParams,
} from "./types";

export const cognitoClient = new CognitoIdentityProviderClient({
  region: env.REACT_APP_REGION,
});

export const handleMfaChallengeResponse = async ({
  challengeName,
  challengeParameters,
}: HandleMfaChallengeResponseParams) => {
  switch (challengeName) {
    case ChallengeNameType.SOFTWARE_TOKEN_MFA: {
      return navigateToRoute(NavigationRoutes.MfaVerifyAuthAppCode);
    }
    case ChallengeNameType.SMS_MFA: {
      sessionStorage.setItem(
        SessionStorageItem.CodeDeliveryDestination,
        String(challengeParameters?.CODE_DELIVERY_DESTINATION)
      );
      return navigateToRoute(NavigationRoutes.MfaSmsVerification);
    }
    default:
      return;
  }
};

export const handleMfaSetupChallengeResponse = async ({
  username,
  challengeName,
  dispatch,
}: HandleMfaSetupChallengeResponseParams) => {
  switch (challengeName) {
    case ChallengeNameType.MFA_SETUP: {
      await performInitialMfaSetup({
        selectedMfaType: MfaOption.SoftwareTokenMfa,
        username,
        dispatch,
      });

      return navigateToRoute(NavigationRoutes.MfaAuthAppSetup);
    }
    case ChallengeNameType.SELECT_MFA_TYPE: {
      const cognitoResponse = await respondToSelectMfaChallenge({
        selectedMfaType: MfaOption.SmsMfa,
        username,
        dispatch,
      });

      const { ChallengeName, ChallengeParameters } =
        cognitoResponse as RespondToAuthChallengeCommandOutput;

      return await handleMfaChallengeResponse({
        challengeName: ChallengeName!,
        challengeParameters: ChallengeParameters,
      });
    }
    default:
      return;
  }
};

export const performInitialMfaSetup = async ({
  selectedMfaType,
  username,
  dispatch,
}: MfaSelectChallengeParams): Promise<RespondToAuthChallengeCommandOutput | void> => {
  const session = retrieveParameterFromSessionStorage(
    SessionStorageItem.Session
  );
  if (!session) {
    return handleMissingCognitoSession(dispatch);
  }

  switch (selectedMfaType) {
    case MfaOption.SmsMfa: {
      const command = new RespondToAuthChallengeCommand({
        ClientId: env.REACT_APP_COGNITO_CLIENT_ID,
        Session: session,
        ChallengeName: ChallengeNameType.MFA_SETUP,
        ChallengeResponses: {
          USERNAME: username,
          SMS_MFA: "true",
        },
      });

      const response = await cognitoClient.send(command);

      if (response?.$metadata?.httpStatusCode !== 200) {
        return handleNegativeCognitoResponse(
          dispatch,
          response?.$metadata?.httpStatusCode
        );
      }

      return response;
    }
    case MfaOption.SoftwareTokenMfa: {
      const associateSoftwareTokenInput: AssociateSoftwareTokenCommandInput = {
        Session: session, // Use the session returned from the initial Auth response
      };

      const associateAuthAppCommand = new AssociateSoftwareTokenCommand(
        associateSoftwareTokenInput
      );
      const response = await cognitoClient.send(associateAuthAppCommand);

      if (response?.$metadata?.httpStatusCode !== 200) {
        return handleNegativeCognitoResponse(
          dispatch,
          response?.$metadata?.httpStatusCode
        );
      }

      sessionStorage.setItem(
        SessionStorageItem.Session,
        response.Session ?? ""
      );
      sessionStorage.setItem(
        SessionStorageItem.SecretCodeForAuthApp,
        response.SecretCode ?? ""
      );

      return response;
    }
    default: {
      return;
    }
  }
};

export const respondToSelectMfaChallenge = async ({
  selectedMfaType,
  username,
  dispatch,
}: MfaSelectChallengeParams): Promise<RespondToAuthChallengeCommandOutput | void> => {
  const session = retrieveParameterFromSessionStorage(
    SessionStorageItem.Session
  );

  if (!session) {
    return handleMissingCognitoSession(dispatch);
  }

  const command = new RespondToAuthChallengeCommand({
    ClientId: env.REACT_APP_COGNITO_CLIENT_ID,
    Session: session,
    ChallengeName: ChallengeNameType.SELECT_MFA_TYPE,
    ChallengeResponses: {
      USERNAME: username,
      ANSWER: selectedMfaType,
    },
  });

  const response = await cognitoClient.send(command);

  if (response?.$metadata?.httpStatusCode !== 200) {
    return handleNegativeCognitoResponse(
      dispatch,
      response?.$metadata?.httpStatusCode
    );
  }

  return response;
};

export const forgotPassword = async ({
  username,
  dispatch,
}: ForgotPasswordParams) => {
  const params = {
    ClientId: env.REACT_APP_COGNITO_CLIENT_ID,
    Username: username,
  };

  try {
    const command = new ForgotPasswordCommand(params);
    const response = await cognitoClient.send(command);

    if (response?.$metadata?.httpStatusCode !== 200) {
      handleNegativeCognitoResponse(
        dispatch,
        response?.$metadata?.httpStatusCode
      );

      throw new Error("Cognito responded with a non-200 status code");
    }

    return response;
  } catch (error) {
    console.error("Error initiating forgot password: ", error);
    throw error;
  }
};

export const confirmForgotPassword = async (
  username: string,
  code: string,
  newPassword: string
) => {
  const params = {
    ClientId: env.REACT_APP_COGNITO_CLIENT_ID,
    Username: username,
    ConfirmationCode: code,
    Password: newPassword,
  };

  try {
    const command = new ConfirmForgotPasswordCommand(params);
    const response = await cognitoClient.send(command);
    return response;
  } catch (error) {
    console.error("Error confirming forgot password: ", error);
    throw error;
  }
};

// TODO: refactor the response of this method to be InitiateAuthCommandOutput
export const signIn = async (
  username: string,
  password: string
): Promise<
  | { type: "AuthenticationResult"; result: AuthenticationResultType }
  | {
      type: "Challenge";
      challengeName: ChallengeNameType;
      challengeParameters?: any;
    }
  | void
> => {
  const params: InitiateAuthCommandInput = {
    AuthFlow: AuthFlowType.USER_PASSWORD_AUTH,
    ClientId: env.REACT_APP_COGNITO_CLIENT_ID,
    AuthParameters: {
      USERNAME: username,
      PASSWORD: password,
    },
  };
  try {
    const command = new InitiateAuthCommand(params);
    const response = await cognitoClient.send(command);

    const {
      AuthenticationResult,
      ChallengeName,
      Session,
      ChallengeParameters,
    } = response;

    if (!ChallengeName) {
      if (AuthenticationResult) {
        return {
          type: "AuthenticationResult",
          result: AuthenticationResult,
        };
      }
    } else {
      sessionStorage.setItem(SessionStorageItem.Session, Session ?? "");
      sessionStorage.setItem(SessionStorageItem.Username, username ?? "");
      return {
        type: "Challenge",
        challengeName: ChallengeName,
        challengeParameters: ChallengeParameters,
      };
    }
  } catch (error) {
    console.error("Error signing in: ", error);
    throw error;
  }
};

export const mfaSignIn = async ({
  session,
  username,
  code,
  dispatch,
}: MfaSignInParams): Promise<AuthenticationResultType | void> => {
  const challengeResponse = {
    ClientId: env.REACT_APP_COGNITO_CLIENT_ID,
    ChallengeName: ChallengeNameType.SOFTWARE_TOKEN_MFA,
    Session: session,
    ChallengeResponses: {
      USERNAME: username,
      SOFTWARE_TOKEN_MFA_CODE: code,
    },
  } as RespondToAuthChallengeCommandInput;
  try {
    const mfaCommand = new RespondToAuthChallengeCommand(challengeResponse);
    const mfaResponse = await cognitoClient.send(mfaCommand);

    if (mfaResponse?.$metadata?.httpStatusCode !== 200) {
      return handleNegativeCognitoResponse(
        dispatch,
        mfaResponse?.$metadata?.httpStatusCode
      );
    }

    if (mfaResponse.AuthenticationResult) {
      return mfaResponse.AuthenticationResult;
    } else {
      handleFailedMfaLogin(dispatch, mfaResponse);
      throw new Error("MFA failed");
    }
  } catch (error) {
    handleFailedMfaLogin(dispatch, undefined, error);
    throw error;
  }
};

export const verifySMSCode = async ({
  code,
  dispatch,
}: CodeVerificationParams): Promise<any> => {
  const session = retrieveParameterFromSessionStorage(
    SessionStorageItem.Session
  );

  if (!session) {
    return handleMissingCognitoSession(dispatch);
  }

  const username = retrieveParameterFromSessionStorage(
    SessionStorageItem.Username
  );

  if (!username) {
    return handleMissingRequiredParameter(
      SessionStorageItem.Username,
      dispatch
    );
  }

  const input: RespondToAuthChallengeCommandInput = {
    ClientId: env.REACT_APP_COGNITO_CLIENT_ID,
    Session: session,
    ChallengeName: ChallengeNameType.SMS_MFA,
    ChallengeResponses: {
      USERNAME: username,
      SMS_MFA_CODE: code,
    },
  };

  const command = new RespondToAuthChallengeCommand(input);
  const response = await cognitoClient.send(command);

  if (response?.$metadata?.httpStatusCode !== 200) {
    handleNegativeCognitoResponse(
      dispatch,
      response?.$metadata?.httpStatusCode
    );
    throw new Error("SMS MFA failed");
  }

  if (response.AuthenticationResult) {
    return response.AuthenticationResult;
  } else {
    handleFailedMfaLogin(dispatch, response, "SMS MFA failed!");
    throw new Error("SMS MFA failed");
  }
};

export const verifySecretCodeForAuthApp = async ({
  code,
  dispatch,
}: CodeVerificationParams): Promise<any> => {
  const session = retrieveParameterFromSessionStorage(
    SessionStorageItem.Session
  );

  if (!session) {
    return handleMissingCognitoSession(dispatch);
  }

  const username = retrieveParameterFromSessionStorage(
    SessionStorageItem.Username
  );

  if (!username) {
    return handleMissingRequiredParameter(
      SessionStorageItem.Username,
      dispatch
    );
  }

  const password = retrieveParameterFromSessionStorage(
    SessionStorageItem.Password
  );

  if (!password) {
    return handleMissingRequiredParameter(
      SessionStorageItem.Password,
      dispatch
    );
  }

  const inputSecretCodeVerify: VerifySoftwareTokenCommandInput = {
    Session: session, // Use the session returned from the initial Auth response
    UserCode: code,
    FriendlyDeviceName: "My Device",
  };

  const secretCodeVerifyCommand = new VerifySoftwareTokenCommand(
    inputSecretCodeVerify
  );

  const secretCodeVerifyCommandResponse = await cognitoClient.send(
    secretCodeVerifyCommand
  );

  if (secretCodeVerifyCommandResponse.$metadata?.httpStatusCode !== 200) {
    handleNegativeCognitoResponse(
      dispatch,
      secretCodeVerifyCommandResponse.$metadata?.httpStatusCode
    );

    throw new Error("MFA failed");
  }

  if (secretCodeVerifyCommandResponse.Status === "SUCCESS") {
    return await signIn(username, password);
  }
};

export const respondWithNewPassword = async ({
  password,
  dispatch,
}: RespondWithNewPasswordParams): Promise<RespondToAuthChallengeCommandOutput | void> => {
  const session = retrieveParameterFromSessionStorage(
    SessionStorageItem.Session
  );

  if (!session) {
    return handleMissingCognitoSession(dispatch);
  }

  const username = retrieveParameterFromSessionStorage(
    SessionStorageItem.Username
  );

  if (!username) {
    return handleMissingRequiredParameter(
      SessionStorageItem.Username,
      dispatch
    );
  }

  const inputSendNewPassword: RespondToAuthChallengeCommandInput = {
    ClientId: env.REACT_APP_COGNITO_CLIENT_ID,
    Session: session, // Use the session returned from the initial Auth response
    ChallengeName: ChallengeNameType.NEW_PASSWORD_REQUIRED,
    ChallengeResponses: {
      USERNAME: username,
      NEW_PASSWORD: password,
    },
  };

  const sendNewPasswordCommand = new RespondToAuthChallengeCommand(
    inputSendNewPassword
  );
  const newPasswordResponse = await cognitoClient.send(sendNewPasswordCommand);

  if (newPasswordResponse?.$metadata?.httpStatusCode !== 200) {
    return handleNegativeCognitoResponse(
      dispatch,
      newPasswordResponse?.$metadata?.httpStatusCode
    );
  }

  sessionStorage.setItem(SessionStorageItem.Password, password);
  sessionStorage.setItem(
    SessionStorageItem.Session,
    newPasswordResponse.Session ?? ""
  );

  return newPasswordResponse;
};

export const resendSMSCode = async (
  username: string,
  password: string
): Promise<any> => {
  try {
    return await signIn(username, password);
  } catch (error) {
    console.error("Error reseting sms code: ", error);
    throw error;
  }
};
