import { TableColumnFormat as ReportColumnFormat } from "../../../../../graphql/operations";
import {
  columnTimezoneDateTimeFormatParams,
  imeiValueFormatter,
} from "../../../../../utils";
import { ReportGridColDef } from "../../../helpers/helpers";

export const defaultColumnVisibilityModel = {
  installDate: false,
  company: false,
  imei: false,
  vin: false,
  assetId: false,
  installerUserName: false,
  installStatus: false,
  location: false,
};

export const getColumns = (timezone: string): ReportGridColDef[] => [
  {
    field: "installDate",
    headerName: "Install Date",
    flex: 1,
    minWidth: 120,
    format: ReportColumnFormat.IsoDateTime,
    ...columnTimezoneDateTimeFormatParams(timezone),
  },
  {
    field: "company",
    headerName: "Organization",
    flex: 1,
    minWidth: 120,
    format: ReportColumnFormat.String,
    filterable: false,
    type: "string",
  },
  {
    field: "assetId",
    headerName: "Asset ID",
    flex: 1,
    minWidth: 120,
    format: ReportColumnFormat.ImeiString,
    valueFormatter: imeiValueFormatter,
    type: "string",
  },
  {
    field: "vin",
    headerName: "VIN",
    format: ReportColumnFormat.String,
    type: "string",
  },
  {
    field: "imei",
    headerName: "Device ID",
    flex: 1,
    minWidth: 120,
    format: ReportColumnFormat.ImeiString,
    valueFormatter: imeiValueFormatter,
    type: "string",
  },
  {
    field: "installerUserName",
    headerName: "Installer",
    type: "string",
    flex: 1,
    minWidth: 120,
    format: ReportColumnFormat.String,
  },
  {
    field: "installStatus",
    headerName: "Install Status",
    flex: 1,
    minWidth: 120,
    format: ReportColumnFormat.String,
    filterable: false,
    sortable: false,
  },
  {
    field: "locationLatitude",
    headerName: "Latitude",
    flex: 1,
    minWidth: 120,
    sortable: false,
    format: ReportColumnFormat.String,
    filterable: true,
    type: "number",
  },
  {
    field: "locationLongitude",
    headerName: "Longitude",
    flex: 1,
    minWidth: 120,
    sortable: false,
    format: ReportColumnFormat.String,
    type: "number",
    filterable: true,
  },
];
