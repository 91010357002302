import { ReactElement } from "react";
import CheckCircleOutlinedIcon from "@mui/icons-material/CheckCircleOutlined";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import NotificationImportantOutlinedIcon from "@mui/icons-material/NotificationImportantOutlined";
import ReportProblemOutlinedIcon from "@mui/icons-material/ReportProblemOutlined";
import SensorsOutlinedIcon from "@mui/icons-material/SensorsOutlined";
import { v4 } from "uuid";
import { ColorsPalette } from "../../../../../../design-system/colors-palette";
import {
  Maybe,
  SensorStatus,
  SensorStatusUppercase,
} from "../../../../../../graphql/operations";
import type { AssetWheelEndsSensorState } from "../../TiresTabPanel/components/cards/psiWheelEnd/AxlesSummaryCards";
import type { WheelEndSensorChips } from "../../TiresTabPanel/components/cards/psiWheelEnd/PsiWheelEndSensorWidget";
import { TPMS_Axle } from "../../TiresTabPanel/interfaces";
import { AxleDualImbalanceData } from "../../TiresTabPanel/types";
import {
  AssetShortTooltipSensor,
  DoorSensorState,
  AssetSensorState,
  AssetSensorType,
  AssetTiresSensorState,
  DwellSensorState,
  CargoState,
  CargoUltrasonicState,
} from "../components/AssetShortTooltipSensors/AssetShortTooltipSensors.interface";
import { SensorStateWidget } from "../components/SensorStateWidget/SensorStateWidget";
import { TiresSensorStateWidget } from "../components/TiresSensorStateWidget/TiresSensorStateWidget";

export interface SensorChip {
  label?: string;
  icon?: ReactElement;
  color: string;
}

export interface TiresSensorChips {
  outerLeft: SensorChip;
  outerRight: SensorChip;
  innerLeft?: SensorChip;
  innerRight?: SensorChip;
}

export interface TiresDualImbalanceChips {
  left: SensorChip;
  right: SensorChip;
}

export interface GetSensorStateWidgetInput {
  isFetchingAssetById?: boolean;
}
interface GetSensorStateWidgetHook {
  getSensorStateWidget: (sensor: AssetShortTooltipSensor) => JSX.Element;
  getHealthyStateWidget: (healthySensorsCount: number) => JSX.Element;
  getDefaultSensorChip: (state?: AssetSensorState) => SensorChip;
  getTiresSensorsChips: (
    states: AssetTiresSensorState,
    override?: Partial<{ iconWidth: string; iconHeight: string }>
  ) => TiresSensorChips;
  getDualImbalanceChips: (
    dualImbalanceAxleStates: Maybe<AxleDualImbalanceData> | undefined,
    override?: Partial<{ iconWidth: string; iconHeight: string }>
  ) => TiresDualImbalanceChips;
  getTireSensorChip: (
    state: AssetSensorState,
    override?: Partial<{ iconWidth: string; iconHeight: string }>
  ) => SensorChip;
  getWheelEndSensorsChips: (
    states: AssetWheelEndsSensorState,
    override?: Partial<{ iconWidth: string; iconHeight: string }>
  ) => WheelEndSensorChips;
  getColoredStatusIcon: (status?: SensorStatusUppercase) => React.ReactNode;
}

export const useGetSensorStateWidget = ({
  isFetchingAssetById = false,
}: GetSensorStateWidgetInput = {}): GetSensorStateWidgetHook => {
  // Fn is used in the map tooltip to render the widget
  // Renders a small Rectangle with the status text inside
  const getSensorStateWidget = ({
    type,
    sensorLabel,
    state,
    icon,
    chipLabel,
    justifyBetween,
  }: AssetShortTooltipSensor): JSX.Element => {
    const key = v4();
    switch (type) {
      case AssetSensorType.Tires: {
        const sensorsChips = getTiresSensorsChips(
          state as AssetTiresSensorState
        );
        const dualImbalanceChips = getDualImbalanceChips(
          state as AxleDualImbalanceData
        );

        return (
          <TiresSensorStateWidget
            sensorLabel={sensorLabel as TPMS_Axle}
            dualImbalanceChips={dualImbalanceChips}
            sensorsChips={sensorsChips}
            key={key}
          />
        );
      }
      case AssetSensorType.Door: {
        const sensorChip = getDoorSensorChip(state as DoorSensorState);

        return (
          <SensorStateWidget
            sensorLabel={sensorLabel}
            sensorChip={sensorChip}
            icon={icon}
            key={key}
            isFetching={isFetchingAssetById}
          />
        );
      }
      case AssetSensorType.CargoUltrasonic:
      case AssetSensorType.CargoVision: {
        const sensorChip = getCargoSensorChip(state as CargoState);

        return (
          <SensorStateWidget
            sensorLabel={sensorLabel}
            sensorChip={sensorChip}
            icon={icon}
            key={key}
            isFetching={isFetchingAssetById}
          />
        );
      }
      case AssetSensorType.Dwell: {
        const sensorChip = getDwellSensorChip(
          state as DwellSensorState,
          chipLabel!
        );

        return (
          <SensorStateWidget
            sensorLabel={sensorLabel}
            sensorChip={sensorChip}
            icon={icon}
            key={key}
            isFetching={isFetchingAssetById}
          />
        );
      }
      case AssetSensorType.Battery:
      case AssetSensorType.Liftgate:
      case AssetSensorType.Lamp:
      case AssetSensorType.TemperatureInternal:
      case AssetSensorType.AtisAlpha:
      case AssetSensorType.LiteSentryGamma:
      case AssetSensorType.WheelEndTemperature:
      default: {
        const sensorChip = getDefaultSensorChip(state as AssetSensorState);

        return (
          <SensorStateWidget
            sensorLabel={sensorLabel}
            sensorChip={sensorChip}
            icon={icon}
            key={key}
            isFetching={isFetchingAssetById}
            justifyBetween={justifyBetween}
          />
        );
      }
    }
  };

  const getHealthyStateWidget = (healthySensorsCount: number): JSX.Element => {
    const sensorChip: SensorChip = {
      label: "Healthy",
      color: "bg-success",
      icon: (
        <CheckCircleOutlinedIcon
          data-testid="healthy-sensors-chip-icon"
          sx={{
            color: "var(--white)",
            height: "12px",
            width: "12px",
          }}
        />
      ),
    };
    const sensorLabel = `${healthySensorsCount} ${
      healthySensorsCount > 1 ? "Sensors" : "Sensor"
    }`;
    const icon = (
      <SensorsOutlinedIcon
        data-testid="healthy-sensors-icon"
        sx={{
          color: "var(--typography-tooltip)",
          height: "18px",
          width: "18px",
        }}
      />
    );

    return (
      <SensorStateWidget
        sensorLabel={sensorLabel}
        sensorChip={sensorChip}
        icon={icon}
        isFetching={isFetchingAssetById}
      />
    );
  };

  const getDefaultSensorChip = (state?: AssetSensorState): SensorChip => {
    const iconStyles = {
      color: "var(--white)",
      height: "12px",
      width: "12px",
    };

    switch (state) {
      case SensorStatus.Healthy:
      case SensorStatusUppercase.Healthy:
        return {
          label: "Healthy",
          color: ColorsPalette.Success,
          icon: (
            <CheckCircleOutlinedIcon
              data-testid="sensor-healthy-icon"
              sx={iconStyles}
            />
          ),
        };
      case SensorStatus.Warning:
      case SensorStatusUppercase.Warning:
        return {
          label: "Warning",
          color: ColorsPalette.Caution,
          icon: (
            <NotificationImportantOutlinedIcon
              data-testid="sensor-warning-icon"
              sx={iconStyles}
            />
          ),
        };
      case SensorStatus.Alert:
      case SensorStatusUppercase.Alert:
        return {
          label: "Alert",
          color: ColorsPalette.Alert,
          icon: (
            <ReportProblemOutlinedIcon
              data-testid="sensor-alert-icon"
              sx={iconStyles}
            />
          ),
        };
      case SensorStatus.Critical:
      case SensorStatusUppercase.Critical:
        return {
          label: "Critical",
          color: ColorsPalette.Error,
          icon: (
            <ErrorOutlineIcon
              data-testid="sensor-critical-icon"
              sx={iconStyles}
            />
          ),
        };
      default:
        return {
          label: "Unknown",
          color: "bg-gray",
          icon: (
            <ErrorOutlineIcon
              data-testid="sensor-unknown-icon"
              sx={iconStyles}
            />
          ),
        };
    }
  };

  const getDoorSensorChip = (state?: AssetSensorState): SensorChip => {
    const iconStyles = {
      color: "var(--white)",
      height: "12px",
      width: "12px",
    };

    switch (state) {
      case DoorSensorState.Open:
        return {
          label: "Open",
          color: "var(--primary-blue)",
          icon: (
            <ErrorOutlineIcon data-testid="door-open-icon" sx={iconStyles} />
          ),
        };
      case DoorSensorState.Closed:
        return {
          label: "Closed",
          color: "var(--primary-blue)",
          icon: (
            <ErrorOutlineIcon data-testid="door-closed-icon" sx={iconStyles} />
          ),
        };
      default:
        return {
          label: "Unknown",
          color: "var(--gray)",
          icon: (
            <ErrorOutlineIcon data-testid="door-unknown-icon" sx={iconStyles} />
          ),
        };
    }
  };

  const getCargoSensorChip = (
    state?: CargoState | CargoUltrasonicState
  ): SensorChip => {
    const iconStyles = {
      color: "var(--white)",
      height: "12px",
      width: "12px",
    };
    switch (state) {
      case CargoUltrasonicState.Unloaded:
      case CargoState.Empty:
        return {
          label: "Empty",
          color: "var(--primary-blue)",
          icon: (
            <ErrorOutlineIcon data-testid="door-open-icon" sx={iconStyles} />
          ),
        };
      case CargoUltrasonicState.Loaded:
      case CargoState.Loaded:
        return {
          label: "Loaded",
          color: "var(--primary-blue)",
          icon: (
            <ErrorOutlineIcon data-testid="door-closed-icon" sx={iconStyles} />
          ),
        };
      default:
        return {
          label: "Unknown",
          color: "bg-gray",
          icon: (
            <ErrorOutlineIcon data-testid="door-unknown-icon" sx={iconStyles} />
          ),
        };
    }
  };

  const getTiresSensorsChips = (
    states: AssetTiresSensorState,
    override?: Partial<{ iconWidth: string; iconHeight: string }>
  ): TiresSensorChips => {
    let tiresSensorChips: TiresSensorChips = {
      outerLeft: getTireSensorChip(states.outerLeft, override),
      outerRight: getTireSensorChip(states.outerRight, override),
    };

    if (states.innerLeft !== undefined && states.innerRight !== undefined) {
      tiresSensorChips.innerLeft = getTireSensorChip(
        states.innerLeft,
        override
      );
      tiresSensorChips.innerRight = getTireSensorChip(
        states.innerRight,
        override
      );
    }

    return tiresSensorChips;
  };

  const getDualImbalanceChips = (
    dualImbalanceAxleStates: Maybe<AxleDualImbalanceData> | undefined,
    override?: Partial<{ iconWidth: string; iconHeight: string }>
  ): TiresDualImbalanceChips => {
    return {
      left: getTireSensorChip(
        dualImbalanceAxleStates?.left?.status as SensorStatusUppercase,
        override
      ),
      right: getTireSensorChip(
        dualImbalanceAxleStates?.right?.status as SensorStatusUppercase,
        override
      ),
    };
  };

  /**
   * Returns icon element depending on status
   */
  const getTireSensorChip = (
    state: AssetSensorState,
    override?: Partial<{ iconWidth: string; iconHeight: string }>
  ): SensorChip => {
    const iconStyles = {
      height: override?.iconWidth ?? "12px",
      width: override?.iconHeight ?? "12px",
    };

    switch (state) {
      case SensorStatus.Healthy:
      case SensorStatusUppercase.Healthy:
        return {
          label: "Healthy",
          color: "bg-tire-sensor-chip-healthy",
          icon: (
            <CheckCircleOutlinedIcon
              data-testid="tire-sensor-healthy-icon"
              sx={{
                color: "var(--tire-sensor-chip-healthy-icon)",
                ...iconStyles,
              }}
            />
          ),
        };
      case SensorStatus.Alert:
      case SensorStatusUppercase.Alert:
        return {
          label: "Alert",
          color: "bg-tire-sensor-chip-alert",
          icon: (
            <ReportProblemOutlinedIcon
              data-testid="tire-sensor-alert-icon"
              sx={{
                color: "var(--tire-sensor-chip-alert-icon)",
                ...iconStyles,
              }}
            />
          ),
        };
      case SensorStatus.Warning:
      case SensorStatusUppercase.Warning:
        return {
          label: "Warning",
          color: "bg-tire-sensor-chip-warning",
          icon: (
            <NotificationImportantOutlinedIcon
              data-testid="tire-sensor-warning-icon"
              sx={{
                color: "var(--tire-sensor-chip-warning-icon)",
                ...iconStyles,
              }}
            />
          ),
        };
      case SensorStatus.Critical:
      case SensorStatusUppercase.Critical:
        return {
          label: "Critical",
          color: "bg-tire-sensor-chip-critical",
          icon: (
            <ErrorOutlineIcon
              data-testid="tire-sensor-critical-icon"
              sx={{
                color: "var(--tire-sensor-chip-critical-icon)",
                ...iconStyles,
              }}
            />
          ),
        };
      default:
        return {
          label: "Unknown",
          color: "bg-tire-sensor-chip-critical",
          icon: (
            <ErrorOutlineIcon
              data-testid="tire-sensor-unknown-icon"
              sx={iconStyles}
            />
          ),
        };
    }
  };

  const getDwellSensorChip = (
    state: DwellSensorState,
    chipLabel: string
  ): SensorChip | null => {
    if (chipLabel === "0 days") return null;

    switch (state) {
      case DwellSensorState.Low:
        return {
          label: chipLabel,
          color: ColorsPalette.Success,
          icon: (
            <CheckCircleOutlinedIcon
              data-testid="dwell-sensors-chip-icon"
              sx={{
                color: "var(--white)",
                height: "12px",
                width: "12px",
              }}
            />
          ),
        };
      case DwellSensorState.Medium:
        return {
          label: chipLabel,
          color: ColorsPalette.Caution,
          icon: (
            <NotificationImportantOutlinedIcon
              data-testid="dwell-sensors-chip-icon"
              sx={{
                color: "var(--white)",
                height: "12px",
                width: "12px",
              }}
            />
          ),
        };
      case DwellSensorState.Large:
        return {
          label: chipLabel,
          color: ColorsPalette.Alert,
          icon: (
            <ReportProblemOutlinedIcon
              data-testid="dwell-sensors-chip-icon"
              sx={{
                color: "var(--white)",
                height: "12px",
                width: "12px",
              }}
            />
          ),
        };
      case DwellSensorState.Mega:
        return {
          label: chipLabel,
          color: ColorsPalette.Error,
          icon: (
            <ErrorOutlineIcon
              data-testid="dwell-sensors-chip-icon"
              sx={{
                color: "var(--white)",
                height: "12px",
                width: "12px",
              }}
            />
          ),
        };
      default:
        return {
          label: chipLabel,
          color: ColorsPalette.LightGray,
          icon: (
            <ErrorOutlineIcon
              data-testid="dwell-sensors-chip-icon"
              sx={{
                color: "var(--white)",
                height: "12px",
                width: "12px",
              }}
            />
          ),
        };
    }
  };

  const getWheelEndSensorsChips = (
    states: AssetWheelEndsSensorState,
    override?: Partial<{ iconWidth: string; iconHeight: string }>
  ): WheelEndSensorChips => {
    const wheelEndSensorChips: WheelEndSensorChips = {
      leftWheelEnd: getTireSensorChip(states.leftWheelEnd, override),
      rightWheelEnd: getTireSensorChip(states.rightWheelEnd, override),
    };

    return wheelEndSensorChips;
  };

  const getColoredStatusIcon = (status?: SensorStatusUppercase) => {
    switch (status) {
      case SensorStatusUppercase.Healthy:
        return <CheckCircleOutlinedIcon sx={{ color: "var(--success)" }} />;
      case SensorStatusUppercase.Warning:
        return (
          <NotificationImportantOutlinedIcon sx={{ color: "var(--caution)" }} />
        );
      case SensorStatusUppercase.Alert:
        return <ReportProblemOutlinedIcon sx={{ color: "var(--warning)" }} />;
      case SensorStatusUppercase.Critical:
        return <ErrorOutlineIcon sx={{ color: "var(--error)" }} />;
      default:
        return null;
    }
  };

  return {
    getSensorStateWidget,
    getHealthyStateWidget,
    getDefaultSensorChip,
    getTiresSensorsChips,
    getTireSensorChip,
    getWheelEndSensorsChips,
    getDualImbalanceChips,
    getColoredStatusIcon,
  };
};
