import React from "react";
import { QueryClient } from "@tanstack/react-query";
import { PAGE_SNACKBAR } from "../../../../../constants";
import { Action } from "../../../../../context/commonTypes";
import { Automation } from "../../../../../graphql/operations";
import { mapServerErrorCodeToHumanReadableMessage } from "../../../../../utils";
import { NavigationRoutes } from "../../../../../utils/routes/routesUtils";
import { AutomationAction } from "../hooks/useAutomationForm";

type onSuccessCreateCallbackParams = {
  dispatch: React.Dispatch<Action>;
  created: Automation;
  queryClient: QueryClient;
  navigate: (path: string) => void;
  setAutomation: (automation: Automation) => void;
  setLoading: (loading: boolean) => void;
  setShouldCreateAutomation: (shouldCreateAutomation: boolean) => void;
};

export const onSuccessCreateCallback = async ({
  dispatch,
  created,
  navigate,
  queryClient,
  setAutomation,
  setLoading,
  setShouldCreateAutomation,
}: onSuccessCreateCallbackParams) => {
  await queryClient.invalidateQueries(["getAutomations"], {
    refetchType: "all",
  });

  setShouldCreateAutomation(false);
  navigate(`${NavigationRoutes.AdminPanelAutomations}/${created._id}`);
  setAutomation(created);
  dispatch({
    type: PAGE_SNACKBAR,
    payload: {
      title: "Success!",
      text: "Automation was created successfully!",
      severity: "success",
    },
  });
  setLoading(false);
};

type onSuccessUpdateCallbackParams = {
  dispatch: React.Dispatch<Action>;
  updated: Automation;
  queryClient: QueryClient;
  setAutomation: (automation: Automation) => void;
  setLoading: (loading: boolean) => void;
  shouldInvalidateQuery?: boolean;
};

export const onSuccessUpdateCallback = async ({
  dispatch,
  updated,
  queryClient,
  setAutomation,
  setLoading,
  shouldInvalidateQuery = true,
}: onSuccessUpdateCallbackParams) => {
  if (shouldInvalidateQuery) {
    await queryClient.invalidateQueries(["getAutomations"], {
      refetchType: "all",
    });
  }
  setAutomation(updated);
  dispatch({
    type: PAGE_SNACKBAR,
    payload: {
      title: "Success!",
      text: "Automation was updated successfully!",
      severity: "success",
    },
  });
  setLoading(false);
};

type onSuccessArchiveCallbackParams = {
  dispatch: React.Dispatch<Action>;
  queryClient: QueryClient;
  navigate: (path: string) => void;
  setLoading: (loading: boolean) => void;
};

export const onSuccessArchiveCallback = async ({
  dispatch,
  navigate,
  queryClient,
  setLoading,
}: onSuccessArchiveCallbackParams) => {
  await queryClient.invalidateQueries(["getAutomations"], {
    refetchType: "all",
  });
  setLoading(false);

  navigate(`${NavigationRoutes.AdminPanelAutomations}`);
  dispatch({
    type: PAGE_SNACKBAR,
    payload: {
      title: "Success!",
      text: "Automation was deleted successfully!",
      severity: "success",
    },
  });
};

type onErrorCallbackParams = {
  error: unknown;
  dispatch: any;
  setLoading: (loading: boolean) => void;
};

export const createAutomationOnErrorCallback = ({
  error,
  dispatch,
  setLoading,
}: onErrorCallbackParams) => {
  dispatch({
    type: PAGE_SNACKBAR,
    payload: {
      title: "Error creating automation",
      text: mapServerErrorCodeToHumanReadableMessage(
        error instanceof Error ? error.message : "Something Went Wrong."
      ),
      severity: "error",
    },
  });
  setLoading(false);
};

export const updateAutomationOnErrorCallback = ({
  error,
  dispatch,
  setLoading,
}: onErrorCallbackParams) => {
  dispatch({
    type: PAGE_SNACKBAR,
    payload: {
      title: "Error updating automation",
      text: mapServerErrorCodeToHumanReadableMessage(
        error instanceof Error ? error.message : "Something Went Wrong."
      ),
      severity: "error",
    },
  });
  setLoading(false);
};
export const archiveAutomationOnErrorCallback = ({
  error,
  dispatch,
  setLoading,
}: onErrorCallbackParams) => {
  dispatch({
    type: PAGE_SNACKBAR,
    payload: {
      title: "Error deleting automation",
      text: mapServerErrorCodeToHumanReadableMessage(
        error instanceof Error ? error.message : "Something Went Wrong."
      ),
      severity: "error",
    },
  });
  setLoading(false);
};

type onSettledCallbackParams = {
  error: unknown;
  dispatch: any;
};

export const onSettledGetAutomations = ({
  error,
  dispatch,
}: onSettledCallbackParams) => {
  if (error) {
    const displayError = error
      ? (error as { message: string }).message
      : "Something Went Wrong.";
    dispatch({
      type: PAGE_SNACKBAR,
      payload: {
        title: "Automations load failed!",
        text: displayError,
        severity: "error",
      },
    });
  }
};

export const mapAutomationActions = (action: AutomationAction) => {
  return {
    command: action.command,
    name: action.name,
    parameters: action.parameters ? JSON.stringify(action.parameters) : null,
  };
};
