import { useCallback, useMemo } from "react";
import {
  AutocompleteElement,
  FieldValues,
  TextFieldElement,
  useForm,
} from "react-hook-form-mui";
import { yupResolver } from "@hookform/resolvers/yup";
import CloseIcon from "@mui/icons-material/Close";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  ThemeProvider,
} from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import * as yup from "yup";
import { PAGE_SNACKBAR } from "../../../../../constants";
import { useAppContext } from "../../../../../context/AppContext";
import { ConfigurationSetType } from "../../../../../graphql/operations";
import {
  Button as SubmitButton,
  TextButton,
} from "../../../../../shared/components/Button";
import Text from "../../../../../shared/components/Text";
import { useFormTheme } from "../../../../../shared/hooks/theme/useFormTheme";
import useBreakpoint from "../../../../../shared/hooks/useBreakpoint";
import { useGlobalOrganizationFilter } from "../../../../../shared/hooks/useGlobalOrganizationFilter";
import { transformers } from "../../../../../utils";
import { useNomenclatures } from "../../../../AssetsView/TableView/hooks";
import { useConfigurationSetsApi } from "../../../hooks/useConfigurationSetsApi";
import { ERROR_CONFIGURATION_SET_NAME_DUPLICATE } from "../../Configurations/configurationsUtils";
import { useRegionsContext } from "../context";
import { useGetRegionsData } from "../hooks/useGetRegionsData";
import {
  countryToTerritoriesNomenclatures,
  RegionCountry,
  RegionData,
  RegionOptionType,
  TerritoryInUse,
} from "../interfaces";

export interface CreateEditRegionDialogProps {
  open: boolean;
  country: RegionCountry;
  isEditDialog?: boolean;
  regionData?: RegionData | null;
  onClose: () => void;
}

export const schema = yup.object().shape({
  name: yup
    .string()
    .required("Field is required!")
    .transform(transformers.string),
  territories: yup.array().min(1, "Field is required!").required(),
});

export const CreateEditRegionDialog: React.FC<CreateEditRegionDialogProps> = ({
  isEditDialog,
  open,
  country,
  regionData,
  onClose,
}) => {
  // Hooks
  const queryClient = useQueryClient();
  const { dispatch } = useAppContext();
  const {
    territoriesInUseUSA,
    territoriesInUseCanada,
    territoriesInUseMexico,
  } = useRegionsContext();
  const formTheme = useFormTheme();
  const isMobile = useBreakpoint("down", "sm");
  const { getCountryHasOwnRegions } = useGetRegionsData();
  const territoriesNomenclatures = useNomenclatures(
    countryToTerritoriesNomenclatures[country]
  );
  const {
    control,
    handleSubmit,
    reset,
    watch,
    formState: { isDirty, isValid },
  } = useForm({
    resolver: yupResolver(schema),
    values: {
      name: regionData?.name ?? "",
      territories: regionData?.territories ?? [],
    },
  });
  const currentRegionName = watch("name");
  const currentTerritories = watch("territories");
  const globalOrgSelected = useGlobalOrganizationFilter();

  // Memoized Variables
  const territoriesInUse = useMemo(() => {
    const territoriesInUse: TerritoryInUse[] = [];
    const countryHasOwnRegions = getCountryHasOwnRegions(country);

    if (countryHasOwnRegions) {
      // Only add territories in use if the country is not using the default regions, but has its own regions
      switch (country) {
        case RegionCountry.UnitedStates:
          territoriesInUse.push(...territoriesInUseUSA);
          break;
        case RegionCountry.Canada:
          territoriesInUse.push(...territoriesInUseCanada);
          break;
        case RegionCountry.Mexico:
          territoriesInUse.push(...territoriesInUseMexico);
          break;
      }
    }

    return territoriesInUse;
  }, [
    country,
    getCountryHasOwnRegions,
    territoriesInUseUSA,
    territoriesInUseCanada,
    territoriesInUseMexico,
  ]);

  const territoriesOptions: RegionOptionType[] = useMemo(() => {
    const options = territoriesNomenclatures.map((nomenclature) => {
      const territoryInUse = territoriesInUse.find(
        (territory) => territory.territoryName === nomenclature.label
      );
      const label =
        territoryInUse && territoryInUse.regionName !== currentRegionName
          ? `${territoryInUse.territoryName} (${territoryInUse.regionName})`
          : nomenclature.label;

      return {
        id: nomenclature.label,
        label,
      };
    });

    return options;
  }, [territoriesNomenclatures, territoriesInUse, currentRegionName]);

  // API
  const createRegionConfigurationSetOnSuccess = () => {
    queryClient.invalidateQueries(["getConfigurationSets"]);

    dispatch({
      type: PAGE_SNACKBAR,
      payload: {
        severity: "success",
        title: "Region created!",
        text: "Your Region is created successfully.",
      },
    });

    onCloseHandler();
  };

  const createRegionConfigurationSetOnError = (error: Error) => {
    const text =
      error.message === ERROR_CONFIGURATION_SET_NAME_DUPLICATE
        ? "Region name already exists!"
        : "An error occurred while creating your Region.";

    dispatch({
      type: PAGE_SNACKBAR,
      payload: {
        severity: "error",
        title: "Region create failed!",
        text,
      },
    });

    onCloseHandler();
  };

  const updateRegionConfigurationSetOnSuccess = () => {
    queryClient.invalidateQueries(["getConfigurationSets"]);

    dispatch({
      type: PAGE_SNACKBAR,
      payload: {
        severity: "success",
        title: "Region updated!",
        text: "Your Region is updated successfully.",
      },
    });

    onCloseHandler();
  };

  const updateRegionConfigurationSetOnError = (error: Error) => {
    const text =
      error.message === ERROR_CONFIGURATION_SET_NAME_DUPLICATE
        ? "Region name already exists!"
        : "An error occurred while updating your Region.";

    dispatch({
      type: PAGE_SNACKBAR,
      payload: {
        severity: "error",
        title: "Region update failed!",
        text,
      },
    });

    onCloseHandler();
  };

  const {
    isLoadingCreateConfigurationSet,
    createConfigurationSet,

    isLoadingUpdateConfigurationSet,
    updateConfigurationSet,
  } = useConfigurationSetsApi({
    createConfigurationSetOnSuccess: createRegionConfigurationSetOnSuccess,
    createConfigurationSetOnError: createRegionConfigurationSetOnError,

    updateConfigurationSetOnSuccess: updateRegionConfigurationSetOnSuccess,
    updateConfigurationSetOnError: updateRegionConfigurationSetOnError,
  });

  // Handlers
  const onCloseHandler = () => {
    reset();
    onClose();
  };

  const onSubmitHandler = ({ name, territories }: FieldValues) => {
    const regionInputBase = {
      name,
      orgId: globalOrgSelected?.value ?? "",
      value: JSON.stringify({ country, territories }),
    };

    if (!isEditDialog) {
      createConfigurationSet({
        type: ConfigurationSetType.RegionGroup,
        ...regionInputBase,
      });
    } else if (regionData) {
      updateConfigurationSet({ _id: regionData.id, ...regionInputBase });
    }
  };

  const isOptionDisabled = useCallback(
    (option: RegionOptionType) => {
      const isOptionUsedInAnotherRegion = territoriesInUse.some(
        (territory) =>
          territory.regionName !== currentRegionName &&
          territory.territoryName === option.id
      );

      return isOptionUsedInAnotherRegion;
    },
    [currentRegionName, territoriesInUse]
  );

  const isLoading =
    isLoadingUpdateConfigurationSet || isLoadingCreateConfigurationSet;
  const isSubmitDisabled = !(isValid && isDirty) || isLoading;

  const baseDialogTestId = isEditDialog
    ? "edit-region-dialog"
    : "create-region-dialog";
  const baseFormTestId = isEditDialog
    ? "edit-region-form"
    : "create-region-form";

  return (
    <Dialog
      data-testid={baseDialogTestId}
      open={open}
      onClose={onCloseHandler}
      PaperProps={{
        sx: {
          padding: "32px 24px 24px 24px",
          width: "100%",
          maxWidth: "680px !important",
        },
      }}
    >
      <DialogTitle className="flex justify-between items-center !p-0 !pb-[32px]">
        <Text classes="!text-2xl !font-semibold capitalize !text-typography-secondary ">
          {isEditDialog ? "Edit Region" : "Create New Region"}
        </Text>
        <IconButton
          onClick={onCloseHandler}
          className="h-6 w-6 flex align-center"
          aria-label="close"
          data-testid={`${baseDialogTestId}-close-icon`}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <ThemeProvider theme={formTheme}>
        <form onSubmit={handleSubmit(onSubmitHandler)} noValidate>
          <DialogContent className="!p-0 !pb-[24px] !overflow-hidden">
            <Grid
              container
              className="bg-background topPaddingDrawerSection !pt-0"
            >
              <Grid item xs={12} className="!pt-0">
                <TextFieldElement
                  name="name"
                  label="Region Name"
                  fullWidth
                  control={control}
                  sx={{ marginBottom: "24px" }}
                  disabled={isLoading}
                  required
                  placeholder={isMobile ? "Type Name" : "Type Name..."}
                  data-testid={`${baseFormTestId}-name`}
                />
              </Grid>
              <Grid item xs={12} className="!pt-0">
                <AutocompleteElement
                  name="territories"
                  label="States"
                  options={territoriesOptions}
                  autocompleteProps={{
                    getOptionDisabled: isOptionDisabled,
                  }}
                  matchId
                  multiple
                  required
                  control={control}
                  textFieldProps={{
                    inputProps: {
                      placeholder: !currentTerritories.length ? "Select" : "",
                      "data-testid": `${baseFormTestId}-territories`,
                    },
                  }}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions
            sx={{
              justifyContent: isMobile ? "center" : "end",
              gap: "4px",
              flexWrap: "wrap",
            }}
          >
            <TextButton
              text="Cancel"
              size="medium"
              theme="blue"
              disabled={isLoading}
              onClick={onCloseHandler}
              data-testid={`${baseFormTestId}-cancel-button`}
            />

            <SubmitButton
              size="medium"
              theme="blue"
              variant="default"
              type="submit"
              text={isLoading ? "Saving..." : "Save"}
              disabled={isSubmitDisabled}
              sx={{ padding: "24px", margin: 0 }}
              dataTestid={`${baseFormTestId}-submit-button`}
            />
          </DialogActions>
        </form>
      </ThemeProvider>
    </Dialog>
  );
};
