import { ColorsPalette } from "../../../../../design-system/colors-palette";

export const AIR_BAG_TRENDING_GRAPH_COLORS_LIGHT = [
  ColorsPalette.LightBlue,
  ColorsPalette.FlatBlack,
  ColorsPalette.Alert,
  ColorsPalette.AccentGreen,
  ColorsPalette.ReChartsPurple,
  ColorsPalette.ReChartsCoral,
];
export const AIR_BAG_TRENDING_GRAPH_COLORS_DARK = [
  ColorsPalette.LightBlue,
  ColorsPalette.FeatherGray,
  ColorsPalette.Alert,
  ColorsPalette.AccentGreen,
  ColorsPalette.ReChartsPurple,
  ColorsPalette.ReChartsCoral,
];

export enum AirBagTrendingGraphLegendKeys {
  AxleOneLeftAirBag = "airbag.avg_airbag_pressure_mbar_0.value",
  AxleOneRightAirBag = "airbag.avg_airbag_pressure_mbar_1.value",
}
