import { FC } from "react";
import {
  AutocompleteElement,
  Control,
  TextFieldElement,
} from "react-hook-form-mui";
import {
  Accordion,
  AccordionSummary,
  Grid,
  ThemeProvider,
} from "@mui/material";
import { ORG_FORM_FIELDS } from "../../../../../constants/organizations";
import {
  DistanceUnit,
  PressureUnit,
  TemperatureUnit,
} from "../../../../../graphql/operations";
import { useFormTheme } from "../../../../../shared/hooks/theme/useFormTheme";
import {
  AutocompleteOption,
  FormFieldDropdownOption,
} from "../../../../../types";
import { UnitDropDownItem } from "../orgUtils";
import { FormFieldsData } from "../types";

interface OrgFormProps {
  control: Control<FormFieldsData, any>;
  breakpoints: {
    xs: number;
  };
  brandOptions: AutocompleteOption[];
  parentOrganizationOptions: AutocompleteOption[];
  orgTypeOptions: FormFieldDropdownOption[];
  timezonesOptions: FormFieldDropdownOption[];
  distanceUnitsOptions: UnitDropDownItem<DistanceUnit>[];
  pressureUnitsOptions: UnitDropDownItem<PressureUnit>[];
  temperatureUnitsOptions: UnitDropDownItem<TemperatureUnit>[];
  dashboardOptions: AutocompleteOption[];
  isEditMode: boolean;
  isNotAnAdmin: boolean;
  isPctMember: boolean;
}

const OrgForm: FC<OrgFormProps> = ({
  control,
  breakpoints,
  brandOptions,
  parentOrganizationOptions,
  orgTypeOptions,
  timezonesOptions,
  distanceUnitsOptions,
  pressureUnitsOptions,
  temperatureUnitsOptions,
  dashboardOptions,
  isEditMode,
  isNotAnAdmin,
  isPctMember,
}) => {
  const formTheme = useFormTheme();

  const {
    orgNameInput,
    accountNumberInput,
    parentOrgInput,
    brandInput,
    orgTypeInput,
    timezoneInput,
    distanceUnitInput,
    pressureUnitInput,
    temperatureUnitInput,
    defaultDashboardInput,
    favoriteDashboardsInput,
  } = ORG_FORM_FIELDS;

  return (
    <ThemeProvider theme={formTheme}>
      <form data-testid="form-org-create-edit">
        <Grid container className="bg-background noTopPaddingDrawerSection">
          <Grid item {...breakpoints}>
            <TextFieldElement
              fullWidth
              control={control}
              name={orgNameInput.name as keyof FormFieldsData}
              required
              label={orgNameInput.label}
              inputProps={{
                "data-testid": orgNameInput.dataTestid,
              }}
            />
          </Grid>

          <Grid item {...breakpoints}>
            <TextFieldElement
              fullWidth
              control={control}
              name={accountNumberInput.name as keyof FormFieldsData}
              required={accountNumberInput.required}
              label={accountNumberInput.label}
              disabled={isEditMode}
              helperText={
                isEditMode ? accountNumberInput.helperText : undefined
              }
              InputProps={{
                readOnly: isEditMode,
              }}
              inputProps={{
                "data-testid": accountNumberInput.dataTestid,
              }}
            />
          </Grid>

          <Grid item {...breakpoints}>
            <AutocompleteElement
              autocompleteProps={{
                renderOption: (props, option) => (
                  <li {...props} key={option.id}>
                    {option.label}
                  </li>
                ),
                disabled: isNotAnAdmin || !parentOrganizationOptions.length,
              }}
              matchId
              rules={{ required: true }}
              required
              label={parentOrgInput.label}
              control={control}
              name={parentOrgInput.name as keyof FormFieldsData}
              options={parentOrganizationOptions}
              textFieldProps={{
                inputProps: { "data-testid": parentOrgInput.dataTestid },
              }}
            />
          </Grid>

          {isPctMember && (
            <Grid item {...breakpoints}>
              <AutocompleteElement
                loading={!brandOptions.length}
                rules={{ required: isPctMember }}
                matchId={true}
                label={brandInput.label}
                control={control}
                name={brandInput.name as keyof FormFieldsData}
                options={brandOptions}
                textFieldProps={{
                  inputProps: { "data-testid": brandInput.dataTestid },
                }}
              />
            </Grid>
          )}

          <Grid item {...breakpoints}>
            <AutocompleteElement
              loading={!orgTypeOptions.length}
              rules={{ required: true }}
              matchId={true}
              required
              label={orgTypeInput.label}
              control={control}
              name={orgTypeInput.name as keyof FormFieldsData}
              options={orgTypeOptions}
              textFieldProps={{
                inputProps: { "data-testid": orgTypeInput.dataTestid },
              }}
            />
          </Grid>

          <Grid item {...breakpoints}>
            <AutocompleteElement
              loading={!timezonesOptions.length}
              rules={{ required: true }}
              matchId={true}
              required
              label={timezoneInput.label}
              control={control}
              name={timezoneInput.name as keyof FormFieldsData}
              options={timezonesOptions}
              textFieldProps={{
                inputProps: { "data-testid": timezoneInput.dataTestid },
              }}
            />
          </Grid>

          <Grid item {...breakpoints}>
            <AutocompleteElement
              name={defaultDashboardInput.name as keyof FormFieldsData}
              label={defaultDashboardInput.label}
              options={dashboardOptions}
              matchId={true}
              control={control}
              textFieldProps={{
                inputProps: { "data-testid": defaultDashboardInput.dataTestid },
              }}
            />
          </Grid>

          <Grid item {...breakpoints}>
            <AutocompleteElement
              name={favoriteDashboardsInput.name as keyof FormFieldsData}
              label={favoriteDashboardsInput.label}
              options={dashboardOptions}
              multiple
              matchId={true}
              control={control}
              textFieldProps={{
                inputProps: {
                  "data-testid": favoriteDashboardsInput.dataTestid,
                },
              }}
            />
          </Grid>

          <Accordion defaultExpanded sx={{ width: "100%" }}>
            <AccordionSummary className="!p-0">
              Unit Preference
            </AccordionSummary>
            <Grid item {...breakpoints} className="!pt-3">
              <AutocompleteElement
                loading={!distanceUnitsOptions.length}
                rules={{ required: true }}
                matchId={true}
                required
                autocompleteProps={{
                  disableClearable: true,
                }}
                label={distanceUnitInput.label}
                control={control}
                name={distanceUnitInput.name as keyof FormFieldsData}
                options={distanceUnitsOptions}
                textFieldProps={{
                  inputProps: { "data-testid": distanceUnitInput.dataTestid },
                }}
              />
            </Grid>

            <Grid item {...breakpoints}>
              <AutocompleteElement
                loading={!pressureUnitsOptions.length}
                rules={{ required: true }}
                matchId={true}
                required
                autocompleteProps={{
                  disableClearable: true,
                }}
                label={pressureUnitInput.label}
                control={control}
                name={pressureUnitInput.name as keyof FormFieldsData}
                options={pressureUnitsOptions}
                textFieldProps={{
                  inputProps: { "data-testid": pressureUnitInput.dataTestid },
                }}
              />
            </Grid>

            <Grid item {...breakpoints}>
              <AutocompleteElement
                loading={!temperatureUnitsOptions.length}
                rules={{ required: true }}
                matchId={true}
                required
                autocompleteProps={{
                  disableClearable: true,
                }}
                label={temperatureUnitInput.label}
                control={control}
                name={temperatureUnitInput.name as keyof FormFieldsData}
                options={temperatureUnitsOptions}
                textFieldProps={{
                  inputProps: {
                    "data-testid": temperatureUnitInput.dataTestid,
                  },
                }}
              />
            </Grid>
          </Accordion>
        </Grid>
      </form>
    </ThemeProvider>
  );
};

export default OrgForm;
