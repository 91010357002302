import { FC, ReactElement, useState, useMemo } from "react";
import { Box } from "@mui/material";
import { sortBy } from "lodash";
import {
  CartesianGrid,
  ComposedChart,
  Line,
  ReferenceArea,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { TemperatureUnit } from "../../../../../../graphql/operations";
import { useCurrentTheme } from "../../../../../../shared/hooks/theme/useCurrentTheme";
import { getConvertedTemperatureValue } from "../../../../../../utils/convertTemperature";
import {
  checkIsValidDate,
  DATE_FORMAT,
  DAY_MONTH_FORMAT,
  formatDate,
  parseISODate,
} from "../../../../../../utils/date";
import {
  RangeReferenceAreaValueType,
  STATE_TO_COLOR,
  SensorState,
} from "../../../Assets/TiresTabPanel/interfaces";
import BatteryChartLegend from "../../BatteryChart/BatteryChartLegend";
import { TemperatureChartTooltip } from "./TemperatureChartTooltip";
import type { TemperatureData } from "./TemperatureTabChartSection";

export interface TemperatureChartBodyProps {
  data?: TemperatureData[];
  referenceAreaValues: Array<RangeReferenceAreaValueType>;
  tempUnitPreference?: TemperatureUnit;
}

const containerHeight = 630;
const darkColors = ["#3C88CC"];
const lightColors = ["#3C88CC"];

export const TemperatureChartBody: FC<TemperatureChartBodyProps> = ({
  data,
  referenceAreaValues,
  tempUnitPreference,
}) => {
  // Compute reference area elements used to display the sensor profile thresholds behind the lines
  const getReferenceAreasForTemperature = () => {
    // Compute reference area elements. For temperature we have repeated areas.
    const referenceAreas: ReactElement[] = [];
    referenceAreaValues.forEach((value: RangeReferenceAreaValueType) => {
      referenceAreas.push(
        <ReferenceArea
          key={`${value.state}-1`}
          yAxisId="temperature"
          y1={getConvertedTemperatureValue(
            Number(value.min1 ?? 0),
            tempUnitPreference ?? TemperatureUnit.Celsius
          )}
          y2={getConvertedTemperatureValue(
            Number(value.max1 ?? 0),
            tempUnitPreference ?? TemperatureUnit.Celsius
          )}
          fill={STATE_TO_COLOR[value.state as SensorState]}
          fillOpacity={0.8}
          ifOverflow="extendDomain"
          data-testid="temperature-body-reference-area"
        />,
        <ReferenceArea
          key={`${value.state}-2`}
          yAxisId="temperature"
          y1={getConvertedTemperatureValue(
            Number(value.min2 ?? 0),
            tempUnitPreference ?? TemperatureUnit.Celsius
          )}
          y2={getConvertedTemperatureValue(
            Number(value.max2 ?? 0),
            tempUnitPreference ?? TemperatureUnit.Celsius
          )}
          fill={STATE_TO_COLOR[value.state as SensorState]}
          fillOpacity={0.8}
          ifOverflow="extendDomain"
          data-testid="temperature-body-reference-area"
        />
      );
    });
    return referenceAreas;
  };

  const yAxisDomain = useMemo(() => {
    const minDomain =
      tempUnitPreference === TemperatureUnit.Fahrenheit
        ? getConvertedTemperatureValue(0, TemperatureUnit.Fahrenheit)
        : 0;

    return [minDomain, 0];
  }, [tempUnitPreference]);

  const muiTheme = useCurrentTheme();
  const isDarkMode = muiTheme.palette.mode === "dark";
  const chartColors = isDarkMode ? darkColors : lightColors;

  const [hoveredLineIndex, setHoveredLineIndex] = useState<number>();
  const [hiddenTempLines, setHiddenTempLines] = useState<string[]>([]);

  const formatXAxisTicks = (tickItem: number) => {
    return formatDate(new Date(tickItem), DAY_MONTH_FORMAT);
  };

  const allLineKeys = ["median"];

  const visibleLines = allLineKeys.filter(
    (key) => !hiddenTempLines.includes(key)
  );
  const formattedData = useMemo(() => {
    return data
      ?.map((d) => {
        const readingDay = d?.temperature?.readingDay ?? d?.date;
        if (!readingDay) {
          console.error(`Invalid reading day: ${readingDay}`);
          return null;
        }
        const date = parseISODate(readingDay);
        if (!checkIsValidDate(date)) {
          console.error(`Invalid date: ${readingDay}`);
          return null;
        }
        return {
          ...d,
          axisDate: date.getTime(),
          toolTipDate: formatDate(date, DATE_FORMAT),
        };
      })
      .filter(Boolean) as Array<{
      axisDate: number;
      toolTipDate: string;
    }>;
  }, [data]);

  const handleLegendClick = (key: string) => {
    setHiddenTempLines((prevState) =>
      prevState.includes(key)
        ? prevState.filter((k) => k !== key)
        : [...prevState, key]
    );
  };

  const sortedData = useMemo(
    () => sortBy(formattedData, "axisDate"),
    [formattedData]
  );
  const domain: [number, number] = [
    sortedData[0]?.axisDate ?? Number.MIN_VALUE,
    sortedData[sortedData.length - 1]?.axisDate ?? Number.MAX_VALUE,
  ];

  return (
    <>
      <Box
        className="relative !text-xs !font-medium"
        data-testid="temperature-chart-body"
        style={{ height: containerHeight }}
      >
        <ResponsiveContainer width="100%" height="100%">
          <ComposedChart
            data={sortedData}
            margin={{ top: 5, right: 0, left: 0, bottom: 5 }}
          >
            <CartesianGrid stroke="#f5f5f5" />
            <XAxis
              dataKey="axisDate"
              tickFormatter={formatXAxisTicks}
              scale="time"
              type="number"
              stroke="#808080"
              domain={domain}
            />
            <YAxis
              label={{
                value: "Temperature",
                angle: -90,
                position: "insideLeft",
              }}
              domain={yAxisDomain}
              type={"number"}
              stroke="#808080"
              yAxisId={"temperature"}
            />
            {getReferenceAreasForTemperature()}
            <Tooltip
              content={
                <TemperatureChartTooltip activeIndex={hoveredLineIndex} />
              }
            />

            {visibleLines.map((key, index) =>
              hiddenTempLines.includes(key) ? null : (
                <Line
                  key={key}
                  strokeWidth={4}
                  type="linear"
                  yAxisId={"temperature"}
                  dataKey={`temperature.${key}`}
                  stroke={chartColors[index]}
                  connectNulls
                  dot={false}
                  activeDot={{
                    onMouseOver: () => {
                      setHoveredLineIndex(
                        visibleLines.findIndex((k) => k === key)
                      );
                    },
                    onMouseOut: () => setHoveredLineIndex(undefined),
                  }}
                />
              )
            )}
          </ComposedChart>
        </ResponsiveContainer>
      </Box>

      {allLineKeys.length > 1 && (
        <Box className="mb-12 flex justify-center gap-8">
          {allLineKeys.map((key, index) => (
            <BatteryChartLegend
              key={key}
              label={key.charAt(0).toUpperCase() + key.slice(1)}
              checked={!hiddenTempLines.includes(key)}
              color={chartColors[index]}
              onClick={() => handleLegendClick(key)}
            />
          ))}
        </Box>
      )}
    </>
  );
};
